import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { noop } from '@scinet-inc/utils';
import { ActorResponse } from '../lib';
import {
  license_nft_metadata_v1_types,
  license_nft_v1_types,
} from '@scinet-inc/api';
import { useAppContext, useUserContext } from '../components';
import { useRouter } from 'next/router';

interface LicensesContextProps {
  addLicense: (licenseId: string) => void;
  getLicensesForProject: (projectId: string) => void;
  getLicenseMetadata: (
    licenseId: string
  ) => Promise<void | license_nft_metadata_v1_types.LicenseMeta | undefined>;
  licenses: license_nft_v1_types.LicenseId[];
  isLoading: boolean;
  selectedLicenseId: string;
}

export type LicensesActionType =
  | 'ADD_LICENSE'
  | 'RESET_LICENSES'
  | 'SET_SELECTED_LICENSE'
  | 'SET_ERROR'
  | 'SET_IS_LOADING'
  | 'SET_LICENSES';

export const LicensesContext = createContext<LicensesContextProps>({
  addLicense: noop,
  getLicensesForProject: noop,
  getLicenseMetadata: async () => undefined,
  licenses: [],
  isLoading: false,
  selectedLicenseId: '',
});

const LicensesReducer = (
  state: any,
  action: { type: LicensesActionType; payload?: any }
) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_LICENSES':
      return {
        ...state,
        licenses: payload,
        isLoading: false,
        selectedLicenseId: undefined,
      };
    case 'RESET_LICENSES':
      return {
        ...state,
        licenses: [],
        isLoading: false,
      };
    case 'SET_SELECTED_LICENSE':
      return {
        ...state,
        selectedLicenseId: payload,
        isLoading: false,
        error: '',
      };
    case 'ADD_LICENSE':
      return {
        ...state,
        selectedLicenseId: payload,
        licenses: [...state.licenses, payload],
      };
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const initialContext = {
  error: '',
  isLoading: false,
  licenses: [],
  selectedLicense: '',
};

// this context is for storing which org the logged-in user belongs to
export const LicensesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { pathname, query } = useRouter();
  const [state, dispatch] = useReducer(LicensesReducer, initialContext);
  const { licenseNFTActor, licenseNFTMetadataActor } = useAppContext();
  const { user } = useUserContext();
  const setLicenses = (licenses: license_nft_v1_types.LicenseId[]) =>
    dispatch({ type: 'SET_LICENSES', payload: licenses });
  const setIsLoading = (isLoading: boolean) =>
    dispatch({ type: 'SET_IS_LOADING', payload: isLoading });
  // const setSelectedLicense = (licenseId: string) =>
  //   dispatch({ type: 'SET_SELECTED_LICENSE', payload: licenseId });
  const addLicense = (licenseId: string) =>
    dispatch({ type: 'ADD_LICENSE', payload: licenseId });

  const getLicensesForProject = async (projectId: string) => {
    setIsLoading(true);
    licenseNFTActor
      ?.getLicenseIdsByProjectId(projectId)
      .then((result: ActorResponse) => {
        if ('ok' in result) {
          const licenses = result.ok;
          if (licenses.length) {
            setLicenses(licenses);
          }
        }
        setIsLoading(false);
      });
  };

  const getLicenseMetadata = async (licenseId: string) => {
    licenseNFTMetadataActor?.get(licenseId).then((result: ActorResponse) => {
      if ('ok' in result) {
        return result.ok;
      } else {
        return undefined;
      }
    });
  };

  useEffect(() => {
    if (
      pathname.includes('project') &&
      query.id &&
      licenseNFTActor !== undefined &&
      user
    ) {
      setLicenses([]);
      getLicensesForProject(query.id as string);
    }
  }, [licenseNFTActor, pathname, query.id, user]);

  const { licenses, isLoading, selectedLicenseId } = state;
  return (
    <LicensesContext.Provider
      value={{
        addLicense,
        getLicensesForProject,
        getLicenseMetadata,
        licenses,
        isLoading,
        selectedLicenseId,
      }}>
      {children}
    </LicensesContext.Provider>
  );
};

export function useLicensesContext() {
  return useContext(LicensesContext);
}
