import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import {
  useWizardFormContext,
  WizardNavigation,
  Button,
  IpFileUploader,
  FilesContainer,
} from 'components';
import { Dialog, Transition } from '@headlessui/react';
import { useIPFiles } from '../../../../lib';

const ipDataExamples = [
  'Anything listed in the Resources list that is private in your case',
  'Detailed Methodologies and Protocols',
  'Full Data (unpublished detailed results, from all stages of research)',
  'Proprietary Bioinformatics Algorithms and Datasets',
  'Manufacturing processes',
  'Intellectual Property Portfolio (Detailed information on scope and status of IP, including pending patents, trade secrets, and other IP assets that are not publicly disclosed)',
  'Regulatory Strategy and Correspondence (Detailed regulatory strategy plans, timelines, and any correspondence with regulatory bodies that reveal strategic insights or regulatory hurdles and their resolutions.)',
  'Financial Projections and Business Plan (if you have already commercialized, or plan to)',
  'Detailed Market Analysis (if you have already commercialized, or plan to)',
  'Technical appendices',
  'Photos, videos, other demonstratives',
];

const IPDataDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => (
  <Transition.Root show={isOpen} as={Fragment}>
    <Dialog onClose={onClose} className="relative z-50">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div className="fixed backdrop-blur-sm inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex h-full items-center justify-center p-4 text-center sm:min-h-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <Dialog.Panel className="mx-auto max-w-lg rounded bg-white ">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={onClose}>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <Dialog.Title className="text-xl text-left font-medium m-4">
                Upload IP Data
              </Dialog.Title>
              <div className="text-lg font-light m-4 text-left">
                <div className="flex text-rhino-900 font-bold text-sm">
                  What to include in IP NFT
                </div>
                <ul className="list-disc ml-4 mt-4">
                  {ipDataExamples.map((example, index) => (
                    <li key={index} className="text-sm font-rhino-900">
                      {example}
                    </li>
                  ))}
                </ul>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);

const IPData = () => {
  const { isLoading, client } = useIPFiles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initFileList, setInitFileList] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);
  const { nextStep } = useWizardFormContext();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    nextStep();
  };

  useEffect(() => {
    if (client && !initFileList && !files.length && !isLoading) {
      const getFiles = async () => {
        const files = await client.list();
        setFiles(files);
      };
      setInitFileList(true);
      getFiles();
    }
  }, [client, isLoading, files, initFileList]);

  const addFile = (file: any) => {
    setFiles((prevFiles) => [file, ...prevFiles]);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mx-auto max-w-3xl">
          <div className="p-4">
            <div className="w-full flex items-center justify-between py-2 mt-2">
              <span className="text-deep-sapphire-900 font-sans text-lg font-bold">
                Upload IP Data
              </span>
              <Button color="tertiary" onClick={() => setIsModalOpen(true)}>
                What is it for?
              </Button>
            </div>
            <IpFileUploader addFile={addFile} useFiles={useIPFiles} />
            <FilesContainer
              client={client}
              files={files}
              setFiles={setFiles}
              canDelete
            />
            <div className="w-full flex items-center justify-center py-2">
              <WizardNavigation disablePrimaryButton={isLoading} />
            </div>
          </div>
        </div>
      </form>
      <IPDataDialog
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default IPData;
