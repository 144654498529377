import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { BiErrorCircle } from 'react-icons/bi';
import { Notification, Typography } from '../..';
import { projects_types } from '@scinet-inc/api/';

type Props = {
  project: any;
  toggleModal: () => void;
  isModalOpen: boolean;
  deleteProject?: (id: string) => any | Promise<projects_types.Result_2>;
  routerPush: (url: any, options?: any) => Promise<boolean>;
};

export default function DeleteProjectConfirmation({
  deleteProject,
  routerPush,
  project,
  toggleModal,
  isModalOpen,
}: Props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [notification, setNotification] = useState<null | { success: boolean }>(
    null,
  );
  const handleReset = () => setNotification(null);

  const onSubmit = async () => {
    setIsLoading(true);

    if (deleteProject)
      await deleteProject(project.id).then(async (res: any) => {
        if ('ok' in res) {
          routerPush('/projects');
          toggleModal();
          setNotification({ success: true });
        } else {
          setErrorMessage(
            'There was an error deleting your project. Please try again later.',
          );
          setNotification({ success: false });
        }
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={isModalOpen} onClose={toggleModal} className="relative z-50">
      {notification && (
        <Notification status={notification} onReset={handleReset} />
      )}
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-lg rounded bg-white ">
          <Dialog.Title className="text-xl font-medium m-4">
            Delete Project
          </Dialog.Title>
          <div className="h-px w-full bg-black mb-8"></div>
          <Dialog.Description className="text-lg font-light m-4">
            Are you sure you want to delete project: {project.title}?
          </Dialog.Description>
          <div className="flex flex-col-reverse lg:flex-row">
            <button
              disabled={isLoading}
              className="max-w-[95%] m-4 px-4 py-2 border rounded hover:bg-purple-400 lg:w-[45%]"
              onClick={() => toggleModal()}>
              Cancel
            </button>
            <button
              disabled={isLoading}
              className="max-w-[95%] mx-4 lg:m-4 px-4 py-2 border rounded bg-purple-700 hover:bg-purple-400 text-white lg:w-[45%]"
              onClick={onSubmit}>
              Delete Project
            </button>
          </div>
          {errorMessage ? (
            <div className="flex flex-row pt-1">
              <BiErrorCircle color="red" />
              <Typography className="text-xs text-gray-400 pl-1">
                {errorMessage}
              </Typography>
            </div>
          ) : (
            <div className="h-5"></div>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
