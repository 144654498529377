import { SyntheticEvent, useState } from 'react';
import ProjectTabs from './ProjectTabs';
import ProjectTabsContent from './ProjectTabsContent';
import { PROJECT_TABS } from './types';
import { Project } from 'scinet-types';

type Props = {
  project: Project;
  menuItemSelected: string;
};

export default function AboutProject({ project, menuItemSelected }: Props) {
  const [tabSelected, setTabSelected] = useState(0);

  const handleTabChange = (event: SyntheticEvent, newValue: number) =>
    setTabSelected(newValue);

  return (
    <div className="max-w-full mr-0 w-full md:max-w-[70%] h-auto border border-rhino-200 mt-4 rounded-lg md:mr-4 p-4 bg-white">
      {menuItemSelected === PROJECT_TABS.OVERVIEW && (
        <ProjectTabs onChange={handleTabChange} selected={tabSelected} />
      )}

      <ProjectTabsContent project={project} selected={tabSelected} />
    </div>
  );
}
