import { PlusIcon } from '@heroicons/react/24/solid';
import { users_types } from '@scinet-inc/api';
import { Avatar, Typography } from 'components';

type Props = {
  user: users_types.User;
  onClick: (userId: string) => void;
};

export default function UserOption({ user, onClick }: Props) {
  const { id, email, firstName, lastName } = user;
  return (
    <div
      onClick={() => onClick(id)}
      className="w-full flex flex-row cursor-pointer justify-between align-middle p-4 hover:bg-purple-200">
      <div className="flex flex-col">
        <Typography className="font-semibold">{`${firstName} ${lastName}`}</Typography>
        <Typography className="text-gray-500 text-xs">{email}</Typography>
      </div>
      <PlusIcon className="h-4 w-4 mt-3" />
    </div>
  );
}
