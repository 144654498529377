import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuthentication } from 'components';
import { getUserOrg } from '../../lib';
import { noop } from '@scinet-inc/utils';

interface OrganizationsTeamsContextProps {
  fetchOrgId: () => void;
  organizationId: string | undefined;
}

export const OrganizationsTeamsContext =
  createContext<OrganizationsTeamsContextProps>({
    fetchOrgId: noop,
    organizationId: '',
  });

// this context is for storing which org the logged-in user belongs to
export const OrganizationsTeamsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [organizationId, setOrganizationId] = useState<string | undefined>(
    undefined
  );
  const { identity } = useAuthentication();

  const fetchOrgId = async () => {
    try {
      const { organizationId, error } = await getUserOrg(identity);
      if (organizationId) {
        return setOrganizationId(organizationId);
      } else {
        // console.error('Error fetching user org id:', error);
      }
    } catch (error) {
      // console.error('in catch Error fetching user org id:', error);
    }
  };

  useEffect(() => {
    if (!identity && organizationId) {
      return setOrganizationId('');
    }

    if (identity && !organizationId) {
      fetchOrgId();
    }
  }, [organizationId, identity]);

  return (
    <OrganizationsTeamsContext.Provider value={{ fetchOrgId, organizationId }}>
      {children}
    </OrganizationsTeamsContext.Provider>
  );
};

export function useOrganizationsTeamsContext() {
  return useContext(OrganizationsTeamsContext);
}
