export const patentStatus = [
  'Pre-patent',
  'Under Review',
  'Rejected',
  'Granted',
  'Provisional',
  'Not applicable',
];

export const projectStatuses = ['draft', 'published'];
