import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  Button,
  LoginButton,
  OrgCard,
  Spinner,
  Typography,
  useAuthentication,
  useOrgContext,
} from 'components';
import { useAppContext } from '../../AppContext';
import { useUserContext } from '../..';
import SingleOrg from '../SingleOrg';
import { useOrganizationsTeamsContext } from '../OrganizationsTeamsContext';
import { EmailVerificationRequiredModal } from '../../../lib';
import { noop } from '@scinet-inc/utils';

export default function OrgsPage() {
  const { query, push } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const { orgActor, orgTeamActor } = useAppContext();
  const { org, resetOrg } = useOrgContext();
  const { emailVerified, user, isUserLoading } = useUserContext();
  const { isAuthing } = useAuthentication();
  const { organizationId } = useOrganizationsTeamsContext();
  const [fullUserOrg, setFullUserOrg] = useState(null);
  const [getUserOrgCalled, setGetUserOrgCalled] = useState(false);
  const [
    showEmailVerificationRequiredModal,
    setShowEmailVerificationRequiredModal,
  ] = useState(false);

  const getUserFullOrg = async (orgId: string) => {
    setIsLoading(true);
    orgActor?.getOrg(orgId).then(async (getResponse: any) => {
      if ('ok' in getResponse) {
        setGetUserOrgCalled(true);
        setFullUserOrg(getResponse['ok']);
      } else {
        console.error('failure', getResponse);
      }
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (!query.id && org.info.id) {
      resetOrg();
    }
  });

  useEffect(() => {
    if (organizationId && !getUserOrgCalled) {
      getUserFullOrg(organizationId);
    }
  });

  const createOrg = () => {
    if (!emailVerified?.emailVerified) {
      return setShowEmailVerificationRequiredModal(true);
    }

    resetOrg();
    push('/organizations/new');
  };

  if (isLoading || isUserLoading || isAuthing) {
    return <Spinner />;
  }

  if (query.id && user) {
    return <SingleOrg />;
  }

  return (
    <div>
      {user ? (
        <div>
          <div className="flex flex-row justify-between md:flex-col">
            <Typography className="font-lg font-semibold">
              Organizations
            </Typography>
            <Button
              className="md:w-32"
              onClick={createOrg}
              disabled={!user || isLoading || !!organizationId}>
              Create New
            </Button>
          </div>
          {fullUserOrg && (
            <div>
              <Typography>Your Organization</Typography>
              <OrgCard
                deleteOrg={noop}
                user={user}
                organization={fullUserOrg}
                orgTeamActor={orgTeamActor}
                routerPush={push}
                hideActions
              />
            </div>
          )}
          <EmailVerificationRequiredModal
            isModalOpen={showEmailVerificationRequiredModal}
            onClose={() => setShowEmailVerificationRequiredModal(false)}
          />
        </div>
      ) : (
        <div className="flex flex-col m-auto w-full text-center justify-center items-center mt-4">
          <Typography>
            Please log in to view or create organizations.
          </Typography>
          <LoginButton />
        </div>
      )}
    </div>
  );
}
