import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { inquiries_types, organizations_teams_types } from '@scinet-inc/api';
import { Button, TextArea, Typography, useProjectContext } from 'components';
import { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ulid } from 'ulidx';
import { sendNewInquiryEmail } from '../../lib/utils/inquiries';
import { useAppContext } from '../AppContext';
import { useOrganizationsTeamsContext } from '../Organizations';
import { useUserContext } from '../User';

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
};

const InquirySubmitted = ({ onClose }: { onClose: () => void }) => {
  return (
    <div>
      <Typography className="font-semibold mx-auto pt-2 text-xl lg:text-2xl text-center">
        Inquiry Submitted
      </Typography>
      <Typography className="text-center mt-4">
        Expect contact from an administrator of the project. If inquiry is
        approved, IP information will be available to be previewed.
      </Typography>
      <Button color="primary" className="w-full mt-4" onClick={() => onClose()}>
        {'Done'}
      </Button>
    </div>
  );
};

export const CreateInquiry = ({ isModalOpen, onClose }: Props) => {
  const { orgTeamActor } = useAppContext();
  const { project } = useProjectContext();
  const { user, actor } = useUserContext();
  const { organizationId: inquiringOrganizationId } =
    useOrganizationsTeamsContext();
  const { inquiriesActor } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [inquiryCreated, setInquiryCreated] = useState(false);
  const [receiverEmail, setReceiverEmail] = useState<string>('');

  const inquiriesForm =
    useForm<Partial<inquiries_types.CreateInquiryRequest>>();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = inquiriesForm;

  const onSubmit = (data: any) => {
    setSubmissionError(null);

    setIsLoading(true);
    const newInquiry = {
      id: ulid(),
      inquirerId: user?.id!,
      inquiringOrganizationId: inquiringOrganizationId!,
      message: data.message,
      organizationId: project.info.organizationId,
      projectId: project.info.id,
      status: 'pending',
    };

    inquiriesActor?.create(newInquiry).then(async (createResponse: any) => {
      if ('ok' in createResponse) {
        setInquiryCreated(true);
        if (receiverEmail) {
          sendNewInquiryEmail(receiverEmail, project.info);
        }
      } else if ('BadRequest' in createResponse.err) {
        setSubmissionError('An inquiry has already been submitted.');
      }
      setIsLoading(false);
    });
  };

  const getReceiverEmail = async (orgId: string) => {
    await orgTeamActor?.read(orgId).then((result: any) => {
      if ('ok' in result) {
        const owner = result.ok.members.find(
          (m: organizations_teams_types.OrgMember) => m.role === 'owner'
        );
        actor?.read(owner.id).then((result: any) => {
          if ('ok' in result) {
            setReceiverEmail(result.ok.email);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (!receiverEmail && orgTeamActor && project.info.organizationId) {
      getReceiverEmail(project.info.organizationId);
    }
  }, [project.info.organizationId]);

  if (!user?.id || !inquiringOrganizationId) {
    return null;
  }

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog onClose={onClose} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed backdrop-blur-sm inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto md:p-5 flex">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <Dialog.Panel
              className={`${
                !inquiryCreated ? 'h-full' : ''
              } mx-auto max-w-lg rounded bg-white p-5 relative flex flex-col mt-auto md:m-auto`}>
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="rounded-md bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={onClose}>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {inquiryCreated ? (
                <InquirySubmitted onClose={onClose} />
              ) : (
                <>
                  <Typography className="font-semibold mx-auto pt-2 text-xl lg:text-2xl">
                    Project Inquiry
                  </Typography>
                  <div className="h-[300px] w-full bg-cover bg-no-repeat bg-[url(/mobile-hero.png)] lg:h-[100vh] mt-5 mb-5" />
                  <Typography className="text-center">
                    Interested in licensing this intellectual property? Submit
                    an inquiry to the project owner to learn more about their
                    project.
                  </Typography>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col flex-1">
                    <div>
                      <Controller
                        name="message"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Message is required',
                          },
                          maxLength: {
                            value: 300,
                            message: 'Message is too long',
                          },
                        }}
                        render={({ field }) => (
                          <TextArea
                            value={field.value || ''}
                            onChange={field.onChange}
                            ref={field.ref}
                            label="Message"
                            error={errors.message?.message}
                            maxLength={300}
                          />
                        )}
                      />
                    </div>
                    {submissionError && (
                      <div className="w-full text-center">
                        <Typography className="m-auto text-red-600 ">
                          {submissionError}
                        </Typography>
                      </div>
                    )}
                    <div className="w-full mt-auto">
                      <Button
                        type="submit"
                        className="w-full"
                        color={isLoading ? 'secondary' : 'primary'}
                        loading={isLoading ? true : false}>
                        {isLoading ? 'Saving...' : 'Submit Inquiry'}
                      </Button>
                      <Button
                        color={isLoading ? 'tertiary' : 'secondary'}
                        className="w-full mb-2 mt-4"
                        onClick={onClose}>
                        {'Cancel'}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
