import { useMemo } from 'react';
import { inquiries_types } from '@scinet-inc/api';
import { useWhiteLists } from '../../lib/hooks';
import { InquiryCard } from './InquiryCard';

export default function InquiriesList({
  inquiries,
  projectId,
  tabSelected,
  userBelongsToProjectOrg,
  userOrgId,
}: {
  inquiries: inquiries_types.Inquiry[];
  projectId: string;
  tabSelected: number;
  userBelongsToProjectOrg: boolean;
  userOrgId?: string;
}) {
  const { getWhiteList, whiteList } = useWhiteLists(projectId);

  const sortedInquiries: inquiries_types.Inquiry[] = useMemo(() => {
    let filteredInquiries: inquiries_types.Inquiry[] = inquiries;
    if (!userBelongsToProjectOrg)
      return inquiries.filter(
        (inquiry) => inquiry.inquiringOrganizationId === userOrgId
      );
    if (tabSelected === 1) {
      filteredInquiries = inquiries.filter(
        (inquiry) => inquiry.status === 'approved'
      );
    } else if (tabSelected === 2) {
      filteredInquiries = inquiries.filter(
        (inquiry) => inquiry.status === 'rejected'
      );
    }

    return filteredInquiries?.sort((a, b) =>
      a.updatedAt < b.updatedAt ? 1 : -1
    );
  }, [inquiries, whiteList, tabSelected, userOrgId, userBelongsToProjectOrg]);

  if (!inquiries) {
    return null;
  }

  return (
    <div>
      {sortedInquiries.map((inquiry, index: number) => (
        <InquiryCard
          inquiry={inquiry}
          key={index}
          index={index}
          whiteList={whiteList}
          userBelongsToProjectOrg={userBelongsToProjectOrg}
          getWhiteList={getWhiteList}
        />
      ))}
    </div>
  );
}
