import { useEffect, useState } from 'react';
import { Organization, Project } from 'scinet-types';
import { OrgListItem } from '../../Organizations';
import { IpFileList } from './IpFileList';
import { IpNftActions } from './IpNftActions';
import { IpNftCard } from './IpNftCard';
import { IpUploadModal } from './IpUploadModal';

type Props = {
  ipNftTokenId: number;
  HOST: string;
  project: Project;
  fetchFullOrg: (id: string) => Promise<Organization>;
  push: (url: any, options?: any) => Promise<boolean>;
  isAdmin: boolean;
  isOnWhiteList: boolean;
  useIPFiles: () => { isLoading: boolean; client: any };
};

const IP_NFT_METADATA_V1_CANISTER_ID =
  process.env.NEXT_PUBLIC_IP_NFT_METADATA_V1_CANISTER_ID;

export const IpNftPage = ({
  ipNftTokenId,
  HOST,
  project,
  fetchFullOrg,
  push,
  isAdmin,
  useIPFiles,
  isOnWhiteList,
}: Props) => {
  const urlBase = HOST?.includes('localhost')
    ? 'http://localhost:3002'
    : 'https://2zqmv-riaaa-aaaap-aa4yq-cai.icp0.io';

  const metadataHref = `${urlBase}/ip-nfts/${IP_NFT_METADATA_V1_CANISTER_ID}/tokens/${project.info.id}`;

  const { isLoading, client } = useIPFiles();
  const [files, setFiles] = useState<any[]>([]);
  const [init, setInit] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (
      (isAdmin || isOnWhiteList) &&
      client &&
      files.length < 1 &&
      project.info.id &&
      !isLoading &&
      !init
    ) {
      const getFiles = async () => {
        const files = await client.list();
        setFiles(files);
      };
      setInit(true);
      getFiles();
    }
  }, [isAdmin, client, isLoading, files, isOnWhiteList, project.info.id, init]);

  const removeFile = async (file: any) => {
    setFiles(files.filter((f) => f.filename !== file.filename));
    await client?.delete(file.filename);
  };

  const downloadFile = async (file: any) => {
    await client?.download(file.filename);
  };

  const addFile = (file: any) => {
    setFiles((prevFiles) => [file, ...prevFiles]);
  };

  return (
    <>
      <div className="pt-0 pl-0 w-full">
        <div className="flex flex-col md:flex-row justify-center md:justify-start">
          <div className="flex flex-col p-5 bg-secondary min-w-345 mb-4 md:mr-4 md:mb-0">
            {ipNftTokenId && (
              <IpNftCard tokenNumber={ipNftTokenId} project={project} />
            )}
            <IpNftActions
              isAdmin={isAdmin}
              project={project}
              push={push}
              metadataHref={metadataHref}
              openUploadModal={() => setIsModalOpen(true)}
            />
            <OrgListItem
              orgId={project.info.organizationId}
              fetchFullOrg={fetchFullOrg}
              push={push}
            />
          </div>
          <div className="flex flex-col md:flex-row justify-center md:justify-start p-5 w-full bg-secondary md:mr-8">
            <IpFileList
              downloadFile={downloadFile}
              removeFile={removeFile}
              canDelete={isAdmin}
              files={files}
            />
          </div>
        </div>
      </div>
      <IpUploadModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        addFile={addFile}
        useIPFiles={useIPFiles}
      />
    </>
  );
};
