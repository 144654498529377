import { createContext, useContext, useReducer } from 'react';
import { noop } from '@scinet-inc/utils';
import { CircleCheckIcon, CircleXIcon, Typography } from 'components';

type Toast = { message: string; variant: 'success' | 'error' };

type Context = {
  toast: { message: string; variant: string } | undefined;
  showToast: (toast: Toast) => void;
};

type Action =
  | {
      type: 'show-toast';
      message: string;
      variant: 'success' | 'error';
    }
  | { type: 'hide-toast' };

const initialContext: Context = {
  toast: undefined,
  showToast: noop,
};

export const ToastContext = createContext(initialContext);

export const ToastContextProvider = ({ children }: any) => {
  const reducer = (state: any, action: Action) => {
    switch (action.type) {
      case 'show-toast':
        return {
          ...state,
          message: action.message,
          variant: action.variant,
        };
      case 'hide-toast':
        return {};
      default:
        return {
          ...state,
        };
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    toast: {},
  });

  const showToast = (toast: Toast) => {
    dispatch({
      type: 'show-toast',
      message: toast.message,
      variant: toast.variant,
    });
    // Hide the toast after a 3 seconds delay.
    setTimeout(() => {
      dispatch({
        type: 'hide-toast',
      });
    }, 3000);
  };

  const hideToast = () => {
    dispatch({
      type: 'hide-toast',
    });
  };

  return (
    <ToastContext.Provider value={{ ...state, showToast, hideToast }}>
      {children}

      <div
        className={`${
          !state.message && 'hidden'
        } flex justify-center md:justify-end`}>
        <div
          onClick={() => hideToast()}
          className="fixed flex flex-row  items-center bottom-4 w-[90vw] md:w-auto m-auto md:right-4 px-4 py-2 rounded-lg shadow-lg z-50 bg-[#001232] hover:cursor-pointer">
          {state.variant === 'error' && (
            <CircleXIcon
              className="h-6 w-6 mr-2 text-red-600"
              color="#001232"
            />
          )}
          {state.variant === 'success' && (
            <CircleCheckIcon
              className="h-6 w-6 mr-2 text-[#BBFF00]"
              color="#001232"
            />
          )}
          <Typography className="text-sm text-gray-100">
            {state.message}
          </Typography>
        </div>
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = (): Context => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
