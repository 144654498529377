import { useIsMobile } from '@scinet-inc/hooks';
import cx from 'classnames';

const tabs = ['All', 'Approved', 'Rejected'];

function a11yProps(index: number) {
  return {
    id: `inquiries-tab-${index}`,
    'aria-controls': `inquiries-tabpanel-${index}`,
  };
}

export default function InquiriesTabs({
  onChange,
  selected,
}: {
  onChange: (newValue: number) => void;
  selected: number;
}) {
  const { isMobile } = useIsMobile();

  return (
    <div
      className={cx('flex flex-row', {
        ['bg-white py-3 justify-between w-full px-2']: isMobile,
      })}>
      {tabs.map((tab, index) => (
        <div
          onClick={() => onChange(index)}
          key={`${tab}-${index}`}
          className={cx(
            'text-gray-600 cursor-pointer text-center w-1/3 lg:mr-4',
            {
              'text-purple-600 border-b-2 border-purple-600':
                selected === index,
              'text-xs mr-0': isMobile,
            }
          )}
          {...a11yProps(index)}>
          {tab}
        </div>
      ))}
    </div>
  );
}
