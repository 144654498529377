import React from 'react';

export default function IPNFTIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="48px"
      height="48px">
      <g transform="matrix(2,0,0,2,0,0)">
        <g>
          <path
            d="M7,12.71v2.58a.64.64,0,0,0,.64.64H9.57a.5.5,0,0,1,.5.5v1.93a.64.64,0,0,0,.64.64h2.58a.64.64,0,0,0,.64-.64V16.43a.5.5,0,0,1,.5-.5h1.93a.64.64,0,0,0,.64-.64V12.71a.64.64,0,0,0-.64-.64H14.43a.5.5,0,0,1-.5-.5V9.64A.64.64,0,0,0,13.29,9H10.71a.64.64,0,0,0-.64.64v1.93a.5.5,0,0,1-.5.5H7.64A.64.64,0,0,0,7,12.71Z"
            fill="#000000"></path>
          <path
            d="M19.5,3H16.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H19a.5.5,0,0,1,.5.5v16a.5.5,0,0,1-.5.5H5a.5.5,0,0,1-.5-.5V5.5A.5.5,0,0,1,5,5H7.25a.25.25,0,0,0,.25-.25V3.25A.25.25,0,0,0,7.25,3H4.5a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2h15a2,2,0,0,0,2-2V5A2,2,0,0,0,19.5,3Z"
            fill="#000000"></path>
          <path
            d="M10,6.5h4a1,1,0,0,0,1-1V3A3,3,0,0,0,13.82.62,3,3,0,0,0,9,3.09V5.5A1,1,0,0,0,10,6.5ZM12,2a.75.75,0,1,1-.75.75A.76.76,0,0,1,12,2Z"
            fill="#000000"></path>
        </g>
      </g>
    </svg>
  );
}
