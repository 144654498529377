import React from 'react';

export default function PreferredTokenIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="48px"
      height="48px">
      <g transform="matrix(2,0,0,2,0,0)">
        <g>
          <path
            d="M4,18a1,1,0,0,0,1-1V15.25A.25.25,0,0,1,5.25,15h5.5a.25.25,0,0,1,.25.25V17a1,1,0,0,0,2,0V15.25a.25.25,0,0,1,.25-.25h5.5a.25.25,0,0,1,.25.25V17a1,1,0,0,0,2,0V14.5A1.5,1.5,0,0,0,19.5,13H4.5A1.5,1.5,0,0,0,3,14.5V17A1,1,0,0,0,4,18Z"
            fill="#000000"></path>
          <path
            d="M12,0a6,6,0,1,0,6,6A6,6,0,0,0,12,0ZM10.83,5.25h2.34a1.92,1.92,0,0,1,0,3.83H13a.25.25,0,0,0-.25.25V9.5a.75.75,0,0,1-1.5,0V9.33A.25.25,0,0,0,11,9.08H9.67a.75.75,0,0,1,0-1.5h3.5a.41.41,0,0,0,.41-.41.41.41,0,0,0-.41-.42H10.83a1.92,1.92,0,0,1,0-3.83H11a.25.25,0,0,0,.25-.25V2.5a.75.75,0,0,1,1.5,0v.17a.25.25,0,0,0,.25.25h1.33a.75.75,0,0,1,0,1.5h-3.5a.41.41,0,0,0-.41.41A.41.41,0,0,0,10.83,5.25Z"
            fill="#000000"></path>
          <circle cx="4" cy="21.5" r="2.5" fill="#000000"></circle>
          <rect
            x="9.5"
            y="19"
            width="5"
            height="5"
            rx="0.5"
            fill="#000000"></rect>
          <path
            d="M20,19a.56.56,0,0,0-.51.32l-1.93,3.85a.56.56,0,0,0,0,.56.55.55,0,0,0,.48.27h3.86a.55.55,0,0,0,.48-.27.56.56,0,0,0,0-.56l-1.93-3.85A.56.56,0,0,0,20,19Z"
            fill="#000000"></path>
        </g>
      </g>
    </svg>
  );
}
