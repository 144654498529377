import cx from 'classnames';

type Props = {
  className?: string;
};

export const RightArrowIcon = ({ className }: Props) => {
  return (
    <svg
      className={cx('h-6 text-white', className)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8751 18.75C13.5751 18.75 13.3585 18.6167 13.2251 18.35C13.0918 18.0833 13.1085 17.825 13.2751 17.575L17.2751 12L13.2751 6.425C13.1085 6.175 13.0918 5.91667 13.2251 5.65C13.3585 5.38333 13.5751 5.25 13.8751 5.25C14.0251 5.25 14.1545 5.28333 14.2631 5.35C14.3711 5.41667 14.4668 5.5 14.5501 5.6L18.7251 11.475C18.7751 11.5583 18.8168 11.6417 18.8501 11.725C18.8835 11.8083 18.9001 11.9 18.9001 12C18.9001 12.1 18.8835 12.1873 18.8501 12.262C18.8168 12.3373 18.7751 12.425 18.7251 12.525L14.5501 18.4C14.4668 18.5 14.3711 18.5833 14.2631 18.65C14.1545 18.7167 14.0251 18.75 13.8751 18.75ZM8.02512 18.75C7.70845 18.75 7.48345 18.6167 7.35012 18.35C7.21679 18.0833 7.23345 17.825 7.40012 17.575L11.4001 12L7.40012 6.425C7.23345 6.175 7.21679 5.91667 7.35012 5.65C7.48345 5.38333 7.70845 5.25 8.02512 5.25C8.15845 5.25 8.27912 5.28333 8.38712 5.35C8.49579 5.41667 8.59179 5.5 8.67512 5.6L12.8501 11.475C12.9001 11.5583 12.9418 11.6417 12.9751 11.725C13.0085 11.8083 13.0251 11.9 13.0251 12C13.0251 12.1 13.0085 12.1917 12.9751 12.275C12.9418 12.3583 12.9001 12.4417 12.8501 12.525L8.67512 18.4C8.59179 18.5 8.49579 18.5833 8.38712 18.65C8.27912 18.7167 8.15845 18.75 8.02512 18.75Z"
        fill="white"
      />
    </svg>
  );
};
