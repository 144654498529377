import { useEffect, useMemo, useState } from 'react';
import { users_types, projects_types } from '@scinet-inc/api';
import { OrganizationRoles, FullProject } from '../../types';
import { Avatar, Button, Card, ProjectCardHeader, Tags } from '../../';
import { noop } from '@scinet-inc/utils';

type Props = {
  projectBase: projects_types.ProjectInfo;
  getProject: (projectId: string) => Promise<FullProject>;
  children?: any;
  deleteProject: (
    id: string,
  ) => any | undefined | Promise<projects_types.Result>;
  user?: users_types.User | any;
  hideActions?: boolean;
  resetFetchAndPush: (projectId: string) => void;
  getIsAuthorized: (
    userId: string,
    organizationId: string,
    desiredRole: OrganizationRoles,
  ) => Promise<boolean>;
  getIpNft: (projectId: string) => Promise<number | null>;
  onClick: () => void;
  push: any;
  filesServiceUrl: string;
};

const determineWidth = (teamLength: number) => {
  if (teamLength === 1) {
    return 49;
  }
  return teamLength * 27.5;
};

export const ProjectCard = ({
  projectBase,
  children,
  deleteProject,
  getProject,
  user,
  hideActions,
  resetFetchAndPush,
  getIsAuthorized,
  onClick,
  getIpNft,
  push,
  filesServiceUrl,
}: Props) => {
  const [fullProject, setFullProject] = useState<FullProject | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuthorizedCalled, setIsAuthorizedCalled] = useState(false);
  const [cta, setCTA] = useState({ ctaText: '', ctaOnClick: noop });
  const [team, setTeam] = useState<string[]>([]);

  const headerNode = fullProject && (
    <ProjectCardHeader
      resetFetchAndPush={resetFetchAndPush}
      deleteProject={deleteProject}
      project={fullProject}
      routerPush={push}
      hideActions={hideActions}
      isAdmin={isAdmin}
    />
  );

  const getCTA = async (isAdmin: boolean) => {
    if (isAdmin && projectBase.status !== 'published') {
      return setCTA({
        ctaText: 'Continue Editing',
        ctaOnClick: () => push(`/projects/${projectBase.id}/edit`),
      });
    }

    const nftRes = await getIpNft(projectBase.id);

    if (nftRes === null) {
      if (isAdmin) {
        setCTA({
          ctaText: 'Commercialize',
          ctaOnClick: () => push(`/projects/${projectBase.id}/nft-wizard`),
        });
      }
    } else {
      setCTA({
        ctaText: 'View IP-NFT',
        ctaOnClick: () => push(`/projects/${projectBase.id}/nft`),
      });
    }
  };

  const checkIfAuthorized = async () => {
    const authorized = await getIsAuthorized(
      user.id,
      projectBase.organizationId,
      OrganizationRoles.ADMIN,
    );

    if (authorized) {
      setIsAdmin(true);
    }
    setIsAuthorizedCalled(true);
  };

  useEffect(() => {
    if (!isAuthorizedCalled && user) {
      checkIfAuthorized();
    }
  }, [isAuthorizedCalled, user]);

  // const getTeamProfiles = async (fullProject: FullProject) => {
  //   const requests = fullProject.details.team.map(async (userId: string) => {
  //     const { profile } = await getProfile(
  //       profilesIndexCanisterId,
  //       userId,
  //       undefined,
  //     );
  //     if (profile) {
  //       return profile.imageUrl;
  //     }

  //     return '';
  //   });

  //   try {
  //     const results: string[] = (await Promise.all(requests)).filter(
  //       (result) => result !== undefined,
  //     );
  //     setTeam(results);
  //   } catch (error) {
  //     console.error('There was an error with one of the requests:', error);
  //   }
  // };

  const getFullProject = async () => {
    const getFullProjectRes = await getProject(projectBase.id);
    setFullProject(getFullProjectRes);
    // getTeamProfiles(getFullProjectRes);
  };

  useEffect(() => {
    getCTA(isAdmin);
  }, [isAdmin, user]);

  useEffect(() => {
    getFullProject();
  }, []);

  const { title } = projectBase;

  const { ctaText, ctaOnClick } = cta;

  const restOfTeamSection = useMemo(() => {
    const [_first, ...rest] = team;
    return rest.filter((imageUrl) => imageUrl !== '');
  }, [team]);

  return (
    <Card
      title={title}
      description={fullProject?.details?.summary}
      headerNode={headerNode}
      className="hover:cursor-pointer hover:shadow-xl m-0 md:mt-4 md:mr-4"
      onClick={onClick}
      style={{ height: 'fit-content' }}>
      <Tags
        tagClassName="text-xs font-medium text-teal-800 bg-teal-100 rounded-3xl px-2 py-1 mr-2"
        className="flex flex-row flex-wrap my-4"
        tags={projectBase.fields}
      />
      {children}
      {team[0] && team.length > 0 && (
        <div
          className="flex flex-row relative bg-rhino-75 rounded-full p-2"
          style={{ width: determineWidth(restOfTeamSection.length + 1) }}>
          <Avatar
            source={team[0]}
            className="w-7 h-7 mr-2 border-2 border-white"
          />
          {restOfTeamSection.map((imageUrl: string, index: number) => (
            <Avatar
              source={imageUrl}
              key={index}
              className="w-7 h-7 mr-2 absolute border-2 border-white unset-top"
              style={{ left: (index + 1) * 16 + 10, zIndex: index + 2 }}
            />
          ))}
        </div>
      )}
      {ctaText && ctaOnClick && (
        <Button
          color="secondary"
          className="mt-3"
          fullWidth
          onClick={(e) => {
            e.stopPropagation();
            ctaOnClick();
          }}>
          {ctaText}
        </Button>
      )}
    </Card>
  );
};
