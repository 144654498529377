import * as amplitude from '@amplitude/analytics-browser';

interface EventParamsMap {
  [TrackEventName.CREATE_ACCOUNT]: {
    country: string;
    email: string;
    firstName: string;
    lastName: string;
  };
}

export enum TrackEventName {
  CREATE_ACCOUNT = 'Create Account',
}

export const trackEvent = <EventName extends TrackEventName>(
  eventName: EventName,
  params?: EventParamsMap[EventName]
) => {
  amplitude.track(eventName, params ?? {});
};

export const analytics = {
  trackEvent,
};

export const useAnalytics = () => analytics;
