export const FolderIcon2 = ({ className }: { className?: string }) => {
  return (
    <svg
      width="50"
      height="46"
      viewBox="0 0 50 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M10.9203 36.4798C10.0243 36.4798 9.32031 35.7758 9.32031 34.8798V9.91982C9.32031 9.02382 10.0243 8.31982 10.9203 8.31982H20.5203C20.9683 8.31982 21.3523 8.51182 21.6723 8.76782L24.2963 11.3918C24.4883 11.5838 24.7443 11.6478 25.0003 11.6478H39.0803C39.9763 11.6478 40.6803 12.3518 40.6803 13.2478V34.8798C40.6803 35.7758 39.9763 36.4798 39.0803 36.4798H10.9203Z"
        fill="#95D5D6"
      />
      <path
        d="M20.52 8.64C20.84 8.64 21.224 8.768 21.416 9.024L24.04 11.648C24.296 11.904 24.616 12.032 24.936 12.032H39.016C39.72 12.032 40.296 12.608 40.296 13.312V34.88C40.296 35.584 39.72 36.16 39.016 36.16H10.92C10.216 36.16 9.64 35.584 9.64 34.88V9.92C9.64 9.216 10.216 8.64 10.92 8.64H20.52ZM20.52 8H10.92C9.832 8 9 8.832 9 9.92V34.88C9 35.904 9.832 36.8 10.92 36.8H39.08C40.168 36.8 41 35.968 41 34.88V13.312C41 12.288 40.168 11.392 39.08 11.392H25C24.808 11.392 24.68 11.328 24.552 11.2L21.928 8.576C21.544 8.192 21.032 8 20.52 8Z"
        fill="#001232"
      />
      <path
        d="M39.0804 12.0322H38.4404V36.1602H39.0804C39.7844 36.1602 40.3604 35.5842 40.3604 34.8802V13.3122C40.3604 12.6082 39.7844 12.0322 39.0804 12.0322Z"
        fill="#6CC4C6"
      />
      <path
        d="M12.5199 24C12.1359 24 11.8799 23.68 11.8799 23.36V14.784C11.8799 14.4 12.1999 14.144 12.5199 14.144H37.4799C37.8639 14.144 38.1199 14.464 38.1199 14.784V23.36C38.1199 23.744 37.7999 24 37.4799 24H12.5199Z"
        fill="#BBFF00"
      />
      <path
        d="M37.4796 14.4642C37.6716 14.4642 37.7996 14.5922 37.7996 14.7842V23.3602C37.7996 23.5522 37.6716 23.6802 37.4796 23.6802H12.5196C12.3276 23.6802 12.1996 23.5522 12.1996 23.3602V14.7842C12.1996 14.5922 12.3276 14.4642 12.5196 14.4642H37.4796ZM37.4796 13.8242H12.5196C12.0076 13.8242 11.5596 14.2082 11.5596 14.7842V23.3602C11.5596 23.8722 12.0076 24.3202 12.5196 24.3202H37.4796C37.9916 24.3202 38.4396 23.8722 38.4396 23.3602V14.7842C38.4396 14.2082 37.9916 13.8242 37.4796 13.8242Z"
        fill="#001232"
      />
      <path
        d="M12.1999 24.64C12.0079 24.64 11.8799 24.512 11.8799 24.32V16.256C11.8799 16.064 12.0079 15.936 12.1999 15.936H37.7999C37.9919 15.936 38.1199 16.064 38.1199 16.256V24.32C38.1199 24.512 37.9919 24.64 37.7999 24.64H12.1999Z"
        fill="white"
      />
      <path
        d="M37.7996 16.2562V24.3202H12.1996V16.2562H37.7996ZM37.7996 15.6162H12.1996C11.8156 15.6162 11.5596 15.8722 11.5596 16.2562V24.3202C11.5596 24.7042 11.8156 24.9602 12.1996 24.9602H37.7996C38.1836 24.9602 38.4396 24.7042 38.4396 24.3202V16.2562C38.4396 15.8722 38.1836 15.6162 37.7996 15.6162Z"
        fill="#001232"
      />
      <path
        d="M10.9203 37.12C10.0243 37.12 9.32031 36.416 9.32031 35.52V21.312C9.32031 20.416 10.0243 19.712 10.9203 19.712H24.4883C24.7443 19.712 25.0003 19.648 25.1283 19.456L28.3283 16.384C28.6483 16.128 29.0323 15.936 29.4163 15.936H39.0163C39.9123 15.936 40.6163 16.64 40.6163 17.536V35.52C40.6163 36.416 39.9123 37.12 39.0163 37.12H10.9203Z"
        fill="#B6E2E2"
      />
      <path
        d="M39.08 16.2562C39.784 16.2562 40.36 16.8322 40.36 17.5362V35.5202C40.36 36.2242 39.784 36.8002 39.08 36.8002H10.92C10.216 36.8002 9.64 36.2242 9.64 35.5202V21.3122C9.64 20.6082 10.216 20.0322 10.92 20.0322H24.488C24.808 20.0322 25.128 19.9042 25.384 19.7122L28.584 16.6402C28.84 16.3842 29.16 16.3202 29.48 16.3202H39.08M39.08 15.6162H29.48C28.968 15.6162 28.52 15.8082 28.136 16.1282L24.936 19.2002C24.808 19.3282 24.68 19.3922 24.488 19.3922H10.92C9.832 19.3922 9 20.2242 9 21.3122V35.5202C9 36.5442 9.832 37.4402 10.92 37.4402H39.08C40.168 37.4402 41 36.6082 41 35.5202V17.5362C41 16.5122 40.168 15.6162 39.08 15.6162Z"
        fill="#001232"
      />
      <path
        d="M39.0804 16.2559H38.4404V36.7999H39.0804C39.7844 36.7999 40.3604 36.2239 40.3604 35.5199V17.5359C40.3604 16.8319 39.7844 16.2559 39.0804 16.2559Z"
        fill="#95D5D6"
      />
    </svg>
  );
};
