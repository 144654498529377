import { forwardRef } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { Spinner } from '../Spinner';

const buttonStyles = cva('items-center font-semibold rounded text-center', {
  variants: {
    color: {
      primary: 'bg-primary text-white hover:bg-primary-light',
      secondary:
        'text-black bg-secondary hover:bg-primary-light hover:text-white border border-deep-sapphire-900',
      tertiary: 'bg-tertiary hover:bg-tertiary-light outline-0 text-primary',
    },
    iconPosition: {
      null: '',
      left: 'flex gap-1',
      right: 'flex gap-1 flex-row-reverse',
    },
    disabled: {
      true: 'cursor-not-allowed',
    },
    fullWidth: {
      true: 'w-full',
    },
    size: {
      sm: 'px-2.5 py-1.5 text-sm',
      md: 'px-3 py-2 text-md',
      lg: 'px-4 py-2 text-lg',
      xl: 'px-5 py-2 text-base',
      '2xl': 'px-6 py-3 text-base',
    },
  },
  compoundVariants: [
    {
      disabled: true,
      color: 'primary',
      className: 'text-rhino-500 bg-rhino-200 hover:bg-rhino-200',
    },
    {
      disabled: true,
      color: 'secondary',
      className: 'text-rhino-500 border border-rhino-500',
    },
    {
      disabled: true,
      color: 'tertiary',
      className: 'text-slate-500',
    },
  ],
  defaultVariants: {
    color: 'primary',
    iconPosition: null,
    size: 'sm',
  },
});

type ButtonProps = VariantProps<typeof buttonStyles> &
  React.ComponentPropsWithoutRef<'button'> & {
    icon?: React.ReactNode;
    loading?: boolean;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color,
      icon,
      iconPosition,
      type = 'button',
      disabled = false,
      size,
      children,
      className,
      fullWidth,
      loading,
      ...rest
    }: ButtonProps,
    ref = null,
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        disabled={disabled || loading}
        className={buttonStyles({
          color,
          disabled,
          size,
          iconPosition,
          className,
          fullWidth,
        })}
        {...rest}>
        {icon && <span className="-ml-0.5 mr-2 h-4 w-4">{icon}</span>}
        {loading ? <Spinner className="h-4 w-4" /> : children}
      </button>
    );
  },
);
