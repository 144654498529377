import { ActorSubclass } from '@dfinity/agent';
import { organizations_teams_types, users_types } from '@scinet-inc/api';
import { Tags, Typography, Avatar, OrgActions } from '../../';
import { useMemo } from 'react';
import { Organization } from 'scinet-types';

type Props = {
  org: Organization;
  deleteOrganization: () => void;
  routerPush: (url: any, options?: any) => Promise<boolean>;
  user?: users_types.User | any;
  // hideActions?: boolean;
  orgTeamActor?: ActorSubclass<organizations_teams_types._SERVICE> | undefined;
};

export function OrgTitle({
  org,
  deleteOrganization,
  routerPush,
  user,
  // hideActions,
  orgTeamActor,
}: Props) {
  const isAdmin = useMemo(() => {
    if (org?.team.members) {
      const existingMember = org.team.members.find(
        (member: organizations_teams_types.OrgMember) => member.id === user?.id,
      );
      if (
        existingMember &&
        (existingMember.role === 'owner' || existingMember.role === 'admin')
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [org, user]);

  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center mb-2">
          <Typography className="w-full text-2xl font-semibold">
            {org?.info.name}
          </Typography>
        </div>

        <div className="hidden lg:flex lg:w-96 lg:relative lg:justify-end mr-4">
          <div className="text-right mr-4">
            <Typography className="text-sm font-bold align-middle ">
              Organization
            </Typography>
            <Typography className="text-sm">
              {org?.team.members && org.team.members.length} members
            </Typography>
          </div>

          <Avatar source={org.details.imageUrl} className="h-10 w-10 mr-2" />
          {isAdmin && (
            <OrgActions
              deleteOrg={deleteOrganization}
              org={org.info}
              routerPush={routerPush}
              user={user}
              hideActions
              orgTeamActor={orgTeamActor}
            />
          )}
        </div>
      </div>
      <Tags
        tags={org?.info.tags}
        className="flex flex-row flex-wrap"
        tagClassName="text-xs font-medium rounded-3xl px-1 py-0.5 mr-2 mb-2"
      />
    </div>
  );
}
