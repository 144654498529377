import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Avatar } from '../Avatar';
import { Typography } from '../Typography';
import {
  ClipboardDocumentIcon,
  UserCircleIcon,
} from '@heroicons/react/24/solid';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { users_types } from '@scinet-inc/api';
import { useToast } from '../Toast';

type Props = {
  isMobile: boolean;
  profile: any;
  clickProfile: () => void;

  logoutClick: () => void;
  isOpen: boolean;
  user?: users_types.User | null | undefined;
};

export const ProfileMenu = ({
  isMobile,
  profile,
  clickProfile,
  logoutClick,
  isOpen,
  user,
}: Props) => {
  const { showToast } = useToast();

  const copyToClipboard = () => {
    if (user) {
      navigator.clipboard.writeText(user.id);
      showToast({
        message: 'User ID copied!',
        variant: 'success',
      });
    }
  };

  if (!isOpen) {
    return null;
  }

  return isMobile ? (
    <Transition as={Fragment}>
      <div className="fixed h-full w-full z-50 left-0 top-0">
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed h-full w-full left-0 top-0 bg-black bg-opacity-50 z-[-1]"></div>
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-y-full "
          enterTo="translate-y-50"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-y-100"
          leaveTo="translate-y-full">
          <div className="fixed bottom-0 w-full">
            <Menu.Items
              static
              className="fixed bottom-0 w-full z-50 py-2 divide-y rounded-t-md divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <div className="flex flex-row items-center p-2">
                    <Avatar
                      source={profile?.imageUrl}
                      className="w-8 h-8 mr-2"
                    />
                    <div className="flex flex-col">
                      <Typography className="text-xs font-medium">
                        {user?.firstName} {user?.lastName}
                      </Typography>
                      <Typography className="text-xs">{user?.email}</Typography>
                    </div>
                  </div>
                )}
              </Menu.Item>
              <div className="h-[0.5px] w-full bg-gray-300"></div>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className="flex flex-row items-center p-2 hover:bg-gray-200 hover:cursor-pointer"
                    onClick={clickProfile}>
                    <UserCircleIcon className="w-4 h-4 text-black mr-2" />
                    <Typography className="text-xs">Edit Profile</Typography>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className="flex flex-row items-center p-2 hover:bg-gray-200 hover:cursor-pointer"
                    onClick={copyToClipboard}>
                    <ClipboardDocumentIcon className="w-4 h-4 text-black mr-2" />
                    <Typography className="text-xs">
                      Copy Principal / User ID
                    </Typography>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className="flex flex-row items-center p-2 hover:bg-gray-200 hover:cursor-pointer"
                    onClick={logoutClick}>
                    <ArrowRightOnRectangleIcon className="h-4 w-4 text-black mr-2" />
                    <Typography className="text-xs">Log Out</Typography>
                  </div>
                )}
              </Menu.Item>
            </Menu.Items>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  ) : (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95">
      <Menu.Items
        static
        className="fixed z-50 right-0 w-56 mr-4 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-2">
          <Menu.Item>
            {({ active }) => (
              <div className="flex flex-row items-center p-2">
                <Avatar source={profile?.imageUrl} className="w-8 h-8 mr-2" />
                <div className="flex flex-col">
                  <Typography className="text-xs font-medium">
                    {user?.firstName} {user?.lastName}
                  </Typography>
                  <Typography className="text-xs">{user?.email}</Typography>
                </div>
              </div>
            )}
          </Menu.Item>
          <div className="h-[0.5px] w-full bg-gray-300"></div>
          <Menu.Item>
            {({ active }) => (
              <div
                className="flex flex-row items-center p-2 hover:bg-gray-200 hover:cursor-pointer"
                onClick={clickProfile}>
                <UserCircleIcon className="w-4 h-4 text-black mr-2" />
                <Typography className="text-xs">Edit Profile</Typography>
              </div>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <div
                className="flex flex-row items-center p-2 hover:bg-gray-200 hover:cursor-pointer"
                onClick={copyToClipboard}>
                <ClipboardDocumentIcon className="w-4 h-4 text-black mr-2" />
                <Typography className="text-xs">
                  Copy Principal / User ID
                </Typography>
              </div>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <div
                className="flex flex-row items-center p-2 hover:bg-gray-200 hover:cursor-pointer"
                onClick={logoutClick}>
                <ArrowRightOnRectangleIcon className="h-4 w-4 text-black mr-2" />
                <Typography className="text-xs">Log Out</Typography>
              </div>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Transition>
  );
};
