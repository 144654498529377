import { ActorClient, IndexClient } from 'components';

import { HttpAgent, Identity } from '@dfinity/agent';
import {
  profiles_index,
  profiles_index_types,
  profiles_service,
  profiles_service_types,
} from '@scinet-inc/api';
import { HOST } from '../constants';

export const initializeProfileIndexClient = (
  host: string,
  canisterId: string,
  identity: Identity | undefined
) => {
  return new IndexClient<profiles_index_types.ProfilesIndex>({
    IDL: profiles_index.idlFactory,
    canisterId,
    agentOptions: {
      host,
      identity,
    },
  });
};

export const initializeProfileServiceClient = (
  host: string,
  indexClient: IndexClient<profiles_index_types.ProfilesIndex>,
  identity: Identity | undefined
) => {
  return new ActorClient<
    profiles_index_types.ProfilesIndex,
    profiles_service_types.ProfilesService
  >({
    actorOptions: {
      IDL: profiles_service.idlFactory,
      agentOptions: {
        host,
        identity,
      },
    },
    indexClient,
  });
};

export const initializeProfileActorClient = (
  host: string,
  canisterId: string,
  identity?: Identity | undefined
) => {
  const profileIndexClient = initializeProfileIndexClient(
    host,
    canisterId,
    identity
  );
  return initializeProfileServiceClient(host, profileIndexClient, identity);
};

export const reduceProfileResults = async (
  profileResults: PromiseSettledResult<any>[]
): Promise<any> => {
  return new Promise<any>(async (resolve) => {
    let result: any = {};
    for (let settledResult of profileResults) {
      if (settledResult.status === 'fulfilled') {
        for (const [k, v] of Object.entries(settledResult.value)) {
          let value = await v;
          result[k] = value;
        }
      }
    }
    resolve(result);
  });
};

export const getProfileActor = async (
  profilesIndexCanisterId: string,
  id: string,
  identity?: Identity
) => {
  const actorClient = initializeProfileActorClient(
    HOST,
    profilesIndexCanisterId,
    identity
  );
  // check if user exists, if not then create, if so then retrieve
  // this call will return a response from each profiles service canister API
  const responses = await actorClient.queryWithCanisterIdMapping<
    profiles_service_types.ProfilesService['skExists']
  >('profiles', (actor) => actor.skExists(id));

  if (!responses.length) {
    return undefined;
  }

  const profileResults = await reduceProfileResults(responses);

  const agent = new HttpAgent({
    identity,
    host: HOST,
  });

  const canisterPair = Object.entries(profileResults).find(
    (pair) => pair[1] === true
  );

  if (!canisterPair) {
    return undefined;
  }

  return profiles_service.createClient(canisterPair[0], agent);
};

export const getProfile = async (
  profilesIndexCanisterId: string,
  id: string,
  identity?: Identity
) => {
  const profileActor = await getProfileActor(
    profilesIndexCanisterId,
    id,
    identity
  );
  let profile: profiles_service_types.Profile | undefined;
  let error: string | undefined;

  if (!profileActor) {
    return { profile, error: 'NotFound' };
  }

  const profileRes = await profileActor.read(id).then((result: any) => {
    if ('ok' in result) {
      profile = result.ok;
    } else {
      if ('NotAuthorized' in result.err) {
        // require a new login
        error = 'NotAuthorized';
      } else if ('NotFound' in result.err) {
        error = 'NotFound';
      }
    }
    return { profile, error };
  });
  return profileRes;
};
