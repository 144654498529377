import { Project } from 'scinet-types';
import { Typography } from '../../../Typography';

const Technology = ({ project }: { project: Project }) => {
  return (
    <div className="flex flex-col my-4">
      <Typography className="text-lg font-bold mb-2">Technology</Typography>
      <Typography className="text-md mb-4">
        {project?.details?.description}
      </Typography>
    </div>
  );
};

export default Technology;
