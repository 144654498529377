import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  ActorResponse,
  isProd,
  isStaging,
  sendVerificationEmail,
} from '../../lib';
import { useUserContext } from './UserContext';
import { Button, Spinner, Typography } from 'components';

export const VerifyEmail = () => {
  const { query } = useRouter();
  const {
    createEmailVerification,
    emailVerified,
    emailVerificationsActor,
    user,
    setEmailVerified,
  } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [tokenExpired, setTokenExpired] = useState(false);
  const [verifyFired, setVerifyFired] = useState(false);

  const sendEmail = async (token?: string) => {
    if (token) {
      const href = `${window.location.origin}/users/${user?.id}/verify/${token}`;
      await sendVerificationEmail(user!.email, href);
      setIsLoading(false);
    } else {
      emailVerificationsActor
        ?.getOwnVerification()
        .then((result: ActorResponse) => {
          if ('ok' in result) {
            const getResult = result.ok;

            const href = `${window.location.origin}/users/${getResult.id}/verify/${getResult.token}`;
            sendVerificationEmail(user!.email, href);
          }
          setIsLoading(false);
        });
    }
  };

  const verifyEmail = async (id: string, email: string, token: string) => {
    emailVerificationsActor
      ?.getVerifiedStatus(id)
      .then((result: ActorResponse) => {
        if ('ok' in result) {
          const getResult = result.ok;
          if (getResult === true) {
            setIsLoading(false);
            setEmailVerified(getResult);
          } else {
            emailVerificationsActor
              ?.verifyEmail(token)
              .then(async (result: ActorResponse) => {
                if ('ok' in result) {
                  const verifyResult = result.ok;
                  if (verifyResult.emailVerified) {
                    setEmailVerified(verifyResult);
                    let base;
                    if (isStaging()) {
                      base = 'https://staging-app.scinet.one';
                    } else if (isProd()) {
                      base = 'https://app.scinet.one';
                    } else {
                      base = 'https://localhost:3000';
                    }

                    await fetch('/api/users/welcome', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        href: `${base}`,
                        newOrgHref: `${base}/organizations/new`,
                        email: user?.email,
                      }),
                    })
                      .then((r) => r.json())
                      .then((result) => {
                        if (result.error) {
                          // eslint-disable-next-line no-console
                          console.log('FAIL send welcome email', result.error);
                          return;
                        }

                        // eslint-disable-next-line no-console
                        console.log('SUCCESS');
                      })
                      .catch(({ error }) => {
                        // eslint-disable-next-line no-console
                        console.log('FAIL', error);
                      });
                  } else {
                    setTokenExpired(true);
                    sendEmail(verifyResult.token);
                  }
                } else {
                  setError('There was an error verifying your email');
                }
                setIsLoading(false);
              });
          }
        } else {
          createEmailVerification(email);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    if (!verifyFired && user && query.id && query.token) {
      verifyEmail(query.id as string, user.email, query.token as string);
      setVerifyFired(true);
    }
  }, [query, user, verifyFired]);

  if (isLoading || !user || !query.id || !query.token) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div className="flex flex-col">
        <Typography className="text-sm text-red-600">{error}</Typography>
        <Typography className="text-sm">
          Would you like to try again?
        </Typography>
        <Button onClick={() => sendEmail()}>Try Again</Button>
      </div>
    );
  }

  if (tokenExpired) {
    return (
      <div>
        <Typography>Please check your email</Typography>
      </div>
    );
  }

  if (emailVerified) {
    // TODO: style this
    return <Typography>You have successfully verified your email!</Typography>;
  }

  // TODO: style this
  return <div></div>;
};
