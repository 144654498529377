import { projects_milestones_types } from '@scinet-inc/api';
import { Typography } from '../../../../Typography';
import { Card } from '../../../../Card';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { FaDollarSign } from 'react-icons/fa';

const TimelineProjectMilestones = ({
  milestones,
}: {
  milestones: projects_milestones_types.Milestone[];
}) => {
  return (
    <div className="flex flex-col  my-4">
      <Typography className="text-lg font-bold mb-2">Milestones</Typography>

      {milestones?.map((milestone, index) => (
        <div className="flex flex-row justify-start" key={index}>
          {/* <div className="timeline-separator">
            {milestone.status === MILESTONE_STATUS.COMPLETE && (
              <HiCheckCircle className="w-4 h-4 text-purple-500 mx-1 mb-1" />
            )}
            {milestone.status === MILESTONE_STATUS.PLANNED && (
              <BiRadioCircle className="w-6 h-6 text-purple-200" />
            )}
            {milestone.status === MILESTONE_STATUS.IN_PROGRESS && (
              <BiRadioCircleMarked className="w-6 h-6 text-purple-700" />
            )}
            {index < milestones.length - 1 && (
              <div className="w-1 h-[88%] bg-gray-200 ml-2.5"></div>
            )}
          </div> */}
          <div className="timeline-content w-full">
            <Card className="w-full md:w-full" title={milestone?.name}>
              <Typography className={'aboutText'}>
                {milestone?.description}
              </Typography>
            </Card>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TimelineProjectMilestones;
