import { LoginButton, Typography } from 'components';

type Props = {
  text: string;
};

export default function LoginPrompt({ text }: Props) {
  return (
    <div className="flex flex-col m-auto w-full text-center justify-center items-center mt-4">
      {text && <Typography className="pb-3">{text}</Typography>}
      <LoginButton />
    </div>
  );
}
