import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { set } from 'local-storage';
import { ActorResponse } from 'scinet-types';
import { Spinner, useAuthentication, useToast } from 'components';
import {
  useAppContext,
  useOrganizationsTeamsContext,
  useUserContext,
} from '../..';
import { LoginPrompt } from '../../../lib';

export default function AcceptInvite({}) {
  const { push, query } = useRouter();
  const { showToast } = useToast();

  const { fetchOrgId } = useOrganizationsTeamsContext();
  const { isAuthing } = useAuthentication();
  const { user, isUserLoading } = useUserContext();
  const { orgTeamActor } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [acceptInviteAttempted, setAcceptInviteAttemped] = useState(false);

  const acceptInvite = async (orgId: string, inviteId: string) => {
    setAcceptInviteAttemped(true);
    await orgTeamActor
      ?.acceptInvite(orgId, inviteId)
      .then(async (acceptInviteResponse: ActorResponse) => {
        if (acceptInviteResponse && 'ok' in acceptInviteResponse) {
          showToast({
            message: 'Invite accepted',
            variant: 'success',
          });
          void fetchOrgId();
          push(`/organizations`);
        } else {
          showToast({
            message: 'Accept invite failed',
            variant: 'error',
          });
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (query.invite_id && user && orgTeamActor && !acceptInviteAttempted) {
      acceptInvite(query.id as string, query.invite_id as string);
    }
  }, [query, user, acceptInviteAttempted, orgTeamActor]);

  useEffect(() => {
    set('shouldNotDirect', true);
  }, []);

  // check for user first
  // if no user, prompt sign up
  // if user, then accept invite

  if (isLoading || isAuthing || isUserLoading || user) {
    return <Spinner />;
  }

  return (
    <div>
      <LoginPrompt text="Please login to accept this invite" />
    </div>
  );
}
