import { Fragment, useEffect, useState } from 'react';
import { useIsMobile } from '@scinet-inc/hooks';
import { Menu, Transition } from '@headlessui/react';
import {
  users_types,
  organizations_types,
  organizations_teams_types,
} from '@scinet-inc/api/';
import { FaEllipsisV } from 'react-icons/fa';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import DeleteOrgConfirmation from './DeleteOrgConfirmation';
import { ActorSubclass } from '@dfinity/agent';

type ActorResponse = {
  [key: string]: any;
};

export function OrgActions({
  deleteOrg,
  org,
  routerPush,
  user,
  hideActions,
  orgTeamActor,
}: {
  deleteOrg: (
    id: string,
  ) => any | undefined | Promise<organizations_types.Result_3>;
  org: organizations_types.OrganizationInfo;
  routerPush: (url: any, options?: any) => Promise<boolean>;
  user?: users_types.User | any;
  hideActions?: boolean | undefined;
  orgTeamActor?: ActorSubclass<organizations_teams_types._SERVICE> | undefined;
}) {
  const { isMobile } = useIsMobile();
  const [isSelected, setIsSelected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teamHasBeenFetched, setTeamHasBeenFetched] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const getTeam = async (id: string) => {
    await orgTeamActor
      ?.read(id)
      .then(async (getTeamResponse: ActorResponse) => {
        if ('ok' in getTeamResponse) {
          const team = getTeamResponse['ok'];
          const existingMember = team.members.find(
            (member: organizations_teams_types.OrgMember) =>
              member.id === user?.id,
          );

          if (
            existingMember &&
            (existingMember.role.toLowerCase() === 'owner' ||
              existingMember.role.toLowerCase() === 'admin')
          ) {
            setIsAuthorized(true);
          }
        } else {
          console.error('failed to get team', id);
        }
        setTeamHasBeenFetched(true);
      });
  };

  useEffect(() => {
    if (!teamHasBeenFetched && org?.id) {
      getTeam(org.id);
    }
  }, [org]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const editOrg = () => {
    routerPush(`/organizations/${org.id}/edit`);
  };

  const menuOption = isMobile ? (
    <Menu as="div" className="inline-block text-left">
      {isAuthorized && (
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-full mt-2 p-1 text-xs font-medium hover:bg-black hover:bg-opacity-10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <FaEllipsisV size="16px" color="gray" />
          </Menu.Button>
        </div>
      )}
      <Transition as={Fragment}>
        <div className="fixed h-full w-full z-50 left-0 top-0">
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed h-full w-full left-0 top-0 bg-black bg-opacity-50 z-[-1]"></div>
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-y-full "
            enterTo="translate-y-50"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-y-100"
            leaveTo="translate-y-full">
            <div className="fixed bottom-0 w-full">
              <Menu.Items className="py-2 divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => editOrg()}
                      className={`${
                        active ? 'bg-violet-500 text-white' : 'text-gray-900'
                      } group flex w-full items-center px-2 py-2 text-sm`}>
                      Edit
                    </button>
                  )}
                </Menu.Item>
                {/* <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={toggleModal}
                      className={`${
                        active ? 'bg-violet-500 text-white' : 'text-gray-900'
                      } group flex w-full items-center px-2 py-2 text-sm`}>
                      Delete
                    </button>
                  )}
                </Menu.Item> */}
              </Menu.Items>
            </div>
          </Transition.Child>
        </div>
      </Transition>
    </Menu>
  ) : (
    <Menu as="div" className="inline-block text-left">
      {isAuthorized && (
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-full mt-2 p-1 text-xs font-medium hover:bg-black hover:bg-opacity-10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <FaEllipsisV size="16px" color="gray" />
          </Menu.Button>
        </div>
      )}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute py-2 mt-2 w-20 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => editOrg()}
                className={`${
                  active ? 'bg-violet-500 text-white' : 'text-gray-900'
                } group flex w-full items-center px-2 py-2 text-sm`}>
                Edit
              </button>
            )}
          </Menu.Item>

          {/* <Menu.Item>
            {({ active }) => (
              <button
                onClick={toggleModal}
                className={`${
                  active ? 'bg-violet-500 text-white' : 'text-red-600'
                } group flex w-[100%] items-center px-2 py-2 text-sm`}>
                Delete
              </button>
            )}
          </Menu.Item> */}
        </Menu.Items>
      </Transition>
    </Menu>
  );

  return (
    <div className="flex flex-row">
      {menuOption}
      {!hideActions && (
        <Menu>
          <div>
            <Menu.Button
              className="rounded-full mt-1.5 p-1 hover:bg-purple-600 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              aria-label="like"
              onClick={(e: { stopPropagation: () => void }) => {
                e.stopPropagation();
                setIsSelected(!isSelected);
              }}>
              {isSelected ? (
                <AiFillStar size="20px" color="#d741ff" />
              ) : (
                <AiOutlineStar size="20px" color="#d741ff" />
              )}
            </Menu.Button>
          </div>
        </Menu>
      )}

      <DeleteOrgConfirmation
        routerPush={routerPush}
        deleteOrg={deleteOrg}
        toggleModal={toggleModal}
        org={org}
        isModalOpen={isModalOpen}
      />
    </div>
  );
}
