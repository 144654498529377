import { useState } from 'react';
import {
  Button,
  Input,
  TextArea,
  Typography,
  WizardNavigation,
  useToast,
  useWizardFormContext,
  useProjectContext,
} from 'components';
import { projects_milestones_types } from '@scinet-inc/api';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useAppContext } from '../../../AppContext';
import { ActorResponse } from '../../../../lib';
import { PlusIcon } from '@heroicons/react/20/solid';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { DatePicker } from '../../../DatePicker';

type TimelineForm = {
  milestones: Partial<projects_milestones_types.Milestone>[];
};

const date = new Date();

const stubMilestone = {
  name: '',
  completedAt: date.toString(),
  description: '',
};

const determineError = (key: string, errors: any, index: number) => {
  if (
    !Object.keys(errors).length ||
    !errors.milestones[index] ||
    !errors.milestones[index][key]
  ) {
    return { message: '', error: false };
  }

  const { message, type } = errors.milestones[index][key];
  let errorMessage = message;
  if (type === 'pattern') {
    errorMessage = 'Please enter a positive whole number';
  }
  return { message: errorMessage, error: !!errorMessage };
};

const TimelineStep = () => {
  const { nextStep } = useWizardFormContext();
  const { project, setProject } = useProjectContext();
  const { projectsMilestonesActor } = useAppContext();
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [milestoneOpen, setMilestoneOpen] = useState<number | undefined>(0);

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<TimelineForm>({
    defaultValues: {
      milestones: project?.milestones.milestones || [stubMilestone],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'milestones' as 'milestones',
  });

  const onSubmit = (data: any) => {
    if (isDirty) {
      setIsLoading(true);
      if (project.milestones.id) {
        projectsMilestonesActor
          ?.update(
            project.info.organizationId,
            project.milestones.id,
            data.milestones
          )
          .then(async (updateResponse: ActorResponse) => {
            if ('ok' in updateResponse) {
              const res = updateResponse['ok'];
              const updatedProject = { ...project, milestones: res };
              showToast({
                message: 'Successfully updated milestones.',
                variant: 'success',
              });
              setProject(updatedProject);
              nextStep();
            } else {
              showToast({
                message: 'Failed to update milestones.',
                variant: 'error',
              });
              console.error('update failure', updateResponse);
              setSubmitError('There was an error updating your milestones');
            }
            setIsLoading(false);
          });
      } else {
        projectsMilestonesActor
          ?.create(
            project.info.organizationId,
            project.info.id,
            data.milestones
          )
          .then(async (createResponse: ActorResponse) => {
            if ('ok' in createResponse) {
              const res = createResponse['ok'];
              const updatedProject = { ...project, milestones: res };
              showToast({
                message: 'Successfully created milestones.',
                variant: 'success',
              });
              setProject(updatedProject);
              nextStep();
            } else {
              showToast({
                message: 'Failed to create milestones.',
                variant: 'error',
              });
              console.error('create failure', createResponse);
              setSubmitError('There was an error creating your milestones');
            }
            setIsLoading(false);
          });
      }
    } else {
      nextStep();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography className="font-semibold">Timeline</Typography>
      <ul className="list-none p-0 mt-0">
        {fields.map(
          (
            field: Partial<projects_milestones_types.Milestone>,
            index: number
          ) => (
            <li key={index}>
              <div className="shadow-lg p-4 md:px-8 border border-gray-200 rounded-md mb-4">
                <div
                  className="flex flex-row justify-between cursor-pointer items-center"
                  onClick={() => {
                    milestoneOpen === index
                      ? setMilestoneOpen(undefined)
                      : setMilestoneOpen(index);
                  }}>
                  <div>
                    <Typography className="flex items-center text-sm font-semibold text-purple-600">
                      {`${index + 1}. `}
                      {field.name || 'New Milestone'}
                    </Typography>
                  </div>
                  <div className="flex flex-row">
                    <Button
                      color="tertiary"
                      className="text-red-500 hidden md:block"
                      onClick={() => remove(index)}>
                      Delete
                    </Button>

                    {milestoneOpen === index ? (
                      <ChevronUpIcon color="gray" className="h-4 w-4 mt-1" />
                    ) : (
                      <ChevronDownIcon color="gray" className="h-4 w-4 mt-1" />
                    )}
                  </div>
                </div>

                {milestoneOpen === index && (
                  <div className="py-4">
                    <Controller
                      name={`milestones.${index}.name`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Title is required',
                        },
                      }}
                      render={({ field, formState: { errors } }) => (
                        <Input
                          value={field.value || ''}
                          onChange={field.onChange}
                          ref={field.ref}
                          label="Title"
                          labelClassName="mt-2 ml-1 text-gray-500 text-xs font-semibold"
                          placeholder="Milestone title"
                          error={determineError('title', errors, index).message}
                          maxLength={150}
                        />
                      )}
                    />
                    <Controller
                      name={`milestones.${index}.description`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Description is required',
                        },
                      }}
                      render={({ field, formState: { errors } }) => (
                        <TextArea
                          value={field.value || ''}
                          onChange={field.onChange}
                          ref={field.ref}
                          error={
                            determineError('description', errors, index).message
                          }
                          label="Description"
                          labelClassName="mt-2 ml-1 text-gray-500 text-xs font-semibold"
                          placeholder="Description for your milestone"
                          maxLength={500}
                        />
                      )}
                    />
                    <Controller
                      name={`milestones.${index}.completedAt`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Date is required',
                        },
                      }}
                      render={({ field, formState: { errors } }) => (
                        <DatePicker
                          onChange={field.onChange}
                          label="Date of milestone completion"
                          dateSelected={field.value ? field.value : undefined}
                        />
                      )}
                    />
                    <div className="flex flex-row w-full justify-center md:hidden">
                      <Button
                        color="tertiary"
                        className="text-red-500"
                        onClick={() => remove(index)}>
                        Delete
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </li>
          )
        )}
      </ul>
      {submitError && (
        <Typography className="text-red-500 text-sm ml-4">
          {submitError}
        </Typography>
      )}
      <div className="w-full flex flex-row justify-center mt-4">
        <Button
          className="flex flex-row align-middle"
          color="primary"
          onClick={() => {
            setMilestoneOpen(
              milestoneOpen !== undefined ? milestoneOpen + 1 : undefined
            );
            append(stubMilestone);
          }}
          disabled={fields.length > 4}
          icon={<PlusIcon />}
          iconPosition="left">
          Add Milestone
        </Button>
      </div>

      <WizardNavigation disablePrimaryButton={isLoading} />
    </form>
  );
};

export default TimelineStep;
