import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  organizations_teams_types,
  projects_service_types,
  users_types,
} from '@scinet-inc/api';

import {
  TeamMember,
  PROJECTS_INDEX_CANISTER_ID,
  initializeProjectsActorClient,
  ORGANIZATIONS_TEAMS_CANISTER_ID,
  ActorResponse,
} from '../../../../../lib';
import {
  Typography,
  WizardNavigation,
  useAuthentication,
  useToast,
  useProjectContext,
} from 'components';
import { useAppContext, useUserContext } from '../../../..';
import { MemberSelector } from './MemberSelector';
import { useRouter } from 'next/router';

type UserHash = { [key: string]: users_types.User };

const TeamStep = () => {
  const { push } = useRouter();
  const { project, setProject } = useProjectContext();
  const { orgTeamActor } = useAppContext();
  const { identity } = useAuthentication();
  const { actor } = useUserContext();
  const [team, setTeam] = useState<
    projects_service_types.ProjectDetails['team']
  >(project.details.team || []);
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [orgMembers, setOrgMembers] = useState<UserHash>({});
  const [orgMembersFetched, setOrgMembersFetched] = useState(false);

  const fetchOrgTeamMembers = async () => {
    await orgTeamActor
      ?.read(project.info.organizationId)
      .then(async (getTeamResponse: ActorResponse) => {
        if ('ok' in getTeamResponse) {
          const members = getTeamResponse['ok'].members;
          const requests = members.map(
            (m: organizations_teams_types.OrgMember) => {
              return actor?.read(m.id).then((result: any) => {
                if ('ok' in result) {
                  return result.ok;
                }
                return;
              });
            }
          );

          try {
            const results: users_types.User[] = await Promise.all(requests);
            const userHash = results.reduce(
              (acc: UserHash, u: users_types.User) => {
                acc[u.id] = u;
                return acc;
              },
              {}
            );

            setOrgMembers(userHash);
          } catch (error) {
            console.error(
              'There was an error with one of the requests:',
              error
            );
          }
        } else {
          console.error('There was an error fetching the team');
        }
      });

    setOrgMembersFetched(true);
  };

  useEffect(() => {
    if (!orgMembersFetched) {
      fetchOrgTeamMembers();
    }
  }, []);

  const { showToast } = useToast();

  const onRemoveTeamMember = useCallback(
    (memberId: string) => {
      setTeam(team.filter((userId) => userId !== memberId));
    },
    [setTeam, team]
  );

  const projectActorClient = initializeProjectsActorClient(
    PROJECTS_INDEX_CANISTER_ID,
    identity!
  );

  const onSubmit = async () => {
    if (team.length !== project.details.team.length) {
      setIsLoading(true);
      try {
        const updateProjectResult = await projectActorClient.update<
          projects_service_types.ProjectsService['updateProjectTeam']
        >(
          'projects',
          project.info.id as string,
          (actor: {
            updateProjectTeam: (
              arg0: string,
              arg1: string,
              arg2: projects_service_types.UpdateProjectTeamRequest,
              arg3: string
            ) => any;
          }) =>
            actor.updateProjectTeam(
              project.info.organizationId,
              project.info.id,
              { team },
              ORGANIZATIONS_TEAMS_CANISTER_ID
            )
        );

        if ('ok' in updateProjectResult) {
          setProject({ ...project, details: updateProjectResult.ok });
          showToast({
            message: 'Successfully updated project.',
            variant: 'success',
          });
          push(`/projects/${project.info.id}/preview`);
        } else {
          showToast({
            message: 'Failed to update project.',
            variant: 'error',
          });
          setSubmitError('There was an issue with your request');
          setIsLoading(false);
        }
      } catch (e) {
        console.error('update details error', e);
        setIsLoading(false);
      }
    } else {
      push(`/projects/${project.info.id}/preview`);
    }
  };

  const addMember = (id: string) => {
    setTeam([...team, id]);
  };

  const teamMembers = useMemo(
    () =>
      team.map((memberId: string, i: number) => {
        return (
          <TeamMember
            key={i}
            memberId={memberId}
            isLoading={false}
            deleteMember={onRemoveTeamMember}
          />
        );
      }),
    [onRemoveTeamMember, team]
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}>
      <Typography className="font-semibold">Team</Typography>
      <MemberSelector onSelect={addMember} userHash={orgMembers} team={team} />
      <div className="flex flex-col">{teamMembers}</div>
      {submitError && <p className="text-red-500 text-sm">{submitError}</p>}
      <WizardNavigation disablePrimaryButton={isLoading} />
    </form>
  );
};

export default TeamStep;
