import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { projects_service_types } from '@scinet-inc/api';
import {
  TextArea,
  Typography,
  useAuthentication,
  useToast,
  useWizardFormContext,
  WizardNavigation,
  useProjectContext,
} from 'components';
import {
  initializeProjectsActorClient,
  ORGANIZATIONS_TEAMS_CANISTER_ID,
  PROJECTS_INDEX_CANISTER_ID,
} from '../../../../../lib';

export default function SummaryStep() {
  const { nextStep } = useWizardFormContext();
  const { project, setProject } = useProjectContext();
  const { identity } = useAuthentication();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<projects_service_types.CreateProjectMetaRequest>({
    defaultValues: project.details.id
      ? {
          summary: project.details.summary,
          background: project.details.background,
          description: project.details.description,
        }
      : {},
  });
  const { showToast } = useToast();

  const [submitError, setSubmitError] = useState('');
  const projectActorClient = initializeProjectsActorClient(
    PROJECTS_INDEX_CANISTER_ID,
    identity!
  );

  const onSubmit = async (
    data: projects_service_types.CreateProjectMetaRequest
  ) => {
    const projectId = project.info.id;
    if (isDirty) {
      setIsLoading(true);
      if (project.details.id) {
        try {
          const updateProjectResult = await projectActorClient.update<
            projects_service_types.ProjectsService['updateProjectSummary']
          >(
            'projects',
            projectId as string,
            (actor: {
              updateProjectSummary: (
                arg0: string,
                arg1: string,
                arg2: projects_service_types.UpdateProjectSummaryRequest,
                arg3: string
              ) => any;
            }) =>
              actor.updateProjectSummary(
                project.info.organizationId,
                projectId,
                data,
                ORGANIZATIONS_TEAMS_CANISTER_ID
              )
          );

          if ('ok' in updateProjectResult) {
            setProject({ ...project, details: updateProjectResult['ok'] });
            showToast({
              message: 'Successfully updated project.',
              variant: 'success',
            });
            nextStep();
          } else {
            showToast({
              message: 'Failed to update project.',
              variant: 'error',
            });
            setSubmitError('There was an issue with your request');
            setIsLoading(false);
          }
        } catch (e) {
          console.error('update details error', e);
          setIsLoading(false);
        }
      } else {
        try {
          const createProjectResult = await projectActorClient.update<
            projects_service_types.ProjectsService['create']
          >(
            'projects',
            projectId as string,
            (actor: {
              create: (
                arg0: string,
                arg1: string,
                arg2: projects_service_types.CreateProjectMetaRequest,
                arg3: string
              ) => any;
            }) =>
              actor.create(
                project.info.organizationId,
                projectId,
                { ...data, projectId },
                ORGANIZATIONS_TEAMS_CANISTER_ID
              )
          );

          if ('ok' in createProjectResult) {
            setProject({ ...project, details: createProjectResult.ok });

            showToast({
              message: 'Successfully updated project.',
              variant: 'success',
            });
            nextStep();
          } else {
            setSubmitError('There was an issue with your request 0');
            showToast({
              message: 'Failed to update project.',
              variant: 'error',
            });
            setIsLoading(false);
          }
        } catch (e) {
          setSubmitError('There was an issue with your request 1');
          console.error('create details error', e);
          setIsLoading(false);
        }
      }
    } else {
      nextStep();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography className="font-semibold">Project Summary</Typography>
      <Controller
        name="summary"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Summary is required',
          },
        }}
        render={({ field }) => (
          <TextArea
            value={field.value || ''}
            onChange={field.onChange}
            ref={field.ref}
            error={errors.summary?.message}
            label="Summary"
            labelClassName="mt-2 ml-1 text-gray-500 text-xs font-semibold"
            placeholder="Summarize the project"
            maxLength={500}
          />
        )}
      />
      <Controller
        name="background"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Background is required',
          },
        }}
        render={({ field }) => (
          <TextArea
            value={field.value || ''}
            onChange={field.onChange}
            ref={field.ref}
            error={errors.background?.message}
            label="Background"
            labelClassName="mt-2 ml-1 text-gray-500 text-xs font-semibold"
            placeholder="Describe contextual information related to the project"
            maxLength={2000}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Description is required',
          },
        }}
        render={({ field }) => (
          <TextArea
            value={field.value || ''}
            onChange={field.onChange}
            ref={field.ref}
            error={errors.background?.message}
            label="Description of Technology"
            labelClassName="mt-2 ml-1 text-gray-500 text-xs font-semibold"
            placeholder="Describe contextual information related to the project"
            maxLength={2000}
          />
        )}
      />
      {submitError && <p className="text-red-500 text-sm">{submitError}</p>}
      <WizardNavigation disablePrimaryButton={isLoading} />
    </form>
  );
}
