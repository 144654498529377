import { cva, VariantProps } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

const typographyStyles = cva(
  'font-sans font-normal leading-normal break-words',
  {
    variants: {
      intent: {
        inherit: 'text-inherit',
        primary: 'text-primary',
        secondary: 'text-secondary',
        tertiary: 'text-tertiary',
        error: 'text-red-500',
        success: 'text-green-500',
      },
    },
    defaultVariants: {
      intent: 'inherit',
    },
  },
);

type TypographyProps = VariantProps<typeof typographyStyles> & {
  component?: React.ElementType;
  children?: any;
  className?: string;
  gutterBottom?: boolean;
};

export const Typography = ({
  children,
  className,
  component = 'p',
  intent = 'inherit',
  ...rest
}: TypographyProps) => {
  const Component = component as any;
  const typograhy = twMerge(
    typographyStyles({
      intent,
      className,
    }),
  );

  return (
    <Component className={typograhy} {...rest}>
      {children}
    </Component>
  );
};
