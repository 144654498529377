import React, { useContext, createContext, useReducer, useEffect } from 'react';
import { clear, remove, set } from 'local-storage';
import { ActorSubclass, Identity } from '@dfinity/agent';
import { noop } from '@scinet-inc/utils';
import { profiles_service_types } from '@scinet-inc/api';
import { useAuthentication } from 'components';
import { getProfileActor, PROFILES_INDEX_CANISTER_ID } from '../../lib';

export const reduceProfileResults = async (
  profileResults: PromiseSettledResult<any>[]
): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    let result: any = {};
    for (let settledResult of profileResults) {
      if (settledResult.status === 'fulfilled') {
        for (const [k, v] of Object.entries(settledResult.value)) {
          let value = await v;
          result[k] = value;
        }
      }
    }
    resolve(result);
  });
};

type IAuthContextProviderProps = {
  children: any;
};

type Context = {
  actor: ActorSubclass<profiles_service_types._SERVICE> | undefined;
  isProfileLoading: boolean;
  clearProfile: () => void;
  profile: profiles_service_types.Profile | undefined;
  renderProfileForm: boolean;
  updateProfileRenderState: (renderProfileForm: boolean) => void;
  updateProfile: (profile?: profiles_service_types.Profile) => void;
  createProfile: (profile?: profiles_service_types.Profile) => void;
  updateAvatar: (avatar: string) => void;
  avatarUrl: string | undefined;
};

type Action =
  | {
      type: 'set-actor';
      actor: ActorSubclass<profiles_service_types._SERVICE> | undefined;
    }
  | {
      type: 'set-profile';
      profile: profiles_service_types.Profile | undefined;
      renderProfileForm: boolean;
    }
  | {
      type: 'set-isProfileLoading';
      isProfileLoading: boolean;
    }
  | {
      type: 'set-renderProfileForm';
      renderProfileForm: boolean;
    };

const initialContext: Context = {
  actor: undefined,
  isProfileLoading: false,
  clearProfile: noop,
  renderProfileForm: false,
  profile: undefined,
  updateProfileRenderState: noop,
  updateProfile: noop,
  createProfile: noop,
  updateAvatar: noop,
  avatarUrl: undefined,
};

export const ProfileContext = createContext(initialContext);

export const ProfileContextProvider = ({
  children,
}: IAuthContextProviderProps) => {
  const reducer = (state: any, action: Action) => {
    switch (action.type) {
      case 'set-profile':
        return {
          ...state,
          isProfileLoading: false,
          renderProfileForm: action.renderProfileForm,
          profile: action.profile,
        };
      case 'set-actor':
        return {
          ...state,
          actor: action.actor,
        };
      case 'set-isProfileLoading':
        return {
          ...state,
          isProfileLoading: action.isProfileLoading,
        };
      case 'set-renderProfileForm':
        return {
          ...state,
          renderProfileForm: action.renderProfileForm,
        };
      default:
        return {
          ...state,
        };
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    profile: null,
    actor: null,
    isProfileLoading: false,
  });

  const { identity, isAuthenticated } = useAuthentication();

  const updateProfile = (
    profile?: profiles_service_types.Profile | undefined,
    renderProfileForm?: boolean
  ) => {
    if (profile) {
      set('profile', profile);
    } else {
      remove('profile');
    }
    dispatch({
      type: 'set-profile',
      profile,
      renderProfileForm: !renderProfileForm ? false : !profile ? true : false,
    });
  };

  const getProfile = (
    actor: ActorSubclass<profiles_service_types._SERVICE>
  ) => {
    dispatch({
      type: 'set-isProfileLoading',
      isProfileLoading: true,
    });

    // @ts-ignore
    actor.read(identity!.getPrincipal().toText()).then((result: any) => {
      if ('ok' in result) {
        const profile = result.ok;
        updateProfile(profile, false);
      } else {
        if ('NotAuthorized' in result.err) {
          // require a new login
          clearProfile();
        } else if ('NotFound' in result.err) {
          console.error('**not found');
          // User has deleted profile
        }
        updateProfile();
      }
    });
  };

  const initActor = async (identity: Identity | undefined) => {
    if (!identity) {
      dispatch({
        type: 'set-actor',
        actor: undefined,
      });
    } else {
      const profileActor = await getProfileActor(
        PROFILES_INDEX_CANISTER_ID,
        identity.getPrincipal().toText(),
        identity
      );
      if (profileActor) {
        dispatch({
          type: 'set-actor',
          actor: profileActor,
        });

        if (
          identity &&
          Object.keys(identity).length &&
          (!state.profile || !state.profile.imageUrl)
        ) {
          getProfile(profileActor);
        }
      } else {
        dispatch({
          type: 'set-actor',
          actor: undefined,
        });
      }
    }
  };

  useEffect(() => {
    if (identity && isAuthenticated) {
      initActor(identity);
    } else {
      initActor(undefined);
    }
  }, [isAuthenticated, identity]);

  const updateProfileRenderState = (renderProfileForm: boolean) =>
    dispatch({
      type: 'set-renderProfileForm',
      renderProfileForm,
    });

  const clearProfile = () => {
    clear();
    updateProfile(undefined, false);
    initActor(undefined);
  };

  return (
    <ProfileContext.Provider
      value={{
        ...state,
        clearProfile,
        updateProfileRenderState,
        updateProfile,
      }}>
      {children}
    </ProfileContext.Provider>
  );
};

export function useProfileContext() {
  return useContext(ProfileContext);
}
