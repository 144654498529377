import {
  inquiries_types,
  profiles_service_types,
  users_types,
} from '@scinet-inc/api';
import { Avatar, ErrorIcon, SuccessIcon, WarningIcon } from 'components';
import { useMemo } from 'react';
import { InquiryStatus, Organization } from '../../../lib';

export default function InquiryCardHeader({
  inquiry,
  inquiringOrg,
  status,
  user,
  profile,
}: {
  inquiry: inquiries_types.Inquiry;
  inquiringOrg?: Organization | null;
  status: string;
  user: users_types.User | null;
  profile: profiles_service_types.Profile | null;
}) {
  const statusIcon = useMemo(() => {
    if (status === InquiryStatus.APPROVED) {
      return <SuccessIcon />;
    } else if (status === InquiryStatus.REJECTED) {
      return <ErrorIcon />;
    } else {
      return <WarningIcon />;
    }
  }, [status]);

  return (
    <div className="flex flex-row justify-start pb-5">
      <div className="relative">
        <Avatar source={inquiringOrg?.details.imageUrl} className="w-[48px]" />
        <Avatar
          className="absolute top-0 left-4 z-2 w-[48px]"
          source={profile?.imageUrl}
        />
      </div>
      <div className="flex flex-col pl-7">
        <span className="font-bold">
          {user?.firstName} {user?.lastName}
        </span>
        <span className="text-sm">{inquiringOrg?.info.name}</span>
        <span className="text-sm">{user?.email}</span>
      </div>
      <div className="ml-auto">{statusIcon}</div>
    </div>
  );
}
