import { createContext, useCallback, useContext, useReducer } from 'react';

export type WizardFormActionType =
  | 'PREV_STEP'
  | 'NEXT_STEP'
  | 'SET_ACTIVE_STEP'
  | 'SET_STEPS'
  | 'SET_FORM_VALUES';

export type WizardFormContextType = {
  activeStep: number;
  steps: string[];
  formValues: any;
  nextStep: () => void;
  previousStep: () => void;
  setActiveStep: (index: number) => void;
  setSteps: (steps: string[]) => void;
  setFormValues: (formValues: any) => Promise<void>;
  onCompleted: () => void;
};

const initialContext = {
  activeStep: 0,
  steps: [],
  formValues: {},
};

export const WizardFormContext = createContext(null);
WizardFormContext.displayName = 'WizardFormContext';

export const useWizardFormContext = (): WizardFormContextType => {
  const context = useContext(WizardFormContext);
  if (!context) {
    throw new Error(
      'useWizardFormContext must be used within a WizardFormContextProvider',
    );
  }
  return context;
};

const WizardFormReducer = (
  state: any,
  action: { type: WizardFormActionType; payload?: any },
) => {
  const { type, payload } = action;

  switch (type) {
    case 'NEXT_STEP':
      return {
        ...state,
        activeStep:
          state.activeStep === state.steps.length - 1
            ? state.activeStep
            : state.activeStep + 1,
      };
    case 'PREV_STEP':
      return {
        ...state,
        activeStep: state.activeStep === 0 ? 0 : state.activeStep - 1,
      };
    case 'SET_ACTIVE_STEP':
      return { ...state, activeStep: action.payload };
    case 'SET_STEPS':
      return { ...state, steps: payload };
    case 'SET_FORM_VALUES':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          ...payload,
        },
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export const WizardFormContextProvider = ({
  children,
  onCompleted,
}: {
  children: JSX.Element[] | JSX.Element;
  onCompleted?: () => void;
}) => {
  const initialContextState = {
    ...initialContext,
  };

  const [state, dispatch] = useReducer(WizardFormReducer, initialContextState);

  const previousStep = () => dispatch({ type: 'PREV_STEP' });
  const nextStep = () => dispatch({ type: 'NEXT_STEP' });
  const setFormValues = useCallback(
    async (values: any) =>
      dispatch({ type: 'SET_FORM_VALUES', payload: values }),
    [dispatch],
  );
  const setActiveStep = (index: number) =>
    dispatch({ type: 'SET_ACTIVE_STEP', payload: index });
  const setSteps = useCallback(
    (steps: string[]) => dispatch({ type: 'SET_STEPS', payload: steps }),
    [dispatch],
  );

  const value = {
    ...state,
    previousStep,
    nextStep,
    setFormValues,
    setActiveStep,
    setSteps,
    onCompleted,
  };

  return (
    <WizardFormContext.Provider value={value}>
      {children}
    </WizardFormContext.Provider>
  );
};
