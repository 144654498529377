import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { users_types } from '@scinet-inc/api';
import { useUserContext } from '..';

export function UserPage() {
  const router = useRouter();
  const { query } = router;
  const { actor } = useUserContext();
  const [user, setUser] = useState<users_types.User>();
  const [fetchUserCalled, setFetchUserCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getUser = async (id: string) => {
    setFetchUserCalled(true);
    setIsLoading(true);
    actor?.read(id).then(async (getResponse: any) => {
      if ('ok' in getResponse) {
        setUser(getResponse['ok']);
      } else {
        console.error('failure', getResponse);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (query.id && actor && !fetchUserCalled) {
      getUser(query.id as string);
    }
  }, [fetchUserCalled, actor, query.id]);

  return (
    <div>
      <h3>User: {isLoading ? 'LOADING' : user?.email}</h3>{' '}
      {/* {user?.avatarUrl && <img src={user?.avatarUrl} alt="user-avatar" />} */}
    </div>
  );
}
