export const determineColor = (index: number) => {
  switch (index) {
    case 0:
      return 'primary';
    case 1:
      return 'secondary';
    case 2:
      return 'tertiary';
    default:
      return 'primary';
  }
};
