import { cx } from 'class-variance-authority';

export const AssetsIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={cx('h-6 w-6 text-white', className)}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 18V16L2 14V18H0ZM4 18V12L6 10V18H4ZM8 18V10L10 12.025V18H8ZM12 18V12.025L14 10.025V18H12ZM16 18V8L18 6V18H16ZM0 12.825V10L7 3L11 7L18 0V2.825L11 9.825L7 5.825L0 12.825Z"
        fill="white"
      />
    </svg>
  );
};
