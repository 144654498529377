import { useEffect, useMemo, useState } from 'react';
import { useAppContext } from '../../AppContext';
import { ActorResponse } from '../../../lib';
import { LicenseCard } from '../LicenseCard';
import { license_nft_metadata_v1_types } from '@scinet-inc/api';

export default function LicensesList({
  licenseIds,
  tabSelected,
}: {
  licenseIds: string[];
  tabSelected: number;
}) {
  const { licenseNFTMetadataActor } = useAppContext();
  const [licenseMetasFetched, setLicenseMetasFetched] =
    useState<boolean>(false);
  const [licenseMetadatas, setLicenseMetadatas] = useState<
    license_nft_metadata_v1_types.LicenseMeta[]
  >([]);

  useEffect(() => {
    const getMetadatas = async () => {
      const promises = licenseIds.map((license: string) => {
        return licenseNFTMetadataActor
          ?.get(license)
          .then((res: ActorResponse) => {
            if ('ok' in res) {
              return res.ok;
            }
            return null;
          });
      });

      const allMetas = await Promise.all(promises);
      setLicenseMetasFetched(true);
      setLicenseMetadatas(allMetas);
    };

    if (!licenseMetasFetched) {
      getMetadatas();
    }
  }, [licenseIds, licenseMetasFetched, licenseNFTMetadataActor]);

  const sortedLicenses: license_nft_metadata_v1_types.LicenseMeta[] =
    useMemo(() => {
      let filteredLicenses: license_nft_metadata_v1_types.LicenseMeta[] =
        licenseMetadatas;

      if (tabSelected === 1) {
        filteredLicenses = licenseMetadatas.filter(
          (license) =>
            license.properties.end_date === undefined ||
            license.properties.end_date > new Date().toISOString()
        );
      } else if (tabSelected === 2) {
        filteredLicenses = licenseMetadatas.filter(
          (license) => license.properties.end_date < new Date().toISOString()
        );
      }

      return filteredLicenses?.sort((a, b) =>
        a.updated_at < b.updated_at ? 1 : -1
      );
    }, [licenseMetadatas, tabSelected]);

  if (!licenseIds) {
    return null;
  }

  return (
    <div>
      {sortedLicenses.map(
        (
          licenseMeta: license_nft_metadata_v1_types.LicenseMeta,
          index: number
        ) => (
          <LicenseCard licenseMeta={licenseMeta} key={index} />
        )
      )}
    </div>
  );
}
