import { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button, TeamSection } from 'components';
import { users_types } from '@scinet-inc/api';
import { ActorSubclass } from '@dfinity/agent';

type Props = {
  userActor: ActorSubclass<users_types._SERVICE> | undefined;
  filesServiceUrl: string;
  teamMemberIds: string[];
  exposeEmail?: boolean;
};

export default function OrgContactSection({
  filesServiceUrl,
  teamMemberIds,
  userActor,
  exposeEmail,
}: Props) {
  const [showMembers, setShowMembers] = useState(false);
  return (
    <div className="w-full lg:w-[330px] h-auto flex flex-col border-2 border-gray-300 bg-white rounded-md p-4 mr-4 mb-2">
      <Button onClick={() => setShowMembers(true)} color="secondary">
        View Members
      </Button>
      {showMembers && (
        <div className="bg-white border-1 rounded-md h-auto w-auto">
          <Dialog onClose={() => setShowMembers(false)} open={showMembers}>
            <Transition appear show={showMembers}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={() => setShowMembers(false)}>
                <Transition.Child
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95">
                      <Dialog.Panel>
                        <TeamSection
                          team={teamMemberIds}
                          className="w-full mt-4 lg:mt-0"
                          userActor={userActor}
                          filesServiceUrl={filesServiceUrl}
                          exposeEmail={exposeEmail}
                        />
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          </Dialog>
        </div>
      )}
    </div>
  );
}
