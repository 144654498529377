import React, { useContext, createContext, useEffect, useState } from 'react';
import { ActorSubclass } from '@dfinity/agent';
import {
  elastic_search,
  elastic_search_types,
  license_nft_v1_types,
  license_nft_metadata_v1_types,
  organizations_types,
  projects,
  projects_types,
  ip_nft_v1,
  ip_nft_v1_types,
  organizations,
  organizations_teams_types,
  organizations_teams,
  projects_milestones_types,
  projects_milestones,
  white_lists,
  white_lists_types,
  inquiries,
  inquiries_types,
  license_nft_v1,
  license_nft_metadata_v1,
} from '@scinet-inc/api';

import { useAuthentication } from 'components';
import {
  getAgent,
  IP_NFT_V1_CANISTER_ID,
  ORGANIZATIONS_CANISTER_ID,
  ORGANIZATIONS_TEAMS_CANISTER_ID,
  PROJECTS_CANISTER_ID,
  PROJECTS_MILESTONES_CANISTER_ID,
  ELASTIC_SEARCH_CANISTER_ID,
  WHITE_LISTS_CANISTER_ID,
  INQUIRIES_CANISTER_ID,
  LICENSE_NFT_V1_CANISTER_ID,
  LICENSE_NFT_METADATA_V1_CANISTER_ID,
} from '../../lib';

type IAppContextProviderProps = {
  children: any;
};

type Actors = {
  inquiriesActor: ActorSubclass<inquiries_types._SERVICE> | undefined;
  ipNftActor: ActorSubclass<ip_nft_v1_types._SERVICE> | undefined;
  licenseNFTActor: ActorSubclass<license_nft_v1_types._SERVICE> | undefined;
  licenseNFTMetadataActor:
    | ActorSubclass<license_nft_metadata_v1_types._SERVICE>
    | undefined;
  orgActor: ActorSubclass<organizations_types._SERVICE> | undefined;
  projectActor: ActorSubclass<projects_types._SERVICE> | undefined;
  // assetsActor: ActorSubclass<projects_assets_types._SERVICE> | undefined;
  orgTeamActor: ActorSubclass<organizations_teams_types._SERVICE> | undefined;
  projectsMilestonesActor:
    | ActorSubclass<projects_milestones_types._SERVICE>
    | undefined;
  searchActor: ActorSubclass<elastic_search_types._SERVICE> | undefined;
  whiteListsActor: ActorSubclass<white_lists_types._SERVICE> | undefined;
};

type Context = Actors;

const initialContext: Context = {
  inquiriesActor: undefined,
  ipNftActor: undefined,
  licenseNFTActor: undefined,
  licenseNFTMetadataActor: undefined,
  orgActor: undefined,
  projectActor: undefined,
  // assetsActor: undefined,
  orgTeamActor: undefined,
  projectsMilestonesActor: undefined,
  searchActor: undefined,
  whiteListsActor: undefined,
};

export const AppContext = createContext(initialContext);
export const AppContextProvider = ({ children }: IAppContextProviderProps) => {
  const [actors, setActors] = useState<Actors>(initialContext);
  const { identity, isAuthenticated } = useAuthentication();

  const initActors = (identity?: any) => {
    const agent = getAgent(identity);

    const inquiriesActor = inquiries.createClient(
      INQUIRIES_CANISTER_ID as string,
      agent
    );

    const ipNftActor = ip_nft_v1.createClient(
      IP_NFT_V1_CANISTER_ID as string,
      agent
    );

    const licenseNFTActor = license_nft_v1.createClient(
      LICENSE_NFT_V1_CANISTER_ID as string,
      agent
    );

    const licenseNFTMetadataActor = license_nft_metadata_v1.createClient(
      LICENSE_NFT_METADATA_V1_CANISTER_ID as string,
      agent
    );

    const orgActor = organizations.createClient(
      ORGANIZATIONS_CANISTER_ID as string,
      agent
    );

    const orgTeamActor = organizations_teams.createClient(
      ORGANIZATIONS_TEAMS_CANISTER_ID as string,
      agent
    );

    const projectActor = projects.createClient(
      PROJECTS_CANISTER_ID as string,
      agent
    );

    const projectsMilestonesActor = projects_milestones.createClient(
      PROJECTS_MILESTONES_CANISTER_ID as string,
      agent
    );

    const searchActor = elastic_search.createClient(
      ELASTIC_SEARCH_CANISTER_ID,
      agent
    );

    const whiteListsActor = white_lists.createClient(
      WHITE_LISTS_CANISTER_ID as string,
      agent
    );

    setActors({
      inquiriesActor,
      ipNftActor,
      licenseNFTActor,
      licenseNFTMetadataActor,
      orgActor,
      orgTeamActor,
      projectActor,
      projectsMilestonesActor,
      searchActor,
      whiteListsActor,
    });
  };

  // const initAssetsActor = (identity?: any) => {
  //   const agent = createAgent(identity);

  //   const actor = projects_assets.createClient(
  //     process.env.NEXT_PUBLIC_PROJECTS_ASSETS_CANISTER_ID as string,
  //     agent
  //   );
  //   setAssetsActor(actor);
  // };

  // call on load
  useEffect(() => {
    initActors(identity);
  }, []);

  // allow isAuthenticated users to make authenticated requests
  useEffect(() => {
    initActors(identity);
  }, [identity, isAuthenticated]);

  return (
    <AppContext.Provider
      value={{
        ...actors,
      }}>
      {children}
    </AppContext.Provider>
  );
};

export function useAppContext() {
  return useContext(AppContext);
}
