import { Fragment, useState } from 'react';
import { useIsMobile } from '@scinet-inc/hooks';
import { Menu, Transition } from '@headlessui/react';
import { projects_types } from '@scinet-inc/api/';
import { FaEllipsisH, FaEllipsisV } from 'react-icons/fa';
// import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import DeleteProjectConfirmation from './DeleteProjectConfirmation';
import { FullProject } from '../../types';

export default function ProjectActions({
  resetFetchAndPush,
  deleteProject,
  project,
  routerPush,
  // hideActions,
  isAdmin,
  useVerticalKebab = false,
}: {
  deleteProject?: (
    id: string,
  ) => any | undefined | Promise<projects_types.Result_2>;
  project: FullProject;
  routerPush: (url: any, options?: any) => Promise<boolean>;
  hideActions?: boolean | undefined;
  resetFetchAndPush: (projectId: string) => void;
  isAdmin: boolean;
  useVerticalKebab?: boolean;
}) {
  const { isMobile } = useIsMobile();
  // const [isSelected, setIsSelected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const menuOption = isMobile ? (
    <Menu as="div" className="inline-block text-left">
      {isAdmin && (
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-full mt-2 p-1 text-xs font-medium hover:bg-black hover:bg-opacity-10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            {useVerticalKebab ? (
              <FaEllipsisV size="16px" color="gray" />
            ) : (
              <FaEllipsisH size="16px" color="gray" />
            )}
          </Menu.Button>
        </div>
      )}
      <Transition as={Fragment}>
        <div className="fixed h-full w-full z-50 left-0 top-0">
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed h-full w-full left-0 top-0 bg-black bg-opacity-50 z-[-1]"></div>
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-y-full "
            enterTo="translate-y-50"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-y-100"
            leaveTo="translate-y-full">
            {isAdmin && (
              <div className="fixed bottom-0 w-full">
                <Menu.Items className="py-2 divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          resetFetchAndPush(project.info.id);
                        }}
                        className={`${
                          active ? 'bg-violet-500 text-white' : 'text-gray-900'
                        } group flex w-full items-center px-2 py-2 text-sm`}>
                        Edit
                      </button>
                    )}
                  </Menu.Item>
                  {project.info.status !== 'published' && (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={toggleModal}
                          className={`${
                            active
                              ? 'bg-violet-500 text-white'
                              : 'text-gray-900'
                          } group flex w-full items-center px-2 py-2 text-sm`}>
                          Delete
                        </button>
                      )}
                    </Menu.Item>
                  )}
                </Menu.Items>
              </div>
            )}
          </Transition.Child>
        </div>
      </Transition>
    </Menu>
  ) : isAdmin ? (
    <Menu as="div" className="inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-full p-1 text-xs font-medium hover:bg-black hover:bg-opacity-10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          {useVerticalKebab ? (
            <FaEllipsisV size="16px" color="gray" />
          ) : (
            <FaEllipsisH size="16px" color="gray" />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute py-2 mt-2 w-40 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => {
                  resetFetchAndPush(project.info.id);
                }}
                className={`${
                  active ? 'bg-violet-500 text-white' : 'text-gray-900'
                } group flex w-full items-center px-2 py-2 text-sm`}>
                Edit
              </button>
            )}
          </Menu.Item>

          {project.info.status !== 'published' && (
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={toggleModal}
                  className={`${
                    active ? 'bg-violet-500 text-white' : 'text-gray-900'
                  } group flex w-[100%] items-center px-2 py-2 text-sm`}>
                  Delete
                </button>
              )}
            </Menu.Item>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  ) : null;

  return (
    <>
      {menuOption}
      {/* {!hideActions && (
        <Menu>
          <div>
            <Menu.Button
              className="rounded-full mt-1.5 p-1 hover:bg-purple-600 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              aria-label="like"
              onClick={(e: { stopPropagation: () => void }) => {
                e.stopPropagation();
                setIsSelected(!isSelected);
              }}>
              {isSelected ? (
                <AiFillStar size="20px" color="#d741ff" />
              ) : (
                <AiOutlineStar size="20px" color="#d741ff" />
              )}
            </Menu.Button>
          </div>
        </Menu>
      )} */}

      <DeleteProjectConfirmation
        routerPush={routerPush}
        deleteProject={deleteProject}
        toggleModal={toggleModal}
        project={project}
        isModalOpen={isModalOpen}
      />
    </>
  );
}
