import ReactCountryFlag from 'react-country-flag';
import { Typography } from 'components';
import { Organization } from '../../../../types/src';

type Props = {
  org: Organization;
  countryCode?: string | null;
};

export function OrgSite({ org, countryCode }: Props) {
  const detailsExist = Object.keys(org.details).length > 0;

  return (
    <div className="w-full lg:w-[330px] h-12 flex flex-row justify-between items-center border-2 border-gray-300 bg-white rounded-md p-4 mr-4 mb-2">
      <div
        // TODO: UPDATE SITE TO WORK WHEN WEBSITE ALREADY HAS HTTPS IN IT
        onClick={() => window.open(org?.info?.websiteUrl, '_blank')}>
        <Typography className="text-md font-medium">
          {org?.info?.websiteUrl}
        </Typography>
      </div>
      {detailsExist && (
        <ReactCountryFlag
          countryCode={countryCode!}
          svg
          style={{
            width: '2em',
            height: '1.5em',
          }}
          className="rounded-full"
        />
      )}
    </div>
  );
}
