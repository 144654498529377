import {
  inquiries_types,
  license_nft_metadata_v1_types,
  license_nft_v1_types,
  users_types,
} from '@scinet-inc/api';
import { Button } from '../../Button';
import { Typography } from '../../Typography';
import { Project } from 'scinet-types';
import { useEffect, useState } from 'react';
import { CTAConfig } from '../../types';
import { determineColor } from '../utils';

type Props = {
  getIpNft: (projectId: string) => Promise<number | null>;
  isAdmin: boolean;
  isAdminInOwnOrg: boolean;
  openCreateInquiryModal: () => void;
  openInquiriesDrawer: () => void;
  openLicensesDrawer: () => void;
  project: Project;
  routerPush: (url: any, options?: any) => Promise<boolean>;
  canCreateInquiry: boolean;
  useInquiries: () => {
    inquiries: inquiries_types.Inquiry[];
    getInquiries: (
      projectId: string,
      projectOrgId: string,
      userOrgId?: string,
    ) => void;
    isLoading: boolean;
    setInquiries: (inquiries: inquiries_types.Inquiry[]) => void;
  };
  useLicenses: () => {
    addLicense: (licenseId: string) => void;
    getLicensesForProject: (projectId: string) => void;
    getLicenseMetadata: (
      licenseId: string,
    ) => Promise<void | license_nft_metadata_v1_types.LicenseMeta | undefined>;
    licenses: license_nft_v1_types.LicenseId[];
    isLoading: boolean;
    selectedLicenseId: string;
  };
  user?: users_types.User;
  userOrganizationId?: string;
};

export default function ProjectMarket({
  getIpNft,
  isAdmin,
  isAdminInOwnOrg,
  openCreateInquiryModal,
  openInquiriesDrawer,
  openLicensesDrawer,
  project,
  routerPush,
  canCreateInquiry,
  useInquiries,
  useLicenses,
  user,
  userOrganizationId,
}: Props) {
  const [ctas, setCTAs] = useState<CTAConfig[]>([]);
  const { inquiries, getInquiries, setInquiries } = useInquiries();
  const { licenses } = useLicenses();
  const [inquiriesFetched, setInquiriesFetched] = useState(false);

  const getCtaConfig = async () => {
    const nftRes = await getIpNft(project.info.id);
    const ctaList = [];

    if (nftRes === null) {
      if (isAdmin) {
        ctaList.push({
          ctaText: 'Commercialize',
          ctaOnClick: () =>
            routerPush(`/projects/${project.info.id}/nft-wizard`),
        });
      }
      setCTAs(ctaList);
    } else {
      // if user belongs to another org, they're an admin there, AND they have not already submitted an inquiry
      //TODO: hide submit inquiry button if one already exists for the inquirer's org
      if (canCreateInquiry) {
        ctaList.push({
          ctaText: 'License',
          ctaOnClick: openCreateInquiryModal,
        });
      }
      ctaList.push({
        ctaText: 'View IP-NFT',
        ctaOnClick: () => routerPush(`/projects/${project.info.id}/nft`),
      });

      if (isAdmin) {
        ctaList.push({
          ctaText: 'Modify IP-NFT',
          ctaOnClick: () =>
            routerPush(`/projects/${project.info.id}/nft-wizard`),
        });
      }

      setCTAs(ctaList);
    }
  };

  useEffect(() => {
    if (project.info.id) {
      getCtaConfig();
    }
  }, [canCreateInquiry, project.info.id, isAdmin, inquiries.length]);

  useEffect(() => {
    if (project.info.id && user && !inquiriesFetched) {
      if (isAdmin) {
        getInquiries(project.info.id, project.info.organizationId);
        setInquiriesFetched(true);
      }
      if (
        isAdminInOwnOrg &&
        userOrganizationId &&
        userOrganizationId !== project.info.organizationId
      ) {
        getInquiries(
          project.info.id,
          project.info.organizationId,
          userOrganizationId,
        );
        setInquiriesFetched(true);
      }
    }
  }, [
    project,
    user,
    isAdmin,
    isAdminInOwnOrg,
    inquiriesFetched,
    getInquiries,
    userOrganizationId,
  ]);

  useEffect(() => {
    return () => {
      setInquiries([]);
    };
  }, []);

  return (
    <div className="border border-rhino-200 mt-4 rounded-lg p-4 bg-white w-auto">
      <div className="flex flex-col">
        <Typography className="text-xl font-bold mb-4">
          Market Opportunity
        </Typography>
        <Typography className="text-md text-gray-900 mb-4">
          IP (Intellectual Property) NFT
        </Typography>
        {ctas.length > 0 &&
          ctas.map((cta: CTAConfig, index: number) => (
            <Button
              onClick={cta.ctaOnClick}
              className="mb-2"
              color={determineColor(index)}
              key={index}>
              {cta.ctaText}
            </Button>
          ))}
        {inquiries.length > 0 && (
          <Button
            className="mb-2"
            onClick={openInquiriesDrawer}
            color={determineColor(ctas.length)}>
            View Inquiries {isAdmin ? inquiries.length : ''}
          </Button>
        )}
        {isAdmin && licenses.length > 0 && (
          <Button
            className="mb-2"
            onClick={openLicensesDrawer}
            color={determineColor(ctas.length)}>
            View Licenses {licenses.length}
          </Button>
        )}
      </div>
    </div>
  );
}
