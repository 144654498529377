import { useEffect, useState } from 'react';
import {
  inquiries_types,
  license_nft_metadata_v1_types,
  license_nft_v1_types,
  projects_types,
  users_types,
} from '@scinet-inc/api';
import { ActorSubclass } from '@dfinity/agent';
import { PROJECT_TABS } from './types';
import { OrganizationRoles, FullProject } from '../..';
import AboutProject from './AboutProject';
import ProjectActionMenuMobile from './ProjectActionMenuMobile';
import ProjectMarket from './ProjectMarket';
import ProjectHeader from './ProjectHeader';
import ProjectStatus from './ProjectStatus';
import TeamSection from './TeamSection';

type MobileProps = {
  project: FullProject;
  menuItemSelected: string;
  userActor?: ActorSubclass<users_types._SERVICE> | undefined;
  routerPush: (url: any, options?: any) => Promise<boolean>;
  assetCount?: number;
  user?: users_types.User;
  isAuthorized: (
    userId: string,
    organizationId: string,
    desiredRole: OrganizationRoles,
  ) => Promise<boolean>;
  isAdmin: boolean;
  isAdminInOwnOrg: boolean;
  getIpNft: (projectId: string) => Promise<number | null>;
  openCreateInquiryModal: () => void;
  canCreateInquiry: boolean;
  useInquiries: () => {
    inquiries: inquiries_types.Inquiry[];
    getInquiries: (
      projectId: string,
      projectOrgId: string,
      userOrgId?: string,
    ) => void;
    isLoading: boolean;
    setInquiries: (inquiries: inquiries_types.Inquiry[]) => void;
  };
  useLicenses: () => {
    addLicense: (licenseId: string) => void;
    getLicensesForProject: (projectId: string) => void;
    getLicenseMetadata: (
      licenseId: string,
    ) => Promise<void | license_nft_metadata_v1_types.LicenseMeta | undefined>;
    licenses: license_nft_v1_types.LicenseId[];
    isLoading: boolean;
    selectedLicenseId: string;
  };
  openInquiriesDrawer: () => void;
  openLicensesDrawer: () => void;
  userOrganizationId?: string;
  filesServiceUrl: string;
};

const ProjectContentDesktop = ({
  project,
  menuItemSelected,
  userActor,
  routerPush,
  isAdmin,
  isAdminInOwnOrg,
  getIpNft,
  openCreateInquiryModal,
  canCreateInquiry,
  useInquiries,
  openInquiriesDrawer,
  openLicensesDrawer,
  useLicenses,
  user,
  userOrganizationId,
  filesServiceUrl,
}: MobileProps) => {
  return (
    <div className="flex flex-row justify-between">
      <AboutProject project={project} menuItemSelected={menuItemSelected} />
      <div className="flex flex-col">
        <ProjectMarket
          routerPush={routerPush}
          project={project}
          isAdmin={isAdmin}
          isAdminInOwnOrg={isAdminInOwnOrg}
          getIpNft={getIpNft}
          openCreateInquiryModal={openCreateInquiryModal}
          openInquiriesDrawer={openInquiriesDrawer}
          openLicensesDrawer={openLicensesDrawer}
          canCreateInquiry={canCreateInquiry}
          useInquiries={useInquiries}
          useLicenses={useLicenses}
          user={user}
          userOrganizationId={userOrganizationId}
        />
        <ProjectStatus project={project} />
        <TeamSection
          team={project.details.team}
          userActor={userActor}
          filesServiceUrl={filesServiceUrl}
        />
      </div>
    </div>
  );
};

const ProjectContentMobile = ({
  project,
  menuItemSelected,
  userActor,
  routerPush,
  getIpNft,
  isAdmin,
  openCreateInquiryModal,
  canCreateInquiry,
  useInquiries,
  useLicenses,
  openInquiriesDrawer,
  openLicensesDrawer,
  isAdminInOwnOrg,
  user,
  userOrganizationId,
  filesServiceUrl,
}: MobileProps) => {
  let component;
  switch (menuItemSelected) {
    case PROJECT_TABS.OVERVIEW:
      component = (
        <AboutProject project={project} menuItemSelected={menuItemSelected} />
      );
      break;
    case PROJECT_TABS.MARKET:
      component = (
        <ProjectMarket
          routerPush={routerPush}
          project={project}
          isAdmin={isAdmin}
          isAdminInOwnOrg={isAdminInOwnOrg}
          getIpNft={getIpNft}
          openCreateInquiryModal={openCreateInquiryModal}
          canCreateInquiry={canCreateInquiry}
          useInquiries={useInquiries}
          openInquiriesDrawer={openInquiriesDrawer}
          openLicensesDrawer={openLicensesDrawer}
          useLicenses={useLicenses}
          user={user}
          userOrganizationId={userOrganizationId}
        />
      );
      break;
    case PROJECT_TABS.PATENTS:
      component = <ProjectStatus project={project} />;
      break;
    case PROJECT_TABS.TEAM:
      component = (
        <TeamSection
          team={project.details.team}
          userActor={userActor}
          filesServiceUrl={filesServiceUrl}
        />
      );
      break;
    // case PROJECT_TABS.ACTIVITY:
    //   component = <ProjectActivity />;
    //   break;
    default:
      return null;
  }

  return <div className="mb-16 w-full">{component}</div>;
};

type Props = {
  project: FullProject;
  deleteProject?: () => any | Promise<projects_types.Result_2> | undefined;
  routerPush: (url: any, options?: any) => Promise<boolean>;
  userActor?: ActorSubclass<users_types._SERVICE> | undefined;
  previewing?: boolean;
  hideActions?: boolean | undefined;
  paddingHeight?: number;
  assetCount?: number;
  user?: users_types.User;
  resetFetchAndPush: (projectId: string) => void;
  isAuthorized: (
    userId: string,
    organizationId: string,
    desiredRole: OrganizationRoles,
  ) => Promise<boolean>;
  getIpNft: (projectId: string) => Promise<number | null>;
  openCreateInquiryModal: () => void;
  canCreateInquiry: boolean;
  useInquiries: () => {
    inquiries: any[];
    getInquiries: (
      projectId: string,
      projectOrgId: string,
      userOrgId?: string,
    ) => void;
    isLoading: boolean;
    setInquiries: (inquiries: inquiries_types.Inquiry[]) => void;
  };
  useLicenses: () => {
    addLicense: (licenseId: string) => void;
    getLicensesForProject: (projectId: string) => void;
    getLicenseMetadata: (
      licenseId: string,
    ) => Promise<void | license_nft_metadata_v1_types.LicenseMeta | undefined>;
    licenses: license_nft_v1_types.LicenseId[];
    isLoading: boolean;
    selectedLicenseId: string;
  };
  openInquiriesDrawer: () => void;
  openLicensesDrawer: () => void;
  userOrganizationId?: string;
  filesServiceUrl: string;
};

export const Project = ({
  project,
  deleteProject,
  routerPush,
  userActor,
  previewing,
  hideActions,
  paddingHeight,
  assetCount,
  user,
  resetFetchAndPush,
  isAuthorized,
  getIpNft,
  openCreateInquiryModal,
  canCreateInquiry,
  useInquiries,
  openInquiriesDrawer,
  openLicensesDrawer,
  userOrganizationId,
  useLicenses,
  filesServiceUrl,
}: Props) => {
  const [menuItemSelected, setMenuItemSelected] = useState('OVERVIEW');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAdminInOwnOrg, setIsAdminInOwnOrg] = useState(false);

  const checkIfAuthorized = async () => {
    const authorized = await isAuthorized(
      user!.id,
      project.info.organizationId,
      OrganizationRoles.ADMIN,
    );

    if (authorized) {
      setIsAdmin(true);
    }
  };

  const checkIfAuthorizedInOwnOrg = async () => {
    const authorized = await isAuthorized(
      user!.id,
      userOrganizationId!,
      OrganizationRoles.ADMIN,
    );

    if (authorized) {
      setIsAdminInOwnOrg(true);
    }
  };

  useEffect(() => {
    if (user && project.info.organizationId && userOrganizationId) {
      checkIfAuthorized();
      checkIfAuthorizedInOwnOrg();
    }
  }, [isAuthorized, user, project.info.organizationId, userOrganizationId]);

  const handleSelectMenuItem = (
    _event: React.ChangeEvent<{}>,
    newValue: string,
  ) => setMenuItemSelected(newValue);

  const padding = paddingHeight ? Math.ceil(paddingHeight) : 0;

  return (
    <div className="min-h-screen w-full bg-gray-100 p-4">
      <div className="max-w-6xl m-auto" style={{ paddingBottom: padding }}>
        <ProjectHeader
          project={project}
          deleteProject={deleteProject}
          routerPush={routerPush}
          user={user}
          userActor={userActor}
          hideActions={hideActions}
          resetFetchAndPush={resetFetchAndPush}
          isAuthorized={isAuthorized}
          isAdmin={isAdmin}
          filesServiceUrl={filesServiceUrl}
        />
        <div className="block md:hidden">
          <ProjectContentMobile
            routerPush={routerPush}
            userActor={userActor}
            project={project}
            menuItemSelected={menuItemSelected}
            assetCount={assetCount}
            user={user}
            isAuthorized={isAuthorized}
            isAdminInOwnOrg={isAdminInOwnOrg}
            isAdmin={isAdmin}
            getIpNft={getIpNft}
            openCreateInquiryModal={openCreateInquiryModal}
            canCreateInquiry={canCreateInquiry}
            useInquiries={useInquiries}
            openInquiriesDrawer={openInquiriesDrawer}
            openLicensesDrawer={openLicensesDrawer}
            useLicenses={useLicenses}
            userOrganizationId={userOrganizationId}
            filesServiceUrl={filesServiceUrl}
          />
        </div>
        <div className="hidden md:block">
          <ProjectContentDesktop
            routerPush={routerPush}
            userActor={userActor}
            project={project}
            menuItemSelected={menuItemSelected}
            assetCount={assetCount}
            user={user}
            isAuthorized={isAuthorized}
            isAdmin={isAdmin}
            isAdminInOwnOrg={isAdminInOwnOrg}
            getIpNft={getIpNft}
            openCreateInquiryModal={openCreateInquiryModal}
            canCreateInquiry={canCreateInquiry}
            useInquiries={useInquiries}
            openInquiriesDrawer={openInquiriesDrawer}
            openLicensesDrawer={openLicensesDrawer}
            useLicenses={useLicenses}
            userOrganizationId={userOrganizationId}
            filesServiceUrl={filesServiceUrl}
          />
        </div>
      </div>
      <div className="block md:hidden">
        <ProjectActionMenuMobile
          menuItemSelected={menuItemSelected}
          onChange={handleSelectMenuItem}
          previewing={previewing}
          padding={padding}
        />
      </div>
    </div>
  );
};
