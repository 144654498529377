import { Fragment } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { projects_milestones_types } from '@scinet-inc/api';
import { Typography } from '../../../../Typography';

const AccordionProjectMilestones = ({
  milestones,
}: {
  milestones: projects_milestones_types.Milestone[];
}) => {
  return (
    <div className="flex flex-col">
      <Typography className="text-lg font-bold mb-2">Milestones</Typography>

      {milestones?.map((milestone, index) => (
        <div className="w-full" key={index}>
          <Disclosure as="div" className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>
                    {index + 1}. {milestone?.name}
                  </span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-purple-500`}
                  />
                </Disclosure.Button>
                <Transition
                  as={Fragment}
                  enter="transition duration-200 ease-out"
                  enterFrom="transform scale-80 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-150 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-80 opacity-0">
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm">
                    <Typography className="aboutText">
                      {milestone?.description}
                    </Typography>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
      ))}
    </div>
  );
};

export default AccordionProjectMilestones;
