import React from 'react';
import { Typography } from 'components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type Props = {
  placeholder?: string;
  label?: string;
  dateSelected: string | undefined;
  onChange: (date: any) => void;
  className?: string;
  disabled?: boolean;
};

const Datepicker = ({
  placeholder,
  label,
  dateSelected,
  onChange,
  className,
  disabled,
}: Props) => {
  return (
    <div className={`flex flex-col mt-4 w-full ${className}`}>
      <Typography className="mt-2 ml-1 mb-1 text-gray-500 text-xs font-semibold">
        {label}
      </Typography>
      <DatePicker
        disabled={disabled}
        dayClassName={(date) => {
          if (
            dateSelected &&
            date.toDateString() === new Date(dateSelected).toDateString()
          ) {
            return 'bg-purple-800';
          } else return null;
        }}
        placeholderText={placeholder}
        wrapperClassName="w-full border focus:border-purple-500"
        selected={dateSelected ? new Date(dateSelected) : undefined}
        onChange={(date) => onChange(date?.toDateString())}
        dateFormat="MM/dd/yyyy"
        className="bg-white text-black text-sm border border-gray-300 rounded px-2 py-1 w-full focus:border-purple-500 focus:ring-purple-200"
      />
    </div>
  );
};

export default Datepicker;
