import { cx } from 'class-variance-authority';

type Props = {
  className?: string;
};

export const PencilIcon = ({ className }: Props) => {
  return (
    <svg
      className={cx('h-8 text-white', className)}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.25 13.75H3.479L10.479 6.75L9.25 5.521L2.25 12.521V13.75ZM14.188 5.542L10.458 1.812L11.479 0.791996C11.8263 0.444663 12.2397 0.270996 12.719 0.270996C13.1983 0.270996 13.6113 0.444663 13.958 0.791996L15.208 2.042C15.5413 2.37533 15.708 2.78833 15.708 3.281C15.708 3.77433 15.5413 4.18766 15.208 4.521L14.188 5.542ZM1.375 15.5C1.139 15.5 0.934 15.4133 0.76 15.24C0.586667 15.066 0.5 14.861 0.5 14.625V12.125C0.5 12.0137 0.520667 11.906 0.562 11.802C0.604 11.698 0.666667 11.6043 0.75 11.521L9.229 3.042L12.958 6.771L4.479 15.25C4.39567 15.3333 4.302 15.396 4.198 15.438C4.094 15.4793 3.98633 15.5 3.875 15.5H1.375ZM9.875 6.146L9.25 5.521L10.479 6.75L9.875 6.146Z"
        fill="#610099"
      />
    </svg>
  );
};
