import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';
import {
  useAuthentication,
  Typography,
  Input,
  Select,
  Button,
  useToast,
} from 'components';
import { CameraIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { countries } from '@scinet-inc/constants';
import { profiles_service_types, users_types } from '@scinet-inc/api';
import { useUserContext } from '.';
import { useProfileContext } from '..';

const options = countries.map((country: string, i: number) => ({
  value: country,
  label: country,
}));

const EditUserForm = () => {
  const { login } = useAuthentication();
  const { emailVerified, user, actor, updateUser, avatarBase64, setAvatar } =
    useUserContext();

  const { profile } = useProfileContext();
  const [isLoading, setIsLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const { showToast } = useToast();
  const { push } = useRouter();

  const userForm = useForm<Partial<users_types.User>>({
    defaultValues: user,
  });

  const profileForm = useForm<Partial<profiles_service_types.Profile>>({
    defaultValues: profile,
  });

  const profileReset = profileForm.reset;

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = userForm;

  const logError = (errorResponse: any) => {
    // eslint-disable-next-line no-console
    console.error('failure', errorResponse);
  };

  // handle Profile creation / updating when image is changed
  const onDrop = useCallback(
    async (acceptedFiles: any[]) => {
      setFileSizeError(false);
      const [file] = acceptedFiles;
      //const convertedFile = await convertToBase64(file);

      // if (file.size > 50000) {
      //   setFileSizeError(true);
      //   return setIsLoading(false);
      // }

      //setTempAvatar(convertedFile);
      setAvatar(file);

      //create profile if user doesn't have one yet
      // if (!profile) {
      //   try {
      //     const createProfileResult = await profileActorClient.update<
      //       profiles_service_types.ProfilesService['addProfile']
      //     >(
      //       'profiles',
      //       identity?.getPrincipal().toText() as string,
      //       (actor: { addProfile: (arg0: { imageUrl: string }) => any }) =>
      //         actor.addProfile({ imageUrl: convertedFile })
      //     );

      //     if ('ok' in createProfileResult) {
      //       updateProfile(createProfileResult.ok);
      //     } else {
      //       console.error('error creating profile', createProfileResult.err);
      //     }
      //     setIsLoading(false);
      //   } catch (e) {
      //     console.error('failed to create profile', e);
      //     setIsLoading(false);
      //   }
      // }

      // update profile if user has one already
      // if (profile) {
      //   profileActor
      //     ?.updateProfile({
      //       ...profile,
      //       imageUrl: convertedFile,
      //     })
      //     .then(async (createResponse: any) => {
      //       if ('ok' in createResponse) {
      //         updateProfile(createResponse.ok);
      //         showToast({
      //           message: 'Account updated.',
      //           variant: 'success',
      //         });
      //       } else {
      //         logError(createResponse);
      //         setSubmissionError('Try a different photo');
      //       }
      //       setIsLoading(false);
      //     });
      // }
    },
    [setFileSizeError, setAvatar]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // handle submission for User data
  const onSubmit = (data: any) => {
    setSubmissionError(null);

    const payload = { ...data };
    setIsLoading(true);
    if (!isDirty) {
      setSubmissionError('User data is current');
      setIsLoading(false);
    }
    if (user && isDirty) {
      actor?.update(payload).then(async (updateResponse: any) => {
        if ('ok' in updateResponse) {
          updateUser(updateResponse.ok);
          showToast({
            message: 'Account updated.',
            variant: 'success',
          });
        } else {
          logError(updateResponse);
          setSubmissionError('Try a different email');
        }
        setIsLoading(false);
      });
    } else {
      actor?.create(payload).then(async (createResponse: any) => {
        if ('ok' in createResponse) {
          updateUser(createResponse.ok);
          showToast({
            message: 'Account created.',
            variant: 'success',
          });
        } else {
          logError(createResponse);
          setSubmissionError('Try a different email');
          setIsLoading(false);
        }
        setIsLoading(false);
      });
    }
  };

  const handleReset = () => {
    profileReset({
      imageUrl: profile!.imageUrl,
    });
    reset({
      firstName: user!.firstName,
      lastName: user!.lastName,
      email: user!.email,
      country: user!.country,
    });
    setSubmissionError('');
    setFileSizeError(false);
    push('/');
  };

  return (
    <div className="flex flex-col justify-center w-full lg:w-96 m-auto px-4 lg:px-0">
      <Typography className="font-semibold mx-auto pt-2 text-xl lg:text-2xl">
        {user ? 'Edit Your Account' : 'Create Your Free Account'}
      </Typography>
      {!user && (
        <div className="flex flex-row m-auto mb-2" onClick={login}>
          <Typography className="text-sm">Already have an account?</Typography>
          <Typography className="text-sm ml-2 text-purple-600">
            Sign In
          </Typography>
        </div>
      )}
      <div>
        <div className="w-full cursor-default">
          <div className="flex flex-col-reverse w-full  h-auto pb-2 lg:flex-row ">
            <div {...getRootProps()} className="m-auto">
              {/** @ts-ignore */}
              <input {...getInputProps()} />
              <div
                className={cx(
                  'flex flex-col bg-gray-200 rounded-full w-16 h-16 justify-center items-center cursor-pointer',
                  {
                    'border-[#2196f3] bg-purple-200': isDragActive,
                  }
                )}>
                {isDragActive ? (
                  <CloudArrowUpIcon className="text-gray-400 flex flex-col bg-transparent rounded-full w-8 h-8 justify-center items-center" />
                ) : avatarBase64 ? (
                  // eslint-disable-next-line @next/next/no-img-element
                  <img
                    height="160px"
                    width="160px"
                    src={avatarBase64}
                    alt="profile-pic"
                    className={cx(
                      'flex flex-col bg-gray-200 rounded-full w-16 h-16 justify-center items-center',
                      {
                        'border-[#2196f3] bg-purple-200': isDragActive,
                      }
                    )}
                  />
                ) : (
                  <CameraIcon className="text-gray-400 bg-transparent w-8 h-8 rounded-full position-relative " />
                )}
              </div>
            </div>
          </div>
          {fileSizeError && (
            <div className="w-full flex">
              <p className="text-red-600 m-auto">File must be under 50kb</p>
            </div>
          )}
        </div>
        <Controller
          name="firstName"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'First Name is required',
            },
            maxLength: { value: 20, message: 'First Name is too long' },
          }}
          render={({ field }) => (
            <Input
              value={field.value || ''}
              onChange={field.onChange}
              ref={field.ref}
              label="First Name"
              error={errors.firstName?.message || errors.firstName?.type}
              maxLength={20}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          rules={{
            required: { value: true, message: 'Last Name is required' },
            maxLength: { value: 30, message: 'Last Name is too long' },
          }}
          render={({ field }) => (
            <Input
              value={field.value || ''}
              onChange={field.onChange}
              ref={field.ref}
              label="Last Name"
              error={errors.lastName?.message || errors.lastName?.type}
              maxLength={30}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{
            required: { value: true, message: 'Email is required' },
            maxLength: { value: 50, message: 'Email is too long' },
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Email does not match email format',
            },
          }}
          render={({ field }) => (
            <Input
              value={field.value || ''}
              onChange={field.onChange}
              ref={field.ref}
              label="Email"
              type="email"
              error={errors.email?.message || errors.email?.type}
              maxLength={50}
              disabled={emailVerified?.emailVerified}
            />
          )}
        />
        <Controller
          name="country"
          control={control}
          rules={{
            required: { value: true, message: 'Country is required' },
          }}
          render={({ field }) => (
            <Select
              value={field.value || ''}
              onChange={field.onChange}
              ref={field.ref}
              error={errors.country?.message}
              label="Select Country"
              options={options}
              className="w-full m-auto"
            />
          )}
        />
      </div>
      <div className="pt-0 w-full lg:max-w-96">
        <form onSubmit={handleSubmit(onSubmit)}>
          {submissionError && (
            <div className="w-full text-center">
              <Typography className="m-auto text-red-600 ">
                {submissionError}
              </Typography>
            </div>
          )}
          <div className="w-full">
            <Button
              disabled={!isDirty}
              loading={isLoading}
              type="submit"
              className="w-full"
              color={isLoading ? 'secondary' : 'primary'}>
              {isLoading ? 'Saving...' : user ? 'Update' : 'Save'}
            </Button>
            <Button
              color={isLoading ? 'tertiary' : 'secondary'}
              className="w-full mb-2 mt-4"
              onClick={handleReset}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUserForm;
