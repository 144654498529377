import { ComponentClass, useCallback, useState } from 'react';
import type { AppProps } from 'next/app';
import {
  DocumentationIcon,
  Layout,
  SciNetLogoDark,
  SciNetLogoLight,
  SupportIcon,
  useAuthentication,
  UserProfile,
  ProjectContextProvider,
  OrgContextProvider,
} from 'components';
import { useRouter } from 'next/router';
import cx from 'classnames';
import Link from 'next/link';
import {
  getFullProject,
  getOrganization,
  menuMap,
  ORGANIZATIONS_INDEX_CANISTER_ID,
} from '../../lib';
import { useProfileContext } from '../Profile';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { useUserContext } from '../User';
import { useAppContext } from '../AppContext';
import { InquiriesContextProvider } from '../../contexts';
import { LicensesContextProvider } from '../../contexts/LicensesContext';

export default function Main({ Component, pageProps }: Partial<AppProps>) {
  const router = useRouter();
  const { identity, isAuthenticated, logout } = useAuthentication();
  const { orgActor, orgTeamActor, projectActor, projectsMilestonesActor } =
    useAppContext();
  const { clearUser } = useUserContext();
  const { clearProfile } = useProfileContext();
  const [secondColumnOpen, setSecondColumnOpen] = useState(false);
  const { user } = useUserContext();

  const logoLight = <SciNetLogoLight className="h-8 mt-2" />;
  const logoDark = <SciNetLogoDark className="ml-auto h-6 mt-2" />;

  const logoutClick = useCallback(() => {
    clearUser();
    clearProfile();
    logout();
  }, [clearProfile, clearUser, logout]);

  const footerNode = [
    {
      text: 'Support',
      href: 'https://scinet.atlassian.net/servicedesk/customer/portal/1',
      // @ts-ignore
      Icon: SupportIcon as ComponentClass<any>,
      onClick: null,
    },
    {
      text: 'Documentation',
      href: 'https://scinet.notion.site/Welcome-to-SCINET-8809a679ded14820b660e841bd96f659',
      // @ts-ignore
      Icon: DocumentationIcon as ComponentClass<any>,
      onClick: null,
    },
    isAuthenticated && {
      text: 'Log Out',
      onClick: logoutClick,
      // @ts-ignore
      Icon: ArrowRightOnRectangleIcon as ComponentClass<any>,
      href: '/',
    },
  ];
  isAuthenticated
    ? {
        text: 'Log Out',
        onClick: logoutClick,
        // @ts-ignore
        Icon: ArrowRightOnRectangleIcon as ComponentClass<any>,
        href: '/',
      }
    : null;

  // const secondColumnNode = (
  //   <Wallet
  //     secondColumnOpen={secondColumnOpen}
  //     setSecondColumnOpen={setSecondColumnOpen}
  //   />
  // );

  // const actionsNode = (
  //   <>
  //     {user && (
  //       <Button
  //         className="fixed right-12 md:right-16"
  //         color="tertiary"
  //         onClick={() => setSecondColumnOpen!(!secondColumnOpen)}>
  //         <WalletIcon className="h-4 w-4" />
  //       </Button>
  //     )}
  //   </>
  // );

  return (
    <Layout
      id="app"
      mainClassName={cx(
        'md:pl-24 md:pr-12 lg:min-h-[calc(100vh-4rem)] overflow-x-hidden ease-in-out duration-300',
        {
          'md:w-[calc(100vw-24rem)] ease-in-out duration-300 h-[100vh]':
            secondColumnOpen,
        }
      )}
      navItems={menuMap}
      // @ts-ignore
      footerNode={footerNode}
      profileSection={
        <UserProfile
          user={user}
          useUserContext={useUserContext}
          useProfileContext={useProfileContext}
          router={router}
        />
      }
      router={router}
      logoLight={logoLight}
      logoDark={logoDark}
      Link={Link}>
      <OrgContextProvider
        useRouter={useRouter}
        organizationsIndexCanisterId={ORGANIZATIONS_INDEX_CANISTER_ID}
        orgActor={orgActor}
        orgTeamActor={orgTeamActor}
        getOrganizationDetails={getOrganization}>
        <ProjectContextProvider
          useRouter={useRouter}
          identity={identity}
          projectsBaseActor={projectActor}
          projectsMilestonesActor={projectsMilestonesActor}
          getFullProject={getFullProject}>
          <InquiriesContextProvider>
            <LicensesContextProvider>
              {/** @ts-ignore */}
              <Component {...pageProps} />
            </LicensesContextProvider>
          </InquiriesContextProvider>
        </ProjectContextProvider>
      </OrgContextProvider>
    </Layout>
  );
}
