type Props = {
  className?: string;
};

export const TickUpIcon = ({ className }: Props) => {
  return (
    <svg
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M0 4L4 0L8 4H0Z" fill="#00CC88" />
    </svg>
  );
};
