import {
  // AssetsIcon,
  HomeIcon,
  OrganizationsIcon,
  ProjectsIcon,
  SearchIcon,
} from 'components';
// import { InfoIcon } from '../../components/Icons';

// const ABOUT = 'ABOUT';
const HOME = 'HOME';
const PROJECTS = 'PROJECTS';
const ORGANIZATIONS = 'ORGANIZATIONS';
const SEARCH = 'SEARCH';
// const ASSETS = 'ASSETS';

export const menuMap = {
  [HOME]: {
    route: '/',
    routeLabel: 'Home',
    parentId: null,
    iconType: HOME,
    renderIcon: () => <HomeIcon />,
  },
  [ORGANIZATIONS]: {
    route: '/organizations',
    routeLabel: 'Organizations',
    parentId: null,
    iconType: HOME,
    renderIcon: () => <OrganizationsIcon />,
  },
  [PROJECTS]: {
    route: '/projects',
    routeLabel: 'Projects',
    parentId: null,
    iconType: HOME,
    renderIcon: () => <ProjectsIcon />,
  },
  // [ASSETS]: {
  //   route: '/assets',
  //   routeLabel: 'My Assets',
  //   parentId: null,
  //   iconType: HOME,
  //   renderIcon: () => <AssetsIcon className=" ml-1 mr-1" />,
  // },
  [SEARCH]: {
    route: '/search',
    routeLabel: 'Search',
    parentId: null,
    iconType: HOME,
    renderIcon: () => <SearchIcon />,
  },
  // [ABOUT]: {
  //   route: '/about',
  //   routeLabel: 'About',
  //   parentId: null,
  //   icon: InfoIcon,
  // },
};
