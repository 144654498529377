import { ReactNode, useEffect, useRef, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

import { Typography } from '../Typography';

export const Accordion = ({
  index,
  title,
  children,
}: {
  index?: number;
  title?: string;
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState('0px');

  useEffect(() => {
    if (isOpen && contentRef.current) {
      setMaxHeight(`${contentRef?.current?.scrollHeight}px`);
    } else {
      setMaxHeight('0px');
    }
  }, [isOpen]);

  return (
    <div className="flex flex-col border border-gray-200 rounded-lg overflow-hidden">
      <button
        className="flex justify-between items-center p-5 w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
        type="button">
        {index && (
          <Typography className="text-electric-violet-600 font-bold">
            {index + 1}.
          </Typography>
        )}
        <span>{title}</span>
        <ChevronDownIcon
          className={`h-5 transition-transform duration-500 ${
            isOpen ? 'rotate-180' : ''
          }`}
          style={isOpen ? { transform: 'rotate(180deg)' } : {}}
        />
      </button>
      <div
        style={{ maxHeight: isOpen && maxHeight ? maxHeight : 0 }}
        ref={contentRef}
        className="transition-[max-height] duration-500 ease-in-out">
        <div className="p-5 border-t border-gray-200">{children}</div>
      </div>
    </div>
  );
};
