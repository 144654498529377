import { projects_types } from '@scinet-inc/api';
import { FullProject } from '../../types';
import ProjectActions from './ProjectActions';
import { Typography } from '../../Typography';

export default function ProjectCardHeader({
  deleteProject,
  project,
  routerPush,
  hideActions,
  resetFetchAndPush,
  isAdmin,
}: {
  deleteProject: (
    id: string,
  ) => any | undefined | Promise<projects_types.Result>;
  project: FullProject;
  routerPush: (url: any, options?: any) => Promise<boolean>;
  hideActions?: boolean;
  resetFetchAndPush: (projectId: string) => void;
  isAdmin: boolean;
}) {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="flex flex-row w-full justify-between items-center">
      <div className="flex flex-row w-3/4">
        <Typography className="text-xs">{project.info.patentStatus}</Typography>
      </div>
      <ProjectActions
        resetFetchAndPush={resetFetchAndPush}
        deleteProject={deleteProject}
        project={project}
        routerPush={routerPush}
        hideActions={hideActions}
        isAdmin={isAdmin}
      />
    </div>
  );
}
