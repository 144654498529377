type Props = {
  tags?: string[];
  className?: string;
  tagClassName?: string;
};

export default function Tags({ tags, tagClassName, className }: Props) {
  if (!tags?.length) {
    return null;
  }

  const mappedTags = tags?.map((tag: string, i: number) => (
    <div key={i} className={tagClassName + ' text-teal-900 bg-teal-100'}>
      {tag}
    </div>
  ));
  return <div className={className}>{mappedTags}</div>;
}
