type Props = {
  className?: string;
};

export const CopyIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.25 15.8125C5.90278 15.8125 5.60764 15.691 5.36458 15.4479C5.12153 15.2048 5 14.9097 5 14.5625V2.89581C5 2.54859 5.12153 2.25345 5.36458 2.0104C5.60764 1.76734 5.90278 1.64581 6.25 1.64581H15.4167C15.7639 1.64581 16.059 1.76734 16.3021 2.0104C16.5451 2.25345 16.6667 2.54859 16.6667 2.89581V14.5625C16.6667 14.9097 16.5451 15.2048 16.3021 15.4479C16.059 15.691 15.7639 15.8125 15.4167 15.8125H6.25ZM6.25 14.5625H15.4167V2.89581H6.25V14.5625ZM3.75 18.3125C3.40278 18.3125 3.10764 18.191 2.86458 17.9479C2.62153 17.7048 2.5 17.4097 2.5 17.0625V5.12498C2.5 4.94442 2.55903 4.79512 2.67708 4.67706C2.79514 4.55901 2.94444 4.49998 3.125 4.49998C3.30556 4.49998 3.45486 4.55901 3.57292 4.67706C3.69097 4.79512 3.75 4.94442 3.75 5.12498V17.0625H13C13.1806 17.0625 13.3299 17.1215 13.4479 17.2396C13.566 17.3576 13.625 17.5069 13.625 17.6875C13.625 17.868 13.566 18.0173 13.4479 18.1354C13.3299 18.2535 13.1806 18.3125 13 18.3125H3.75ZM6.25 2.89581V14.5625V2.89581Z"
        fill="#7C8596"
      />
    </svg>
  );
};
