import { cx } from 'class-variance-authority';

type Props = {
  className?: string;
};

export const SearchIcon = ({ className }: Props) => {
  return (
    <svg
      className={cx('h-8 text-white', className)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0254 20.05L13.2504 14.3C12.7504 14.7167 12.1754 15.0417 11.5254 15.275C10.8754 15.5083 10.2087 15.625 9.52539 15.625C7.80872 15.625 6.35872 15.0333 5.17539 13.85C3.99206 12.6667 3.40039 11.2167 3.40039 9.5C3.40039 7.8 3.99206 6.354 5.17539 5.162C6.35872 3.97067 7.80872 3.375 9.52539 3.375C11.2254 3.375 12.6671 3.96667 13.8504 5.15C15.0337 6.33333 15.6254 7.78333 15.6254 9.5C15.6254 10.2167 15.5087 10.9 15.2754 11.55C15.0421 12.2 14.7254 12.7667 14.3254 13.25L20.1004 19.025C20.2337 19.1583 20.3004 19.325 20.3004 19.525C20.3004 19.725 20.2254 19.9 20.0754 20.05C19.9254 20.2 19.7464 20.275 19.5384 20.275C19.3297 20.275 19.1587 20.2 19.0254 20.05V20.05ZM9.52539 14.125C10.8087 14.125 11.8964 13.675 12.7884 12.775C13.6797 11.875 14.1254 10.7833 14.1254 9.5C14.1254 8.21667 13.6797 7.125 12.7884 6.225C11.8964 5.325 10.8087 4.875 9.52539 4.875C8.22539 4.875 7.12972 5.325 6.23839 6.225C5.34639 7.125 4.90039 8.21667 4.90039 9.5C4.90039 10.7833 5.34639 11.875 6.23839 12.775C7.12972 13.675 8.22539 14.125 9.52539 14.125V14.125Z"
        fill="white"
      />
    </svg>
  );
};
