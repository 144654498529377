import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button, Typography } from 'components';
import { Fragment, useState } from 'react';
import { ActorResponse } from 'scinet-types';
import { useUserContext } from '../../../components';
import { sendVerificationEmail } from '../../utils';

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
};

const VerificationEmailSent = ({ onClose }: { onClose: () => void }) => {
  return (
    <div>
      <Typography className="font-semibold mx-auto pt-2 text-xl lg:text-2xl">
        Verification Email Sent!
      </Typography>
      <Typography className="text-center mt-4">
        Please follow the link in your email to complete the verification
        process.
      </Typography>
      <Button color="primary" className="w-full mt-4" onClick={() => onClose()}>
        {'Done'}
      </Button>
    </div>
  );
};

export const EmailVerificationRequiredModal = ({
  isModalOpen,
  onClose,
}: Props) => {
  const { emailVerificationsActor, setEmailVerified, user } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const sendEmail = async () => {
    // reset email with a new expiration
    // if success, send a new email
    if (!user?.email) return;
    setIsLoading(true);
    emailVerificationsActor
      ?.resetExpiration()
      .then(async (res: ActorResponse) => {
        if ('ok' in res) {
          const successRes = res.ok;
          setEmailSent(true);
          const href = `${window.location.origin}/users/${successRes.id}/verify/${successRes.token}`;
          sendVerificationEmail(user.email, href);
          setIsLoading(false);
          setEmailVerified(successRes);
        } else {
          // console.error('failure', getResponse);
          setIsLoading(false);
        }
      });
  };

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog onClose={onClose} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed backdrop-blur-sm inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="flex fixed inset-0 z-10 overflow-y-auto md:p-5">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <Dialog.Panel
              className="mx-auto max-w-lg rounded bg-white p-5 lg:relative flex flex-col fixed bottom-0 lg:mt-auto lg:mb-auto"
              style={{ height: 'fit-content' }}>
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="rounded-md bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={onClose}>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {emailSent ? (
                <VerificationEmailSent onClose={onClose} />
              ) : (
                <>
                  <Typography className="font-semibold mx-auto pt-2 text-xl lg:text-2xl">
                    Email Verification Required
                  </Typography>
                  <Typography className="text-center">
                    Please verify your email address before creating an
                    organization.
                  </Typography>
                  <div className="w-full mt-auto flex flex-col lg:flex-row">
                    <Button
                      type="button"
                      onClick={sendEmail}
                      className="w-full mb-2 mt-4 lg:mr-2 lg:ml-2"
                      color={isLoading ? 'secondary' : 'primary'}
                      loading={isLoading ? true : false}>
                      {isLoading ? 'Sending...' : 'Resend Email'}
                    </Button>
                  </div>
                </>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
