import { dateFactory } from '@scinet-inc/utils';
import { FILES_SERVICE_URL, NETWORK } from '../constants';

export const getBigIntTime = (timestamp: BigInt) => {
  const date = new Date(Number(timestamp) / 1000000);
  return dateFactory(date).toString();
};

export const isStaging = () => {
  return NETWORK === 'staging';
};

export const isProd = () => {
  return NETWORK === 'production';
};

export const getGravityBaseUrl = () => {
  let base;
  if (isStaging()) {
    base = 'https://staging-gravity.scinet.one';
  } else if (isProd()) {
    base = 'https://gravity.scinet.one';
  } else {
    base = 'https://localhost:3001';
  }

  return base;
};

export const getSciFiBaseUrl = () => {
  let base;
  if (isStaging()) {
    base = 'https://staging-app.scinet.one';
  } else if (isProd()) {
    base = 'https://app.scinet.one';
  } else {
    base = 'https://localhost:3000';
  }

  return base;
};

export const getAvatarRequestPath = (id?: string) => {
  const project = isStaging() ? 'scinet-staging.appspot.com' : 'scinet-prod.appspot.com';
  return `https://storage.googleapis.com/${project}/user-avatar/${id}/avatar.webp`
}



