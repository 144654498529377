import { Identity } from '@dfinity/agent';
import { FILES_SERVICE_URL } from '../constants';

export type FileServicesClientConfig = {
  identity?: Identity | undefined;
  rootUrl?: string;
};


// Base class for interacting with Cloud Services
// - Initializes the client with the project ID and identity
// - Defines a fetch method that appends auth headers to every request
export class FilesServiceClient {
  rootUrl: string;
  identity?: Identity | undefined;
  token?: string | undefined;
  fileServiceEndpoint: string;

  constructor(config: FileServicesClientConfig
  ) {
    this.identity = config.identity;
    this.fileServiceEndpoint = FILES_SERVICE_URL
    this.rootUrl = config.rootUrl || '';
  }

  setToken(token: string) {
    this.token = token;
  }

  //  append auth headers and other options to every fetch request
  async _fetch(url: string | URL, options: RequestInit) {
    if (!this.identity) {
      throw new Error('No identity provided');
    }
    if (!this.token) {
      console.warn('No token set');
    }

    const identity = this.identity.getPrincipal().toText();

    return fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${this.token || ''}`,
        Identity: identity,
      },
    });
  }

  async upload(file: File) {
    const { name } = file;
    const url = new URL(`${this.rootUrl}/${name}`);
    // get signed upload url
    const response = await this._fetch(url, {
      headers: {
        'Content-Type': file.type,
      },
      method: 'POST',
    });
    const json = await response.json();
    const uploadUrl = json.uploadUrl

    // perform upload
    await fetch(uploadUrl, {
      method: 'PUT',
      body: file,
    });
  }

  async download(filename: string) {
    const url = new URL(`${this.rootUrl}/${filename}`);
    try {
      const resp = await this._fetch(url, {
        method: 'GET',
      })

      const blob = await resp.blob();
      const objUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objUrl;
      a.download = filename;
      a.click();
    } catch (e) {
      console.error('Error downloading file', e);
    }
  }

  async list() {
    const url = new URL(this.rootUrl);
    const response = await this._fetch(url, {
      method: 'GET',
    });
    const json = await response.json();
    return json;
  }

  async delete(filename: string) {
    const url = new URL(`${this.rootUrl}/${filename}`);
    const response = await this._fetch(url, {
      method: 'DELETE',
    });
    const json = await response.json();
    return json;
  }
}
