import { useRouter } from 'next/router';
import {
  SingleOrg as OrgPage,
  Spinner,
  useAuthentication,
  useOrgContext,
  useProjectContext,
} from 'components';
import {
  FILES_SERVICE_URL,
  countryObjectList,
  getCountryCode,
  getIpNftForProject,
  getProjectDetails,
  isAuthorized,
} from '../../lib';
import { useUserContext } from '../User';
import { useAppContext } from '../AppContext';

type Props = {
  pushFooter?: boolean;
};

export default function SingleOrg({ pushFooter = false }: Props) {
  const { push } = useRouter();
  const { ipNftActor } = useAppContext();
  const { identity } = useAuthentication();
  const { org, isLoading } = useOrgContext();
  const { user, actor } = useUserContext();
  const { resetFetchAndPush } = useProjectContext();
  const { orgTeamActor, projectActor, projectsMilestonesActor } =
    useAppContext();

  const getIpNft = async (projectId: string) => {
    return await getIpNftForProject(projectId, ipNftActor);
  };

  if (isLoading) {
    return (
      <div className="mt-16">
        <Spinner />
      </div>
    );
  }

  const countryCode = getCountryCode(org.details.country, countryObjectList);

  return (
    <OrgPage
      org={org}
      user={user}
      userActor={actor}
      filesServiceUrl={FILES_SERVICE_URL}
      orgTeamActor={orgTeamActor}
      push={push}
      projectsBaseActor={projectActor}
      identity={identity}
      getProjectDetails={getProjectDetails}
      projectsMilestonesActor={projectsMilestonesActor}
      isAuthorized={isAuthorized}
      resetFetchAndPush={resetFetchAndPush}
      pushFooter={pushFooter}
      getIpNft={getIpNft}
      countryCode={countryCode}
    />
  );
}
