import { cx } from 'class-variance-authority';

type Props = {
  className?: string;
};

export const SupportIcon = ({ className }: Props) => {
  return (
    <svg
      className={cx('h-8 textWhite', className)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 9.00004c0.00011 -0.54997 0.15139 -1.08933 0.43732 -1.55913s0.69548 -0.85196 1.18398 -1.10472c0.4884 -0.25275 1.037 -0.36637 1.5856 -0.32843 0.5487 0.03793 1.0764 0.22596 1.5254 0.54353 0.449 0.31757 0.8021 0.75246 1.0206 1.25714 0.2186 0.50468 0.2942 1.05973 0.2186 1.60448 -0.0756 0.54475 -0.2994 1.05829 -0.6471 1.48439 -0.3477 0.4261 -0.8059 0.7484 -1.3244 0.9317 -0.2926 0.1035 -0.5459 0.2951 -0.725 0.5485 -0.1791 0.2535 -0.2752 0.5562 -0.275 0.8665v1.006"
        strokeWidth="1.5"></path>
      <path
        stroke="#ffffff"
        d="M12 18c-0.2071 0 -0.375 -0.1679 -0.375 -0.375s0.1679 -0.375 0.375 -0.375"
        strokeWidth="1.5"></path>
      <path
        stroke="#ffffff"
        d="M12 18c0.2071 0 0.375 -0.1679 0.375 -0.375s-0.1679 -0.375 -0.375 -0.375"
        strokeWidth="1.5"></path>
      <path
        stroke="#ffffff"
        strokeMiterlimit="10"
        d="M12 23.25c6.2132 0 11.25 -5.0368 11.25 -11.25S18.2132 0.75 12 0.75 0.75 5.7868 0.75 12 5.7868 23.25 12 23.25Z"
        strokeWidth="1.5"></path>
    </svg>
  );
};
