import { Typography } from '../Typography';

interface Props {
  percentage: number;
}

export const ProgressBar = ({ percentage }: Props) => {
  const width = percentage < 100 ? percentage + 1.5 : 100;

  return (
    <div className="w-full mt-2">

      <div
        style={{ boxShadow: 'inset 0px 0px 10px rgba(0,0,0,0.2)' }}
        className={
          'flex h-6 bg-gray-100 rounded-full ring-1 ring-gray-200 shadow-inner'
        }>
        <div
          style={{
            width: `${width}% `,
            background: 'linear-gradient(90deg, #F6E5FF 0%, #ECCCFF 100%)',
          }}
          className="rounded-full -mr-4"></div>
        <div
          style={{ boxShadow: '0px 0px 10px rgba(0,0,0,0.2)' }}
          className="flex flex-row justify-center align-center h-full w-12 rounded-full bg-purple-400 text-xs">
          <Typography className="flex items-center">{percentage}%</Typography>
        </div>
      </div>
    </div>
  );
};
