import { FolderIcon, MembersIcon, MicroscopeIcon } from '../../Icons';
import { Typography } from '../../';
import cx from 'classnames';

type Props = {
  tab: 'Overview' | 'Projects' | 'Members';
  setTabSelected: React.Dispatch<React.SetStateAction<ORG_TABS>>;
};

enum ORG_TABS {
  OVERVIEW = 'Overview',
  PROJECTS = 'Projects',
  MEMBERS = 'Members',
}

export function OrgTabs({ tab, setTabSelected }: Props) {
  return (
    <div className="flex flex-row justify-around">
      <div
        onClick={() => setTabSelected(ORG_TABS.OVERVIEW)}
        className={cx(
          'text-gray-500 w-full flex flex-col justify-center items-center pl-8',
          {
            'text-purple-700': tab === ORG_TABS.OVERVIEW,
          },
        )}>
        <MicroscopeIcon
          className="ml-2"
          color={cx(undefined, { '#610099': tab === ORG_TABS.OVERVIEW })}
        />
        <Typography className="text-sm">Overview</Typography>
        <div
          className={cx('h-1', {
            'w-full bg-purple-700': tab === ORG_TABS.OVERVIEW,
          })}></div>
      </div>
      <div
        onClick={() => setTabSelected(ORG_TABS.PROJECTS)}
        className={cx(
          'text-gray-500 w-full flex flex-col justify-center items-center -mt-0.5',
          {
            'text-purple-700': tab === ORG_TABS.PROJECTS,
          },
        )}>
        <FolderIcon
          color={cx(undefined, { '#610099': tab === ORG_TABS.PROJECTS })}
        />
        <Typography className="text-sm">Projects</Typography>
        <div
          className={cx('h-1 mt-0.5', {
            'w-full bg-purple-700': tab === ORG_TABS.PROJECTS,
          })}></div>
      </div>
      <div
        onClick={() => setTabSelected(ORG_TABS.MEMBERS)}
        className={cx(
          'text-gray-500 w-full flex flex-col justify-center items-center pr-8',
          {
            'text-purple-700': tab === ORG_TABS.MEMBERS,
          },
        )}>
        <MembersIcon
          className="pt-1"
          color={cx(undefined, { '#610099': tab === ORG_TABS.MEMBERS })}
        />
        <Typography className="text-sm">Members</Typography>
        <div
          className={cx('h-1', {
            'w-full bg-purple-700': tab === ORG_TABS.MEMBERS,
          })}></div>
      </div>
    </div>
  );
}
