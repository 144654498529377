import { useMemo, useState } from 'react';
import { Input } from 'components';
import { users_types } from '@scinet-inc/api';
import UserOption from './UserOption';

type UserHash = { [key: string]: users_types.User };

type Props = {
  team: string[];
  userHash: UserHash;
  onSelect: (value: string) => void;
};

export default function MemberSelector({ onSelect, userHash, team }: Props) {
  const [inputValue, setInputValue] = useState('');
  const filteredOptions = Object.values(userHash).filter((u) =>
    team.findIndex((userId: string) => u.id === userId)
  );

  const mappedOptions = useMemo(() => {
    if (inputValue === '') {
      return [];
    }

    return filteredOptions
      .filter((u) => {
        const { firstName, lastName, email } = u;
        return `${firstName} ${lastName} ${email}`
          .toLowerCase()
          .replace(' ', '')
          .includes(inputValue);
      })
      .map((u, i) => {
        return <UserOption user={u} key={i} onClick={onSelect} />;
      });
  }, [inputValue, filteredOptions]);

  return (
    <div>
      <Input
        value={inputValue}
        onChange={(value) => setInputValue(value.toLowerCase())}
        label="Add members"
        id="project-team"
      />
      {inputValue && (
        <div
          className="border-b border-x border-purple-300 rounded mb-1 pt-1 -mt-5"
          onClick={() => setInputValue('')}>
          {mappedOptions}
        </div>
      )}
    </div>
  );
}
