import { license_nft_metadata_v1_types } from '@scinet-inc/api';
import { Avatar } from 'components';
import { useMemo } from 'react';
import { LicenseStatus, Organization } from '../../../lib';

export default function InquiryCardHeader({
  licenseMeta,
  ownerOrg,
}: {
  licenseMeta: license_nft_metadata_v1_types.LicenseMeta;
  ownerOrg?: Organization | null;
}) {
  const {
    properties: { start_date, end_date },
  } = licenseMeta;

  const status = useMemo(() => {
    if (end_date === undefined || end_date > new Date().toISOString()) {
      return LicenseStatus.ACTIVE;
    } else {
      return LicenseStatus.EXPIRED;
    }
  }, [end_date]);

  return (
    <div className="flex flex-row justify-start pb-5">
      <div className="relative">
        <Avatar source={ownerOrg?.details.imageUrl} className="w-[48px]" />
      </div>
      <div className="flex flex-col pl-7">
        <span className="font-bold">{ownerOrg?.info.name}</span>
        <span>{status}</span>
      </div>
    </div>
  );
}
