import { useState, useEffect } from 'react';
import { AccountIdentifier, LedgerCanister } from '@dfinity/nns';
import { Principal } from '@dfinity/principal';

function useBalance(principal: Principal) {
  const [balance, setBalance] = useState(0);
  const [error, setError] = useState(false);

  const ledger = LedgerCanister.create();

  const checkBalanceFromPrincipal = async () => {
    try {
      const accountIdentifier = AccountIdentifier.fromPrincipal({ principal });
      const balance = await ledger.accountBalance({ accountIdentifier });

      setBalance(Number(balance) / 100000000);
      if (error) {
        setError(false);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  useEffect(() => {
    if (!principal) return;
    const interval = setInterval(checkBalanceFromPrincipal, 180000);

    return () => {
      clearInterval(interval);
    };
  }, [principal]);

  useEffect(() => {
    principal && checkBalanceFromPrincipal();
  });

  return { error, balance };
}

export default useBalance;
