import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import {
  organizations_types,
  organizations_service_types,
  organizations_teams_types,
} from '@scinet-inc/api';
import { Organization } from 'scinet-types';
import { useAuthentication } from '..';
import { ActorSubclass, Identity } from '@dfinity/agent';

export type OrgFormActionType =
  | 'RESET_ORG'
  | 'SET_ERROR'
  | 'SET_IS_LOADING'
  | 'SET_FULL_ORG'
  | 'SET_ORG_FETCHED';

export type OrgContextType = {
  fetchFullOrg: (id: string) => Promise<Organization>;
  setOrgInfo: (data: organizations_types.OrganizationInfo) => void;
  setOrgDetails: (
    data: organizations_service_types.OrganizationDetails,
  ) => void;
  setOrgTeam: (data: organizations_teams_types.OrganizationTeam) => void;
  resetOrg: () => void;
  setFullOrg: (data: Organization) => void;
  org: Organization;
  isLoading: boolean;
  setOrgInfoAndTeam: (
    info: organizations_types.OrganizationInfo,
    team: organizations_teams_types.OrganizationTeam,
  ) => void;
};

const emptyOrg = {
  info: {},
  details: {},
  team: {},
};

const initialContext = {
  error: '',
  isLoading: false,
  org: emptyOrg,
  orgFetched: false,
};

export const OrgContext = createContext(null);
OrgContext.displayName = 'OrgContext';

export const useOrgContext = (): OrgContextType => {
  const context = useContext(OrgContext);
  if (!context) {
    throw new Error('useOrgContext must be used within a OrgContextProvider');
  }
  return context;
};

const OrgFormReducer = (
  state: any,
  action: { type: OrgFormActionType; payload?: any },
) => {
  const { type, payload } = action;

  switch (type) {
    case 'RESET_ORG':
      return {
        ...state,
        org: emptyOrg,
        orgFetched: false,
      };
    case 'SET_FULL_ORG':
      return {
        ...state,
        org: payload,
        isLoading: false,
        orgFetched: true,
        error: '',
      };
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export const OrgContextProvider = ({
  children,
  organizationsIndexCanisterId,
  orgActor,
  orgTeamActor,
  useRouter,
  getOrganizationDetails,
}: {
  children: JSX.Element[] | JSX.Element;
  organizationsIndexCanisterId: string;
  getOrganizationDetails: (
    organizationsIndexCanisterId: string,
    id: string,
    identity?: Identity,
  ) => Promise<{
    organization: organizations_service_types.OrganizationDetails;
    error: string | undefined;
  }>;
  useRouter: () => any;
  orgActor: ActorSubclass<organizations_types._SERVICE> | undefined;
  orgTeamActor: ActorSubclass<organizations_teams_types._SERVICE> | undefined;
}) => {
  const initialContextState = {
    ...initialContext,
  };
  const { identity } = useAuthentication();
  const { pathname, query } = useRouter();

  const [state, dispatch] = useReducer(OrgFormReducer, initialContextState);

  const setIsLoading = (loading: boolean) => {
    dispatch({ type: 'SET_IS_LOADING', payload: loading });
  };

  const setFullOrg = (org: Organization) =>
    dispatch({ type: 'SET_FULL_ORG', payload: org });

  const setOrgDetails = (
    details: organizations_service_types.OrganizationDetails,
  ) => {
    const updatedOrg = { ...state.org, details };
    dispatch({ type: 'SET_FULL_ORG', payload: updatedOrg });
  };

  const setOrgInfo = (info: organizations_types.OrganizationInfo) => {
    const updatedOrg = { ...state.org, info };
    dispatch({ type: 'SET_FULL_ORG', payload: updatedOrg });
  };

  const setOrgInfoAndTeam = (
    info: organizations_types.OrganizationInfo,
    team: organizations_teams_types.OrganizationTeam,
  ) => {
    const updatedOrg = { ...state.org, info, team };
    dispatch({ type: 'SET_FULL_ORG', payload: updatedOrg });
  };

  const setOrgTeam = (team: organizations_types.OrganizationInfo) => {
    const updatedOrg = { ...state.org, team };

    dispatch({ type: 'SET_FULL_ORG', payload: updatedOrg });
  };

  const setError = (errorMessage: string) =>
    dispatch({ type: 'SET_ERROR', payload: errorMessage });

  const fetchFullOrg = async (
    id: string,
    orgActor: ActorSubclass<organizations_types._SERVICE> | undefined,
    orgTeamActor: ActorSubclass<organizations_teams_types._SERVICE> | undefined,
    identity?: Identity,
  ) => {
    setIsLoading(true);
    try {
      // Define all the asynchronous operations
      const orgInfoPromise = orgActor?.getOrg(id);
      const orgDetailsPromise = getOrganizationDetails(
        organizationsIndexCanisterId,
        id,
        identity,
      );
      const orgTeamPromise = orgTeamActor?.read(id);

      // Wait for all promises to resolve
      const [getResponse, getOrgMetaRes, getTeamResponse] = await Promise.all([
        orgInfoPromise,
        orgDetailsPromise,
        orgTeamPromise,
      ]);

      // Process org info response
      const orgInfo =
        'ok' in getResponse!
          ? getResponse['ok']
          : ({} as organizations_types.OrganizationInfo);
      if (!('ok' in getResponse!)) {
        console.error('error retrieving org info');
      }

      // Process org details response
      const orgDetails = !getOrgMetaRes.error
        ? getOrgMetaRes.organization
        : ({} as organizations_service_types.OrganizationDetails);
      if (getOrgMetaRes.error) {
        console.error('error retrieving org details');
      }

      // Process org team response
      const orgTeam =
        'ok' in getTeamResponse!
          ? getTeamResponse['ok']
          : ({} as organizations_teams_types.OrganizationTeam);
      if (!('ok' in getTeamResponse!)) {
        console.error('error retrieving org team');
      }

      // Compile the result
      const result: Organization = {
        info: orgInfo,
        details: orgDetails,
        team: orgTeam,
      };

      setIsLoading(false);
      return result;
    } catch (error) {
      setIsLoading(false);
      return { info: {}, details: {}, team: {} };
    }
  };

  const fetchFullOrgAndSet = useCallback(
    async (
      id: string,
      orgActor: ActorSubclass<organizations_types._SERVICE>,
      orgTeamActor: ActorSubclass<organizations_teams_types._SERVICE>,
    ) => {
      const fullOrgResult = await fetchFullOrg(
        id,
        orgActor,
        orgTeamActor,
        identity,
      );

      dispatch({
        type: 'SET_FULL_ORG',
        payload: fullOrgResult,
      });
    },
    [],
  );

  const resetOrg = () => dispatch({ type: 'RESET_ORG' });

  useEffect(() => {
    if (
      orgActor &&
      orgTeamActor &&
      query.id &&
      pathname.includes('organizations')
    ) {
      fetchFullOrgAndSet(query.id as string, orgActor, orgTeamActor);
    }
  }, [
    orgActor,
    pathname,
    query,
    state.orgFetched,
    fetchFullOrgAndSet,
    identity,
  ]);

  useEffect(() => {
    if (!pathname.includes('organizations') && state.orgFetched) {
      resetOrg();
    }
  }, [pathname, state.orgFetched]);

  const value = {
    ...state,
    fetchFullOrg,
    resetOrg,
    setFullOrg,
    setOrgInfo,
    setOrgDetails,
    setOrgTeam,
    setOrgInfoAndTeam,
  };

  return <OrgContext.Provider value={value}>{children}</OrgContext.Provider>;
};
