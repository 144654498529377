import { ArrowDownTrayIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { PdfIcon, DocIcon } from '../../Icons';

type Props = {
  canDelete: boolean;
  downloadFile: (file: any) => void;
  files: any[];
  removeFile: (file: any) => void;
};

export const IpFileList = ({
  canDelete,
  downloadFile,
  files,
  removeFile,
}: Props) => {
  return (
    <div className="w-full mt-2">
      {files.map((file, index) => (
        <div
          key={`${index}-${file.filename}`}
          className="flex items-center gap-2 w-full border-b-2 p-4">
          {file.filename.includes('.pdf') ? <PdfIcon /> : <DocIcon />}
          <div className="flex flex-col w-full">
            <span
              className="text-rhino-900 font-sans text-sm font-bold hover:underline hover:cursor-pointer overflow-hidden whitespace-nowrap truncate"
              onClick={() => downloadFile(file)}>
              {file.filename}
            </span>
            <div className="flex items-center">
              {file.size && (
                <span className="text-rhino-600 text-xs">
                  {Math.ceil(file.size / 1024)} kb
                </span>
              )}
            </div>
          </div>
          <div className="flex-grow">
            <div className="flex justify-end">
              <ArrowDownTrayIcon
                className="w-4 h-4 hover:cursor-pointer"
                onClick={() => downloadFile(file)}
              />
              {canDelete && (
                <XMarkIcon
                  className="w-4 h-4 ml-3 hover:cursor-pointer"
                  onClick={() => removeFile(file)}
                />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
