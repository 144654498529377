import { useRouter } from 'next/router';
import { Button, Typography, useProjectContext, useToast } from 'components';
import { forwardRef, useState } from 'react';
import { useAppContext } from '../../AppContext';

const PreviewActions = forwardRef((_props, ref) => {
  const { push } = useRouter();
  const { project, setProject } = useProjectContext();
  const { projectActor } = useAppContext();
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const updateStatus = () => {
    const newStatusString =
      project.info.status === 'published' ? 'draft' : 'published';
    if (submitError) {
      setSubmitError('');
    }
    setIsLoading(true);
    projectActor
      ?.updateProject(project.info.id, {
        ...project.info,
        status: newStatusString,
      })
      .then(async (updateResponse) => {
        if ('ok' in updateResponse) {
          const res = updateResponse['ok'];
          const updatedProject = { ...project, info: res };
          showToast({
            message: 'Successfully updated project.',
            variant: 'success',
          });
          setProject(updatedProject);
          newStatusString === 'published' &&
            push(`/projects/${project.info.id}`);
        } else {
          showToast({
            message: 'Failed to update project.',
            variant: 'error',
          });
          console.error('update failure', updateResponse);
          setSubmitError('There was an error updating your project');
        }
        setIsLoading(false);
      });
  };

  return (
    <div
      //@ts-ignore
      ref={ref}
      className="w-full bg-white flex flex-col fixed bottom-0 left-0 z-50 items-center px-4 py-2 shadow-lg shadow-rhino-300 md:flex-row md:justify-between">
      <Typography className="text-md mb-4 w-full md:mx-16">
        {`This is what your project will look like once it's published. Please
        make sure everything looks correct before submitting.`}
      </Typography>
      <div className="flex flex-row w-full justify-between ">
        <Button
          disabled={isLoading}
          id="wizard_previous"
          type="reset"
          color="secondary"
          className="w-[48%]"
          onClick={() => push(`/projects/${project.info.id}/edit`)}>
          Return to Edit
        </Button>

        {/* <Toggle
          value={booleanStatus || false}
          onChange={updateStatus}
          ref={toggleRef}
          label="Publish Project"
          labelClassName="mt-1 ml-1 text-gray-500 text-xs font-semibold"
        /> */}
        <Button
          disabled={isLoading}
          type="submit"
          // TODO: if the project has already published then change the onclick to just update the project rather than submit for approval again
          onClick={updateStatus}
          color="primary"
          id="wizard_next"
          className="w-[48%] ml-0 md:ml-4">
          {isLoading
            ? 'Saving...'
            : project.info.status === 'published'
            ? 'Change to Draft'
            : 'Publish'}
        </Button>
      </div>
    </div>
  );
});

PreviewActions.displayName = 'PreviewActions';

export default PreviewActions;
