import { forwardRef, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { BiErrorCircle } from 'react-icons/bi';
import cx from 'classnames';
import { Typography } from '../Typography';
import { Tags } from '../Tags';

type Props = {
  onChange?: (value: string | string[], field?: string) => void;
  options: { label: string; value: string }[];
  label?: string;
  labelClassName?: string;
  helperText?: string;
  helperTextClassName?: string;
  multiple?: boolean;
  className?: string;
  value: string | string[];
  error?:
    | string
    | FieldError
    | Partial<{ type: string | number; message: string }>
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined;
  placeholder?: string;
  ref?: any;
  disabled?: boolean;
};

export const Select = forwardRef(
  (
    {
      onChange,
      error,
      value,
      options,
      label,
      helperText,
      helperTextClassName,
      multiple,
      labelClassName,
      placeholder,
      className = 'w-80',
      disabled,
    }: Props,
    ref,
  ) => {
    const content = multiple ? (
      <div className={className}>
        <Listbox value={value} onChange={onChange} multiple disabled={disabled}>
          <div className="flex flex-row w-full justify-between pb-1 items-end">
            <Listbox.Label className={labelClassName}>{label}</Listbox.Label>
            {helperText && (
              <Typography className={helperTextClassName}>
                {helperText}
              </Typography>
            )}
          </div>
          <div className="relative mt-1 rounded-md shadow h-0 mb-8">
            <Listbox.Button
              className={
                error
                  ? 'relative w-full cursor-pointed border border-red-300 ring-1 ring-red-100 rounded-md bg-white py-1 px-2 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'
                  : 'relative w-full cursor-pointed border border-slate-200 rounded-md bg-white py-1 px-2 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'
              }>
              <span
                className={cx('block truncate', {
                  'text-gray-400': value.length === 0,
                })}>
                {value.length === 0 ? (
                  placeholder || label
                ) : (
                  <Tags
                    tagClassName="text-xs font-medium rounded-3xl px-1 py-1.5 mr-2 text-ellipsis overflow-hidden ... "
                    className="flex flex-row flex-no-wrap w-[90%] "
                    tags={
                      typeof value !== 'string'
                        ? value.map((option) => option)
                        : [value]
                    }
                  />
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <IoIosArrowDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute bottom-0 max-h-80 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
                {options.map((option: any, optionIdx: number) => (
                  <Listbox.Option
                    key={optionIdx}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-1 pl-10 pr-4 z-50 ${
                        active ? 'bg-teal-200 text-teal-900' : 'text-gray-900'
                      }`
                    }
                    value={option.value}>
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}>
                          {option.label}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-teal-600">
                            <AiOutlineCheckCircle />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
        {error ? (
          <div className="flex flex-row pt-1">
            <BiErrorCircle color="red" />
            <Typography className="text-xs text-gray-400 pl-1">
              {error}
            </Typography>
          </div>
        ) : (
          <div className="h-5"></div>
        )}
      </div>
    ) : (
      <div className={className}>
        <Listbox value={value} onChange={onChange} disabled={disabled}>
          <div className="flex flex-row w-full justify-between pb-1 items-end">
            <Listbox.Label className={labelClassName}>{label}</Listbox.Label>
          </div>
          <div className="select-none relative mt-1 rounded-md shadow h-0 mb-8">
            <Listbox.Button
              className={
                error
                  ? 'relative w-full cursor-pointed border border-red-300 ring-1 ring-red-100 rounded-md bg-white py-1 sm:py-1.5 px-2 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'
                  : 'relative w-full cursor-pointed border border-slate-300 rounded-md bg-white py-1 sm:py-1.5 px-2 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'
              }>
              <span
                className={cx('block truncate', {
                  'text-gray-400': value === '' || value === undefined,
                })}>
                {value ? value : placeholder || label}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <IoIosArrowDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute bottom-0 max-h-80 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
                {options.map((option: any, optionIdx: number) => (
                  <Listbox.Option
                    key={optionIdx}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-1 pl-10 pr-4 z-50 ${
                        active
                          ? 'bg-purple-200 text-purple-900'
                          : 'text-gray-900'
                      }`
                    }
                    value={option.value}>
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}>
                          {option.label}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-purple-600">
                            <AiOutlineCheckCircle />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
          {helperText && (
            <Typography className={helperTextClassName}>
              {helperText}
            </Typography>
          )}
        </Listbox>
        {error ? (
          <div className="flex flex-row pt-1">
            <BiErrorCircle color="red" />
            <Typography className="text-xs text-gray-400 pl-1">
              {error}
            </Typography>
          </div>
        ) : (
          <div className="h-5"></div>
        )}
      </div>
    );

    return <>{content}</>;
  },
);

Select.displayName = 'Select';
