import {
  ComponentClass,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import cx from 'classnames';
import { NavItem } from '@scinet-inc/types';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FlaskLogo } from '../Logos';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { RightArrowIcon } from '../Icons';

type Props = {
  id: string;
  navItems: { [key: string]: NavItem };
  mobile?: boolean;
  menuOpen?: boolean;
  setMenuOpen: Function;
  footerNode?: {
    onClick?: () => void;
    text: string;
    href?: string;
    Icon: ComponentClass<any>;
  }[];
  logo?: ReactNode;
  router: any;
  Link: any;
};

export const Drawer = ({
  id,
  navItems,
  mobile,
  menuOpen,
  setMenuOpen,
  footerNode,
  logo,
  router,
  Link,
}: Props) => {
  const drawerRef = useRef<HTMLDivElement>(null);

  const onRouteChange = useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        drawerRef &&
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        menuOpen
      ) {
        onRouteChange();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [drawerRef, menuOpen, onRouteChange]);

  const footerLinks =
    footerNode?.map(({ Icon, text, href, onClick }, index) => {
      if (href) {
        return (
          <a
            key={index}
            target="_blank"
            rel="noopener noreferrer"
            className={cx(
              'flex flex-row w-16 bg-transparent text-white pl-4 py-4 items-center cursor-pointer hover:bg-[#002758]',
              {
                hidden: !menuOpen && mobile,
                'w-64': menuOpen && !mobile,
                'w-56': menuOpen && mobile,
              },
            )}
            href={href}>
            {(!mobile || menuOpen) && <Icon className="h-8" />}
            {menuOpen && (
              <Typography className="text-base pl-6">{text}</Typography>
            )}
          </a>
        );
      }

      return (
        <Button
          key={index}
          className={cx(
            'flex flex-row w-16 bg-transparent text-white pl-4 py-4 items-center cursor-pointer hover:bg-[#002758]',
            {
              hidden: !menuOpen && mobile,
              'w-64': menuOpen && !mobile,
              'w-56': menuOpen && mobile,
            },
          )}
          onClick={onClick}>
          {(!mobile || menuOpen) && Icon && <Icon className="h-8" />}
          {menuOpen && (
            <Typography className="text-base pl-6">{text}</Typography>
          )}
        </Button>
      );
    }) || [];

  return (
    // could check out SwipeableDrawer for iOS here at some point, when we have time to optimize/iterate
    <div
      id={id}
      ref={drawerRef}
      className={cx(
        'fixed left-0 h-full w-0 bg-[#003366] z-40 ease-in-out duration-300',
        {
          'w-16': !mobile,
          'w-64 ease-in-out duration-300': menuOpen && !mobile,
          'w-56': menuOpen && mobile,
        },
      )}>
      <Link href="/">
        <div
          className={cx(
            'flex flex-row w-full cursor-pointer pt-2 pl-4 min-w-fit gap-4',
            {
              hidden: mobile,
            },
          )}>
          {!mobile && <FlaskLogo className="h-12" />}
          {menuOpen && !mobile && logo}
        </div>
      </Link>

      {!mobile && (
        <>
          {!menuOpen ? (
            <div
              className="flex flex-row w-full pl-5 mt-4 py-2 items-center cursor-pointer hover:bg-[#002758]"
              onClick={() => setMenuOpen(true)}>
              <RightArrowIcon />
            </div>
          ) : (
            <div
              className="flex flex-row w-full pl-5 mt-4 py-2 items-center cursor-pointer hover:bg-[#002758]"
              onClick={() => setMenuOpen(false)}>
              <RightArrowIcon className="rotate-180" />
            </div>
          )}
        </>
      )}

      <>
        {mobile && menuOpen ? (
          <div className="pl-4 pt-2">
            <XMarkIcon
              onClick={() => {
                setMenuOpen(false);
              }}
              className="h-8 text-white"
            />
          </div>
        ) : (
          <span onClick={() => setMenuOpen(false)}></span>
        )}
        <ul>
          {Object.values(navItems).map((item: NavItem, i: number) => {
            const { container, route, routeLabel, renderIcon } = item;
            const isSelected = route === router.asPath;

            const content = (
              <div
                key={routeLabel}
                onClick={onRouteChange}
                className={cx(
                  'flex flex-row my-4 py-2 items-center cursor-pointer hover:bg-[#002758]',
                  {
                    hidden: mobile && !menuOpen,
                    'pl-4': !isSelected,
                    'border-l-4 pl-3 border-green-400 bg-[#001232]':
                      isSelected && (!mobile || menuOpen),
                  },
                )}>
                <>
                  {renderIcon && (!mobile || menuOpen) && (
                    <div>{renderIcon()}</div>
                  )}
                  {menuOpen && (
                    <Typography className="text-white pl-6 whitespace-nowrap">
                      {routeLabel}
                    </Typography>
                  )}
                </>
              </div>
            );
            if (!container) {
              return (
                <Link href={route as string} key={i}>
                  {content}
                </Link>
              );
            }
            return <li>{content}</li>;
          })}
        </ul>
        <div className="flex flex-col fixed bottom-4">{footerLinks}</div>
      </>
    </div>
  );
};

export default Drawer;
