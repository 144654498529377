import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import * as amplitude from '@amplitude/analytics-browser';

import {
  AuthContextProvider,
  ThemeProvider as ScinetThemeProvider,
  ToastContextProvider,
} from 'components';

import {
  AccountCompletion,
  AppContextProvider,
  Main,
  ProfileContextProvider,
  IPNFTContextProvider,
  UserContextProvider,
} from '../components';
import '../lib/styles/global.css';
import { OrganizationsTeamsContextProvider } from '../components/Organizations/OrganizationsTeamsContext';
import { AuthorizationContextProvider } from '../contexts';
import { AMPLITUDE_API_KEY } from '../lib';

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles?.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    amplitude.init(AMPLITUDE_API_KEY);
  }, []);

  return (
    <ScinetThemeProvider>
      <ToastContextProvider>
        <AuthContextProvider>
          <AuthorizationContextProvider>
            <UserContextProvider>
              <ProfileContextProvider>
                <AppContextProvider>
                  <OrganizationsTeamsContextProvider>
                    <AccountCompletion>
                      <IPNFTContextProvider>
                        <Main Component={Component} pageProps={pageProps} />
                      </IPNFTContextProvider>
                    </AccountCompletion>
                  </OrganizationsTeamsContextProvider>
                </AppContextProvider>
              </ProfileContextProvider>
            </UserContextProvider>
          </AuthorizationContextProvider>
        </AuthContextProvider>
      </ToastContextProvider>
    </ScinetThemeProvider>
  );
}
