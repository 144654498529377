import { projects_types } from '@scinet-inc/api';
export const sendNewInquiryEmail = async (
  email: string,
  project: projects_types.ProjectInfo
) => {
  const href = `${window.location.origin}/projects/${project.id}`;
  await fetch('/api/inquiries/new-inquiry', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      href,
      title: project.title,
    }),
  })
    .then((r) => r.json())
    .then((result) => {
      if (result.error) {
        // eslint-disable-next-line no-console
        console.log('FAIL', result.error);
        return;
      }
      // eslint-disable-next-line no-console
      console.log('SUCCESS', result.text);
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('FAIL', error.text);
    });
};
