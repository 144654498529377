import React, { createContext, useContext, useState } from 'react';
import { noop } from '@scinet-inc/utils';
import { ActorResponse } from '../lib';
import { inquiries_types } from '@scinet-inc/api';
import { useAppContext, useOrganizationsTeamsContext } from '../components';

interface InquiriesContextProps {
  getInquiries: (
    projectId: string,
    projectOrgId: string,
    userOrgId?: string
  ) => void;
  getInquiry: (inquiryId: string, index?: number) => void;
  inquiries: inquiries_types.Inquiry[];
  isLoading: boolean;
  setInquiries: (inquiries: inquiries_types.Inquiry[]) => void;
}

export const InquiriesContext = createContext<InquiriesContextProps>({
  getInquiries: noop,
  getInquiry: noop,
  inquiries: [],
  isLoading: false,
  setInquiries: noop,
});

// this context is for storing which org the logged-in user belongs to
export const InquiriesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { inquiriesActor } = useAppContext();
  const { organizationId } = useOrganizationsTeamsContext();
  const [inquiries, setInquiries] = useState<inquiries_types.Inquiry[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getInquiries = async (
    projectId: string,
    projectOrgId: string,
    userOrgId?: string
  ) => {
    if (!organizationId) return null;
    setIsLoading(true);
    inquiriesActor
      ?.getInquiriesByProjectId(projectId, projectOrgId, organizationId)
      .then((result: ActorResponse) => {
        if ('ok' in result) {
          const inquiries = result.ok;
          if (inquiries.length) {
            if (userOrgId) {
              const filteredInquiries = inquiries.filter(
                (inquiry: inquiries_types.Inquiry) =>
                  inquiry.inquiringOrganizationId === userOrgId
              );
              setInquiries(filteredInquiries);
            } else {
              setInquiries(inquiries);
            }
          }
        }
        setIsLoading(false);
      });
  };

  const getInquiry = async (inquiryId: string, index?: number) => {
    inquiriesActor?.read(inquiryId).then((result: ActorResponse) => {
      if ('ok' in result) {
        const inquiry: inquiries_types.Inquiry = result.ok;
        setInquiries((currentInquiries) => {
          const newInquiries = [...currentInquiries];
          if (index !== undefined && index >= 0) {
            newInquiries[index] = inquiry;
          } else {
            const existingIndex = newInquiries.findIndex(
              (inquiry) => inquiry.id === inquiryId
            );
            if (existingIndex !== -1) {
              newInquiries[existingIndex] = inquiry;
            }
          }
          return newInquiries;
        });
      }
    });
  };

  return (
    <InquiriesContext.Provider
      value={{ getInquiries, getInquiry, inquiries, isLoading, setInquiries }}>
      {children}
    </InquiriesContext.Provider>
  );
};

export function useInquiriesContext() {
  return useContext(InquiriesContext);
}
