import { useEffect, useState } from 'react';
import { Project } from 'scinet-types';
import { Button } from '../../Button';
import { CTAConfig } from '../../types';
import { determineColor } from '../utils';

type Props = {
  isAdmin: boolean;
  project: Project;
  push: (url: any, options?: any) => Promise<boolean>;
  metadataHref: string;
  openUploadModal: () => void;
};

export const IpNftActions = ({
  isAdmin,
  project,
  push,
  metadataHref,
  openUploadModal,
}: Props) => {
  const [ctas, setCTAs] = useState<CTAConfig[]>([]);

  const getCtaConfig = async () => {
    const ctaList = [];

    if (isAdmin) {
      ctaList.push({
        ctaText: 'Modify IP-NFT',
        ctaOnClick: () => push(`/projects/${project.info.id}/nft-wizard`),
        disabled: !project.info.id,
      });

      ctaList.push({
        ctaText: 'Upload IP Documents',
        ctaOnClick: openUploadModal,
      });
    }

    setCTAs(ctaList);
  };

  useEffect(() => {
    getCtaConfig();
  }, [isAdmin]);

  return (
    <div className="flex flex-col w-full mt-4">
      {ctas.length > 0 &&
        ctas.map((cta: CTAConfig, index: number) => (
          <Button
            onClick={cta.ctaOnClick}
            className="mb-2"
            color={determineColor(index)}
            disabled={cta.disabled}
            key={index}>
            {cta.ctaText}
          </Button>
        ))}
      {metadataHref && (
        <a
          className="hover:pointer-cursor text-sm font-semibold"
          target="_blank"
          rel="noopener noreferrer"
          href={metadataHref}>
          <Button className="mb-2 w-full" color={determineColor(ctas.length)}>
            View IP-NFT Metadata
          </Button>
        </a>
      )}
    </div>
  );
};
