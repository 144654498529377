type Props = {
  className?: string;
};

export const WalletIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 10.5C13.4333 10.5 13.7916 10.3583 14.075 10.075C14.3583 9.79164 14.5 9.43331 14.5 8.99998C14.5 8.56664 14.3583 8.20831 14.075 7.92498C13.7916 7.64164 13.4333 7.49998 13 7.49998C12.5666 7.49998 12.2083 7.64164 11.925 7.92498C11.6416 8.20831 11.5 8.56664 11.5 8.99998C11.5 9.43331 11.6416 9.79164 11.925 10.075C12.2083 10.3583 12.5666 10.5 13 10.5ZM1.99997 14.9999V16V1.99997V14.9999ZM2.3077 17.5C1.80257 17.5 1.375 17.325 1.025 16.975C0.675 16.625 0.5 16.1974 0.5 15.6923V2.3077C0.5 1.80257 0.675 1.375 1.025 1.025C1.375 0.675 1.80257 0.5 2.3077 0.5H15.6923C16.1974 0.5 16.625 0.675 16.975 1.025C17.325 1.375 17.5 1.80257 17.5 2.3077V5.02885H16V2.3077C16 2.21795 15.9711 2.14423 15.9134 2.08653C15.8557 2.02883 15.782 1.99997 15.6923 1.99997H2.3077C2.21795 1.99997 2.14423 2.02883 2.08653 2.08653C2.02883 2.14423 1.99997 2.21795 1.99997 2.3077V15.6923C1.99997 15.782 2.02883 15.8557 2.08653 15.9134C2.14423 15.9711 2.21795 16 2.3077 16H15.6923C15.782 16 15.8557 15.9711 15.9134 15.9134C15.9711 15.8557 16 15.782 16 15.6923V12.9711H17.5V15.6923C17.5 16.1974 17.325 16.625 16.975 16.975C16.625 17.325 16.1974 17.5 15.6923 17.5H2.3077ZM10.3077 13.5C9.80257 13.5 9.375 13.325 9.025 12.975C8.675 12.625 8.5 12.1974 8.5 11.6923V6.3077C8.5 5.80257 8.675 5.375 9.025 5.025C9.375 4.675 9.80257 4.5 10.3077 4.5H16.6922C17.1974 4.5 17.625 4.675 17.975 5.025C18.325 5.375 18.5 5.80257 18.5 6.3077V11.6923C18.5 12.1974 18.325 12.625 17.975 12.975C17.625 13.325 17.1974 13.5 16.6922 13.5H10.3077ZM16.6922 12C16.782 12 16.8557 11.9711 16.9134 11.9134C16.9711 11.8557 17 11.782 17 11.6923V6.3077C17 6.21795 16.9711 6.14423 16.9134 6.08653C16.8557 6.02883 16.782 5.99998 16.6922 5.99998H10.3077C10.218 5.99998 10.1442 6.02883 10.0865 6.08653C10.0288 6.14423 9.99998 6.21795 9.99998 6.3077V11.6923C9.99998 11.782 10.0288 11.8557 10.0865 11.9134C10.1442 11.9711 10.218 12 10.3077 12H16.6922Z"
        fill="#610099"
      />
    </svg>
  );
};
