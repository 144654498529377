import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button, Typography } from 'components';
import { useRouter } from 'next/router';
import { Fragment } from 'react';

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
};

export const CreateOrgPromptModal = ({ isModalOpen, onClose }: Props) => {
  const { push } = useRouter();
  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog onClose={onClose} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed backdrop-blur-sm inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="mt-auto fixed inset-0 z-10 overflow-y-auto md:p-5 md:mb-auto flex justify-center items-center m-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <Dialog.Panel className="mt-auto mx-auto max-w-lg rounded bg-white p-5 relative flex flex-col md:mb-auto">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="rounded-md bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={onClose}>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex flex-col">
                <Typography className="font-semibold mx-auto pt-2 pb-3 text-xl lg:text-2xl">
                  Project Inquiry
                </Typography>
                <Typography className="text-center pb-5">
                  You must belong to an organization before you can create a
                  project.
                </Typography>
                <Button
                  type="button"
                  className="w-full"
                  color="primary"
                  onClick={() => push('/organizations/new')}>
                  Create Organization
                </Button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
