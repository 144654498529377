import { forwardRef, useCallback } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import cx from 'classnames';
import { BiErrorCircle } from 'react-icons/bi';
import { Typography } from '../Typography';

type Props = {
  onChange?: (value: string, field?: string) => void;
  type?: string;
  fieldName?: string;
  ariaLabel?: string;
  value?: string | number;
  defaultValue?: string | number;
  helperText?: string;
  helperTextClassName?: string;
  id?: string;
  label?: string;
  disabled?: boolean;
  labelClassName?: string;
  placeholder?: string;
  className?: string;
  error?:
    | string
    | FieldError
    | Partial<{ type: string | number; message: string }>
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined;
  ref?: any;
  maxLength?: number;
};

export const Input = forwardRef(
  (
    {
      onChange,
      type = 'text',
      fieldName,
      label,
      disabled,
      labelClassName,
      placeholder,
      id = 'name',
      value,
      defaultValue = '',
      className,
      ariaLabel,
      helperText,
      helperTextClassName,
      error,
      ...rest
    }: Props,
    ref,
  ) => {
    const handleChange = useCallback(
      (e: any) => {
        const { value } = e.target;
        if (onChange) {
          onChange(
            type === 'number' ? Number.parseInt(value) : value,
            fieldName,
          );
        }
      },
      [onChange, fieldName, type],
    );

    const fullId = `${id}-input`;
    return (
      <div className="w-full">
        <div className="flex flex-row w-full justify-between pb-1">
          <Typography className={labelClassName} aria-label={ariaLabel}>
            {label}
          </Typography>
          {helperText && (
            <Typography className={helperTextClassName}>
              {helperText}
            </Typography>
          )}
        </div>
        <input
          ref={ref}
          onChange={handleChange}
          className={cx(
            'placeholder:text-slate-400 block bg-white w-full h-full border rounded-md py-2 pl-2 pr-3 shadow-md focus:outline-none  focus:ring-2 sm:text-sm',
            className,
            {
              'border-red-300': error,
              'ring-red-100': error,
              'ring-1': error,
              'focus:border-red-400': error,
              'focus:ring-red-200': error,
              'border-slate-300': !error,
              'border-purple-400': !error,
              'focus:ring-purple-200': !error,
              'focus:border-purple-400': !error,
            },
          )}
          value={value ? value : defaultValue}
          placeholder={placeholder}
          type={type}
          id={fullId}
          disabled={disabled}
          {...rest}
        />
        {error ? (
          <div className="flex flex-row pt-1">
            <BiErrorCircle color="red" />
            <Typography className="text-xs text-gray-400 pl-1">
              {error}
            </Typography>
          </div>
        ) : (
          <div className="h-5"></div>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';
