export const tags = [
  'Sustainability',
  'Climate',
  'Green Tech',
  'Carbon Capture',
  'Energy',
  'Ag Tech',
  'Food Science',
  'Bio Fuel',
  'Therapeutics',
  'Cannabis Science',
  'Psychedelic Research',
  'Longevity',
  'Microbiology',
  'Biology',
  'Ecology',
  'Marine Science',
  'Neurology',
];

export const mappedTags = tags.map((tag: string) => ({
  value: tag,
  label: tag,
}));
