import { Spinner, useProjectContext } from 'components';
import { useState } from 'react';
import { useInquiriesContext } from '../../contexts';
import { useOrganizationsTeamsContext } from '../Organizations/OrganizationsTeamsContext';
import InquiriesList from './InquiriesList';
import InquiriesTabs from './InquiriesTabs';

export default function Inquiries() {
  const [tabSelected, setTabSelected] = useState(0);
  const { inquiries, isLoading } = useInquiriesContext();
  const { project } = useProjectContext();
  const { organizationId: userOrgId } = useOrganizationsTeamsContext();

  const userBelongsToProjectOrg = project.info.organizationId === userOrgId;

  return (
    <div className="flex flex-col pt-5">
      {userBelongsToProjectOrg && (
        <InquiriesTabs
          onChange={(index: number) => setTabSelected(index)}
          selected={tabSelected}
        />
      )}
      {isLoading ? (
        <div className="mt-10">
          <Spinner />
        </div>
      ) : (
        <InquiriesList
          inquiries={inquiries}
          projectId={project.info.id}
          tabSelected={tabSelected}
          userBelongsToProjectOrg={userBelongsToProjectOrg}
          userOrgId={userOrgId}
        />
      )}
    </div>
  );
}
