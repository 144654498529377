import { ActorSubclass } from '@dfinity/agent';
import {
  organizations_types,
  organizations_teams_types,
  users_types,
} from '@scinet-inc/api';
import { Card, Typography, Tags } from '../..';
import { OrgCardHeader } from './OrgCardHeader';

export function OrgCard({
  user,
  organization,
  deleteOrg,
  orgTeamActor,
  routerPush,
  hideActions,
}: {
  deleteOrg: (
    id: string,
  ) => any | undefined | Promise<organizations_types.Result_3>;
  user?: users_types.User | any;
  organization: organizations_types.OrganizationInfo;
  orgTeamActor?: ActorSubclass<organizations_teams_types._SERVICE> | undefined;
  routerPush: (url: any, options?: any) => Promise<boolean>;
  hideActions?: boolean;
}) {
  const headerNode = (
    <OrgCardHeader
      deleteOrg={deleteOrg}
      org={organization}
      user={user}
      routerPush={routerPush}
      orgTeamActor={orgTeamActor}
      hideActions={hideActions}
    />
  );

  return (
    <Card
      className="w-full lg:w-80 bg-white h-64 lg:mr-4 hover:cursor-pointer"
      onClick={() => routerPush(`/organizations/${organization?.id}`)}
      headerNode={headerNode}>
      <div className="flex flex-col h-full justify-between">
        <Typography className="w-full h-24 font-sm overflow-hidden mb-4">
          {organization?.description}
        </Typography>
        <Tags
          className="flex flex-row flex-wrap"
          tagClassName="text-xs font-medium rounded-3xl px-1 py-0.5 mr-2 mb-2"
          tags={organization?.tags}
        />
      </div>
    </Card>
  );
}
