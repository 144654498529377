import { useEffect, useState } from 'react';
import { Avatar } from '../Avatar';
import { Button } from '../Button';
import { Organization } from '../types';
import { Typography } from '../Typography';

type Props = {
  className?: string;
  fetchFullOrg: ((id: string) => Promise<Organization>) | null;
  orgId: string;
  push: (url: any, options?: any) => Promise<boolean>;
};

export const OrgListItem = ({
  fetchFullOrg,
  orgId,
  push,
  className,
}: Props) => {
  const [fullOrg, setFullOrg] = useState<Organization | null>(null);

  const getFullOrg = async (orgId: string) => {
    const orgResult = await fetchFullOrg!(orgId);
    setFullOrg(orgResult);
  };

  useEffect(() => {
    if (orgId) {
      getFullOrg(orgId);
    }
  }, [orgId, fetchFullOrg]);

  if (!fullOrg) {
    return null;
  }

  return (
    <div
      className={`flex flex-row p-3 border border-gray-200 rounded-lg w-full ${className}`}>
      <Avatar source={fullOrg.details.imageUrl} className="h-10 w-10 mr-2" />
      <div className="flex flex-col items-center mr-auto">
        <Typography className="w-full text-sm font-semibold">
          {fullOrg?.info.name}
        </Typography>
        <Typography className="w-full text-xs text-rhino-900">
          {fullOrg?.team.members?.length} members
        </Typography>
      </div>
      <Button
        className="text-primary"
        color="tertiary"
        type="button"
        onClick={() => push(`/organizations/${orgId}`)}>
        View
      </Button>
    </div>
  );
};
