import { useCallback, useEffect, useState } from 'react';
import { Avatar, Spinner, useAuthentication } from 'components';
import { Menu } from '@headlessui/react';
import { MdFingerprint } from 'react-icons/md';
import { useIsMobile } from '@scinet-inc/hooks';
import { ProfileMenu } from './ProfileMenu';
import { users_types } from '@scinet-inc/api';

type Props = {
  useProfileContext: () => {
    isProfileLoading: boolean;
    profile: any;
    clearProfile: () => void;
  };
  router: any;
  user: users_types.User | null | undefined;
  useUserContext?: any;
};

export const UserProfile = ({
  useProfileContext,
  router,
  user,
  useUserContext,
}: Props) => {
  const { isMobile } = useIsMobile();

  const { isAuthenticated, isAuthing, login, logout, nfid } =
    useAuthentication();
  const { isProfileLoading, profile, clearProfile } = useProfileContext();
  const { avatarBase64 } = useUserContext();
  const { clearUser } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isAuthing);
  }, [setIsOpen, isAuthing]);

  const callLogin = useCallback(() => {
    if (isAuthing && !isOpen) {
      setIsOpen(true);
    }
    login();
  }, [isAuthing, isOpen, setIsOpen, login]);

  const clickProfile = useCallback(() => {
    router.push('/profile');
  }, [isMobile, router]);

  const logoutClick = useCallback(() => {
    clearUser();
    clearProfile();
    logout();
  }, [logout]);

  const loginClick = () => {
    callLogin();
  };

  return (
    <div className="wrapper flex">
      {isProfileLoading ? (
        <Spinner />
      ) : (
        <Menu>
          {({ open }) => {
            return (
              <>
                <Menu.Button disabled={!nfid}>
                  {!isAuthenticated ? (
                    <MdFingerprint
                      className={
                        'fingerPrint h-12 w-12 text-purple-700 hover:bg-gray-200 rounded-full p-2'
                      }
                      onClick={loginClick}
                    />
                  ) : (
                    <Avatar
                      className="avatar h-12 w-12 text-purple-700 hover:bg-gray-200 rounded-full p-2"
                      source={avatarBase64}
                    />
                  )}
                </Menu.Button>
                {isAuthenticated && (
                  <ProfileMenu
                    user={user}
                    isOpen={open}
                    isMobile={isMobile}
                    profile={{
                      ...profile,
                      imageUrl: avatarBase64,
                    }}
                    clickProfile={clickProfile}
                    logoutClick={logoutClick}
                  />
                )}
              </>
            );
          }}
        </Menu>
      )}
    </div>
  );
};
