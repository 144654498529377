import React, { ReactNode } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { FlaskLogo } from '../Logos';

type Props = {
  id: string;
  setMenuOpen: Function;
  menuOpen?: boolean;
  mobile?: boolean;
  appBarChildren?: ReactNode;
  profileSection?: ReactNode;
  logo?: ReactNode;
  actionsNode?: ReactNode;
};

export const Header = ({
  id,
  setMenuOpen,
  mobile,
  appBarChildren,
  profileSection,
  logo,
  actionsNode,
}: Props) => {
  return (
    <div
      id={id}
      className="flex flex-row w-full h-16 bg-white fixed z-10 top-0 px-4 shadow-md items-center justify-center">
      {mobile && (
        <>
          <Bars3Icon
            className="absolute left-2 h-8 hover:cursor-pointer md:hidden"
            onClick={() => setMenuOpen(true)}
          />
          {logo && (
            <div className="flex flex-row">
              <FlaskLogo className="h-10 mr-1" />
              {logo}
            </div>
          )}
        </>
      )}
      {!mobile && appBarChildren && <div>{appBarChildren}</div>}
      <div className="flex flex-row items-center justify-end">
        {actionsNode}
        {profileSection && (
          <div className="flex items-center fixed right-2">
            {profileSection}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
