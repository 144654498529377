export const DocIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M7.95593 31.652C6.98201 31.652 6.2168 30.8868 6.2168 29.9129V6.60853C6.2168 6.53896 6.28636 6.39983 6.28636 6.33027L12.1994 0.417221C12.269 0.347656 12.3385 0.347656 12.4777 0.347656H27.4342C28.4081 0.347656 29.1733 1.11287 29.1733 2.08679V29.9129C29.1733 30.8868 28.4081 31.652 27.4342 31.652H7.95593Z"
        fill="#F3F4F6"
      />
      <path
        d="M27.4344 0.695652C28.1996 0.695652 28.8257 1.32174 28.8257 2.08696V29.913C28.8257 30.6783 28.1996 31.3043 27.4344 31.3043H7.9561C7.19088 31.3043 6.56479 30.6783 6.56479 29.913V6.6087L12.4778 0.695652H27.4344ZM27.4344 0H12.4778C12.2691 0 12.13 0.0695652 11.9909 0.208696L6.07784 6.12174C5.93871 6.26087 5.86914 6.4 5.86914 6.6087V29.913C5.86914 31.0957 6.77349 32 7.9561 32H27.4344C28.617 32 29.5213 31.0957 29.5213 29.913V2.08696C29.5213 0.904348 28.617 0 27.4344 0Z"
        fill="#001232"
      />
      <path
        d="M6.5656 6.60853C6.42647 6.60853 6.28734 6.53896 6.21777 6.39983C6.21777 6.2607 6.21777 6.12157 6.28734 5.98244L11.8526 0.417221C11.9221 0.347656 11.9917 0.347656 12.1308 0.347656C12.2004 0.347656 12.2004 0.347656 12.2699 0.347656C12.4091 0.417221 12.4786 0.556352 12.4786 0.695482V6.2607C12.4786 6.4694 12.3395 6.60853 12.1308 6.60853H6.5656Z"
        fill="#E6E6E6"
      />
      <path
        d="M12.1309 0.695652V6.26087H6.56566L12.1309 0.695652ZM12.1309 0C11.9222 0 11.7831 0.0695652 11.6439 0.208696L6.0787 5.77391C5.87001 5.98261 5.80044 6.26087 5.93957 6.53913C6.0787 6.81739 6.2874 6.95652 6.56566 6.95652H12.1309C12.5483 6.95652 12.8265 6.67826 12.8265 6.26087V0.695652C12.8265 0.417391 12.6874 0.13913 12.4091 0.0695652C12.3396 0 12.2004 0 12.1309 0Z"
        fill="#001232"
      />
      <path
        d="M20.4783 25.739H2.3913C1.62609 25.739 1 25.1129 1 24.3477V15.2347V13.2173L2.3913 14.6086H20.4783V25.739Z"
        fill="#3399FF"
        stroke="#001232"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.21739 14.6088H2.3913C1.62609 14.6088 1 13.9827 1 13.2175C1 12.4523 1.62609 11.8262 2.3913 11.8262H6.21739V14.6088Z"
        fill="#007FFF"
        stroke="#001232"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1303 14.9568V25.3916H2.39113C1.83461 25.3916 1.34766 24.9046 1.34766 24.3481V22.6785C3.78244 22.4698 6.21722 21.9829 8.37374 21.4263C12.4781 20.3829 16.0955 18.3655 18.3216 14.8872H20.1303V14.9568Z"
        fill="#007FFF"
      />
      <path
        d="M4.33887 22.1915V18.0176H5.8693C7.19104 18.0176 8.02582 18.9219 8.02582 20.1045C8.02582 21.2871 7.19104 22.1915 5.8693 22.1915H4.33887ZM4.96495 21.635H5.79974C6.77365 21.635 7.33017 20.9393 7.33017 20.1045C7.33017 19.2698 6.77365 18.5741 5.79974 18.5741H4.96495V21.635Z"
        fill="white"
      />
      <path
        d="M10.7391 17.8784C11.9913 17.8784 12.8261 18.7828 12.8261 20.0349C12.8261 21.2871 11.9913 22.1915 10.7391 22.1915C9.48698 22.1915 8.65219 21.2871 8.65219 20.0349C8.58263 18.8523 9.48698 17.8784 10.7391 17.8784ZM10.7391 18.4349C9.8348 18.4349 9.27828 19.1306 9.27828 20.0349C9.27828 20.9393 9.8348 21.6349 10.7391 21.6349C11.6435 21.6349 12.2 20.9393 12.2 20.0349C12.2 19.1306 11.6435 18.4349 10.7391 18.4349Z"
        fill="white"
      />
      <path
        d="M13.4521 20.1043C13.4521 18.7825 14.4261 17.9478 15.6087 17.9478C16.4435 17.9478 16.9304 18.3651 17.2782 18.8521L16.7217 19.1304C16.513 18.7825 16.0956 18.5043 15.6087 18.5043C14.7739 18.5043 14.0782 19.1999 14.0782 20.1043C14.0782 21.0086 14.7043 21.7043 15.6087 21.7043C16.0956 21.7043 16.513 21.426 16.7217 21.0782L17.2782 21.3565C16.9304 21.8434 16.4435 22.2608 15.6087 22.2608C14.3565 22.2608 13.4521 21.3564 13.4521 20.1043Z"
        fill="white"
      />
      <path
        d="M27.435 0.695801H25.9741V21.7741C26.3915 24.9741 24.5828 28.5914 20.4089 28.5914H6.56543V29.9828C6.56543 30.748 7.19152 31.3741 7.95673 31.3741H27.435C28.2002 31.3741 28.8263 30.748 28.8263 29.9828V2.08711C28.8263 1.32189 28.2002 0.695801 27.435 0.695801Z"
        fill="#E6E6E6"
      />
    </svg>
  );
};
