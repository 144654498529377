type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...rest } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`project-details-tabpanel-${index}`}
      aria-labelledby={`project-details-tab-${index}`}
      {...rest}>
      {value === index && <div>{children}</div>}
    </div>
  );
};

export default TabPanel;
