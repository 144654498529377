import { Typography } from '../Typography';
import cx from 'classnames';

type Props = {
  className?: string;
  children?: React.ReactNode;
  headerNode?: React.ReactNode;
  description?: string;
  title?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
};

export const Card = ({
  className,
  children,
  headerNode,
  description,
  title,
  onClick,
  style,
  ...rest
}: Props) => {
  return (
    <div
      onClick={onClick}
      className={cx(
        'flex flex-col border-solid rounded-md shadow bg-white p-4 my-2 w-full md:w-80',
        className,
      )}
      style={style}
      {...rest}>
      {headerNode && <div className="w-full">{headerNode}</div>}
      <div className="flex flex-col items-center h-full">
        {title && (
          <Typography className="text-xl font-bold w-full mb-2">
            {title}
          </Typography>
        )}
        {description && (
          <Typography className="flex flex-col w-full overflow-hidden h-[120px]">
            {description}
          </Typography>
        )}
        <div className="w-full h-full flex flex-col">{children}</div>
      </div>
    </div>
  );
};
