type Props = {
  className?: string;
};

export const WalletIconColor = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 32 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2112_55934)">
        <path
          d="M1.40831 27.328C0.832313 27.328 0.320312 26.816 0.320312 26.24V6.65598L0.704313 6.59198L27.2643 7.67998V26.24C27.2643 26.816 26.7523 27.328 26.1763 27.328H1.40831Z"
          fill="#99CCFF"
        />
        <path
          d="M0.704 6.91197L27.008 7.99997V26.24C27.008 26.688 26.688 27.008 26.24 27.008H1.408C0.96 27.008 0.64 26.688 0.64 26.24L0.704 6.91197C0.64 6.91197 0.64 6.91197 0.704 6.91197ZM0.704 6.27197C0.32 6.27197 0 6.59197 0 6.97597V26.24C0 27.008 0.64 27.648 1.408 27.648H26.24C27.008 27.648 27.648 27.008 27.648 26.24V7.99997V7.35997H27.008L0.704 6.27197Z"
          fill="#001232"
        />
        <path
          d="M25.4726 7.93597C25.6006 14.72 18.9446 19.584 13.1846 22.144C9.60063 23.68 5.95262 24.384 2.11262 24.832C1.60062 24.896 1.15263 24.96 0.640625 25.024V26.304C0.640625 26.752 0.960625 27.072 1.40863 27.072H26.2406C26.6886 27.072 27.0086 26.752 27.0086 26.304V7.99997L25.4726 7.93597Z"
          fill="#66B2FF"
        />
        <path
          d="M26.9443 25.152V8.76803C26.9443 8.51203 26.7523 8.32003 26.4963 8.32003H1.79231C1.02431 8.32003 0.320312 7.68003 0.320312 6.91203C0.320312 6.14403 0.960312 5.50403 1.79231 5.50403H28.8003C29.3763 5.50403 29.8883 5.95203 29.8883 6.59203V24.128C29.8883 24.704 29.3763 25.216 28.8003 25.216H26.9443V25.152Z"
          fill="#3399FF"
        />
        <path
          d="M28.8 5.82402C29.184 5.82402 29.568 6.14402 29.568 6.59202V24.128C29.568 24.576 29.248 24.896 28.8 24.896H27.328V8.76802C27.328 8.38402 27.008 8.00002 26.56 8.00002H1.792C1.152 8.00002 0.64 7.48802 0.64 6.91202C0.64 6.33602 1.152 5.82402 1.792 5.82402H28.8ZM28.8 5.18402H1.792C0.768 5.18402 0 5.95202 0 6.91202C0 7.87202 0.768 8.64002 1.792 8.64002H26.56C26.624 8.64002 26.688 8.70402 26.688 8.76802V24.832V25.472H27.328H28.8C29.568 25.472 30.208 24.832 30.208 24.064V6.52802C30.144 5.82402 29.568 5.18402 28.8 5.18402Z"
          fill="#001232"
        />
        <path
          d="M3.07227 8.32003L9.34427 2.04803C9.53627 1.85603 9.85627 1.72803 10.1763 1.72803C10.4963 1.72803 10.7523 1.85603 10.9443 2.04803L14.4643 5.56803L11.6483 8.32003H3.07227Z"
          fill="#ECCCFF"
        />
        <path
          d="M10.1767 2.04802C10.3687 2.04802 10.5607 2.11202 10.7527 2.30402L14.0167 5.56802L11.5207 8.00002H3.84069L9.60069 2.24002C9.72869 2.11202 9.92069 2.04802 10.1767 2.04802ZM10.1767 1.40802C9.79269 1.40802 9.40869 1.53602 9.15269 1.85602L3.39269 7.61602L2.30469 8.70402H3.84069H11.5207H11.7767L11.9687 8.51202L14.4647 6.01602L14.9127 5.56802L14.4647 5.12002L11.2007 1.85602C10.8807 1.53602 10.5607 1.40802 10.1767 1.40802Z"
          fill="#001232"
        />
        <path
          d="M10.752 8.32003L17.024 2.04803C17.216 1.85603 17.536 1.72803 17.856 1.72803C18.176 1.72803 18.432 1.85603 18.624 2.04803L24.896 8.32003H10.752Z"
          fill="#F3F4F6"
        />
        <path
          d="M17.8564 2.04802C18.0484 2.04802 18.2404 2.11202 18.4324 2.30402L24.1924 8.06402H11.5204L14.0164 5.56802L17.2804 2.24002C17.4084 2.11202 17.6004 2.04802 17.8564 2.04802ZM17.8564 1.40802C17.4724 1.40802 17.0884 1.53602 16.8324 1.85602L13.5684 5.12002L11.0724 7.61602L9.98438 8.70402H11.5204H24.1924H25.7284L24.6404 7.61602L18.8804 1.85602C18.5604 1.53602 18.2404 1.40802 17.8564 1.40802Z"
          fill="#001232"
        />
        <path
          d="M22.7203 8H24.1283L18.3683 2.24C18.1123 1.92 17.6003 1.92 17.2803 2.24L17.1523 2.368L22.7203 8Z"
          fill="#E6E6E6"
        />
        <path
          d="M13.5032 5.056L10.6872 2.24C10.4312 1.92 9.91916 1.92 9.59916 2.24L9.53516 2.304L12.9272 5.696C13.0552 5.504 13.3112 5.248 13.5032 5.056Z"
          fill="#DA99FF"
        />
        <path
          d="M22.2081 18.944C20.6081 18.944 19.3281 17.664 19.3281 16.064C19.3281 14.464 20.6081 13.184 22.2081 13.184H30.5921C31.1681 13.184 31.6801 13.696 31.6801 14.272V17.856C31.6801 18.432 31.1681 18.944 30.5921 18.944H22.2081Z"
          fill="#99CCFF"
        />
        <path
          d="M30.5918 13.504C31.0398 13.504 31.3598 13.824 31.3598 14.272V17.856C31.3598 18.304 31.0398 18.624 30.5918 18.624H22.2078C20.7998 18.624 19.6478 17.472 19.6478 16.064C19.6478 14.656 20.7998 13.504 22.2078 13.504H30.5918ZM30.5918 12.864H22.2078C20.4158 12.864 19.0078 14.272 19.0078 16.064C19.0078 17.856 20.4158 19.264 22.2078 19.264H30.5918C31.3598 19.264 31.9998 18.624 31.9998 17.856V14.272C31.9998 13.504 31.3598 12.864 30.5918 12.864Z"
          fill="#001232"
        />
        <path
          d="M22.5916 17.472C21.8236 17.472 21.1836 16.832 21.1836 16.064C21.1836 15.296 21.8236 14.656 22.5916 14.656C23.3596 14.656 23.9996 15.296 23.9996 16.064C23.9996 16.832 23.3596 17.472 22.5916 17.472Z"
          fill="#BBFF00"
        />
        <path
          d="M22.5913 14.976C23.1673 14.976 23.6793 15.488 23.6793 16.064C23.6793 16.64 23.1673 17.152 22.5913 17.152C22.0153 17.152 21.5033 16.64 21.5033 16.064C21.5033 15.424 21.9513 14.976 22.5913 14.976ZM22.5913 14.336C21.6313 14.336 20.8633 15.104 20.8633 16.064C20.8633 17.024 21.6313 17.792 22.5913 17.792C23.5513 17.792 24.3193 17.024 24.3193 16.064C24.3193 15.104 23.5513 14.336 22.5913 14.336Z"
          fill="#001232"
        />
      </g>
      <defs>
        <clipPath id="clip0_2112_55934">
          <rect width="32" height="29.44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
