type Props = {
  className?: string;
  color?: string;
};

export const CloseXMark = ({ className, color }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00039 8.0499L1.92539 13.1249C1.79206 13.2582 1.62139 13.3289 1.41339 13.3369C1.20472 13.3456 1.02539 13.2749 0.875391 13.1249C0.725391 12.9749 0.650391 12.7999 0.650391 12.5999C0.650391 12.3999 0.725391 12.2249 0.875391 12.0749L5.95039 6.9999L0.875391 1.9249C0.742057 1.79157 0.671391 1.62057 0.663391 1.4119C0.654724 1.2039 0.725391 1.0249 0.875391 0.874902C1.02539 0.724902 1.20039 0.649902 1.40039 0.649902C1.60039 0.649902 1.77539 0.724902 1.92539 0.874902L7.00039 5.9499L12.0754 0.874902C12.2087 0.741569 12.3797 0.670569 12.5884 0.661902C12.7964 0.653902 12.9754 0.724902 13.1254 0.874902C13.2754 1.0249 13.3504 1.1999 13.3504 1.3999C13.3504 1.5999 13.2754 1.7749 13.1254 1.9249L8.05039 6.9999L13.1254 12.0749C13.2587 12.2082 13.3294 12.3789 13.3374 12.5869C13.3461 12.7956 13.2754 12.9749 13.1254 13.1249C12.9754 13.2749 12.8004 13.3499 12.6004 13.3499C12.4004 13.3499 12.2254 13.2749 12.0754 13.1249L7.00039 8.0499Z"
        fill={color ? color : 'white'}
      />
    </svg>
  );
};
