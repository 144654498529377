import { RiMicroscopeLine, RiTeamLine } from 'react-icons/ri';
import { HiTrendingUp, HiOutlineClipboardList } from 'react-icons/hi';
import cx from 'classnames';
import { Typography } from '../../Typography';
import { PROJECT_TABS } from './types';

const actionContainer =
  'flex flex-col text-gray-500 w-[20%] h-12 items-center justify-center';

const ProjectActionMenuMobile = ({
  menuItemSelected,
  onChange,
  previewing,
  padding,
}: {
  menuItemSelected: string;
  onChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  previewing: boolean | undefined;
  padding: number | undefined;
}) => {
  return (
    <div
      className="fixed z-[0] bottom-0 left-0 flex flex-row w-full h-16 bg-white pt-1 border border-gray-200 justify-evenly"
      style={{ marginBottom: padding }}>
      <div
        className={cx(actionContainer, {
          'text-purple-500': menuItemSelected === PROJECT_TABS.OVERVIEW,
        })}
        onClick={(e) => onChange(e, PROJECT_TABS.OVERVIEW)}>
        <RiMicroscopeLine className="h-10 w-10" />
        <Typography className="text-sm">Overview</Typography>
      </div>
      <div
        className={cx(actionContainer, {
          'text-purple-500': menuItemSelected === PROJECT_TABS.MARKET,
        })}>
        <HiTrendingUp
          className="h-10 w-10"
          onClick={(e) => onChange(e, PROJECT_TABS.MARKET)}
        />
        <Typography className="text-sm">Market</Typography>
      </div>
      <div
        className={cx(actionContainer, {
          'text-purple-500': menuItemSelected === PROJECT_TABS.PATENTS,
        })}
        onClick={(e) => onChange(e, PROJECT_TABS.PATENTS)}>
        <HiOutlineClipboardList className="h-10 w-10" />
        <Typography className="text-sm">Patents</Typography>
      </div>
      <div
        className={cx(actionContainer, {
          'text-purple-500': menuItemSelected === PROJECT_TABS.TEAM,
        })}
        onClick={(e) => onChange(e, PROJECT_TABS.TEAM)}>
        <RiTeamLine className="h-9 w-9" />
        <Typography className="text-sm">Team</Typography>
      </div>
    </div>
  );
};

export default ProjectActionMenuMobile;
