import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';
import { CloseXMark, Typography } from 'components';
import { Fragment } from 'react';
import { useIsMobile } from '@scinet-inc/hooks';
import Licenses from './Licenses';

type Props = {
  isDrawerOpen: boolean;
  onClose: () => void;
};

export const LicensesDrawer = ({ isDrawerOpen, onClose }: Props) => {
  const { isMobile } = useIsMobile();

  if (isMobile) {
    return (
      <Transition.Root show={isDrawerOpen} as={Fragment}>
        <Dialog onClose={onClose} className="relative z-50">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed backdrop-blur-sm inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto md:p-5">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="h-full mx-auto max-w-lg rounded bg-white p-5 relative flex flex-col">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="rounded-md bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={onClose}>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <Licenses />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
  return (
    <div
      className={cx(
        'overflow-y-scroll overflow-x-hidden fixed right-0 sm:top-0 md:mt-16 w-0 h-full md:h-[calc(100vh-4rem)] bg-white z-30 md:z-0 ease-in-out duration-300 overflow-y-none',
        {
          'w-[100%] md:w-96 ease-in-out duration-300 ': isDrawerOpen,
        }
      )}>
      {isDrawerOpen && (
        <>
          <div
            className="flex justify-end fixed md:relative md:items-center text-white top-4 right-4 md:left-4 md:top-4 hover:cursor-pointer ease-in-out duration-300"
            onClick={onClose}>
            <div className="flex flex-row items-center">
              <CloseXMark className="w-4 h-4" color="black" />
              <Typography className="hidden md:flex md:ml-2 text-sm ">
                Close
              </Typography>
            </div>
          </div>
          <div
            className={cx(
              'bg-white w-full md:w-96 rounded-t-3xl px-8 ease-in-out duration-300',
              {
                'w-full ease-in-out duration-300': isDrawerOpen,
              }
            )}>
            <Licenses />
          </div>
        </>
      )}
    </div>
  );
};
