import { Project } from 'scinet-types';
import { Typography } from '../../../Typography';

const Overview = ({ project }: { project: Project }) => {
  return (
    <div className="flex flex-col my-4">
      <Typography className="text-lg font-bold mb-2">Summary</Typography>
      <Typography className="text-md mb-4">
        {project.details.summary}
      </Typography>
      <Typography className="text-lg font-bold mb-2">Background</Typography>
      <Typography className="text-md mb-4">
        {project?.details?.background}
      </Typography>
      <Typography className="text-lg font-bold mb-2">Details</Typography>
      <Typography className="text-md mb-4">
        {project?.details?.description}
      </Typography>
    </div>
  );
};

export default Overview;
