import { useProjectContext, WizardForm, useToast } from 'components';
import IPData from './Steps/IPData';
import NFTDetails from './Steps/NFTDetails';
import WhiteList from './Steps/WhiteList';
import { dateFactory } from '@scinet-inc/utils';
import { useRouter } from 'next/router';
import { getBigIntTime } from '../../../lib';

const renderSteps = () => {
  return [
    <NFTDetails key="NFT" />,
    <IPData key="IPData" />,
    <WhiteList key="WhiteList" />,
  ];
};

const steps = ['NFT', 'IPData', 'White List'];

const ProjectNFTForm = () => {
  const { push } = useRouter();
  const { showToast } = useToast();
  const { project } = useProjectContext();

  const handleSubmit = () => {
    showToast({
      message: 'NFT successfully created',
      variant: 'success',
    });
    push('/projects');
  };

  return (
    <div className="mx-auto max-w-3xl px-4 pb-16 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between py-2">
        <span className="text-black font-sans text-xl font-bold">
          Create IP-NFT
        </span>
        {project.info.updatedAt ? (
          <span className="text-rhino-600 font-sans text-sm ml-4">
            Last saved: {getBigIntTime(project.info.updatedAt)}
          </span>
        ) : null}
      </div>
      <div className="rounded-lg bg-white shadow p-4">
        <WizardForm
          steps={steps}
          id="project-nft-form"
          onCompleted={handleSubmit}>
          {renderSteps()}
        </WizardForm>
      </div>
    </div>
  );
};

export default ProjectNFTForm;
