import { ActorSubclass, Identity } from '@dfinity/agent';
import {
  projects_types,
  projects_index,
  projects_index_types,
  projects_milestones_types,
  projects_service,
  projects_service_types,
  ip_nft_v1_types,
} from '@scinet-inc/api';
import { ActorClient, IndexClient } from 'components';
import { ActorResponse } from 'scinet-types';
import { HOST, PROJECTS_INDEX_CANISTER_ID } from '../constants';
import { getAgent } from './api';
import { MILESTONE_STATUS } from '../constants';

export const getStatusDescription = (status: string) => {
  switch (status) {
    case MILESTONE_STATUS.PLANNED:
      return 'Planned';
    case MILESTONE_STATUS.IN_PROGRESS:
      return 'On going';
    case MILESTONE_STATUS.COMPLETE:
      return 'Completed';
    default:
      return 'Not Started';
  }
};

export const initializeProjectsIndexClient = (
  canisterId: string,
  identity: Identity | undefined
) => {
  return new IndexClient<projects_index_types.ProjectsIndex>({
    IDL: projects_index.idlFactory,
    canisterId,
    agentOptions: {
      host: HOST,
      identity,
    },
  });
};

export const initializeProjectsServiceClient = (
  indexClient: IndexClient<projects_index_types.ProjectsIndex>,
  identity: Identity | undefined
) => {
  return new ActorClient<
    projects_index_types.ProjectsIndex,
    projects_service_types.ProjectsService
  >({
    actorOptions: {
      IDL: projects_service.idlFactory,
      agentOptions: {
        host: HOST,
        identity,
      },
    },
    indexClient,
  });
};

export const initializeProjectsActorClient = (
  canisterId: string,
  identity?: Identity | undefined
) => {
  const projectsIndexClient = initializeProjectsIndexClient(
    canisterId,
    identity
  );
  const serviceClient = initializeProjectsServiceClient(
    projectsIndexClient,
    identity
  );

  return serviceClient;
};

export const reduceProjectsResults = async (
  projectsResults: PromiseSettledResult<any>[]
): Promise<any> => {
  return new Promise<any>(async (resolve) => {
    let result: any = {};
    for (let settledResult of projectsResults) {
      if (settledResult.status === 'fulfilled') {
        for (const [k, v] of Object.entries(settledResult.value)) {
          let value = await v;
          result[k] = value;
        }
      }
    }
    resolve(result);
  });
};

export const getProjectsActor = async (id: string, identity?: Identity) => {
  const actorClient = initializeProjectsActorClient(
    PROJECTS_INDEX_CANISTER_ID,
    identity
  );
  // check if user exists, if not then create, if so then retrieve
  // this call will return a response from each projects service canister API
  const responses = await actorClient.queryWithCanisterIdMapping<
    projects_service_types.ProjectsService['skExists']
  >('projects', (actor) => actor.skExists(id));

  if (!responses.length) {
    return undefined;
  }

  const projectsResults = await reduceProjectsResults(responses);

  const agent = getAgent(identity);

  const canisterPair = Object.entries(projectsResults).find(
    (pair) => pair[1] === true
  );

  if (!canisterPair) {
    return undefined;
  }

  return projects_service.createClient(canisterPair[0], agent);
};

export const getProjectDetails = async (id: string, identity?: Identity) => {
  const projectActor = await getProjectsActor(id, identity);
  let project: projects_service_types.ProjectDetails | undefined;
  let error: string | undefined;

  if (!projectActor) {
    return { project, error: 'NotFound' };
  }

  return await projectActor.read(id).then((result: any) => {
    if ('ok' in result) {
      project = result.ok;
    } else {
      if ('NotAuthorized' in result.err) {
        // require a new login
        error = 'NotAuthorized';
      } else if ('NotFound' in result.err) {
        error = 'NotFound';
      }
    }
    return { project, error };
  });
};

export const getProjectMilestones = async (
  projectId: string,
  projectsMilestonesActor:
    | ActorSubclass<projects_milestones_types._SERVICE>
    | undefined
) => {
  let projectMilestones = {} as projects_milestones_types.Milestones;
  await projectsMilestonesActor
    ?.read(projectId)
    .then(async (getTeamResponse: ActorResponse) => {
      if ('ok' in getTeamResponse) {
        projectMilestones = getTeamResponse['ok'];
      } else {
        console.error('error retrieving project milestones');
      }
    });
  return projectMilestones;
};

export const getFullProject = async (
  id: string,
  projectsBaseActor: ActorSubclass<projects_types._SERVICE> | undefined,
  projectsMilestonesActor:
    | ActorSubclass<projects_milestones_types._SERVICE>
    | undefined,
  identity?: Identity
) => {
  let projectInfo = {} as projects_types.ProjectInfo;
  await projectsBaseActor?.read(id).then(async (getResponse: ActorResponse) => {
    if ('ok' in getResponse) {
      projectInfo = getResponse['ok'];
    } else {
      console.error('error retrieving project base');
    }
  });
  let projectDetails = {} as projects_service_types.ProjectDetails;
  const getProjectDetailsRes = await getProjectDetails(id, identity);

  if (!getProjectDetailsRes.error) {
    projectDetails = getProjectDetailsRes.project;
  }

  let projectMilestones = await getProjectMilestones(
    id,
    projectsMilestonesActor
  );

  return {
    info: projectInfo,
    details: projectDetails,
    milestones: projectMilestones,
  };
};

export const getOrgProjects = async (
  organizationId: string,
  projectsBaseActor: ActorSubclass<projects_types._SERVICE> | undefined
): Promise<{ projects: projects_types.ProjectInfo[]; error: boolean }> => {
  const response = projectsBaseActor
    ?.listAllProjectsForOrg(organizationId)
    .then(async (getResponse: any) => {
      if ('ok' in getResponse) {
        return { projects: getResponse['ok'], error: false };
      } else {
        console.error('failure getOrgProjects', getResponse);
        return {
          projects: [],
          error: true,
        };
      }
    });
  if (response) {
    return response;
  }

  return { projects: [], error: true };
};

export const getIpNftForProject = async (
  projectId: string,
  ipNftActor: ActorSubclass<ip_nft_v1_types._SERVICE> | undefined
) => {
  const response = ipNftActor
    ?.getTokenId(projectId)
    .then(async (getResponse: ActorResponse) => {
      if ('ok' in getResponse) {
        return getResponse['ok'];
      } else {
        // console.log('failure', getResponse);
        return null;
      }
    });

  if (response) {
    return response;
  }

  return null;
};
