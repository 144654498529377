// import { useEffect, useState } from 'react';
import {
  // CBPayInstanceType,
  generateOnRampURL,
  // initOnRamp,
} from '@coinbase/cbpay-js';
import { Button } from 'components';

type Props = {
  address: string;
};

const COINBASE_APP_ID = '09909a92-965d-46ef-b2f6-3c92ccc8f739';

export const BuyWithCoinbaseButton = ({ address }: Props) => {
  // const [onrampInstance, setOnrampInstance] = useState<
  //   CBPayInstanceType | undefined | null
  // >();

  // useEffect(() => {
  //   initOnRamp(
  //     {
  //       appId: COINBASE_APP_ID,
  //       widgetParameters: {
  //         destinationWallets: [
  //           {
  //             address: address,
  //             blockchains: ['dfinity'],
  //           },
  //         ],
  //       },
  //       onSuccess: () => {
  //         console.log('success');
  //       },
  //       onExit: () => {
  //         console.log('exit');
  //       },
  //       onEvent: (event) => {
  //         console.log('event', event);
  //       },
  //       experienceLoggedIn: 'popup',
  //       experienceLoggedOut: 'popup',
  //       closeOnExit: true,
  //       closeOnSuccess: true,
  //     },
  //     (_, instance) => {
  //       console.log('**instance', instance);
  //       setOnrampInstance(instance);
  //     }
  //   );

  //   return () => {
  //     onrampInstance?.destroy();
  //   };
  // }, []);

  // const handleClick = () => {
  //   onrampInstance?.open();
  // };

  const onRampURL = generateOnRampURL({
    appId: COINBASE_APP_ID,
    destinationWallets: [{ address, blockchains: ['dfinity'] }],
  });

  return (
    /* eslint-disable */
    <a href={onRampURL} target="_blank">
      <Button color="tertiary">Add Coinbase Funds</Button>
    </a>
  );
};
