import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  Project as ProjectContainer,
  Spinner,
  useProjectContext,
} from 'components';
import { useAppContext } from '../../AppContext';
import {
  FILES_SERVICE_URL,
  getIpNftForProject,
  getRole,
  isAuthorized,
  OrganizationRoles,
} from '../../../lib';
import { useOrganizationsTeamsContext, useUserContext } from '../..';
import { CreateInquiry, InquiriesDrawer } from '../../Inquiries';
import { useInquiriesContext } from '../../../contexts';
import { LicensesDrawer } from '../../Licenses/LicensesDrawer/LicensesDrawer';
import { useLicensesContext } from '../../../contexts/LicensesContext';

export default function SingleProject() {
  const { push } = useRouter();
  const { ipNftActor, orgTeamActor, projectActor } = useAppContext();
  const { user, actor } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const { project, resetFetchAndPush } = useProjectContext();
  const [assetCount, setAssetCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInquiriesDrawerOpen, setIsInquiriesDrawerOpen] = useState(false);
  const [isLicensesDrawerOpen, setIsLicensesDrawerOpen] = useState(false);
  const {
    inquiries,
    getInquiries,
    isLoading: areInquiriesLoading,
  } = useInquiriesContext();
  const [inquiriesFetched, setInquiriesFetched] = useState(false);

  // TODO: make more consistent
  const [canCreateInquiry, setCanCreateInquiry] = useState(false);
  const { organizationId } = useOrganizationsTeamsContext();

  const deleteProject = async () => {
    setIsLoading(true);
    await projectActor
      ?.delete(project!.info.id)
      .then(async (deleteResponse: any) => {
        if ('ok' in deleteResponse) {
          push('/projects');
        } else {
          setError(
            'There was an error deleting your project. Please try again later.'
          );
          setIsLoading(false);
        }
      });
  };

  const getIpNft = async (projectId: string) => {
    return await getIpNftForProject(projectId, ipNftActor);
  };

  const determineCanCreateInquiry = async () => {
    // if user belongs to another org, they're an admin there, AND they have not already submitted an inquiry
    if (
      organizationId &&
      organizationId !== project?.info.organizationId &&
      inquiriesFetched
    ) {
      const role = await getRole(user?.id!, organizationId!, orgTeamActor!);

      const inquiryExists = inquiries.find(
        (inquiry) => inquiry.inquiringOrganizationId === organizationId
      );

      if (inquiryExists) {
        return setCanCreateInquiry(false);
      }

      if (
        role === OrganizationRoles.ADMIN ||
        (role === OrganizationRoles.OWNER && !inquiryExists)
      ) {
        setCanCreateInquiry(true);
      }
    }
  };

  useEffect(() => {
    if (!inquiriesFetched && project.info.id && organizationId) {
      getInquiries(
        project.info.id,
        project.info.organizationId,
        organizationId
      );
      setInquiriesFetched(true);
    }
  }, [inquiriesFetched, organizationId, project.info.id]);

  useEffect(() => {
    if (
      user &&
      organizationId &&
      project?.info.organizationId &&
      inquiriesFetched &&
      !areInquiriesLoading
    ) {
      determineCanCreateInquiry();
    }
  }, [
    inquiries,
    areInquiriesLoading,
    inquiriesFetched,
    user,
    organizationId,
    project?.info.organizationId,
  ]);

  let content = (
    <div className="flex justify-center mt-16">
      <Spinner />
    </div>
  );
  if (error) {
    content = <p className="text-red-600">{error}</p>;
  } else if (project.info.id) {
    content = (
      <>
        <ProjectContainer
          project={project}
          deleteProject={deleteProject}
          routerPush={push}
          userActor={actor}
          user={user}
          assetCount={assetCount}
          filesServiceUrl={FILES_SERVICE_URL}
          resetFetchAndPush={resetFetchAndPush}
          getIpNft={getIpNft}
          isAuthorized={(
            userId: string,
            organizationId: string,
            desiredRole: OrganizationRoles
          ) => isAuthorized(userId, organizationId, desiredRole, orgTeamActor!)}
          openCreateInquiryModal={() => setIsModalOpen(true)}
          openInquiriesDrawer={() => setIsInquiriesDrawerOpen(true)}
          openLicensesDrawer={() => setIsLicensesDrawerOpen(true)}
          canCreateInquiry={canCreateInquiry}
          useInquiries={useInquiriesContext}
          userOrganizationId={organizationId}
          useLicenses={useLicensesContext}
        />
        <CreateInquiry
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
        <InquiriesDrawer
          isDrawerOpen={isInquiriesDrawerOpen}
          onClose={() => setIsInquiriesDrawerOpen(false)}
        />
        <LicensesDrawer
          isDrawerOpen={isLicensesDrawerOpen}
          onClose={() => setIsLicensesDrawerOpen(false)}
        />
      </>
    );
  } else if (isLoading) {
    content = (
      <div className="mt-16">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:m-auto pt-8 lg:pt-0 max-w-[1200px] min-h-[500px]">
      {content}
    </div>
  );
}
