import cx from 'classnames';
import { Project } from 'scinet-types';

import { Tags, Typography } from '../..';

type Props = {
  className?: string;
  tokenNumber?: number;
  project: Project;
};

export const IpNftCard = ({ className, tokenNumber, project }: Props) => {
  if (!tokenNumber) {
    return null;
  }
  return (
    <div>
      <div className="relative">
        <Typography className="text-secondary font-bold text-sm text-deep-sapphire-900 absolute top-5 left-5">
          IP-NFT #{tokenNumber}
        </Typography>
        <img
          className={cx('flex flex-col w-full justify-between', {
            className: className,
          })}
          src="/ip-token.png"
          alt="/ip-token.png"
        />
      </div>
      <div className="flex flex-col justify-between p-5 bg-deep-sapphire-900 rounded-b-lg">
        <Typography className="text-secondary font-bold text-sm">
          {project.info.title}
        </Typography>
        <Tags
          tags={project.info.fields}
          className="flex flex-row flex-wrap"
          tagClassName="text-xs font-medium text-turquoise-700 bg-teal-100 rounded-3xl px-2 py-1 mr-1 mt-2"
        />
      </div>
    </div>
  );
};
