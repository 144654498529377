import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { projects_types, projects_milestones_types } from '@scinet-inc/api';
import { ActorSubclass, Identity } from '@dfinity/agent';
import { Project } from 'scinet-types';

export type ProjectActionType =
  | 'RESET_PROJECT'
  | 'SET_ERROR'
  | 'SET_IS_LOADING'
  | 'SET_FULL_PROJECT'
  | 'SET_PROJECT_FETCHED';

export type ProjectContextType = {
  project: Project;
  isLoading: boolean;
  resetProject: () => void;
  resetFetchAndPush: (projectId: string) => void;
  setProject: (data: Project) => void;
};

const emptyProject = {
  info: {
    id: '',
    title: '',
    organizationId: '',
    fields: [],
    patentStatus: '',
    patentLink: '',
    createdAt: 0,
    updatedAt: 0,
  },
  details: {
    id: '',
    summary: '',
    background: '',
    benefits: '',
    applications: '',
    opportunities: '',
    description: '',
    terms: [],
    termsAreNegotiable: false,
    team: [],
    createdAt: 0,
    updatedAt: 0,
  },
  milestones: {
    id: '',
    createdAt: 0,
    updatedAt: 0,
    milestones: [],
  },
};

const initialContext = {
  error: '',
  isLoading: false,
  project: emptyProject,
  projectFetched: false,
};

export const ProjectContext = createContext(null);
ProjectContext.displayName = 'ProjectContext';

export const useProjectContext = (): ProjectContextType => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error(
      'useProjectContext must be used within a ProjectContextProvider',
    );
  }
  return context;
};

const ProjectReducer = (
  state: any,
  action: { type: ProjectActionType; payload?: any },
) => {
  const { type, payload } = action;

  switch (type) {
    case 'RESET_PROJECT':
      return {
        ...state,
        project: emptyProject,
        projectFetched: false,
      };
    case 'SET_FULL_PROJECT':
      return {
        ...state,
        project: payload,
        isLoading: false,
        error: '',
      };
    case 'SET_PROJECT_FETCHED':
      return {
        ...state,
        projectFetched: payload,
      };
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export const ProjectContextProvider = ({
  children,
  projectsBaseActor,
  projectsMilestonesActor,
  useRouter,
  getFullProject,
  identity,
}: {
  children: JSX.Element[] | JSX.Element;
  projectsBaseActor: ActorSubclass<projects_types._SERVICE> | undefined;
  projectsMilestonesActor:
    | ActorSubclass<projects_milestones_types._SERVICE>
    | undefined;
  useRouter: () => any;
  getFullProject: (
    id: string,
    projectsBaseActor: ActorSubclass<projects_types._SERVICE> | undefined,
    projectsMilestonesActor:
      | ActorSubclass<projects_milestones_types._SERVICE>
      | undefined,
    identity?: Identity,
  ) => Promise<Project>;
  identity: Identity | undefined;
}) => {
  const { pathname, query, push } = useRouter();

  const [state, dispatch] = useReducer(ProjectReducer, initialContext);

  const setIsLoading = (loading: boolean) => {
    dispatch({ type: 'SET_IS_LOADING', payload: loading });
  };

  const setProject = useCallback(
    (project: Project) =>
      dispatch({ type: 'SET_FULL_PROJECT', payload: project }),
    [dispatch],
  );

  const setError = (errorMessage: string) =>
    dispatch({ type: 'SET_ERROR', payload: errorMessage });

  const fetchFullProject = useCallback(
    async (id: string) => {
      setIsLoading(true);
      const fullProject = await getFullProject(
        id,
        projectsBaseActor,
        projectsMilestonesActor,
        identity,
      );

      dispatch({
        type: 'SET_FULL_PROJECT',
        payload: fullProject,
      });
    },
    [projectsBaseActor],
  );

  const setProjectFetched = (fetched: boolean) =>
    dispatch({ type: 'SET_PROJECT_FETCHED', payload: fetched });

  const resetProject = () => {
    dispatch({ type: 'RESET_PROJECT' });
  };

  const resetFetchAndPush = async (projectId: string) => {
    dispatch({ type: 'RESET_PROJECT' });

    await fetchFullProject(projectId);
    push(`/projects/${projectId}/edit`);
  };

  useEffect(() => {
    if (
      projectsBaseActor &&
      query.id &&
      !state.projectFetched &&
      pathname.includes('project')
    ) {
      fetchFullProject(query.id as string);
      setProjectFetched(true);
    }
  }, [
    projectsBaseActor,
    pathname,
    query,
    state.projectFetched,
    fetchFullProject,
  ]);

  useEffect(() => {
    if (
      pathname.includes('project') &&
      query.id !== state.project.info.id &&
      query.id
    ) {
      resetProject();
      fetchFullProject(query.id as string);
    }
  }, [pathname, query.id, state.project]);

  useEffect(() => {
    if (state.project.info.id !== '' && !pathname.includes('project')) {
      resetProject();
    }
  }, [pathname, state.project.info.id]);

  const value = {
    ...state,
    fetchProject: fetchFullProject,
    resetProject,
    resetFetchAndPush,
    setProject,
  };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};
