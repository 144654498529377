export const ALREADY_EXISTS = 'AlreadyExists';
export const NOT_AUTHORIZED = 'NotAuthorized';
export const NOT_FOUND = 'NotFound';
export const BAD_REQUEST = 'BadRequest';

export const errorMessages = {
  [ALREADY_EXISTS]: 'Entity with that name already exists',
  [NOT_AUTHORIZED]: 'Unauthorized',
  [NOT_FOUND]: 'Entity not found',
  [BAD_REQUEST]: 'Bad Request',
};
