import { useWizardFormContext } from './WizardFormContext';
import { Typography } from '../Typography';
import cx from 'classnames';

export const WizardFormStepper = ({
  stepOnClick,
  disableSteps,
  stepOnClickOverride,
}: {
  stepOnClick: (index: number) => void;
  disableSteps?: boolean;
  stepOnClickOverride?: any;
}) => {
  const { activeStep, steps } = useWizardFormContext();

  const callStep = (index: number) => {
    stepOnClick(index);
    stepOnClickOverride && stepOnClickOverride(index);
  };

  return (
    <div className="w-full flex flex-col items-center px-2 pb-4">
      <nav
        className="w-full flex flex-col justify-around"
        aria-label="Progress">
        <ol role="list" className="flex items-center w-full mb-4">
          {steps.map((label: string, index: number) => (
            <li
              key={label}
              className={cx('relative', {
                'w-full pr-4 sm:pr-28': index !== steps.length - 1,
              })}>
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true">
                  <div
                    className={cx('w-full h-1 bg-rhino-100', {
                      'bg-gradient-to-r from-electric-violet-100 to-electric-violet-600':
                        index < activeStep,
                    })}
                  />
                </div>
                <div
                  className={cx(
                    'relative flex flex-col gap-2 h-8 w-8 items-center justify-center rounded-full ',
                    {
                      'bg-primary cursor-default': index === activeStep,
                      'bg-electric-violet-100 cursor-pointer hover:bg-electric-violet-200':
                        index < activeStep,
                      'bg-white border-rhino border-2 cursor-default':
                        index > activeStep,
                    },
                  )}
                  onClick={() => (disableSteps ? () => {} : callStep(index))}>
                  <Typography
                    className={cx(
                      'w-20 rounded-full flex items-center justify-center mt-6',
                      {
                        'text-white': index === activeStep,
                        'text-primary': index < activeStep,
                        'text-rhino': index > activeStep,
                      },
                    )}>
                    {index + 1}
                  </Typography>
                  <Typography
                    className={cx(
                      'text-xs flex items-center justify-center break-keep w-20',
                      {
                        'text-primary': index === activeStep && !disableSteps,
                        'text-black': index < activeStep && !disableSteps,
                        'text-rhino': index > activeStep && !disableSteps,
                      },
                    )}>
                    {label}
                  </Typography>
                </div>
              </>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};
