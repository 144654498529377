import React, { createContext, useContext, useEffect, useState } from 'react';
import { tokens } from '@scinet-inc/api';
import { useAuthentication } from 'components';
import { ActorResponse } from 'scinet-types';
import { getAgent, TOKENS_CANISTER_ID } from '../lib';

interface AuthorizationContextProps {
  tokenId: string | undefined;
}

export const AuthorizationContext = createContext<AuthorizationContextProps>({
  tokenId: '',
});

export const AuthorizationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [tokenId, setTokenId] = useState<string | undefined>(undefined);
  const { identity } = useAuthentication();

  const fetchTokenId = async () => {
    const agent = getAgent(identity);
    const tokensActor = tokens.createClient(
      TOKENS_CANISTER_ID as string,
      agent
    );

    try {
      await tokensActor.getByUserId().then(async (getRes: ActorResponse) => {
        if ('ok' in getRes) {
          const tokenId = getRes['ok'];
          return setTokenId(tokenId);
        } else {
          await tokensActor
            .createToken()
            .then(async (createRes: ActorResponse) => {
              if ('ok' in createRes) {
                const tokenId = createRes['ok'];
                return setTokenId(tokenId);
              } else {
                console.error('failed to create tokenId', createRes);
              }
            });
        }
      });
    } catch (error) {
      console.error('Error fetching user token:', error);
    }
  };

  useEffect(() => {
    // remove the token if they logout
    if (!identity && tokenId) {
      return setTokenId('');
    }

    // add the token if they login
    if (identity && !tokenId) {
      fetchTokenId();
    }
  }, [tokenId, identity]);

  return (
    <AuthorizationContext.Provider value={{ tokenId }}>
      {children}
    </AuthorizationContext.Provider>
  );
};

export function useAuthorizationContext() {
  return useContext(AuthorizationContext);
}
