import cx from 'classnames';
import { forwardRef, useCallback } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { BiErrorCircle } from 'react-icons/bi';
import { Typography } from '../Typography';

type Props = {
  onChange?: (value: string, field?: string) => void;
  value?: string;
  fieldName?: string;
  className?: string;
  labelClassName?: string;
  label?: string;
  ariaLabel?: string;
  id?: string;
  placeholder?: string;
  defaultValue?: string;
  error?:
    | string
    | FieldError
    | Partial<{ type: string | number; message: string }>
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined;
  helperText?: string;
  helperTextClassName?: string;
  maxLength?: number;
};

export const TextArea = forwardRef(
  (
    {
      onChange,
      value,
      id,
      fieldName,
      placeholder,
      className,
      labelClassName,
      label,
      ariaLabel,
      defaultValue,
      error,
      helperText,
      helperTextClassName,
      maxLength = 3000,
    }: Props,
    ref,
  ) => {
    const handleChange = useCallback(
      (e: any) => {
        const { value } = e.target;
        if (onChange) {
          onChange(value, fieldName);
        }
      },
      [onChange, fieldName],
    );

    return (
      <div>
        <div className="flex flex-row w-full justify-between pb-1">
          <Typography className={labelClassName} aria-label={ariaLabel}>
            {label}
          </Typography>
          {helperText && (
            <Typography className={helperTextClassName}>
              {helperText}
            </Typography>
          )}
        </div>
        <textarea
          //@ts-ignore
          ref={ref}
          onChange={handleChange}
          className={cx(
            'placeholder:text-slate-400 block bg-white w-full h-full border rounded-md py-2 pl-2 pr-3 shadow-md focus:outline-none  focus:ring-2 sm:text-sm',
            className,
            {
              'border-red-300': error,
              'ring-red-100': error,
              'ring-1': error,
              'focus:border-red-400': error,
              'focus:ring-red-200': error,
              'border-slate-300': !error,
              'border-purple-400': !error,
              'focus:ring-purple-200': !error,
              'focus:border-purple-400': !error,
            },
          )}
          placeholder={placeholder}
          id={id}
          value={value ? value : defaultValue}
          maxLength={maxLength}
        />
        {error ? (
          <div className="flex flex-row pt-1">
            <BiErrorCircle color="red" />
            <Typography className="text-xs text-gray-400 pl-1">
              {error}
            </Typography>
          </div>
        ) : (
          <div className="h-5"></div>
        )}
      </div>
    );
  },
);
