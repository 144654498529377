import {
  users_types,
  projects_types,
  projects_service_types,
  projects_milestones_types,
  organizations_types,
  organizations_service_types,
  organizations_teams_types,
} from '@scinet-inc/api';

export type FullProject = {
  info: projects_types.ProjectInfo;
  details: projects_service_types.ProjectDetails;
  milestones: projects_milestones_types.Milestones;
};
export type User = users_types.User;
export type Organization = {
  info: organizations_types.OrganizationInfo;
  details: organizations_service_types.OrganizationDetails;
  team: organizations_teams_types.OrganizationTeam;
};

export enum OrganizationRoles {
  OWNER = 'owner',
  ADMIN = 'admin',
  MEMBER = 'member',
}
