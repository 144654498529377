import { Fragment, useEffect, useMemo, useState } from 'react';
import {
  useAuthentication,
  useWizardFormContext,
  WizardNavigation,
  Autocomplete,
  useToast,
  useProjectContext,
  Button,
} from 'components';
import { white_lists, white_lists_types } from '@scinet-inc/api';
import { getAgent, WHITE_LISTS_CANISTER_ID } from '../../../../lib';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { ActorResponse } from 'scinet-types';
import { Dialog, Transition } from '@headlessui/react';
import { useRouter } from 'next/router';

type WalletObject = {
  label: string;
  value: string;
};

const WhiteListDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => (
  <Transition.Root show={isOpen} as={Fragment}>
    <Dialog onClose={onClose} className="relative z-50">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div className="fixed backdrop-blur-sm inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex h-full items-center justify-center p-4 text-center sm:min-h-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <Dialog.Panel className="mx-auto max-w-lg rounded bg-white ">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={onClose}>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <Dialog.Title className="text-xl text-left font-medium m-4">
                White List
              </Dialog.Title>
              <Dialog.Description className="text-lg font-light m-4 text-left">
                Once you start receiving inquiries about your IP, you’ll need to
                grant access to serious inquirors so they can see the IPData
                that you uploaded. You will receive their wallet address during
                the course of the inquiry, and you can add that here to grant
                access. If you do not need to add anyone to the whitelist, you
                can simple exit out of this window.
              </Dialog.Description>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);

const WhiteList = () => {
  const { push } = useRouter();
  const { isLoading } = useProjectContext();
  const { nextStep, setFormValues } = useWizardFormContext();
  const [walletIds, setWalletIds] = useState<string[]>([]);
  const [formWalletIds, setFormWalletIds] = useState<WalletObject[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { identity, isAuthenticated } = useAuthentication();
  const { project } = useProjectContext();

  const [error, setError] = useState('');
  const [whiteListExistsCalled, setWhiteListExistsCalled] = useState(false);
  const { showToast } = useToast();
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [whiteList, setWhiteList] =
    useState<white_lists_types.WhiteListNode | null>(null);

  const agent = useMemo(() => getAgent(identity), [identity]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const whiteListsActor = white_lists.createClient(
      WHITE_LISTS_CANISTER_ID as string,
      agent
    );
    const mappedWalletIds = formWalletIds.map(
      ({ value }: { value: string; label: string }) => value.trim()
    );

    const finalWalletIdsList = [...walletIds, ...mappedWalletIds];
    setFormIsLoading(true);

    if (whiteList) {
      if (isDirty) {
        await whiteListsActor
          ?.update(
            project.info.organizationId,
            project.info.id,
            finalWalletIdsList
          )
          .then(async (udpateRes: any) => {
            if (udpateRes.ok) {
              showToast({
                message: 'Update wallet white list succeeded.',
                variant: 'success',
              });
              setFormValues(finalWalletIdsList);
              setFormIsLoading(false);
              setWhiteList(udpateRes.ok);
              setWalletIds(udpateRes.ok.whiteList);
              setFormWalletIds([]);
              return nextStep();
            }
            showToast({
              message: 'Update wallet white list failed.',
              variant: 'error',
            });
            setFormIsLoading(false);
            return setError(udpateRes.err);
          });
      }
      return push(`/projects/${project.info.id}/nft`);
    } else {
      const createPayload = {
        whiteList: finalWalletIdsList,
        projectId: project.info.id,
      };
      await whiteListsActor
        ?.create(project.info.organizationId, createPayload)
        .then(async (createRes: any) => {
          if (createRes.ok) {
            showToast({
              message: 'Create wallet white list succeeded.',
              variant: 'success',
            });
            setFormValues(finalWalletIdsList);
            return push(`/projects/${project.info.id}/nft`);
          }

          showToast({
            message: 'Create wallet white list failed.',
            variant: 'error',
          });
          setFormIsLoading(false);
          return setError(createRes.err);
        });
    }
  };

  const checkIfWhiteListExists = async () => {
    const whiteListsActor = white_lists.createClient(
      WHITE_LISTS_CANISTER_ID as string,
      agent
    );

    await whiteListsActor
      .get(project.info.id)
      .then(async (res: ActorResponse) => {
        if (res.ok) {
          setWhiteListExistsCalled(true);
          setWhiteList(res.ok);
          return setWalletIds(res.ok.whiteList);
        }

        setError('There was an issue getting white list data.');
      });
  };

  useEffect(() => {
    if (isAuthenticated && !whiteListExistsCalled && project.info.id) {
      checkIfWhiteListExists();
    }
  }, [isAuthenticated, whiteListExistsCalled, project]);

  const removePrincipal = async (textPrincipal: string) => {
    const newWalletIds = walletIds.filter((id: string) => id !== textPrincipal);
    setWalletIds(newWalletIds);
    const whiteListsActor = white_lists.createClient(
      WHITE_LISTS_CANISTER_ID as string,
      agent
    );
    await whiteListsActor
      .removeFromWhiteList(
        project.info.organizationId,
        project.info.id,
        textPrincipal
      )
      .then(async (res: any) => {
        if (res.ok) {
          const updatedWhiteList = { ...whiteList, whiteList: newWalletIds };

          // @ts-ignore
          return setWhiteList(updatedWhiteList);
        }

        setError('There was an issue getting white list data.');
      });

    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const onChange = (values: any[]) => {
    setFormWalletIds(values);
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="mx-auto max-w-3xl">
          <div className="p-4">
            <div className="w-full flex items-center justify-between py-2 mt-2">
              <span className="text-deep-sapphire-900 font-sans text-lg font-bold">
                Add Principal / Wallet
              </span>
              <Button color="tertiary" onClick={() => setIsModalOpen(true)}>
                What is this?
              </Button>
            </div>

            <Autocomplete
              value={formWalletIds}
              multiple
              canAddNew
              onChange={(value) => {
                onChange(value);
              }}
              placeHolder="Wallet Ids"
            />
          </div>
          {walletIds.length > 0 && (
            <ul
              className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              tabIndex={-1}>
              {walletIds.map((textPrincipal, index) => (
                <li
                  onClick={() => removePrincipal(textPrincipal)}
                  key={index}
                  className="cursor-default select-none relative py-2 pl-8 pr-4 text-primary bg-electric-violet-100 flex flex-row items-center">
                  <div className="flex items-center">
                    <span className="font-normal block truncate">
                      {textPrincipal}
                    </span>
                  </div>
                  <div className="cursor-pointer ml-auto rounded border-2 border-transparent hover:border-gray-200">
                    <XMarkIcon className="h-4 w-4" />
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="w-full flex items-center justify-center py-2">
          <WizardNavigation
            nextStepLabelOverride="Save and Exit"
            disablePrimaryButton={isLoading || formIsLoading}
          />
        </div>
      </form>
      <WhiteListDialog
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default WhiteList;
