import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import {
  AtomTrigger,
  useWindowDimensions,
  useWindowScroll,
} from 'react-atom-trigger';
import { elastic_search_types } from '@scinet-inc/api';
import { useDebounce } from '@scinet-inc/hooks';
import { useAppContext } from '../AppContext';
import { Button, Card, Input, Tags, Typography } from 'components';

type InitialSearchParams = {
  itemsRemaining: undefined | bigint;
  limit: bigint;
  lastIndex: bigint;
};

const initialSearchParams = {
  itemsRemaining: undefined,
  limit: BigInt(20),
  lastIndex: BigInt(0),
};

export function SearchPage() {
  const [cachedRecords, setCachedRecords] = useState<
    elastic_search_types.Record[]
  >([]);
  const { searchActor } = useAppContext();
  const [queryString, setQueryString] = useState<string>('');
  const [searchParams, setSearchParams] =
    useState<InitialSearchParams>(initialSearchParams);
  const debouncedQueryString = useDebounce<string>(queryString, 1000);
  const { push } = useRouter();
  const windowDimensions = useWindowDimensions();
  const scrollInfo = useWindowScroll();

  const callES = () => {
    const { limit, lastIndex } =
      searchParams === initialSearchParams ? initialSearchParams : searchParams;
    searchActor
      ?.queryIndex(debouncedQueryString, limit, lastIndex, 'all')
      .then(async (queryRes) => {
        if ('ok' in queryRes) {
          const { records, nextLastIndex, itemsRemaining } = queryRes['ok'];
          setSearchParams({
            limit: initialSearchParams.limit,
            lastIndex: nextLastIndex,
            itemsRemaining: itemsRemaining,
          });
          const fullRecordsList = cachedRecords.concat(records);
          setCachedRecords(fullRecordsList);
        } else {
          console.log('failure', queryRes);
        }
      });
  };

  const clearResults = () => {
    setCachedRecords([]);
    setQueryString('');
    setSearchParams(initialSearchParams);
  };

  useEffect(() => {
    if (queryString !== debouncedQueryString) {
      setCachedRecords([]);
      setSearchParams(initialSearchParams);
    }
  }, [debouncedQueryString, queryString]);

  useEffect(() => {
    if (debouncedQueryString) {
      callES();
    }
  }, [debouncedQueryString]);

  const memoCachedRecords = useMemo(
    () =>
      cachedRecords.map(
        (record: elastic_search_types.Record, index: number) => {
          let name;
          let href: string;
          switch (record.entityType) {
            // case 'user':
            //   // @ts-ignore
            //   name = record.entity.user?.username;
            //   // @ts-ignore
            //   href = `/${record.entityType}s?username=${record.entity.user?.username}`;
            //   break;
            case 'organization':
              // @ts-ignore
              name = record.attributes[1][1].text;
              // @ts-ignore
              href = `/${record.entityType}s/${record.id}`;
              break;
            case 'project':
              // @ts-ignore
              name = record.attributes[1][1].text;
              // @ts-ignore
              href = `/${record.entityType}s/${record.id}`;
              break;
            default:
              return;
          }
          const tagsIndex = record.attributes.findIndex(
            (array) => array[0] === 'tags' || array[0] === 'fields'
          );
          // @ts-ignore
          const tags = record.attributes[tagsIndex][1].arrayText;
          return (
            <Card
              key={index}
              onClick={() => push(href)}
              className="hover:cursor-pointer hover:shadow-xl m-0 md:mt-4 md:mr-4">
              <div>
                <Typography>{name}</Typography>
                {tagsIndex && tags && (
                  <Tags
                    tags={tags}
                    tagClassName="text-xs font-medium text-teal-800 bg-teal-100 rounded-3xl px-2 py-1 mr-2"
                    className="flex flex-row flex-wrap my-4"
                  />
                )}
              </div>
            </Card>
          );
        }
      ),
    [cachedRecords, push]
  );

  const { itemsRemaining } = searchParams;

  return (
    <>
      {/** build will break if you remove this */}
      {/** @ts-ignore */}
      <div className="mb-30">
        <div className="flex flex-row justify-space">
          <Typography className="font-lg font-semibold flex items-center">
            Search
          </Typography>
          <Button color="tertiary" onClick={clearResults}>
            Clear Results
          </Button>
        </div>

        <Input
          value={queryString}
          onChange={(value) => setQueryString(value)}
        />
        <div className="flex flex-wrap">{memoCachedRecords}</div>
        {itemsRemaining && (
          <AtomTrigger
            className="mb-30"
            behavior="enter"
            scrollEvent={scrollInfo}
            callback={() => callES()}
            dimensions={windowDimensions}
          />
        )}
      </div>
    </>
  );
}
