import React from 'react';

export default function RoyaltyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="48px"
      height="48px">
      <g transform="matrix(2,0,0,2,0,0)">
        <g>
          <path
            d="M19.39,1a.48.48,0,0,0,0-.48.51.51,0,0,0-.43-.24H13.56a.51.51,0,0,0-.43.24.48.48,0,0,0,0,.48l1.07,2.14a.24.24,0,0,0,.22.14h3.7a.24.24,0,0,0,.22-.14Z"
            fill="#000000"></path>
          <path
            d="M19.31,14a.26.26,0,0,0-.17-.09A.22.22,0,0,0,19,14a1,1,0,0,1-.66.25c-7.76,0-6.18,0-7.63-.1a.24.24,0,0,0-.22.08,8.61,8.61,0,0,0-2.19,5.29c0,3.12,3.6,4.23,6.68,4.23s6.85-1.11,6.82-4.23A9.42,9.42,0,0,0,19.31,14Zm-4.73,5.15c-.81-.54-1.58-1.06-1.58-1.87A1.22,1.22,0,0,1,14.06,16a.24.24,0,0,0,.19-.23.75.75,0,0,1,1.5,0,.19.19,0,0,0,.19.19h.31a.75.75,0,1,1,0,1.5h-.76a.25.25,0,0,0-.24.17.27.27,0,0,0,.17.33C16.23,18.49,17,19,17,19.81a1.26,1.26,0,0,1-1.06,1.33.24.24,0,0,0-.19.24.75.75,0,0,1-1.5,0,.18.18,0,0,0-.19-.19h-.31a.75.75,0,1,1,0-1.5h.76a.25.25,0,0,0,.24-.18C14.81,19.32,14.65,19.23,14.58,19.18Z"
            fill="#000000"></path>
          <path
            d="M11.74,13.25h6.41a.26.26,0,0,0,.21-.11l.65-1a1,1,0,0,0,.12-.87l-.19-.58a1,1,0,0,1,.12-.87l.36-.54a1,1,0,0,0,0-1.1l-.27-.4a1,1,0,0,1,0-1.1l.27-.4a1,1,0,0,0,0-1.1l-.34-.5a1,1,0,0,0-.83-.45H13.4a.24.24,0,0,1-.22-.14l-.92-1.84a.25.25,0,0,0-.33-.11l-1.88.94a1,1,0,0,0-.45.45L8.44,5.88a1,1,0,0,1-.58.5l-.94.31a.26.26,0,0,0-.17.24v5.59a.25.25,0,0,0,.23.25Z"
            fill="#000000"></path>
          <path
            d="M4.25,6.25H2.5a.25.25,0,0,0-.25.25V13a.25.25,0,0,0,.25.25H4.25a1,1,0,0,0,1-1v-5A1,1,0,0,0,4.25,6.25Z"
            fill="#000000"></path>
        </g>
      </g>
    </svg>
  );
}
