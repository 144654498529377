import Head from 'next/head';
import { useAuthentication } from 'components';
import { useUserContext } from '..';
import { SignUpForm } from '../User';

type Props = {
  children: JSX.Element;
};

export function AccountCompletion({ children }: Props) {
  const { isAuthenticated } = useAuthentication();
  const { user, isUserLoading, renderUserForm } = useUserContext();

  if (isAuthenticated && !user && !isUserLoading && renderUserForm) {
    return (
      <>
        <Head>
          <title>SCINET: Complete Registration</title>
        </Head>
        <SignUpForm />
      </>
    );
  } else {
    return children;
  }
}
