import React from 'react';

interface BlockedCountryProps {
  country: string;
}

const BlockedCountry: React.FC<BlockedCountryProps> = ({ country }) => {
  return (
    <div className="pt-5 m-auto">
      Creating an account in {country} is forbidden. Please see our website for
      more information.
    </div>
  );
};

export default BlockedCountry;
