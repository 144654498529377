import { XMarkIcon } from '@heroicons/react/24/outline';
import { DocIcon, PdfIcon } from '../Icons';

export const FilesContainer = ({
  client,
  files,
  setFiles,
  canDelete = false,
}: {
  client: any | undefined;
  files: any[];
  setFiles: (files: any[]) => void;
  canDelete?: boolean;
}) => {
  return (
    <div className="w-full mt-2">
      {files.map((file) => (
        <div key={file.filename} className="flex items-center gap-2 py-2">
          {file.filename.includes('.pdf') ? <PdfIcon /> : <DocIcon />}
          <div className="flex flex-col w-full">
            <span
              className="text-rhino-900 font-sans text-sm font-bold hover:underline hover:cursor-pointer"
              onClick={async () => {
                await client?.download(file.filename);
              }}>
              {file.filename}
            </span>
            <div className="flex items-center">
              {file.size && (
                <span className="text-rhino-600 text-xs">
                  {Math.ceil(file.size / 1024)} kb
                </span>
              )}
            </div>
          </div>
          {canDelete && (
            <div className="flex-grow">
              <div className="flex justify-end cursor-pointer p-1 rounded border-2 border-transparent hover:border-gray-200">
                <XMarkIcon
                  className="w-4 h-4"
                  onClick={async () => {
                    setFiles(files.filter((f) => f.filename !== file.filename));
                    await client?.delete(file.filename);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
