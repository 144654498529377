import { cx } from 'class-variance-authority';

export const AdminIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      fill="none"
      className={cx('h-8 text-white', className)}>
      <g>
        <path
          d="M7.36,13.43h0a1,1,0,0,1-.72,0h0a9.67,9.67,0,0,1-6.14-9V1.5a1,1,0,0,1,1-1h11a1,1,0,0,1,1,1V4.42A9.67,9.67,0,0,1,7.36,13.43Z"
          fill="none"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"></path>
        <circle
          cx="7"
          cy="5.5"
          r="2.5"
          fill="none"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"></circle>
        <path
          d="M3.25,11.19a5,5,0,0,1,7.5,0"
          fill="none"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"></path>
      </g>
    </svg>
  );
};
