import { useEffect, useMemo, useState } from 'react';
import {
  inquiries_types,
  license_nft_metadata_v1_types,
  white_lists_types,
} from '@scinet-inc/api';
import {
  Button,
  Card,
  Spinner,
  useAuthentication,
  useProjectContext,
  useToast,
} from 'components';

import { fetchFullOrg, Organization } from '../../../lib';
import { useAppContext } from '../../AppContext';
import LicenseCardHeader from './LicenseCardHeader';
import { useRouter } from 'next/router';
import { noop } from '@scinet-inc/utils';

export default function LicenseCard({
  licenseMeta,
}: {
  licenseMeta: license_nft_metadata_v1_types.LicenseMeta;
}) {
  const { identity } = useAuthentication();
  const { orgActor, orgTeamActor } = useAppContext();
  const [fullOrg, setFullOrg] = useState<Organization | null>(null);
  const { push } = useRouter();
  const { project } = useProjectContext();

  useEffect(() => {
    if (!fullOrg) {
      fetchFullOrg(
        licenseMeta.properties.owner,
        orgActor,
        orgTeamActor,
        identity
      ).then((res) => {
        setFullOrg(res);
      });
    }
  }, [fullOrg, identity, licenseMeta.properties.owner, orgActor, orgTeamActor]);

  const headerNode = (
    <LicenseCardHeader licenseMeta={licenseMeta} ownerOrg={fullOrg} />
  );

  return (
    <Card
      // description={inquiry.message}
      headerNode={headerNode}
      className="m-0 md:mt-4 md:mr-4"
      onClick={noop}>
      <Button
        color="primary"
        className="mt-4 mr-2"
        fullWidth
        onClick={() =>
          push(
            `/projects/${project.info.id}/licenses/${licenseMeta.license_id}`
          )
        }>
        View License
      </Button>
    </Card>
  );
}
