import { forwardRef, useCallback } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { BiErrorCircle } from 'react-icons/bi';
import { Switch } from '@headlessui/react';
import { Typography } from '../Typography';

type Props = {
  onChange?: (value: boolean, field?: string) => void;
  fieldName?: string;
  ariaLabel?: string;
  value?: boolean;
  helperText?: string;
  helperTextClassName?: string;
  id?: string;
  label?: string;
  disabled?: boolean;
  labelClassName?: string;
  className?: string;
  error?:
    | string
    | FieldError
    | Partial<{ type: string | number; message: string }>
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined;
  ref?: any;
};

export const Toggle = forwardRef(
  ({
    onChange,
    fieldName,
    label,
    disabled,
    labelClassName,
    id = 'name',
    value,
    className,
    ariaLabel,
    helperText,
    helperTextClassName,
    error,
  }: Props) =>
    // ref,
    {
      const handleChange = useCallback(
        (changeValue: boolean) => {
          if (onChange) {
            onChange(changeValue, fieldName);
          }
        },
        [onChange, fieldName],
      );

      const fullId = `${id}-toggle-input`;
      return (
        <div>
          <div>
            {helperText && (
              <Typography className={helperTextClassName}>
                {helperText}
              </Typography>
            )}
            <div className="flex flex-row">
              <Switch
                disabled={disabled}
                id={fullId}
                checked={value}
                onChange={handleChange}
                className={`${value ? 'bg-electric-violet-700' : 'bg-rhino-500'}
          relative inline-flex h-[24px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ${className}`}>
                <span
                  aria-hidden="true"
                  className={`${value ? 'translate-x-4' : 'translate-x-0'}
            pointer-events-none inline-block h-[16px] w-[16px] transform rounded-full ${
              disabled ? 'bg-rhino-300' : 'bg-white'
            } shadow-lg ring-0 transition duration-200 ease-in-out mt-0.5 ml-0.5`}
                />
              </Switch>
              {label && (
                <Typography
                  className={`pl-2 ${labelClassName}`}
                  aria-label={ariaLabel}>
                  {label}
                </Typography>
              )}
            </div>
          </div>
          {error ? (
            <div className="flex flex-row pt-1">
              <BiErrorCircle color="red" />
              <Typography className="text-xs text-gray-400 pl-1">
                {error}
              </Typography>
            </div>
          ) : (
            <div className="h-5"></div>
          )}
        </div>
      );
    },
);
Toggle.displayName = 'Toggle';
