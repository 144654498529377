import useSWR from 'swr';
import { USERS_SERVICE_URL } from '../constants';
import { useAuthorizationContext } from '../../contexts';
import { useAuthentication } from 'components';
import { users_types } from '@scinet-inc/api';
import { User } from 'scinet-types';

type UserProfile = Omit<
  users_types.User,
  'principal' | 'createdAt' | 'updatedAt'
>;

const fetchWithIdentity = async ({
  userId,
  token,
  method,
  body,
}: {
  userId?: string;
  token?: string;
  method?: 'GET' | 'POST';
  body?: any;
}) => {
  if (userId && token) {
    const resp = await fetch(USERS_SERVICE_URL, {
      method: method || 'GET',
      headers: {
        authorization: `Bearer ${token}`,
        identity: userId,
      },
      body: JSON.stringify(body),
    });
    if (resp.ok) {
      return resp.json() as Promise<Omit<User, 'principal'>>;
    }
  }
  return Promise.resolve(null);
};

// get private profile and expose functions to update
export const useUsersService = (): {
  user?: UserProfile;
  error: Error;
  isLoading: boolean;
  createOrUpdateUser: (
    user: UserProfile
  ) => Promise<Omit<User, 'principal'> | null>;
} => {
  const { identity } = useAuthentication();
  const { tokenId } = useAuthorizationContext();
  const userId = identity?.getPrincipal().toString();

  const { data, isLoading, error, mutate } = useSWR(
    [USERS_SERVICE_URL, userId, tokenId],
    ([_, userId, token]) => fetchWithIdentity({ userId, token })
  );

  return {
    user: data as UserProfile | undefined,
    error,
    isLoading,
    createOrUpdateUser: async (user: UserProfile) => {
      const resp = await fetchWithIdentity({
        userId,
        token: tokenId,
        method: 'POST',
        body: user,
      });
      mutate(resp);
      return resp;
    },
  };
};
