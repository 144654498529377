import { projects_types, users_types } from '@scinet-inc/api';
import { ActorSubclass } from '@dfinity/agent';
import { GetProfile, OrganizationRoles, Project } from 'scinet-types';
import ProjectActions from '../ProjectCard/ProjectActions';
import { Tags, TeamMember, Typography } from '../..';

type Props = {
  project: Project;
  deleteProject?: () => any | undefined | Promise<projects_types.Result_2>;
  routerPush: (url: any, options?: any) => Promise<boolean>;
  userActor?: ActorSubclass<users_types._SERVICE> | undefined;
  hideActions?: boolean | undefined;
  user?: users_types.User;
  resetFetchAndPush: (projectId: string) => void;
  isAuthorized: (
    userId: string,
    organizationId: string,
    desiredRole: OrganizationRoles,
  ) => Promise<boolean>;
  isAdmin: boolean;
  filesServiceUrl: string;
};

export default function ProjectHeader({
  project,
  deleteProject,
  routerPush,
  userActor,
  hideActions,
  isAdmin,
  resetFetchAndPush,
  filesServiceUrl,
}: Props) {
  const teamMember =
    project.details.team && project.details.team.length > 0
      ? project?.details?.team[0]
      : '';
  return (
    <div className="md:mt-0">
      <div className="flex flex-row justify-between">
        <Typography className="text-2xl font-semibold md:text-3xl md:font-bold">
          {project?.info.title}
        </Typography>
        <div className="hidden md:flex flex-row content-center">
          <TeamMember
            memberId={teamMember}
            alignRight
            userActor={userActor}
            filesServiceUrl={filesServiceUrl}
          />
          <ProjectActions
            isAdmin={isAdmin}
            deleteProject={deleteProject}
            project={project}
            routerPush={routerPush}
            hideActions={hideActions}
            resetFetchAndPush={resetFetchAndPush}
            useVerticalKebab
          />
        </div>
      </div>
      <Tags
        tags={project?.info.fields}
        tagClassName="text-xs font-medium text-turquoise-700 bg-teal-100 rounded-3xl px-2 py-1 mr-1 mt-2"
        className="flex flex-row flex-wrap"
      />
    </div>
  );
}
