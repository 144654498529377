import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { HttpAgent } from '@dfinity/agent';
import { ip_nft_v1 } from '@scinet-inc/api';
import { useRouter } from 'next/router';
import { useAuthentication } from 'components';

// export type ProjectAssetsActionType =
//   | 'SET_PROJECT_ASSETS'
//   | 'SET_PROJECT_ASSETS_ERROR'
//   | 'SET_PROJECT_ASSETS_IS_LOADING'
//   | 'SET_PROJECT_ASSETS_FETCHED'
//   | 'RESET_PROJECT_ASSETS';

export type ProjectIPNFTActionType =
  | 'SET_IP_NFT_TOKEN_ID'
  | 'SET_IP_NFT_ERROR'
  | 'SET_IP_NFT_IS_LOADING'
  | 'SET_IP_NFT_FETCHED'
  | 'RESET_IP_NFT';

// export type ProjectAssetsContextType = {
//   projectAssets: projects_assets_types.AssetsMetaData | undefined;
//   projectAssetsFetched: boolean;
//   projectAssetsIsLoading: boolean;
//   projectAssetsError: string;
// };

export type IPNFTContextType = {
  ipNftTokenId: number;
  ipNftFetched: boolean;
  ipNftIsLoading: boolean;
  ipNftError: string;
};

// const emptyProjectAssets = {
//   id: '',
//   createdAt: '',
//   collections: [],
//   updatedAt: '',
// };

// const initialProjectAssetsContext = {
//   projectAssetsError: '',
//   projectAssetsIsLoading: false,
//   projectAssetsFetched: false,
//   projectAssets: emptyProjectAssets,
// };

const initialIPNFTContext = {
  ipNftTokenId: null,
  ipNftFetched: false,
  ipNftIsLoading: false,
  ipNftError: '',
};

// export const ProjectAssetsContext = createContext(null);
// ProjectAssetsContext.displayName = 'ProjectAssetsContext';

export const IPNFTContext = createContext(null);
IPNFTContext.displayName = 'IPNFTContext';

// export const useProjectAssetsContext = (): ProjectAssetsContextType => {
//   const context = useContext(ProjectAssetsContext);
//   if (!context) {
//     throw new Error(
//       'useProjectAssetsContext must be used within a ProjectAssetsContextProvider'
//     );
//   }
//   return context;
// };

export const useIPNFTContext = (): IPNFTContextType => {
  const context = useContext(IPNFTContext);
  if (!context) {
    throw new Error(
      'useIPNFTContext must be used within a IPNFTContextProvider'
    );
  }
  return context;
};

// const ProjectAssetsReducer = (
//   state: any,
//   action: { type: ProjectAssetsActionType; payload?: any }
// ) => {
//   const { type, payload } = action;

//   switch (type) {
//     case 'SET_PROJECT_ASSETS':
//       return {
//         ...state,
//         projectAssets: payload,
//         projectAssetsIsLoading: false,
//         projectAssetsError: '',
//         projectAssetsFetched: true,
//       };
//     case 'SET_PROJECT_ASSETS_ERROR':
//       return {
//         ...state,
//         projectAssetsError: payload,
//       };
//     case 'SET_PROJECT_ASSETS_IS_LOADING':
//       return {
//         ...state,
//         projectAssetsIsLoading: payload,
//       };
//     case 'SET_PROJECT_ASSETS_FETCHED':
//       return {
//         ...state,
//         projectAssetsFetched: payload,
//       };
//     case 'RESET_PROJECT_ASSETS':
//       return {
//         ...state,
//         projectAssets: emptyProjectAssets,
//         projectAssetsFetched: false,
//         projectAssetsError: '',
//         projectAssetsIsLoading: false,
//       };
//     default:
//       throw new Error(`Unhandled action type: ${type}`);
//   }
// };

const IPNFTReducer = (
  state: any,
  action: { type: ProjectIPNFTActionType; payload?: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_IP_NFT_TOKEN_ID':
      return {
        ...state,
        ipNftTokenId: payload,
        ipNftIsLoading: false,
        ipNftError: '',
        ipNftFetched: true,
      };
    case 'SET_IP_NFT_ERROR':
      return {
        ...state,
        ipNftError: payload,
      };
    case 'SET_IP_NFT_IS_LOADING':
      return {
        ...state,
        ipNftIsLoading: payload,
      };
    case 'SET_IP_NFT_FETCHED':
      return {
        ...state,
        ipNftFetched: payload,
      };
    case 'RESET_IP_NFT':
      return {
        ...state,
        ipNftTokenId: 0,
        ipNftFetched: false,
        ipNftError: '',
        ipNftIsLoading: false,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

// export const ProjectAssetsContextProvider = ({
//   children,
// }: {
//   children: JSX.Element[] | JSX.Element;
// }) => {
//   const initialProjectAssetsContextState = {
//     initialProjectAssetsContext,
//   };
//   const { pathname, query, push } = useRouter();
// const { identity } = useAuthentication();

// const assetsAgent = useMemo(
//   () =>
//     new HttpAgent({
//       identity,
//       host: process.env.NEXT_PUBLIC_IC_HOST,
//     }),
//   [identity]
// );

// const assetsActor = useMemo(
//   () =>
//     projects_assets.createClient(
//       process.env.NEXT_PUBLIC_PROJECTS_ASSETS_CANISTER_ID as string,
//       assetsAgent
//     ),
//   [identity]
// );

// const [state, dispatch] = useReducer(
//   ProjectAssetsReducer,
//   initialProjectAssetsContextState
// );

// const setProjectAssetsIsLoading = (loading: boolean) => {
//   dispatch({ type: 'SET_PROJECT_ASSETS_IS_LOADING', payload: loading });
// };

// const setProjectAssetsError = (errorMessage: string) =>
//   dispatch({ type: 'SET_PROJECT_ASSETS_ERROR', payload: errorMessage });

// const setProjectAssetsFetched = (isFetched: boolean) => {
//   dispatch({ type: 'SET_PROJECT_ASSETS_FETCHED', payload: isFetched });
// };

// const setProjectAssets = useCallback(
//   (project: projects_assets_types.AssetsMetaData | undefined) =>
//     dispatch({ type: 'SET_PROJECT_ASSETS', payload: project }),
//   [dispatch]
// );

// const getProjectAssets = useCallback(
//   async (id: string) => {
//     setProjectAssetsError('');
//     setProjectAssetsIsLoading(true);

//     await assetsActor?.get_project_assets(id).then(async (res: any) => {
//       if (res.ok) {
//         setProjectAssetsFetched(true);
//         setProjectAssets(res.ok);
//       } else if (res.err.hasOwnProperty('NotFound')) {
//         setProjectAssets(undefined);
//         setProjectAssetsError(
//           'There are currently no assets available for this project.'
//         );
//       } else if (res.err.hasOwnProperty('NotAuthorized')) {
//         setProjectAssets(undefined);
//         setProjectAssetsError(
//           'You are not authorized to check ProjectAssets data.'
//         );
//       } else if (res.err.hasOwnProperty('BadRequest')) {
//         setProjectAssets(undefined);
//         setProjectAssetsError(
//           'This request did not go through correctly, please try again.'
//         );
//       } else {
//         setProjectAssets(undefined);
//         setProjectAssetsError(
//           'There was an error retrieving Project Assets data.'
//         );
//       }
//       setProjectAssetsIsLoading(false);
//     });
//   },
//   [assetsActor, setProjectAssets]
// );

// const resetProjectAssets = () => dispatch({ type: 'RESET_PROJECT_ASSETS' });

// useEffect(() => {
//   if (
//     assetsActor &&
//     query.id &&
//     !state.projectAssetsFetched &&
//     pathname.includes('project')
//   ) {
//     getProjectAssets(query.id as string);
//   }
// }, [
//   assetsActor,
//   getProjectAssets,
//   pathname,
//   query.id,
//   state.projectAssetsFetched,
// ]);

// useEffect(() => {
//   resetProjectAssets();
// }, [query.id]);

// const value = {
//   ...state,
//   getProjectAssets,
//   assetsActor,
// };
// return (
//   <ProjectAssetsContext.Provider value={value}>
//     {children}
//   </ProjectAssetsContext.Provider>
// );
// };

export const IPNFTContextProvider = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const initialIPNFTContextState = {
    initialIPNFTContext,
  };
  const { pathname, query, push } = useRouter();
  const { identity } = useAuthentication();

  const ipNftAgent = useMemo(
    () =>
      new HttpAgent({
        identity,
        host: process.env.NEXT_PUBLIC_IC_HOST,
      }),
    [identity]
  );

  const ipNftActor = useMemo(
    () =>
      ip_nft_v1.createClient(
        process.env.NEXT_PUBLIC_IP_NFT_V1_CANISTER_ID as string,
        ipNftAgent
      ),
    [identity]
  );

  const [state, dispatch] = useReducer(IPNFTReducer, initialIPNFTContextState);

  const setIpNftIsLoading = (loading: boolean) => {
    dispatch({ type: 'SET_IP_NFT_IS_LOADING', payload: loading });
  };

  const setIpNftError = (errorMessage: string) =>
    dispatch({ type: 'SET_IP_NFT_ERROR', payload: errorMessage });

  const setIpNftFetched = (isFetched: boolean) => {
    dispatch({ type: 'SET_IP_NFT_FETCHED', payload: isFetched });
  };

  const setIpNftTokenId = useCallback(
    (tokenNumber: number | undefined) =>
      dispatch({ type: 'SET_IP_NFT_TOKEN_ID', payload: tokenNumber }),
    [dispatch]
  );

  const getIpNftTokenId = useCallback(
    async (id: string) => {
      setIpNftError('');
      setIpNftIsLoading(true);
      await ipNftActor?.getTokenId(id).then(async (res: any) => {
        if (res.ok) {
          setIpNftFetched(true);
          const tokenNumber = Number(res.ok);
          setIpNftTokenId(tokenNumber);
        } else if (res.err.hasOwnProperty('NotFound')) {
          setIpNftTokenId(undefined);
          // setIpNftError(
          //   'There are currently no IP-NFT tokens available for this project.'
          // );
        } else if (res.err.hasOwnProperty('NotAuthorized')) {
          setIpNftTokenId(undefined);
          setIpNftError('You are not authorized to check IpNft data');
        } else if (res.err.hasOwnProperty('BadRequest')) {
          setIpNftTokenId(undefined);
          setIpNftError(
            'This request did not go through correctly, please try again.'
          );
        } else {
          setIpNftTokenId(undefined);
          setIpNftError('There was an error retrieving IPNFT data.');
        }
        setIpNftIsLoading(false);
      });
    },
    [ipNftActor, setIpNftTokenId]
  );

  const resetIpNft = () => dispatch({ type: 'RESET_IP_NFT' });

  useEffect(() => {
    if (
      ipNftActor &&
      query.id &&
      query.id !== null &&
      !state.ipNftFetched &&
      pathname.includes('project')
    ) {
      getIpNftTokenId(query.id as string);
    }
  }, [getIpNftTokenId, ipNftActor, pathname, query.id, state.ipNftFetched]);

  useEffect(() => {
    resetIpNft();
  }, [query.id]);

  const value = {
    ...state,
    getIpNftTokenId,
    ipNftActor,
  };
  return (
    <IPNFTContext.Provider value={value}>{children}</IPNFTContext.Provider>
  );
};
