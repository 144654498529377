import { cx } from 'class-variance-authority';

export const SuccessIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      fill="none"
      className={cx('h-8', className)}>
      <path
        d="M7.00016 13.6666C3.31816 13.6666 0.333496 10.6819 0.333496 6.99992C0.333496 3.31792 3.31816 0.333252 7.00016 0.333252C10.6822 0.333252 13.6668 3.31792 13.6668 6.99992C13.6668 10.6819 10.6822 13.6666 7.00016 13.6666ZM6.2295 9.98992L10.9428 5.27592L10.0002 4.33325L6.2295 8.10459L4.3435 6.21859L3.40083 7.16125L6.2295 9.98992Z"
        fill="#2BA741"
      />
    </svg>
  );
};
