import { useState } from 'react';
import { projects_types } from '@scinet-inc/api';
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';
import {
  Typography,
  Select,
  Input,
  useToast,
  useWizardFormContext,
  WizardNavigation,
  useProjectContext,
  LoginButton,
} from 'components';
import { ActorResponse } from 'scinet-types';
import { mappedTags, patentStatus, projectStatuses } from '../../../../lib/';

import { useAppContext } from '../../../AppContext';
import { useOrganizationsTeamsContext } from '../../../Organizations';
import { useUserContext } from '../../../User';
import { ulid } from 'ulidx';

const requiresPatentLink = (status: string) => {
  switch (status) {
    case 'Provisional':
      return true;
    case 'Granted':
      return true;
    case 'Under Review':
      return true;
    default:
      return false;
  }
};

const patentStatusOptions = patentStatus?.map((status) => ({
  label: status,
  value: status,
}));
patentStatusOptions?.unshift({ label: 'Select a status', value: '' });

const projectStatusOptions = projectStatuses?.map((status) => ({
  label: status,
  value: status,
}));

const InformationStep = () => {
  const { push } = useRouter();
  const { nextStep } = useWizardFormContext();
  const { projectActor } = useAppContext();
  const { project, setProject, resetProject } = useProjectContext();
  const { organizationId } = useOrganizationsTeamsContext();
  const { user } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToast();
  const [submitError, setSubmitError] = useState('');

  const {
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors, isDirty },
  } = useForm<projects_types.ProjectInfo>({
    defaultValues: project.info.title
      ? {
          organizationId: project.info.organizationId,
          title: project.info.title,
          createdAt: project.info.createdAt,
          patentLink: project.info.patentLink,
          fields: project.info.fields,
          updatedAt: project.info.updatedAt,
          patentStatus: project.info.patentStatus,
          status: project.info.status,
        }
      : {
          patentLink: '',
          status: 'draft',
        },
  });
  const patentStatus = watch('patentStatus');

  const onSubmit = async (data: projects_types.ProjectInfo) => {
    if (!organizationId) {
      //TODO: show something to the user in this case
      return;
    }

    if (data.fields.length > 3) {
      return setError('fields', {
        type: 'manual',
        message: 'You can only select up to 3 tags',
      });
    }
    if (!isDirty) {
      if (project.info.id) {
        return nextStep();
      }
      return;
    }

    setIsLoading(true);

    if (submitError) {
      setSubmitError('');
    }

    if (project.info.id) {
      projectActor
        ?.updateProject(project.info.id, { ...data, id: project.info.id })
        .then(async (updateResponse) => {
          if ('ok' in updateResponse) {
            const res = updateResponse['ok'];
            const updatedProject = { ...project, info: res };
            showToast({
              message: 'Successfully updated project.',
              variant: 'success',
            });
            setProject(updatedProject);
            nextStep();
          } else {
            showToast({
              message: 'Failed to update project.',
              variant: 'error',
            });
            console.error('update failure', updateResponse);
            setSubmitError('There was an error updating your project');
          }
          setIsLoading(false);
        });
    } else {
      projectActor
        ?.create(organizationId, {
          ...data,
          id: ulid(),
          organizationId: organizationId,
          status: 'draft',
        })
        .then(async (createResponse: ActorResponse) => {
          if ('ok' in createResponse) {
            const res = createResponse['ok'];
            const updatedProject = { ...project, info: res };
            showToast({
              message: 'Successfully created project.',
              variant: 'success',
            });
            setProject(updatedProject);
            nextStep();
          } else {
            showToast({
              message: 'Failed to create project.',
              variant: 'error',
            });
            console.error('create failure', createResponse);
            setSubmitError('There was an error creating your project');
          }
          setIsLoading(false);
        });
    }
  };

  if (!user) {
    return (
      <div className="flex flex-col m-auto w-full text-center justify-center items-center mt-4">
        <Typography>Please log in to create or modify projects.</Typography>
        <LoginButton />
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography className="font-semibold">Basic Information</Typography>
      <Controller
        name="title"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Title is required',
          },
        }}
        render={({ field }) => (
          <Input
            labelClassName="mt-2 ml-1 text-gray-500 text-xs font-semibold"
            value={field.value || ''}
            onChange={field.onChange}
            ref={field.ref}
            error={errors.title?.message}
            label="Title"
            placeholder="New Project"
            maxLength={150}
          />
        )}
      />
      <Controller
        name="fields"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Fields are required',
          },
          validate: (value) => {
            if (value?.length > 3) {
              return 'You can only select up to 3 fields';
            }
            return true;
          },
        }}
        render={({ field }) => (
          <Select
            label="Fields"
            labelClassName="mt-2 ml-1 text-gray-500 text-xs font-semibold"
            placeholder="Select Fields"
            className="w-full border-gray-300"
            onChange={field.onChange}
            multiple
            value={field.value || []}
            error={errors.fields?.message}
            options={mappedTags}
          />
        )}
      />
      <Controller
        name="patentStatus"
        control={control}
        render={({ field }) => (
          <Select
            value={field.value || ''}
            onChange={field.onChange}
            ref={field.ref}
            error={errors.patentStatus?.message}
            label="Patent Status"
            labelClassName="mt-2 ml-1 text-gray-500 text-xs font-semibold"
            options={patentStatusOptions}
            className="w-full"
          />
        )}
      />
      {patentStatus && (
        <Controller
          name="patentLink"
          control={control}
          rules={{
            required: {
              value: requiresPatentLink(patentStatus) ? true : false,
              message: 'Patent Link is required',
            },
            pattern: {
              value: /^https?:\/\/[^\s$.?#].[^\s]*$/,
              message: 'Please enter a valid url',
            },
          }}
          render={({ field }) => (
            <Input
              value={field.value || ''}
              onChange={field.onChange}
              ref={field.ref}
              error={errors.patentLink?.message}
              label="Patent Link"
              labelClassName="mt-2 ml-1 text-gray-500 text-xs font-semibold"
              placeholder="Patent Link"
              maxLength={150}
            />
          )}
        />
      )}
      {project.info.id && (
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <Select
              value={field.value || ''}
              onChange={field.onChange}
              ref={field.ref}
              error={errors.status?.message}
              label="Project Status"
              labelClassName="mt-2 ml-1 text-gray-500 text-xs font-semibold"
              options={projectStatusOptions}
              className="w-full border-gray-300"
            />
          )}
        />
      )}
      {submitError && <p className="text-red-500 text-sm">{submitError}</p>}
      <WizardNavigation
        disablePrimaryButton={isLoading}
        stepOneCancelCallback={() => {
          resetProject();
          push('/projects');
        }}
      />
    </form>
  );
};

export default InformationStep;
