import { createContext, useContext, useState } from 'react';
import { noop } from '@scinet-inc/utils';
import { useProfileContext } from '..';

type Props = {
  children: JSX.Element;
};

type Context = {
  toggleShowForm: () => void;
};

const initialContext: Context = {
  toggleShowForm: noop,
};

export const AccreditationContext = createContext(initialContext);

export function AccreditationContextProvider({ children }: Props) {
  const { profile } = useProfileContext();
  const [showForm, setShowForm] = useState(false);

  const toggleShowForm = () => setShowForm(!showForm);

  return (
    <AccreditationContext.Provider value={{ toggleShowForm }}>
      {profile && showForm ? children : children}
    </AccreditationContext.Provider>
  );
}

export function useAccreditationContext() {
  return useContext(AccreditationContext);
}
