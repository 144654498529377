import { cx } from 'class-variance-authority';

type Props = {
  className?: string;
};

export const DocumentationIcon = ({ className }: Props) => {
  return (
    <svg
      className={cx('h-8 text-white', className)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="#ffffff"
        d="M12 17.25c-0.2071 0 -0.375 -0.1679 -0.375 -0.375s0.1679 -0.375 0.375 -0.375"
        strokeWidth="1.5"></path>
      <path
        stroke="#ffffff"
        d="M12 17.25c0.2071 0 0.375 -0.1679 0.375 -0.375S12.2071 16.5 12 16.5"
        strokeWidth="1.5"></path>
      <path
        stroke="#ffffff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="M12 13.5V5.25"
        strokeWidth="1.5"></path>
      <path
        stroke="#ffffff"
        strokeMiterlimit="10"
        d="M12 23.25c6.2132 0 11.25 -5.0368 11.25 -11.25S18.2132 0.75 12 0.75 0.75 5.7868 0.75 12 5.7868 23.25 12 23.25Z"
        strokeWidth="1.5"></path>
    </svg>
  );
};
