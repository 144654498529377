type Props = {
  className?: string;
};

export const TickDownIcon = ({ className }: Props) => {
  return (
    <svg
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M4 4L0 0H8L4 4Z" fill="#DE3532" />
    </svg>
  );
};
