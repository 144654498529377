import { Button } from '../Button';
import { useWizardFormContext } from './WizardFormContext';

type WizardNavigationProps = {
  disablePrimaryButton?: boolean;
  disableSecondaryButton?: boolean;
  nextStepLabelOverride?: string;
  previousStepCallOverride?: any;
  previousStepLabelOverride?: string;
  stepOneCancelCallback?: () => void;
};

export const WizardNavigation = ({
  disablePrimaryButton,
  disableSecondaryButton,
  nextStepLabelOverride,
  previousStepCallOverride,
  previousStepLabelOverride,
  stepOneCancelCallback,
}: WizardNavigationProps) => {
  const { activeStep, previousStep } = useWizardFormContext();
  return (
    <div className="flex gap-5 bg-white fixed bottom-0 left-0 justify-between p-3 w-full z-10 md:static md:pt-5 md:px-0 md:justify-end">
      <Button
        id="wizard_previous"
        color="secondary"
        className="w-3/5 md:w-48"
        onClick={() => {
          if (previousStepCallOverride) {
            return previousStepCallOverride();
          }

          previousStep();
          if (stepOneCancelCallback) {
            stepOneCancelCallback();
          }
        }}
        disabled={disableSecondaryButton}>
        {previousStepLabelOverride
          ? previousStepLabelOverride
          : activeStep === 0
          ? 'Cancel'
          : 'Back'}
      </Button>
      <Button
        type="submit"
        color="primary"
        id="wizard_next"
        className="w-3/5 md:w-48"
        disabled={disablePrimaryButton}>
        {nextStepLabelOverride
          ? nextStepLabelOverride
          : activeStep === 4
          ? 'Preview'
          : 'Next'}
      </Button>
    </div>
  );
};
