// src/index.ts
function createTheme({
  primary,
  primaryLight,
  secondary,
  secondaryLight,
  tertiary,
  tertiaryLight
}) {
  return {
    "--theme-primary": primary,
    "--theme-primary-light": primaryLight,
    "--theme-secondary": secondary,
    "--theme-secondary-light": secondaryLight,
    "--theme-tertiary": tertiary,
    "--theme-tertiary-light": tertiaryLight
  };
}
var applyTheme = (theme) => {
  const root = document.documentElement;
  Object.keys(theme).forEach((cssVar) => {
    root.style.setProperty(cssVar, theme[cssVar]);
  });
};
var baseTheme = createTheme({
  primary: "#610099",
  primaryLight: "#b433ff",
  secondary: "white",
  secondaryLight: "#b433ff",
  tertiary: "transparent",
  tertiaryLight: "#f3f4f6"
});
var darkTheme = createTheme({
  primary: "blue",
  primaryLight: "lightblue",
  secondary: "yellow",
  secondaryLight: "lightyellow",
  tertiary: "blue",
  tertiaryLight: "lightblue"
});
export {
  applyTheme,
  baseTheme,
  darkTheme
};
