import cx from 'classnames';
import { Button } from '../Button';
import { Typography } from '../Typography';

type Props = {
  className?: string;
  variant: 'Royalty' | 'License' | 'IP-NFT' | 'Art-Based';
  tokenNumber?: number;
  metadataHref?: string;
};

export const TokenCard = ({
  className,
  variant,
  tokenNumber,
  metadataHref,
}: Props) => {
  return (
    <div
      className={cx(
        'flex flex-col h-[344px] w-[344px] justify-between border-solid rounded-lg p-4 m-auto md:m-0 my-2 bg-cover md:mr-4',
        {
          className: className,
          'bg-[url("/license-token.png")]': variant === 'License',
          'bg-[url("/royalty-token.png")]': variant === 'Royalty',
          'bg-[url("/ip-token.png")]': variant === 'IP-NFT',
        },
      )}>
      <div className="flex flex-row">
        {variant === 'Royalty' && (
          <>
            <div className="bg-[#BBFF00] rounded-full px-2 py-1 mr-2 text-xs font-semibold ">
              Round 1
            </div>
            <div className="bg-[#BBFF00] rounded-full px-2 py-1 mr-2 text-xs font-semibold">
              Ends in 3 days!
            </div>
          </>
        )}
      </div>
      <div className="flex flex-row justify-between h-16 p-3 bg-[#FFFFFF] bg-opacity-40 rounded-md">
        <div className="flex flex-col">
          {variant === 'IP-NFT' ? (
            <a
              className="hover:pointer-cursor text-sm font-semibold"
              target="_blank"
              rel="noopener noreferrer"
              href={metadataHref}>
              IP-NFT Token
            </a>
          ) : (
            <Typography className="text-sm font-semibold">
              {variant} Token
            </Typography>
          )}

          <div className="flex flex-row items-baseline">
            <Typography className="text-sm font-semibold">1.78 ICP</Typography>
            <Typography className="text-xs pl-2">
              {tokenNumber ? tokenNumber : 50} / 10,000
            </Typography>
          </div>
        </div>
        {variant === 'Royalty' && <Button className="w-20">Buy Now</Button>}
      </div>
    </div>
  );
};
