// import { projects_assets_types } from '@scinet-inc/api';

import { Project } from 'scinet-types';
import { TokenCard } from './TokenCard';

type Props = {
  // projectAssets?: projects_assets_types.AssetsMetaData;
  ipNftTokenId: number;
  HOST: string;
  project: Project;
};

const IP_NFT_METADATA_V1_CANISTER_ID =
  process.env.NEXT_PUBLIC_IP_NFT_METADATA_V1_CANISTER_ID;

export const Assets = ({
  // projectAssets,
  ipNftTokenId,
  HOST,
  project,
}: Props) => {
  const urlBase = HOST?.includes('localhost')
    ? 'http://localhost:3002'
    : 'https://2zqmv-riaaa-aaaap-aa4yq-cai.icp0.io';

  const metadataHref = `${urlBase}/ip-nfts/${IP_NFT_METADATA_V1_CANISTER_ID}/tokens/${ipNftTokenId}`;

  return (
    <div className="pt-0 pl-0 w-full">
      <div className="flex flex-col md:flex-row justify-center md:justify-start">
        {/* {projectAssets?.collections.map((collection, index) => {
          if (collection.collection_type === 'royalty_nft') {
            return <TokenCard variant="Royalty" key={index} />;
          } else if (collection.collection_type === 'license_nft') {
            return <TokenCard variant="License" key={index} />;
          }
        })} */}
        {ipNftTokenId && (
          <TokenCard
            variant="IP-NFT"
            tokenNumber={ipNftTokenId}
            metadataHref={metadataHref}
          />
        )}
      </div>
    </div>
  );
};
