import { ComponentClass, FC, ReactNode, useState } from 'react';
import { useIsMobile } from '@scinet-inc/hooks';
import cx from 'classnames';
import Header from './Header';
import Drawer from './Drawer';

type Props = {
  appBarChildren?: ReactNode;
  children: ReactNode;
  className?: string;
  drawerHeaderNode?: ReactNode;
  footerNode: {
    onClick: () => void;
    text: string;
    href: string;
    Icon: ComponentClass<any>;
  }[];
  logoLight?: ReactNode;
  logoDark?: ReactNode;
  id: string;
  mainClassName?: string;
  navItems: any;
  profileSection?: ReactNode;
  router: any;
  Link: any;
  secondColumnNode?: ReactNode;
  actionsNode?: ReactNode;
};

export const Layout: FC<Props> = ({
  id,
  navItems,
  children,
  appBarChildren,
  footerNode,
  profileSection,
  mainClassName,
  className,
  logoLight,
  logoDark,
  router,
  Link,
  secondColumnNode,
  actionsNode,
}) => {
  const { isMobile } = useIsMobile();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className={className}>
      <Drawer
        id={`${id}-drawer`}
        navItems={navItems}
        mobile={isMobile}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        footerNode={footerNode}
        logo={logoLight}
        router={router}
        Link={Link}
      />
      <main>
        <Header
          id={`${id}-header`}
          setMenuOpen={setMenuOpen}
          menuOpen={menuOpen}
          mobile={isMobile}
          appBarChildren={appBarChildren}
          profileSection={profileSection}
          logo={logoDark}
          actionsNode={actionsNode}
        />
        <div
          className={cx(mainClassName, {
            'pointer-events-none opacity-50': menuOpen && isMobile,
          })}>
          <div className="flex flex-row">
            <div className="flex flex-col pt-17 w-full lg:pt-24 items-center">
              {children}
            </div>
            <div className="flex flex-col">{secondColumnNode}</div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default Layout;
