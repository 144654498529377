import React, { useEffect } from 'react';
import {
  useWizardFormContext,
  WizardFormContextProvider,
} from './WizardFormContext';
import { WizardFormStepper } from './WizardFormStepper';

const WizardFormContainer = ({
  children,
  id,
  steps,
  disableSteps,
  stepOnClickOverride,
}: {
  children: React.ReactNode;
  id: string;
  steps?: string[];
  disableSteps?: boolean;
  stepOnClickOverride?: any;
}) => {
  const { activeStep, setActiveStep, setSteps } = useWizardFormContext();
  const allSteps = React.Children.toArray(children);

  const currentStep = allSteps[activeStep];

  useEffect(() => {
    if (!steps) {
      return;
    }
    setSteps(steps);
  }, [setSteps, steps]);

  return (
    <div id={`${id}-wizard`} className="bg-white md:rounded-md">
      {steps && (
        <WizardFormStepper
          stepOnClick={setActiveStep}
          disableSteps={disableSteps}
          stepOnClickOverride={stepOnClickOverride}
        />
      )}
      <div>{currentStep}</div>
    </div>
  );
};

export const WizardForm = ({
  id,
  steps,
  children,
  disableSteps,
  onCompleted,
  stepOnClickOverride,
}: {
  id: string;
  steps?: string[];
  children?: JSX.Element[];
  disableSteps?: boolean;
  onCompleted?: () => void;
  stepOnClickOverride?: any;
}) => {
  return (
    <WizardFormContextProvider onCompleted={onCompleted}>
      <WizardFormContainer
        steps={steps}
        id={id}
        disableSteps={disableSteps}
        stepOnClickOverride={stepOnClickOverride}>
        {children}
      </WizardFormContainer>
    </WizardFormContextProvider>
  );
};

export default WizardForm;
