import { Fragment, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { PlusIcon } from '@heroicons/react/24/solid';
import { Dialog, Transition } from '@headlessui/react';

import {
  Button,
  Input,
  Typography,
  useWizardFormContext,
  WizardNavigation,
  useToast,
  useAuthentication,
  useProjectContext,
} from 'components';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { HttpAgent } from '@dfinity/agent';
import {
  ip_nft_metadata_v1,
  ip_nft_metadata_v1_types,
  ip_nft_v1,
} from '@scinet-inc/api';
import {
  getSciFiBaseUrl,
  IP_NFT_METADATA_V1_CANISTER_ID,
  IP_NFT_V1_CANISTER_ID,
  isProd,
  isStaging,
} from '../../../../lib';
import { XMarkIcon } from '@heroicons/react/24/outline';

const ipDataExamples = [
  'Published papers and articles (including preprints)',
  'Patents and patent applications (if not included in your project already',
  'Conference presentations/proceedings',
  'Public datasets',
  'Open source tools created for your research (e.g., a Github repository)',
  'Press releases and media coverage',
  'Endorsements and testimonials',
  'Regulatory approvals or submissions (e.g., FDA)',
  'Collaboration agreements with other institutions',
  'Grants, awards, recognition, etc.',
  'Education material, presentations, webinars, demonstrations',
  'Clinical Trial Data and Registrations (e.g., at clinicaltrials.gov) if public',
  'Bioinformatics resources (e.g., Genbank, Protein Data Bank, etc.) if public',
  'Ethical approvals and statements (from review boards or ethics committees)',
  'Impact evidence (e.g., how the research could address unmet needs or contribute to the greater good)',
  'Patient or practitioner testimonials',
  'Technology Transfer and Existing Licensing Info (any relevant policies, agreements, contracts with parties who may have claim on the IP)',
  'External lab/project website',
  'Professional profiles (ORCID, Google Scholar, University Profiles)',
];

const IPNFTDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => (
  <Transition.Root show={isOpen} as={Fragment}>
    <Dialog onClose={onClose} className="relative z-50">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div className="fixed backdrop-blur-sm inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          className="flex items-center justify-center p-4 text-center"
          style={{ height: 'auto' }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <Dialog.Panel className="mx-auto max-w-lg rounded bg-white ">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={onClose}>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <Dialog.Title className="text-xl text-left font-medium m-4">
                Resources
              </Dialog.Title>
              <div className="text-lg font-light m-4 text-left">
                <div className="flex text-rhino-900 font-bold text-sm">
                  What to include in your resources
                </div>
                <Dialog.Description className="text-lg font-light m-4 text-left">
                  These are public resources, or resources you are willing to
                  make public, that can help a potential licensee better
                  understand your IP. Please include URLs in the format of
                  “https://www.”. For now, any files that you want to include
                  here should be shared via Google Drive or other publicly
                  shared link.
                </Dialog.Description>
                <ul className="list-disc ml-4 mt-4">
                  {ipDataExamples.map((example, index) => (
                    <li key={index} className="text-sm font-rhino-900">
                      {example}
                    </li>
                  ))}
                </ul>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);

const determineError = (key: string, errors: any, index: number) => {
  if (
    !Object.keys(errors).length ||
    !errors.resources[index] ||
    !errors.resources[index][key]
  ) {
    return { message: '', error: false };
  }

  const { message, type } = errors.resources[index][key];
  let errorMessage = message;
  return { message: errorMessage, error: !!errorMessage };
};

const resource = {
  value: '',
  resource_label: '',
  description: '',
};

const NFTDetails = () => {
  const { push, query } = useRouter();
  const { project, isLoading } = useProjectContext();
  const { nextStep, formValues } = useWizardFormContext();
  const [ipNFTExists, setIpNftExists] = useState(false);
  const [ipNFTExistsCalled, setIpNftExistsCalled] = useState(false);
  const [existingMetadata, setExistingMetadata] =
    useState<ip_nft_metadata_v1_types.IPMetaCreateRequest>();
  const [metadataExistsCalled, setMetadataExistsCalled] = useState(false);
  const [error, setError] = useState('');
  const { showToast } = useToast();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      resources: existingMetadata?.resources || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'resources',
    // rules: {
    //   required: 'You must have at least one resource',
    // },
  });

  const [resourceOpen, setResourceOpen] = useState<number | undefined>(0);

  const { identity, isAuthenticated } = useAuthentication();

  const agent = useMemo(
    () =>
      new HttpAgent({
        identity,
        host: process.env.NEXT_PUBLIC_IC_HOST,
      }),
    [identity]
  );

  const createIPNFT = async () => {
    const ipNftActor = ip_nft_v1.createClient(
      IP_NFT_V1_CANISTER_ID as string,
      agent
    );
    // TODO: swap this with the metadata uri, which is the UI url of the ui canister with the /canister/:canId/:projectId
    const uri = window.location.href.replace('/nft', '');

    return await ipNftActor
      .mint(project.info.id, project.info.organizationId, uri)
      .then(async (createRes: any) => {
        if (createRes.ok) {
          showToast({
            message: 'Create IP NFT succeeded',
            variant: 'success',
          });
          return { error: null, data: createRes.ok };
        }
        return { error: createRes.err, data: null };
      });
  };

  const createIpNftAndMetadata = async (data: any) => {
    const ipNftMetadataActor = ip_nft_metadata_v1.createClient(
      IP_NFT_METADATA_V1_CANISTER_ID as string,
      agent
    );
    const { resources } = data;
    const mappedResources = resources.map(
      ({
        description,
        resource_label,
        value,
      }: ip_nft_metadata_v1_types.Resource) => ({
        description,
        resource_label,
        value,
      })
    );

    const researchLead =
      project.details?.team && project.details?.team.length > 0
        ? project.details?.team[0]
        : '';

    // const external_url = window.location.href.replace('/nft', '');
    const baseUrl = getSciFiBaseUrl();

    const fullUrl = `${baseUrl}/projects/${query.id}`;

    const ipMetaData: ip_nft_metadata_v1_types.IPMetaCreateRequest = {
      name: project.info.title,
      description: project.details?.summary || '',
      // TODO: replace this with an actual image url
      image: '',
      external_url: fullUrl,
      properties: {
        organization_id: project.info.organizationId,
        project_id: project.info.id,
        research_lead: researchLead,
        tags: project.info.fields,
      },
      project_id: project.info.id,
      resources: mappedResources,
    };

    // TODO: make sure we don't create the metadata twice ie !!existingMetadata
    // TODO: replace the empty string with the link to the IP NFT Metadata
    return await ipNftMetadataActor
      .create(project.info.organizationId, ipMetaData)
      .then(async (createRes: any) => {
        if (createRes.ok) {
          showToast({
            message: 'Create IP NFT metadata succeeded',
            variant: 'success',
          });

          const { error: createIpNftError, data: ipNftData } =
            await createIPNFT();

          if (createIpNftError) {
            return { error: createIpNftError, data: null };
          }

          return { error: null, data: createRes.ok };
        }
        showToast({
          message: 'Create IP NFT failed',
          variant: 'error',
        });
        return { error: createRes.err, data: null };
      });
  };

  const updateMetadata = async (data: any) => {
    const actor = ip_nft_metadata_v1.createClient(
      IP_NFT_METADATA_V1_CANISTER_ID as string,
      agent
    );
    const { resources } = data;
    const mappedResources = resources.map(
      ({
        description,
        resource_label,
        value,
      }: ip_nft_metadata_v1_types.Resource) => ({
        description,
        resource_label,
        value,
      })
    );

    // TODO: replace the empty string with the link to the IP NFT Metadata
    return await actor
      .updateResources(
        project.info.id,
        project.info.organizationId,
        mappedResources
      )
      .then(async (updateRes: any) => {
        if (updateRes.ok) {
          showToast({
            message: 'Update IP NFT resources succeeded',
            variant: 'success',
          });
          return { error: null, data: updateRes.ok };
        }

        showToast({
          message: 'Update IP NFT resources failed',
          variant: 'error',
        });
        return { error: updateRes.err, data: null };
      });
  };

  const checkIfIPNFTExists = async () => {
    const actor = ip_nft_v1.createClient(
      IP_NFT_V1_CANISTER_ID as string,
      agent
    );

    await actor.getTokenId(project.info.id).then(async (res: any) => {
      if (res.ok) {
        setIpNftExistsCalled(true);
        return setIpNftExists(res.ok);
      }

      setError('There was an issue getting IPNFT data.');
    });
  };

  const checkMetadataExists = async () => {
    const actor = ip_nft_metadata_v1.createClient(
      IP_NFT_METADATA_V1_CANISTER_ID as string,
      agent
    );

    await actor.get(project.info.id).then(async (res: any) => {
      if (res.ok) {
        setValue('resources', res.ok.resources);
        setMetadataExistsCalled(true);
        return setExistingMetadata(res.ok);
      }

      setError('There was an issue getting IPNFT data.');
    });
  };

  useEffect(() => {
    if (isAuthenticated && !ipNFTExistsCalled && project.info.id) {
      checkIfIPNFTExists();
    }
  }, [isAuthenticated, ipNFTExistsCalled, project]);

  useEffect(() => {
    if (isAuthenticated && !metadataExistsCalled && project.info.id) {
      checkMetadataExists();
    }
  }, [isAuthenticated, metadataExistsCalled, project]);

  const resources = formValues.resources || fields;

  const onSubmit = async (data: any) => {
    setIsFormLoading(true);

    if (!ipNFTExists) {
      const { error, data: resData } = await createIpNftAndMetadata(data);
      if (error) {
        return;
      }
      nextStep();
    } else {
      if (isDirty) {
        const { error, data: resData } = await updateMetadata(data);
        setIsFormLoading(false);
        if (error) {
          return;
        }
      }

      return nextStep();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mx-auto max-w-3xl">
          <div className="p-4">
            <div className="w-full flex items-center justify-between py-2 mt-2">
              <span className="text-deep-sapphire-900 font-sans text-lg font-bold">
                IP-NFT Details
              </span>
              <Button color="tertiary" onClick={() => setIsModalOpen(true)}>
                What to include?
              </Button>
            </div>
            <div className="w-full py-2">
              <div className="text-rhino font-sans text-xs">Name</div>
              <span className="text-deep-sapphire-900 font-sans text-sm">
                {project.info.title}
              </span>
            </div>
            <div className="w-full py-2">
              <div className="text-rhino font-sans text-xs">Description</div>
              <span className="text-deep-sapphire-900 font-sans text-sm">
                {project.details.summary}
              </span>
            </div>
            <div className="w-full py-2">
              <span className="text-deep-sapphire-900 font-sans text-lg font-bold">
                Resources
              </span>
              <ul>
                {resources.map((resourceField: Partial<any>, index: number) => (
                  <li key={resourceField.id}>
                    <div className="shadow-lg p-4 md:px-8 border border-gray-200 rounded-md mb-4">
                      <div
                        className="flex flex-row justify-between cursor-pointer items-center"
                        onClick={() => {
                          resourceOpen === index
                            ? setResourceOpen(undefined)
                            : setResourceOpen(index);
                        }}>
                        <div>
                          <Typography className="flex items-center text-sm font-semibold text-purple-600">
                            {`${index + 1}. `}
                            {resourceField.resource_label || 'New Resource'}
                          </Typography>
                        </div>
                        <div className="flex flex-row">
                          <Button
                            color="tertiary"
                            className="text-red-500 hidden md:block"
                            onClick={() => remove(index)}>
                            Delete
                          </Button>

                          {resourceOpen === index ? (
                            <ChevronUpIcon
                              color="gray"
                              className="h-4 w-4 mt-1"
                            />
                          ) : (
                            <ChevronDownIcon
                              color="gray"
                              className="h-4 w-4 mt-1"
                            />
                          )}
                        </div>
                      </div>

                      {resourceOpen === index && (
                        <div className="py-4">
                          <Controller
                            name={`resources.${index}.resource_label`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: 'Label is required',
                              },
                            }}
                            render={({ field, formState: { errors } }) => (
                              <Input
                                value={field.value || ''}
                                onChange={field.onChange}
                                ref={field.ref}
                                label="Label"
                                labelClassName="mt-2 ml-1 text-gray-500 text-xs font-semibold"
                                placeholder="Resource label"
                                error={
                                  determineError('label', errors, index).message
                                }
                                maxLength={150}
                              />
                            )}
                          />
                          <Controller
                            name={`resources.${index}.value`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: 'Value is required',
                              },
                            }}
                            render={({ field, formState: { errors } }) => (
                              <Input
                                value={field.value || ''}
                                onChange={field.onChange}
                                ref={field.ref}
                                error={
                                  determineError('value', errors, index).message
                                }
                                label="Value"
                                labelClassName="mt-2 ml-1 text-gray-500 text-xs font-semibold"
                                placeholder="Value for the resource"
                                maxLength={500}
                              />
                            )}
                          />
                          <div className="flex flex-row w-full justify-center md:hidden">
                            <Button
                              color="tertiary"
                              className="text-red-500"
                              onClick={() => remove(index)}>
                              Delete
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full flex items-center justify-center py-2">
              <Button
                color="tertiary"
                onClick={() => append(resource)}
                icon={<PlusIcon className="h-4 w-4" aria-hidden="true" />}
                iconPosition="left">
                Resource
              </Button>
            </div>
            {errors?.resources && (
              <div className="w-full flex items-center justify-center py-2">
                <Typography intent="error" className=" font-sans text-sm">
                  {errors.resources?.root?.message}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className="w-full flex items-center justify-center py-2">
          <WizardNavigation
            disablePrimaryButton={
              isLoading || isFormLoading || !isAuthenticated
            }
            stepOneCancelCallback={() => push('/projects')}
          />
        </div>
      </form>
      <IPNFTDialog isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default NFTDetails;
