import { cx } from 'class-variance-authority';

export const WarningIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 12"
      fill="none"
      className={cx('h-8', className)}>
      <path
        d="M0.25 11.6667L7 0L13.75 11.6667H0.25ZM7 9.86667C7.15556 9.86667 7.28333 9.81667 7.38333 9.71667C7.48333 9.61667 7.53333 9.48889 7.53333 9.33333C7.53333 9.17778 7.48333 9.05 7.38333 8.95C7.28333 8.85 7.15556 8.8 7 8.8C6.84444 8.8 6.71667 8.85 6.61667 8.95C6.51667 9.05 6.46667 9.17778 6.46667 9.33333C6.46667 9.48889 6.51667 9.61667 6.61667 9.71667C6.71667 9.81667 6.84444 9.86667 7 9.86667ZM6.5 8.13333H7.5V4.8H6.5V8.13333Z"
        fill="#FE9526"
      />
    </svg>
  );
};
