import { useCallback, useEffect, useState } from 'react';
import { useAuthentication, Button } from 'components';

export const LoginButton = () => {
  const { isAuthing, login, nfid } = useAuthentication();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isAuthing);
  }, [setIsOpen, isAuthing]);

  const callLogin = useCallback(() => {
    if (isAuthing && !isOpen) {
      setIsOpen(true);
    }
    login();
  }, [isAuthing, isOpen, setIsOpen, login]);

  const loginClick = () => {
    callLogin();
  };

  return (
    <Button onClick={loginClick} disabled={!nfid}>
      Log In
    </Button>
  );
};
