import { useMemo, useState } from 'react';
import { ActorSubclass, Identity } from '@dfinity/agent';
import {
  organizations_teams_types,
  projects_types,
  projects_milestones_types,
  projects_service_types,
  users_types,
} from '@scinet-inc/api';
import { Organization, OrganizationRoles } from 'scinet-types';
import { TeamSection } from '../..';
import { AboutOrg, OrgAddress, OrgTitle, OrgSite, OrgTabs } from '.';

import OrgContactSection from './OrgContactSection';
import { OrgProjects } from './OrgProjects';

export enum ORG_TABS {
  OVERVIEW = 'Overview',
  PROJECTS = 'Projects',
  MEMBERS = 'Members',
}

type Props = {
  org: Organization;
  orgTeamActor: ActorSubclass<organizations_teams_types._SERVICE> | undefined;
  push: (url: any, options?: any) => Promise<boolean>;
  user: users_types.User | undefined;
  userActor: ActorSubclass<users_types._SERVICE> | undefined;
  identity: Identity | undefined;
  projectsMilestonesActor:
    | ActorSubclass<projects_milestones_types._SERVICE>
    | undefined;
  isAuthorized: (
    userId: string,
    organizationId: string,
    desiredRole: OrganizationRoles,
    orgTeamActor: ActorSubclass<organizations_teams_types._SERVICE>,
  ) => Promise<boolean>;
  getProjectDetails: (
    projectId: string,
    identity?: Identity,
  ) => Promise<{
    project: projects_service_types.ProjectDetails;
    error?: string;
  }>;
  resetFetchAndPush: (projectId: string) => void;
  projectsBaseActor: ActorSubclass<projects_types._SERVICE> | undefined;
  pushFooter?: boolean;
  getIpNft: (projectId: string) => Promise<number | null>;
  exposeEmail?: boolean;
  filesServiceUrl: string;
  countryCode?: string | null;
};

export function OrgContent({
  org,
  orgTeamActor,
  push,
  user,
  userActor,
  projectsBaseActor,
  identity,
  getProjectDetails,
  projectsMilestonesActor,
  isAuthorized,
  resetFetchAndPush,
  getIpNft,
  filesServiceUrl,
  pushFooter = false,
  exposeEmail = false,
  countryCode,
}: Props) {
  // const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [tabSelected, setTabSelected] = useState<ORG_TABS>(ORG_TABS.OVERVIEW);

  // const deleteOrganization = async () => {
  //   setIsDeleteLoading(true);
  //   await orgActor?.delete(org.info.id).then(async (deleteResponse: any) => {
  //     if ('ok' in deleteResponse) {
  //       showToast({
  //         message: 'Successfully deleted organization.',
  //         variant: 'success',
  //       });
  //       router.push('/organizations');
  //     } else {
  //       console.error('** Delete failed');
  //     }
  //   });
  //   setIsDeleteLoading(false);
  // };

  const memberIds = useMemo(() => {
    if (!org?.team.members) {
      return [];
    }

    return org.team.members.map((m) => m.id);
  }, [org.team.members]);

  return (
    <div className="flex flex-col w-full">
      <div className="items-center flex flex-col p-4 max-w-[1200px] lg:px-0">
        <OrgTitle
          org={org}
          deleteOrganization={() => console.log('TO DO ?? : DELETE ORG')}
          user={user}
          routerPush={push}
          orgTeamActor={orgTeamActor}
        />
        <div className="w-full flex flex-col lg:flex-row-reverse pt-2">
          {tabSelected === ORG_TABS.OVERVIEW && (
            <>
              <div className="hidden lg:flex flex-col">
                <OrgContactSection
                  userActor={userActor}
                  teamMemberIds={memberIds}
                  filesServiceUrl={filesServiceUrl}
                  exposeEmail={exposeEmail}
                />
                <OrgAddress org={org} countryCode={countryCode} />
              </div>
              <div className="lg:hidden">
                <OrgSite org={org} countryCode={countryCode} />
              </div>
              <AboutOrg org={org.info} />
            </>
          )}
          {tabSelected === ORG_TABS.PROJECTS && (
            <OrgProjects
              projectsBaseActor={projectsBaseActor}
              identity={identity}
              getProjectDetails={getProjectDetails}
              projectsMilestonesActor={projectsMilestonesActor}
              isAuthorized={isAuthorized}
              resetFetchAndPush={resetFetchAndPush}
              push={push}
              orgTeamActor={orgTeamActor}
              orgId={org.info.id}
              user={user}
              title=""
              getIpNft={getIpNft}
              filesServiceUrl={filesServiceUrl}
            />
          )}
          {tabSelected === ORG_TABS.MEMBERS && (
            <div className="flex-col">
              <TeamSection
                team={memberIds}
                className="w-full lg:w-[320px] mt-4 lg:mt-0"
                userActor={userActor}
                filesServiceUrl={filesServiceUrl}
                exposeEmail={exposeEmail}
              />
            </div>
          )}
        </div>
      </div>
      <div className="hidden lg:block">
        <OrgProjects
          getIpNft={getIpNft}
          projectsBaseActor={projectsBaseActor}
          identity={identity}
          getProjectDetails={getProjectDetails}
          projectsMilestonesActor={projectsMilestonesActor}
          isAuthorized={isAuthorized}
          resetFetchAndPush={resetFetchAndPush}
          push={push}
          orgTeamActor={orgTeamActor}
          orgId={org.info.id}
          user={user}
          title="Projects"
          filesServiceUrl={filesServiceUrl}
        />
      </div>
      <div
        className={`lg:hidden fixed left-0 bottom-0 w-full bg-white border-gray-500 border-t-[1px] py-2 ${
          pushFooter && 'mb-16'
        }`}>
        <OrgTabs tab={tabSelected} setTabSelected={setTabSelected} />
      </div>
    </div>
  );
}
