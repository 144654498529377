import { Typography } from 'components';
import { Organization } from 'scinet-types';
import ReactCountryFlag from 'react-country-flag';

type Props = {
  org: Organization;
  countryCode?: string | null;
};

export function OrgAddress({ org, countryCode }: Props) {
  const detailsExist = Object.keys(org.details).length > 0;

  return (
    <div className="w-full lg:w-[330px] h-auto flex flex-col border-2 border-gray-300 bg-white rounded-md p-4 mr-4 mb-2">
      {detailsExist && (
        <div className="flex flex-row align-baseline mb-1">
          <ReactCountryFlag
            countryCode={countryCode!}
            svg
            style={{
              width: '2em',
              height: '1.5em',
            }}
            className="rounded-full "
          />
          <Typography className="text-sm ml-2 font-bold mt-1">
            {org.details.country}
          </Typography>
        </div>
      )}
      {detailsExist && (
        <div className="flex flex-row">
          <Typography className="text-sm mr-1">
            {org?.details.address}
          </Typography>
          <Typography className="text-sm mr-1">{org?.details.city},</Typography>
          <Typography className="text-sm">
            {org?.details.stateOrProvince}
          </Typography>
        </div>
      )}
      <div
        //TODO: HANDLE WEBSITE ADDRESS WITH HTTPS ALREADY IN IT
        onClick={() => window.open(org?.info.websiteUrl, '_blank')}>
        <Typography className="text-sm cursor-pointer font-medium ">
          {org.info.websiteUrl}
        </Typography>
      </div>
    </div>
  );
}
