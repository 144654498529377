import {
  organizations_types,
  organizations_teams_types,
  users_types,
} from '@scinet-inc/api';
import { Avatar, Typography } from '../../';
import { OrgActions } from './OrgActions';
import { ActorSubclass } from '@dfinity/agent';

export function OrgCardHeader({
  deleteOrg,
  org,
  routerPush,
  user,
  hideActions,
  orgTeamActor,
}: {
  deleteOrg: (
    id: string,
  ) => any | undefined | Promise<organizations_types.Result_3>;
  org: organizations_types.OrganizationInfo;
  routerPush: (url: any, options?: any) => Promise<boolean>;
  user?: users_types.User | any;
  hideActions?: boolean;
  orgTeamActor?: ActorSubclass<organizations_teams_types._SERVICE> | undefined;
}) {
  return (
    <div className="flex flex-row w-full justify-between">
      <div className="flex flex-row items-center mb-4">
        <Avatar className="mr-2" />
        <div className="flex flex-col">
          <Typography className="font-semibold">{org?.name}</Typography>
          {/* <Typography className="text-xs"># members</Typography> */}
        </div>
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <OrgActions
          deleteOrg={deleteOrg}
          org={org}
          routerPush={routerPush}
          user={user}
          hideActions={hideActions}
          orgTeamActor={orgTeamActor}
        />
      </div>
    </div>
  );
}
