import { ActorSubclass, Identity } from '@dfinity/agent';
import {
  organizations_index,
  organizations_index_types,
  organizations_service,
  organizations_service_types,
  organizations_teams,
  organizations_teams_types,
  organizations_types,
} from '@scinet-inc/api';
import { ActorClient, IndexClient } from 'components';
import { Organization } from 'scinet-types';
import {
  HOST,
  ORGANIZATIONS_INDEX_CANISTER_ID,
  ORGANIZATIONS_TEAMS_CANISTER_ID,
} from '../constants';
import { ActorResponse, OrganizationRoles } from '../types';
import { getAgent } from './api';

export const initializeOrganizationsIndexClient = (
  canisterId: string,
  identity: Identity | undefined
) => {
  return new IndexClient<organizations_index_types.OrganizationsIndex>({
    IDL: organizations_index.idlFactory,
    canisterId,
    agentOptions: {
      host: HOST,
      identity,
    },
  });
};

export const initializeOrganizationsServiceClient = (
  indexClient: IndexClient<organizations_index_types.OrganizationsIndex>,
  identity: Identity | undefined
) => {
  return new ActorClient<
    organizations_index_types.OrganizationsIndex,
    organizations_service_types.OrganizationsService
  >({
    actorOptions: {
      IDL: organizations_service.idlFactory,
      agentOptions: {
        host: HOST,
        identity,
      },
    },
    indexClient,
  });
};

export const initializeOrganizationsActorClient = (
  canisterId: string,
  identity?: Identity | undefined
) => {
  const organizationsIndexClient = initializeOrganizationsIndexClient(
    canisterId,
    identity
  );
  const serviceClient = initializeOrganizationsServiceClient(
    organizationsIndexClient,
    identity
  );

  return serviceClient;
};

export const reduceOrganizationsResults = async (
  organizationsResults: PromiseSettledResult<any>[]
): Promise<any> => {
  return new Promise<any>(async (resolve) => {
    let result: any = {};
    for (let settledResult of organizationsResults) {
      if (settledResult.status === 'fulfilled') {
        for (const [k, v] of Object.entries(settledResult.value)) {
          let value = await v;
          result[k] = value;
        }
      }
    }
    resolve(result);
  });
};

export const getOrganizationsActor = async (
  organizationsIndexCanisterId: string,
  id: string,
  identity?: Identity
) => {
  const actorClient = initializeOrganizationsActorClient(
    organizationsIndexCanisterId,
    identity
  );
  // check if user exists, if not then create, if so then retrieve
  // this call will return a response from each organizations service canister API
  const responses = await actorClient.queryWithCanisterIdMapping<
    organizations_service_types.OrganizationsService['skExists']
  >('organizations', (actor) => actor.skExists(id));

  if (!responses.length) {
    return undefined;
  }
  const organizationsResults = await reduceOrganizationsResults(responses);

  const agent = getAgent(identity);

  const canisterPair = Object.entries(organizationsResults).find(
    (pair) => pair[1] === true
  );

  if (!canisterPair) {
    return undefined;
  }

  return organizations_service.createClient(canisterPair[0], agent);
};

export const getOrganization = async (
  organizationsIndexCanisterId: string,
  id: string,
  identity?: Identity
) => {
  const orgActor = await getOrganizationsActor(
    organizationsIndexCanisterId,
    id,
    identity
  );
  let organization: organizations_service_types.OrganizationDetails | undefined;
  let error: string | undefined;

  if (!orgActor) {
    return { organization, error: 'NotFound' };
  }

  const organizationsRes = await orgActor.read(id).then((result: any) => {
    if ('ok' in result) {
      organization = result.ok;
    } else {
      if ('NotAuthorized' in result.err) {
        // require a new login
        error = 'NotAuthorized';
      } else if ('NotFound' in result.err) {
        error = 'NotFound';
      }
    }
    return { organization, error };
  });
  return organizationsRes;
};

export const getUserOrg = async (
  identity?: any
): Promise<{ error: boolean; organizationId?: string }> => {
  const agent = getAgent(identity);

  const actor = organizations_teams.createClient(
    ORGANIZATIONS_TEAMS_CANISTER_ID as string,
    agent
  );

  try {
    return await actor
      ?.getUserOrg(identity.getPrincipal().toText())
      .then(async (res: ActorResponse) => {
        if (res.ok) {
          return { organizationId: res.ok, error: false };
        } else {
          return { organizationId: '', error: false };
        }
      });
  } catch (e) {
    return { organizationId: '', error: true };
  }
};

export const getRole = async (
  userId: string,
  organizationId: string,
  orgTeamActor: ActorSubclass<organizations_teams_types._SERVICE>
) => {
  return await orgTeamActor
    ?.getUserRole(organizationId, userId)
    .then(async (getTeamResponse: ActorResponse) => {
      if ('ok' in getTeamResponse) {
        return getTeamResponse['ok'];
      } else {
        return '';
      }
    });
};

export const isAuthorized = async (
  userId: string,
  organizationId: string,
  desiredRole: OrganizationRoles,
  orgTeamActor: ActorSubclass<organizations_teams_types._SERVICE>
) => {
  const role = await getRole(userId, organizationId, orgTeamActor);

  switch (role) {
    case OrganizationRoles.OWNER:
      return true;
    case OrganizationRoles.ADMIN:
      if (
        desiredRole === OrganizationRoles.ADMIN ||
        desiredRole === OrganizationRoles.OWNER
      ) {
        return true;
      }
      return false;
    case OrganizationRoles.MEMBER:
      return true;
    default:
      return false;
  }
};

export const fetchFullOrg = async (
  id: string,
  orgActor: ActorSubclass<organizations_types._SERVICE> | undefined,
  orgTeamActor: ActorSubclass<organizations_teams_types._SERVICE> | undefined,
  identity?: Identity
) => {
  try {
    // Define all the asynchronous operations
    const orgInfoPromise = orgActor?.getOrg(id);
    const orgDetailsPromise = getOrganization(
      ORGANIZATIONS_INDEX_CANISTER_ID,
      id,
      identity
    );
    const orgTeamPromise = orgTeamActor?.read(id);

    // Wait for all promises to resolve
    const [getResponse, getOrgMetaRes, getTeamResponse] = await Promise.all([
      orgInfoPromise,
      orgDetailsPromise,
      orgTeamPromise,
    ]);

    // Process org info response
    const orgInfo =
      'ok' in getResponse!
        ? getResponse['ok']
        : ({} as organizations_types.OrganizationInfo);
    if (!('ok' in getResponse!)) {
      // console.error('error retrieving org info');
    }

    // Process org details response
    const orgDetails = !getOrgMetaRes.error ? getOrgMetaRes.organization : {};
    if (getOrgMetaRes.error) {
      // console.error('error retrieving org details');
    }

    // Process org team response
    const orgTeam =
      'ok' in getTeamResponse!
        ? getTeamResponse['ok']
        : ({} as organizations_teams_types.OrganizationTeam);
    if (!('ok' in getTeamResponse!)) {
      // console.error('error retrieving org team');
    }

    // Compile the result
    const result: Organization = {
      info: orgInfo,
      details: orgDetails,
      team: orgTeam,
    };

    return result;
  } catch (error) {
    return {
      info: {} as organizations_types.OrganizationInfo,
      details: {} as organizations_service_types.OrganizationDetails,
      team: {} as organizations_teams_types.OrganizationTeam,
    };
  }
};

export const getCountryCode = (
  countryToFind: string,
  countryObjectList: {
    label: string;
    value: string;
  }[]
): string | null => {
  const country = countryObjectList.find(
    (c) => c.label.toLowerCase() === countryToFind?.toLowerCase()
  );

  return country ? country.value : null;
};
