import {
  Button,
  LoginButton,
  Spinner,
  Typography,
  useAuthentication,
  useProjectContext,
} from 'components';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useOrganizationsTeamsContext } from '../../Organizations';
import { useUserContext } from '../../User';
import { CreateOrgPromptModal } from './CreateOrgPromptModal';
import ProjectList from './ProjectList';

export default function ProjectsPage() {
  const { push } = useRouter();
  const { user, isUserLoading } = useUserContext();
  const { isAuthing } = useAuthentication();
  const { resetProject } = useProjectContext();
  const { organizationId } = useOrganizationsTeamsContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (isUserLoading || isAuthing) {
    return <Spinner />;
  }

  const createProject = () => {
    if (!organizationId) {
      return setIsModalOpen(true);
    }

    resetProject();
    push('/projects/new');
  };

  return (
    <>
      {user ? (
        <div className="p4">
          <Button
            disabled={!user}
            color="primary"
            onClick={createProject}
            className="mb-2">
            Create Project
          </Button>
          <ProjectList showOrgProjects organizationId={organizationId} />
          <CreateOrgPromptModal
            onClose={() => setIsModalOpen(false)}
            isModalOpen={isModalOpen}
          />
        </div>
      ) : (
        <div className="flex flex-col m-auto w-full text-center justify-center items-center mt-4">
          <Typography>Please log in to view or create projects.</Typography>
          <LoginButton />
        </div>
      )}
    </>
  );
}
