import { ActorSubclass, Identity } from '@dfinity/agent';
import {
  projects_types,
  organizations_teams_types,
  projects_milestones_types,
  projects_service_types,
  users_types,
} from '@scinet-inc/api';
import { Organization, OrganizationRoles } from 'scinet-types';
import { OrgContent } from './OrgContent';

type Props = {
  org: Organization;
  orgTeamActor: ActorSubclass<organizations_teams_types._SERVICE> | undefined;
  push: (url: any, options?: any) => Promise<boolean>;
  user: users_types.User | undefined;
  userActor: ActorSubclass<users_types._SERVICE> | undefined;
  identity: Identity | undefined;
  projectsMilestonesActor:
    | ActorSubclass<projects_milestones_types._SERVICE>
    | undefined;
  isAuthorized: (
    userId: string,
    organizationId: string,
    desiredRole: OrganizationRoles,
    orgTeamActor: ActorSubclass<organizations_teams_types._SERVICE>,
  ) => Promise<boolean>;
  getProjectDetails: (
    projectId: string,
    identity?: Identity,
  ) => Promise<{
    project: projects_service_types.ProjectDetails;
    error?: string;
  }>;
  resetFetchAndPush: (projectId: string) => void;
  projectsBaseActor: ActorSubclass<projects_types._SERVICE> | undefined;
  pushFooter?: boolean;
  getIpNft: (projectId: string) => Promise<number | null>;
  exposeEmail?: boolean;
  filesServiceUrl: string;
  countryCode?: string | null;
};

export const SingleOrg = ({
  org,
  orgTeamActor,
  push,
  user,
  userActor,
  projectsBaseActor,
  identity,
  getProjectDetails,
  projectsMilestonesActor,
  isAuthorized,
  resetFetchAndPush,
  pushFooter,
  getIpNft,
  filesServiceUrl,
  exposeEmail = false,
  countryCode,
}: Props) => {
  return (
    <div className="flex flex-col w-full lg:max-w-[1200px] lg:min-w-[660px] min-h-[500px]">
      <OrgContent
        org={org}
        orgTeamActor={orgTeamActor}
        push={push}
        user={user}
        userActor={userActor}
        projectsBaseActor={projectsBaseActor}
        identity={identity}
        getProjectDetails={getProjectDetails}
        projectsMilestonesActor={projectsMilestonesActor}
        isAuthorized={isAuthorized}
        resetFetchAndPush={resetFetchAndPush}
        pushFooter={pushFooter}
        getIpNft={getIpNft}
        exposeEmail={exposeEmail}
        filesServiceUrl={filesServiceUrl}
        countryCode={countryCode}
      />
    </div>
  );
};
