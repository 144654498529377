import { JsonnableEd25519KeyIdentity } from '@dfinity/identity/lib/cjs/identity/ed25519';

export const identities: JsonnableEd25519KeyIdentity[] = [
  [
    '302a300506032b65700321003b6a27bcceb6a42d62a3a8d02a6f0d73653215771de243a63ac048a18b59da29',
    '0000000000000000000000000000000000000000000000000000000000000000',
  ],
  [
    '302a300506032b657003210073a090a43da7b9b42d0f7bd12fe2f52ee074f914a9b60f3143b674bdd12d5782',
    '93a7de9100000000000000000000000000000000000000000000000000000000',
  ],
  [
    '302a300506032b65700321005085ad459400107777acbbce12ea2efd4420f9c165d7eedf069adda0ffe0227e',
    '93a851bd00000000000000000000000000000000000000000000000000000000',
  ],
  [
    '302a300506032b65700321002ec294ac49e1ba32a6ac05e8ec24d0c265f5e52de149d851565cccdc8389e4b9',
    '93a8e69500000000000000000000000000000000000000000000000000000000',
  ],
];
