import { useState } from 'react';
import { Spinner } from 'components';
import { useLicensesContext } from '../../../contexts/LicensesContext';
import LicensesList from './LicensesList';
import LicensesTabs from './LicensesTabs';

export default function Licenses() {
  const [tabSelected, setTabSelected] = useState(0);
  const { licenses, isLoading } = useLicensesContext();

  return (
    <div className="flex flex-col pt-5">
      <LicensesTabs
        onChange={(index: number) => setTabSelected(index)}
        selected={tabSelected}
      />
      {isLoading ? (
        <div className="mt-10">
          <Spinner />
        </div>
      ) : (
        <LicensesList licenseIds={licenses} tabSelected={tabSelected} />
      )}
    </div>
  );
}
