import { useEffect, useState } from 'react';
import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { profiles_service_types, users_types } from '@scinet-inc/api';
import { useUserContext } from '../../../components';
import { Spinner, Avatar, Typography } from 'components';
import cx from 'classnames';
import { getAvatarRequestPath } from '../../utils';

export type Member = {
  id: string;
};

type Props = {
  isLoading?: boolean;
  memberId: string;
  alignRight?: boolean;
  deleteMember?: (memberId: string) => void;
};

export default function TeamMember({
  memberId,
  alignRight,
  deleteMember,
}: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState<profiles_service_types.Profile | null>(
    null
  );
  const [user, setUser] = useState<users_types.User | null>(null);
  const { actor } = useUserContext();

  const getUser = async () => {
    actor?.read(memberId).then((result: any) => {
      if ('ok' in result) {
        setUser(result.ok);
      } else {
        console.error('**get user error', result);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  const canDelete = !!deleteMember;

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex flex-row items-center justify-between w-full mb-3">
          <div
            className={cx('flex flex-row w-full', {
              'justify-between': alignRight,
            })}>
            {!alignRight && (
              <Avatar
                className={cx('h-10 w-10 mr-2', { 'ml-2': alignRight })}
                source={getAvatarRequestPath(memberId)}
              />
            )}
            {alignRight && canDelete && (
              <div
                aria-label="settings"
                className="w-8 h-8 cursor-pointer"
                onClick={() => deleteMember(memberId)}>
                <MinusCircleIcon color="gray" />
              </div>
            )}
            <div className={cx('flex flex-col', { 'justify-end': alignRight })}>
              <div
                className={cx('flex flex-row', {
                  'justify-end': alignRight,
                })}>
                <Typography className="font-semibold pr-1">
                  {user?.firstName}
                </Typography>
                <Typography className="font-semibold">
                  {user?.lastName}
                </Typography>
              </div>
            </div>
          </div>
          {alignRight && (
            <Avatar
              className="h-10 w-10"
              source={getAvatarRequestPath(memberId)}
            />
          )}
          {!alignRight && canDelete && (
            <div
              aria-label="settings"
              className="w-8 h-8 cursor-pointer"
              onClick={() => deleteMember(memberId)}>
              <MinusCircleIcon color="gray" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
