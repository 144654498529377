import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { BiErrorCircle } from 'react-icons/bi';
import { Notification, Spinner, Typography } from 'components';
import { organizations_types } from '@scinet-inc/api/';

type Props = {
  org: organizations_types.OrganizationInfo;
  toggleModal: () => void;
  isModalOpen: boolean;
  deleteOrg?: (id: string) => any | Promise<organizations_types.Result_3>;
  routerPush: (url: any, options?: any) => Promise<boolean>;
};

export default function DeleteOrgConfirmation({
  deleteOrg,
  routerPush,
  org,
  toggleModal,
  isModalOpen,
}: Props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [notification, setNotification] = useState<null | { success: boolean }>(
    null,
  );
  const handleReset = () => setNotification(null);

  const onSubmit = async () => {
    setIsLoading(true);

    if (deleteOrg) {
      deleteOrg(org.id);
      toggleModal();
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Dialog open={isModalOpen} onClose={toggleModal} className="relative z-50">
      {notification && (
        <Notification status={notification} onReset={handleReset} />
      )}
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-[600px] rounded bg-white ">
          <Dialog.Title className="text-xl font-medium m-4">
            Delete Organization
          </Dialog.Title>
          <div className="h-px w-full bg-black mb-8"></div>
          <Dialog.Description className="text-lg font-light m-4">
            Are you sure you want to delete project: {org?.name}?
          </Dialog.Description>
          <div className="flex h-20 flex-col-reverse lg:flex-row">
            <button
              disabled={isLoading}
              className="max-w-[95%] m-4 px-4 py-2 border rounded hover:bg-purple-400 lg:w-[45%]"
              onClick={toggleModal}>
              Cancel
            </button>
            <button
              disabled={isLoading}
              className="max-w-[95%] mx-4 lg:m-4 px-4 py-2 border rounded bg-purple-700 hover:bg-purple-400 text-white lg:w-[45%]"
              onClick={onSubmit}>
              Delete
            </button>
          </div>
          {errorMessage ? (
            <div className="flex flex-row pt-1">
              <BiErrorCircle color="red" />
              <Typography className="text-xs text-gray-400 pl-1">
                {errorMessage}
              </Typography>
            </div>
          ) : (
            <div className="h-5"></div>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
