export const HOST = process.env.NEXT_PUBLIC_IC_HOST || '';
export const NETWORK = process.env.NEXT_PUBLIC_DFX_NETWORK;
export const AMPLITUDE_API_KEY =
  process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || '';

export const ADMIN_CANISTER_ID =
  process.env.NEXT_PUBLIC_ADMIN_CANISTER_ID || '';
export const EMAIL_VERIFICATIONS_CANISTER_ID =
  process.env.NEXT_PUBLIC_EMAIL_VERIFICATIONS_CANISTER_ID || '';
export const INQUIRIES_CANISTER_ID =
  process.env.NEXT_PUBLIC_INQUIRIES_CANISTER_ID || '';
export const IP_NFT_V1_CANISTER_ID =
  process.env.NEXT_PUBLIC_IP_NFT_V1_CANISTER_ID || '';
export const IP_NFT_METADATA_V1_CANISTER_ID =
  process.env.NEXT_PUBLIC_IP_NFT_METADATA_V1_CANISTER_ID || '';
export const LICENSE_NFT_V1_CANISTER_ID =
  process.env.NEXT_PUBLIC_LICENSE_NFT_V1_CANISTER_ID || '';
export const LICENSE_NFT_METADATA_V1_CANISTER_ID =
  process.env.NEXT_PUBLIC_LICENSE_NFT_METADATA_V1_CANISTER_ID || '';
export const ORGANIZATIONS_CANISTER_ID =
  process.env.NEXT_PUBLIC_ORGANIZATIONS_CANISTER_ID || '';
export const ORGANIZATIONS_INDEX_CANISTER_ID =
  process.env.NEXT_PUBLIC_ORGANIZATIONS_INDEX_CANISTER_ID || '';
export const ORGANIZATIONS_TEAMS_CANISTER_ID =
  process.env.NEXT_PUBLIC_ORGANIZATIONS_TEAMS_CANISTER_ID || '';
export const PROFILES_INDEX_CANISTER_ID =
  process.env.NEXT_PUBLIC_PROFILES_INDEX_CANISTER_ID || '';
export const PROJECTS_CANISTER_ID =
  process.env.NEXT_PUBLIC_PROJECTS_CANISTER_ID || '';
export const PROJECTS_INDEX_CANISTER_ID =
  process.env.NEXT_PUBLIC_PROJECTS_INDEX_CANISTER_ID || '';
export const PROJECTS_MILESTONES_CANISTER_ID =
  process.env.NEXT_PUBLIC_PROJECTS_MILESTONES_CANISTER_ID || '';
export const ELASTIC_SEARCH_CANISTER_ID =
  process.env.NEXT_PUBLIC_ELASTIC_SEARCH_CANISTER_ID || '';
export const TOKENS_CANISTER_ID =
  process.env.NEXT_PUBLIC_TOKENS_CANISTER_ID || '';
export const USERS_CANISTER_ID =
  process.env.NEXT_PUBLIC_USERS_CANISTER_ID || '';
export const WHITE_LISTS_CANISTER_ID =
  process.env.NEXT_PUBLIC_WHITE_LISTS_CANISTER_ID || '';
export const USERS_SERVICE_URL =
  process.env.NEXT_PUBLIC_USERS_SERVICE_URL || '';
export const FILES_SERVICE_URL =
  process.env.NEXT_PUBLIC_FILES_SERVICE_URL || '';
