import { useState } from 'react';
import cx from 'classnames';
import { users_types } from '@scinet-inc/api';
import { ActorSubclass } from '@dfinity/agent';
import { Button, TeamMember, Typography } from '../..';

type Props = {
  className?: string;
  team?: string[];
  userActor: ActorSubclass<users_types._SERVICE> | undefined;
  exposeEmail?: boolean;
  filesServiceUrl: string;
};

const MAX_TEAM_SIZE = 5;

export default function TeamSection({
  team,
  className,
  userActor,
  filesServiceUrl,
  exposeEmail = false,
}: Props) {
  const [showMore, setShowMore] = useState(false);
  const handleShowMore = () => setShowMore(!showMore);
  const teamMembers =
    team?.map((memberId: string, i: number) => (
      <div className="mb-4" key={i}>
        <TeamMember
          memberId={memberId}
          userActor={userActor}
          exposeEmail={exposeEmail}
          filesServiceUrl={filesServiceUrl}
        />
      </div>
    )) || undefined;

  return (
    <div
      className={cx(
        'border border-rhino-200 mt-4 rounded-lg p-4 bg-white w-auto',
        className,
      )}>
      <div className="flex flex-row justify-between">
        <Typography className="text-xl font-bold mb-4">Members</Typography>
        {teamMembers && teamMembers.length > MAX_TEAM_SIZE && (
          <Button className="mb-4" onClick={handleShowMore} color="tertiary">
            {showMore ? 'See Less' : 'See More'}
          </Button>
        )}
      </div>
      {showMore ? teamMembers : teamMembers?.slice(0, MAX_TEAM_SIZE)}
    </div>
  );
}
