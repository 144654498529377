import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { projects_types } from '@scinet-inc/api';
import {
  ProjectCard,
  Spinner,
  useProjectContext,
  useAuthentication,
  Typography,
} from 'components';
import {
  getOrgProjects,
  getProjectDetails,
  getProjectMilestones,
  isAuthorized,
  OrganizationRoles,
  FullProject,
  getIpNftForProject,
  getProfile,
  PROFILES_INDEX_CANISTER_ID,
  FILES_SERVICE_URL,
} from '../../../lib';
import { useAppContext, useUserContext } from '../..';

type Props = {
  organizationId?: string;
  showOrgProjects?: boolean;
};

export default function ProjectList({
  organizationId,
  showOrgProjects = false,
}: Props) {
  const { push } = useRouter();
  const { ipNftActor, orgTeamActor, projectActor, projectsMilestonesActor } =
    useAppContext();
  const { identity } = useAuthentication();
  const [projects, setProjects] = useState<projects_types.ProjectInfo[]>([]);
  const [fetchProjectsCalled, setFetchProjectsCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUserContext();
  const [orgProjects, setOrgProjects] = useState<projects_types.ProjectInfo[]>(
    []
  );
  const [orgProjectsFetched, setOrgProjectsFetched] = useState(false);
  const { resetFetchAndPush } = useProjectContext();

  const getAllOrgProjects = async (organizationId: string) => {
    setIsLoading(true);
    const { projects: orgProjects, error } = await getOrgProjects(
      organizationId,
      projectActor
    );
    setIsLoading(false);

    if (error) {
      return;
    }

    setOrgProjectsFetched(true);
    setOrgProjects(orgProjects);
  };

  useEffect(() => {
    if (organizationId && showOrgProjects && !orgProjectsFetched) {
      getAllOrgProjects(organizationId);
    }
  }, [organizationId, showOrgProjects, orgProjectsFetched]);

  const getProjects = async () => {
    setIsLoading(true);
    projectActor?.listAllPublishedProjects().then(async (getResponse: any) => {
      if ('ok' in getResponse) {
        setProjects(getResponse['ok']);
      } else {
        console.error('failure', getResponse);
      }
    });

    setIsLoading(false);
  };

  const deleteProject = async (id: any) => {
    projectActor?.delete(id).then(async (res: any) => {
      if ('ok' in res) {
        const newProjectList = projects.filter(
          (project) => project.id !== res['ok']
        );
        setProjects(newProjectList);
      } else {
        console.error('failure', res);
      }
    });
  };

  useEffect(() => {
    if (projectActor && !fetchProjectsCalled && !showOrgProjects) {
      getProjects();
      setFetchProjectsCalled(true);
    }
  }, [fetchProjectsCalled, projectActor]);

  const getIpNft = async (projectId: string) => {
    return await getIpNftForProject(projectId, ipNftActor);
  };

  const fetchFullProject = async (
    project: projects_types.ProjectInfo
  ): Promise<FullProject> => {
    let details = {};

    const getProjectDetailsRes = await getProjectDetails(project.id, identity);
    if (!getProjectDetailsRes.error) {
      details = getProjectDetailsRes.project;
    }

    let milestones = await getProjectMilestones(
      project.id,
      projectsMilestonesActor
    );

    // @ts-ignore
    return { info: project, details, milestones };
  };

  const memoProjects = useMemo(
    () =>
      projects.map((project: projects_types.ProjectInfo, index: number) => {
        return (
          <ProjectCard
            getIsAuthorized={(
              userId: string,
              organizationId: string,
              desiredRole: OrganizationRoles
            ) =>
              isAuthorized(userId, organizationId, desiredRole, orgTeamActor!)
            }
            deleteProject={() => deleteProject(project.id)}
            projectBase={project}
            getProject={() => fetchFullProject(project)}
            resetFetchAndPush={resetFetchAndPush}
            key={index}
            user={user}
            push={push}
            getIpNft={getIpNft}
            onClick={() => push(`/projects/${project.id}`)}
            filesServiceUrl={FILES_SERVICE_URL}
            hideActions></ProjectCard>
        );
      }),
    [projects, user]
  );

  const memoOrgProjects = useMemo(
    () =>
      orgProjects.map((project: projects_types.ProjectInfo, index: number) => {
        return (
          <ProjectCard
            getIsAuthorized={(
              userId: string,
              organizationId: string,
              desiredRole: OrganizationRoles
            ) =>
              isAuthorized(userId, organizationId, desiredRole, orgTeamActor!)
            }
            deleteProject={() => deleteProject(project.id)}
            projectBase={project}
            getProject={() => fetchFullProject(project)}
            resetFetchAndPush={resetFetchAndPush}
            key={index}
            user={user}
            hideActions
            push={push}
            getIpNft={getIpNft}
            filesServiceUrl={FILES_SERVICE_URL}
            onClick={() => push(`/projects/${project.id}`)}></ProjectCard>
        );
      }),
    [orgProjects, user]
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col">
      {memoProjects.length > 0 && (
        <div className="flex flex-wrap">{memoProjects}</div>
      )}
      {orgProjects.length > 0 && (
        <div>
          <Typography className="font-lg font-semibold">
            Your Organization&apos;s Research Projects
          </Typography>
          <div className="flex flex-wrap">{memoOrgProjects}</div>
        </div>
      )}
    </div>
  );
}
