import {
  useContext,
  createContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { AuthClient } from '@dfinity/auth-client';
import { Identity } from '@dfinity/agent';
import { noop } from '@scinet-inc/utils';
import { clear, get, remove, set } from 'local-storage';
import { NFID } from '@nfid/embed';
import { Ed25519KeyIdentity } from '@dfinity/identity';
import { identities } from './localIdentities';

const network = process.env.NEXT_PUBLIC_DFX_NETWORK;

type IAuthContextProviderProps = {
  children: any;
};

type Context = {
  isAuthenticated: boolean;
  authClient: AuthClient | undefined;
  login: () => void;
  isAuthing: boolean;
  logout: () => void;
  identity: Identity | undefined;
  nfid: NFID | undefined;
};

type Action =
  | {
      type: 'login';
      isAuthenticated: boolean;
      identity: Identity;
    }
  | {
      type: 'logout';
    }
  | {
      type: 'set-client';
      isAuthenticated: boolean;
      identity: Identity;
      client: AuthClient;
    }
  | {
      type: 'set-isAuthing';
      isAuthing: boolean;
    };

const initialContext: Context = {
  isAuthenticated: false,
  authClient: undefined,
  login: noop,
  isAuthing: false,
  logout: noop,
  identity: undefined,
  nfid: undefined,
};

export const AuthContext = createContext(initialContext);

export const AuthContextProvider = ({
  children,
}: IAuthContextProviderProps) => {
  const reducer = (state: any, action: Action) => {
    switch (action.type) {
      case 'login':
        return {
          ...state,
          isAuthenticated: action.isAuthenticated,
          identity: action.identity,
          isAuthing: false,
        };
      case 'logout':
        return {
          identity: undefined,
          isAuthenticated: false,
          isAuthing: false,
          authClient: undefined,
        };
      case 'set-client':
        return {
          ...state,
          isAuthenticated: action.isAuthenticated,
          identity: action.identity,
          authClient: action.client,
        };
      case 'set-isAuthing':
        return {
          ...state,
          isAuthing: action.isAuthing,
        };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    identity: null,
    agent: null,
    isAuthenticated: false,
    isAuthing: false,
  });

  const [nfid, setNfid] = useState<NFID>();

  const loginLocal = () => {
    // modify the key identityIndex with a number 0-3 in browser local storage for http://localhost:3000

    let identityIndexLS: number = get('identityIndex');
    if (!identityIndexLS || identityIndexLS > 3) {
      identityIndexLS = 0;
    }
    const identity = Ed25519KeyIdentity.fromParsedJson(
      identities[identityIndexLS],
    );

    set('identity', identity);
    dispatch({
      type: 'login',
      isAuthenticated: true,
      identity,
    });
  };

  const login = async () => {
    if (network !== 'dev' && !nfid) {
      throw new Error('NFID not initialized');
    }

    dispatch({
      type: 'set-isAuthing',
      isAuthing: true,
    });

    try {
      if (network === 'dev') {
        loginLocal();
      } else {
        const identity = await nfid!.getDelegation({
          maxTimeToLive: BigInt(7 * 24 * 60 * 60 * 1000 * 1000 * 1000),
        });

        set('identity', identity);
        dispatch({
          type: 'login',
          isAuthenticated: true,
          identity,
        });
      }
    } catch (e) {
      dispatch({
        type: 'login',
        isAuthenticated: false,
        identity: state.identity,
      });
    }
  };

  const logout = () => {
    clear();
    remove('identity');
    dispatch({
      type: 'logout',
    });
    nfid?.logout();
  };

  const initialize = async () => {
    if (network === 'dev') {
      loginLocal();
      // const existingIdentity = get('identity');
      // console.log('**existingIdentity', existingIdentity);
      // if (existingIdentity) {
      //   dispatch({
      //     type: 'login',
      //     isAuthenticated: true,
      //     // @ts-ignore
      //     identity: existingIdentity,
      //   });
      // }
    }
    const initialNfid: NFID = await NFID.init({
      application: {
        name: 'SCINET',
        logo: 'https://www.scinet.one/logo-no-text.png',
      },
    });
    if (initialNfid?.isAuthenticated) {
      const identity = initialNfid.getIdentity();
      // TODO: store your identity within your local state:
      dispatch({
        type: 'login',
        isAuthenticated: true,
        identity,
      });
    }
    setNfid(initialNfid);
  };
  useEffect(() => {
    initialize();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        nfid,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthentication() {
  return useContext(AuthContext);
}
