import { Project } from 'scinet-types';
import TimelineProjectMilestones from './TimelineProjectMilestones';
import AccordionProjectMilestones from './AccordionProjectMilestones';

export const Milestones = ({ project }: { project: Project }) => {
  return (
    <>
      <div className="block md:hidden">
        <AccordionProjectMilestones
          milestones={project?.milestones.milestones}
        />
      </div>
      <div className="hidden md:block">
        <TimelineProjectMilestones
          milestones={project?.milestones.milestones}
        />
      </div>
    </>
  );
};
