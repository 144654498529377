import cx from 'classnames';
import { Typography } from '../Typography';

type Props = {
  className?: string;
  variant: 'Royalty' | 'License' | 'IP-NFT' | 'Art-Based';
  tokenNumber?: number;
  title?: string;
  routerPush: (url: any, options?: any) => Promise<boolean>;
};

export const AssetCard = ({
  className,
  variant,
  tokenNumber,
  title,
  routerPush,
}: Props) => {
  return (
    <div
      className={cx(
        'flex flex-col h-20 w-full lg:w-80 border-solid rounded-lg p-2 m-auto my-2 bg-cover bg-white hover:cursor-pointer hover:border-2 hover:border-purple-200 hover:shadow-md',
        {
          className: className,
        },
      )}
      // Add routing for asset click
      //   onClick={() => routerPush('/')}
    >
      <div className="flex flex-row h-16 bg-[#FFFFFF] bg-opacity-40 rounded-md">
        <div
          className={cx('w-16 h-16 rounded-lg bg-contain', {
            className: className,
            'bg-[url("/small-license-token.png")]': variant === 'License',
            'bg-[url("/small-royalty-token.png")]': variant === 'Royalty',
            'bg-[url("/small-ip-token.png")]': variant === 'IP-NFT',
          })}></div>
        <div className="ml-2 flex flex-col justify-center">
          <Typography className="text-sm font-semibold">{title}</Typography>
          <Typography className="text-xs text-gray-400">
            {variant} #{tokenNumber}
          </Typography>
        </div>
      </div>
    </div>
  );
};
