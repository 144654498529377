import { useEffect, useMemo, useState } from 'react';
import {
  inquiries_types,
  profiles_service_types,
  users_types,
  white_lists_types,
} from '@scinet-inc/api';
import {
  Button,
  Card,
  useAuthentication,
  useProjectContext,
  useToast,
} from 'components';

import {
  ADMIN_CANISTER_ID,
  fetchFullOrg,
  getProfile,
  getSciFiBaseUrl,
  InquiryStatus,
  isProd,
  isStaging,
  Organization,
  PROFILES_INDEX_CANISTER_ID,
} from '../../../lib';
import { useAppContext } from '../../AppContext';
import InquiryCardHeader from './InquiryCardHeader';
import { useInquiriesContext } from '../../../contexts';
import { useRouter } from 'next/router';
import { noop } from '@scinet-inc/utils';
import { useUserContext } from '../../User';

export default function InquiryCard({
  getWhiteList,
  inquiry,
  index,
  whiteList,
  userBelongsToProjectOrg,
}: {
  getWhiteList: () => void;
  inquiry: inquiries_types.Inquiry;
  index: number;
  whiteList?: white_lists_types.WhiteListNode | null;
  userBelongsToProjectOrg: boolean;
}) {
  const { identity } = useAuthentication();
  const { licenseNFTMetadataActor, orgActor, orgTeamActor, whiteListsActor } =
    useAppContext();
  const [fullOrg, setFullOrg] = useState<Organization | null>(null);
  const { inquiriesActor } = useAppContext();
  const { getInquiry } = useInquiriesContext();
  const { showToast } = useToast();
  const { push } = useRouter();
  const { project } = useProjectContext();
  const [existingLicenseMetadata, setExistingLicenseMetadata] =
    useState<any>(undefined);
  const [licenseNFTQueryCalled, setLicenseNFTQueryCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { actor } = useUserContext();
  const [user, setUser] = useState<users_types.User | null>(null);
  const [profile, setProfile] = useState<profiles_service_types.Profile | null>(
    null
  );
  const { status, inquirerId } = inquiry;

  const getUser = async (id: string) => {
    actor?.read(id).then((result: any) => {
      if ('ok' in result) {
        setUser(result.ok);
      } else {
        console.error('**get user error', result); //TODO: handle error and show it in ui
      }
    });

    const { profile, error } = await getProfile(
      PROFILES_INDEX_CANISTER_ID,
      inquirerId,
      undefined
    );
    if (profile) {
      setProfile(profile);
    }
  };

  useEffect(() => {
    if (inquirerId && !user) {
      getUser(inquirerId);
    }
  }, [inquirerId, user]);

  const checkIfLicenseExists = async () => {
    setIsLoading(true);
    licenseNFTMetadataActor?.get(inquiry.id).then((res: any) => {
      if ('ok' in res) {
        setExistingLicenseMetadata(res.ok);
      }
      setLicenseNFTQueryCalled(true);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!licenseNFTQueryCalled && inquiry.status === InquiryStatus.APPROVED) {
      checkIfLicenseExists();
    }
  }, [licenseNFTQueryCalled, inquiry.status]);

  const isOnWhiteList = useMemo(
    () => whiteList?.whiteList?.includes(inquiry.inquirerId),
    [inquiry.inquirerId, whiteList]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const approveInquiry = async () => {
    setIsLoading(true);
    inquiriesActor
      ?.approveOrDeny(inquiry.id, InquiryStatus.APPROVED, ADMIN_CANISTER_ID)
      .then(async (res: any) => {
        if ('ok' in res) {
          const base = getSciFiBaseUrl();

          await fetch('/api/inquiries/inquiry-response', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: user?.email,
              href: `${base}/projects/${project.info.id}/nft`,
              title: project.info.title,
              isApproved: true,
            }),
          });
          showToast({
            message: 'Inquiry approved.',
            variant: 'success',
          });

          getInquiry(res.ok, index);

          if (isOnWhiteList) {
            return setIsLoading(false);
          }

          await whiteListsActor
            ?.addToWhiteList(
              inquiry.organizationId,
              inquiry.projectId,
              inquiry.inquirerId
            )
            .then(async (updateRes: any) => {
              if (updateRes.ok) {
                showToast({
                  message: 'Update wallet white list succeeded.',
                  variant: 'success',
                });
              } else {
                showToast({
                  message: 'Update wallet white list failed.',
                  variant: 'error',
                });
              }
              getWhiteList();
              return setIsLoading(false);
            });
        }
        setIsLoading(false);
      });
  };

  const rejectInquiry = async () => {
    setIsLoading(true);

    inquiriesActor
      ?.approveOrDeny(inquiry.id, InquiryStatus.REJECTED, ADMIN_CANISTER_ID)
      .then(async (res: any) => {
        if ('ok' in res) {
          await fetch('/api/inquiries/inquiry-response', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: user?.email,
              title: project.info.title,
              isApproved: false,
            }),
          });

          showToast({
            message: 'Inquiry rejected.',
            variant: 'success',
          });

          getInquiry(res.ok, index);

          if (!isOnWhiteList) {
            return setIsLoading(false);
          }

          await whiteListsActor
            ?.removeFromWhiteList(
              inquiry.organizationId,
              inquiry.projectId,
              inquiry.inquirerId
            )
            .then(async (updateRes: any) => {
              if (updateRes.ok) {
                showToast({
                  message: 'Update white list succeeded.',
                  variant: 'success',
                });
              } else {
                showToast({
                  message: 'Update white list failed.',
                  variant: 'error',
                });
              }
              getWhiteList();
              return setIsLoading(false);
            });

          setIsLoading(false);
        }
      });
  };

  const addToWhiteList = async () => {
    setIsLoading(true);
    await whiteListsActor
      ?.addToWhiteList(
        inquiry.organizationId,
        inquiry.projectId,
        inquiry.inquirerId
      )
      .then(async (res: any) => {
        if (res.ok) {
          showToast({
            message: 'Added to white list.',
            variant: 'success',
          });
          getWhiteList();
        } else {
          showToast({
            message: 'Failed to add to white list.',
            variant: 'error',
          });
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!fullOrg) {
      fetchFullOrg(
        inquiry.inquiringOrganizationId,
        orgActor,
        orgTeamActor,
        identity
      ).then((res) => {
        setFullOrg(res);
      });
    }
  }, [
    fullOrg,
    identity,
    inquiry.inquiringOrganizationId,
    orgActor,
    orgTeamActor,
  ]);

  const headerNode = (
    <InquiryCardHeader
      inquiry={inquiry}
      inquiringOrg={fullOrg}
      status={status}
      profile={profile}
      user={user}
    />
  );

  const bottomNode = useMemo(() => {
    if (userBelongsToProjectOrg) {
      if (inquiry.status === InquiryStatus.APPROVED) {
        if (existingLicenseMetadata) {
          return (
            <div className="flex flex-row">
              <Button
                color="primary"
                className="mt-4 mr-2"
                fullWidth
                disabled={isLoading}
                onClick={() =>
                  push(`/projects/${project.info.id}/licenses/${inquiry.id}`)
                }>
                View License
              </Button>
            </div>
          );
        }

        return (
          <div className="flex flex-row">
            <Button
              color="primary"
              className="mt-4 mr-2"
              fullWidth
              disabled={isLoading}
              onClick={() =>
                push(
                  `/projects/${project.info.id}/licenses/${inquiry.id}/new?organization_id=${inquiry.inquiringOrganizationId}`
                )
              }>
              Create License
            </Button>
            <Button
              color="secondary"
              className="mt-4 mr-2"
              fullWidth
              disabled={isLoading}
              onClick={rejectInquiry}>
              Reject
            </Button>
          </div>
        );
      }

      if (!isOnWhiteList) {
        <div className="flex flex-row">
          <Button
            color="primary"
            className="mt-4 mr-2"
            fullWidth
            disabled={isLoading}
            onClick={addToWhiteList}>
            Add to White List
          </Button>
        </div>;
      }

      return (
        <div className="flex flex-row">
          <Button
            color="primary"
            className="mt-4 mr-2"
            fullWidth
            disabled={isLoading || inquiry.status === InquiryStatus.APPROVED}
            onClick={approveInquiry}>
            Approve
          </Button>
          <Button
            color="secondary"
            className="mt-4 mr-2"
            fullWidth
            disabled={isLoading}
            onClick={rejectInquiry}>
            Reject
          </Button>
        </div>
      );
    }

    if (inquiry.status === InquiryStatus.APPROVED) {
      return (
        <div className="flex flex-col">
          <span className="text-green-500">
            Your inquiry has been approved! View the IP-NFT and its data below.
          </span>
          <Button
            color="primary"
            className="w-full mt-4"
            onClick={() => push(`/projects/${inquiry.projectId}/nft`)}>
            View IP-NFT
          </Button>
        </div>
      );
    } else if (inquiry.status === InquiryStatus.REJECTED) {
      return (
        <span className="text-red-500">Your inquiry has been rejected.</span>
      );
    } else {
      return (
        <span className="text-orange-500">
          Your inquiry is awaiting review.
        </span>
      );
    }
  }, [
    userBelongsToProjectOrg,
    inquiry.status,
    inquiry.id,
    inquiry.inquiringOrganizationId,
    inquiry.projectId,
    isOnWhiteList,
    isLoading,
    approveInquiry,
    rejectInquiry,
    existingLicenseMetadata,
    push,
    project.info.id,
    addToWhiteList,
  ]);

  return (
    <Card
      description={inquiry.message}
      headerNode={headerNode}
      className="m-0 md:mt-4 md:mr-4"
      onClick={noop}>
      <div className="mt-4">{bottomNode}</div>
    </Card>
  );
}
