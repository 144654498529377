import { useState, useRef, useEffect } from 'react';
import { Chip } from '../Chip';
import cx from 'classnames';
import { PlusIcon } from '@heroicons/react/24/solid';

type AutocompleteProps = {
  options?: any[];
  onSelect?: (option: any) => void;
  multiple?: boolean;
  canAddNew?: boolean;
  value?: any[];
  onChange?: (value: any[]) => void;
  placeHolder?: string;
};

export const Autocomplete = ({
  options = [],
  onSelect = () => {},
  multiple,
  canAddNew,
  value = [],
  onChange = () => {},
  placeHolder,
  ...rest
}: AutocompleteProps) => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>(value);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [filteredOptions, setFilteredOptions] = useState<any[]>(options);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  const handleSelect = (option: any) => {
    let optionsSelected: any[] = [];
    if (option) {
      if (selectedOptions.some((o) => o.value === option.value)) {
        return;
      }

      if (multiple) {
        optionsSelected = [
          ...selectedOptions,
          { label: option, value: option },
        ];
        setSelectedOptions(optionsSelected);
        onChange(optionsSelected);
        setInputValue('');
      } else {
        optionsSelected = [
          {
            label: option,
            value: option,
          },
        ];
        setSelectedOptions(optionsSelected);
        onChange(optionsSelected);
        setIsOpen(false);
      }
    } else {
      if (
        inputValue === '' ||
        selectedOptions.some((o) => o.value === inputValue)
      ) {
        return;
      }

      if (multiple) {
        optionsSelected = [
          ...selectedOptions,
          { label: inputValue, value: inputValue },
        ];
        setSelectedOptions(optionsSelected);
        onChange(optionsSelected);
        setInputValue('');
      } else {
        optionsSelected = [{ label: inputValue, value: inputValue }];
        setSelectedOptions(optionsSelected);
        onChange(optionsSelected);
        setIsOpen(false);
      }
    }

    const newOptions = options.filter(
      (o) => !optionsSelected.some((s) => s.value === o.value),
    );
    setFilteredOptions(newOptions);
    onSelect(option);
  };

  const handleDelete = (option: any) => {
    setSelectedOptions(selectedOptions.filter((o) => o !== option));
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      setHighlightedIndex((highlightedIndex + 1) % filteredOptions.length);
    } else if (event.key === 'ArrowUp') {
      setHighlightedIndex(
        (highlightedIndex - 1 + filteredOptions.length) %
          filteredOptions.length,
      );
    } else if (event.key === 'Enter') {
      event.preventDefault();
      handleSelect(filteredOptions[highlightedIndex]);
    } else if (event.key === 'Escape' || event.key === 'Tab') {
      setIsOpen(false);
      inputRef.current?.blur();
    } else if (event.key === 'Backspace' && inputValue === '') {
      setSelectedOptions(selectedOptions.slice(0, -1));
      const newOptions = options.filter(
        (o) => !selectedOptions.slice(0, -1).some((s) => s.value === o.value),
      );
      setFilteredOptions(newOptions);
    }
  };

  return (
    <div className="relative">
      <div
        className={cx('relative border rounded-md p-2', {
          'border-primary': isFocused,
          'border-gray-300': !isFocused,
        })}>
        <div className="flex flex-wrap gap-2">
          {selectedOptions.map((option) => (
            <Chip key={option.value} onDelete={() => handleDelete(option)}>
              <span className="truncate">{option.value}</span>
            </Chip>
          ))}
          <div className="relative flex-1">
            <input
              ref={inputRef}
              type="text"
              className="w-full px-2 font-sm text-sm text-gray-900 placeholder-gray-500 border-none focus:ring-0 focus:outline-none"
              placeholder={placeHolder}
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              onFocus={() => {
                setIsOpen(true);
                setIsFocused(true);
              }}
              onBlur={(e) => {
                if (e.relatedTarget === null) {
                  setIsOpen(false);
                  setIsFocused(false);
                } else {
                  setIsFocused(true);
                  setIsOpen(true);
                  inputRef.current?.focus();
                }
              }}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="absolute z-50 w-full mt-1 bg-white rounded-md shadow-lg">
          {filteredOptions.length > 0 ? (
            <ul
              className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              tabIndex={-1}>
              {filteredOptions.map((option, index) => (
                <li
                  key={option.value}
                  className={cx(
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                    {
                      'text-primary bg-electric-violet-100':
                        highlightedIndex === index,
                    },
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(true);
                    handleSelect(option);
                  }}>
                  <div className="flex items-center">
                    <span
                      className={cx('font-normal block truncate', {
                        'font-semibold': highlightedIndex === index,
                      })}>
                      {option.label}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            options.length !== 0 && <div className="px-3 py-2">No options</div>
          )}
          {canAddNew && inputValue && (
            <div
              className="flex items-center px-4 py-2 text-sm text-indigo-700 bg-indigo-100 cursor-pointer hover:bg-indigo-200"
              onMouseDown={(e) => {
                e.preventDefault();
                setIsOpen(true);
                handleSelect(inputValue);
                setInputValue('');
              }}>
              <PlusIcon className="w-5 h-5 mr-2" />
              <span>Add {`"${inputValue}"`}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
