import { Typography } from 'components';
import { organizations_types } from '@scinet-inc/api';

type Props = {
  org: organizations_types.OrganizationInfo;
};

export function AboutOrg({ org }: Props) {
  return (
    <div className="w-full h-auto flex flex-col border-2 border-gray-300 bg-white rounded-md p-4 mr-4 mb-32 lg:mb-0">
      <Typography className="text-sm font-bold">
        Organization Description
      </Typography>
      <Typography className="text-md">{org?.description}</Typography>
    </div>
  );
}
