import router from 'next/router';
import { Button, Card, Typography } from 'components';
import { Flask, Rocket } from '../Icons';

export default function GetStarted() {
  return (
    <div className="px-5">
      <Typography className="font-bold text-2xl">
        Get Started With SCINET
      </Typography>
      <div>
        <Card className="mb-5">
          <Rocket />
          <Typography className="font-bold py-3">
            Licensing Opportunities
          </Typography>
          <Typography className="pb-3">
            Browse scientific research that can take your business to the next
            level.
          </Typography>
          <Button
            onClick={() => {
              router.push('/');
            }}>
            Explore
          </Button>
        </Card>
        <Card>
          <Flask />
          <Typography className="font-bold py-3">
            Publish IP on SCINET
          </Typography>
          <Typography className="pb-3">
            The next step in your research journey is to create an organization.
            From there you can create projects and publish your IP. If
            you&apos;ve been invited to SCINET by an existing user, reach out to
            them for an invite to their organization.
          </Typography>
          <Button onClick={() => router.push('/organizations/new')}>
            Start
          </Button>
        </Card>
      </div>
    </div>
  );
}
