import { useEffect, useState } from 'react';
import { useAuthentication } from 'components';
import { SignIdentity } from '@dfinity/agent';
import { useAuthorizationContext } from '../../contexts';
import { convertToBase64 } from '@scinet-inc/utils';
import { getAvatarRequestPath } from '../utils';
import { FilesServiceClient } from '../api/files-service-client';
import { FILES_SERVICE_URL } from '../constants';

export const useUsersAvatar = () => {
  const { identity, isAuthing } = useAuthentication();
  const { tokenId } = useAuthorizationContext();
  const [client, setClient] = useState<FilesServiceClient | undefined>();
  const [init, setInit] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<File>();
  const [avatarEncoded, setAvatarEncoded] = useState<string>();

  useEffect(() => {
    if (identity && tokenId && !client && !isAuthing) {
      const svc = new FilesServiceClient({
        rootUrl: `${FILES_SERVICE_URL}/avatar/${identity.getPrincipal().toString()}`,
        identity: identity as SignIdentity,
      });
      svc.setToken(tokenId);
      setClient(svc);
    }
  }, [identity, client, tokenId, isAuthing]);

  useEffect(() => {
    if (client && avatar && tokenId) {
      const uploadAvatar = async () => {
        setAvatarEncoded(await convertToBase64(avatar));
        await client.upload(avatar);
      };
      uploadAvatar();
    }
  }, [client, avatar, tokenId]);

  useEffect(() => {
    if (client && !avatar && !init && identity) {
      const loadAvatar = async () => {
        try {
          setInit(true);

          const resp = await fetch(
            getAvatarRequestPath(identity?.getPrincipal().toString())
          );
          if (resp.status === 200) {
            const blob = await resp.blob();
            setAvatarEncoded(await convertToBase64(blob));
          }
        } catch (e) {
          // console.error('Error loading avatar', e);
        }
      };

      loadAvatar();
    }
  }, [init, client, avatar, avatarEncoded, identity]);

  return {
    isLoading: isAuthing,
    setAvatar,
    avatarBase64: avatarEncoded,
    avatarFile: avatar,
  };
};
