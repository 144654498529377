import { useEffect, useState } from 'react';
import { useAuthentication, useProjectContext } from 'components';
import { SignIdentity } from '@dfinity/agent';
import { useAuthorizationContext } from '../../contexts';
import { FilesServiceClient } from '../api/files-service-client';
import { FILES_SERVICE_URL } from '../constants';

export const useIPFiles = () => {
  const { identity, isAuthing } = useAuthentication();
  const { tokenId } = useAuthorizationContext();
  const { project, isLoading: isProjectContextLoading } = useProjectContext();
  const [client, setClient] = useState<FilesServiceClient | undefined>();

  useEffect(() => {
    if (
      identity &&
      tokenId &&
      project.info.id &&
      !client &&
      !isAuthing &&
      !isProjectContextLoading
    ) {
      const svc = new FilesServiceClient({
        rootUrl: `${FILES_SERVICE_URL}/ip/${project.info.organizationId}/${project.info.id}`,
        // This is safe because we know the identity is a SignIdentity
        identity: identity as SignIdentity,
      });
      svc.setToken(tokenId);
      setClient(svc);
    }
  }, [
    identity,
    client,
    tokenId,
    isAuthing,
    project.info.id,
    project.info.organizationId,
    isProjectContextLoading,
  ]);

  return {
    client,
    isLoading: isAuthing || isProjectContextLoading,
  };
};
