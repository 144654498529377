import { UserCircleIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

type Props = {
  source?: string;
  className?: string;
  style?: React.CSSProperties;
};

export const Avatar = ({ source, className, style }: Props) => {
  return (
    <>
      {source ? (
        <img
          src={source}
          className={cx('rounded-full', className)}
          style={style}
        />
      ) : (
        <UserCircleIcon
          width="48px"
          className={className}
          color="grey"
          style={style}
        />
      )}
    </>
  );
};
