import React, {
  useContext,
  createContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { clear, get, remove, set } from 'local-storage';
import { ActorSubclass, Identity } from '@dfinity/agent';
import { noop } from '@scinet-inc/utils';
import {
  email_verifications,
  email_verifications_types,
  users,
  users_types,
} from '@scinet-inc/api';
import { useAuthentication } from 'components';
import {
  ActorResponse,
  EMAIL_VERIFICATIONS_CANISTER_ID,
  getAgent,
  sendVerificationEmail,
  USERS_CANISTER_ID,
} from '../../lib';
import { useUsersService } from '../../lib/hooks/useUsersService';
import { useAuthorizationContext } from '../../contexts';
import { useUsersAvatar } from '../../lib/hooks/useUsersAvatar';

type IAuthContextProviderProps = {
  children: any;
};

type Context = {
  actor: ActorSubclass<users_types._SERVICE> | undefined;
  isUserLoading: boolean;
  user: users_types.User | undefined;
  renderUserForm: boolean;
  updateUser: (user?: users_types.User, userAvatarEncoded?: string) => void;
  clearUser: () => void;
  setEmailVerified: (value: email_verifications_types.Verification) => void;
  createEmailVerification: (value: string) => void;
  emailVerified: email_verifications_types.Verification | null;
  emailVerificationsActor:
    | ActorSubclass<email_verifications_types._SERVICE>
    | undefined;
  getVerificationStatus: (email: string) => void;
  setAvatar: (avatar: Blob) => void;
  avatarBase64: string | undefined;
};

type Action =
  | {
      type: 'set-user';
      user: users_types.User | undefined;
      renderUserForm: boolean;
    }
  | {
      type: 'set-actor';
      actor: ActorSubclass<users_types._SERVICE>;
      isAuthenticated: boolean;
    }
  | {
      type: 'set-isUserLoading';
      isUserLoading: boolean;
    };

const initialContext: Context = {
  actor: undefined,
  emailVerified: null,
  isUserLoading: false,
  renderUserForm: false,
  user: undefined,
  updateUser: noop,
  clearUser: noop,
  setEmailVerified: noop,
  createEmailVerification: noop,
  emailVerificationsActor: undefined,
  getVerificationStatus: noop,
  setAvatar: noop,
  avatarBase64: undefined,
};

export const UserContext = createContext(initialContext);

export const UserContextProvider = ({
  children,
}: IAuthContextProviderProps) => {
  const agent = getAgent(undefined);
  const initialEmailVerificationsActor = email_verifications.createClient(
    EMAIL_VERIFICATIONS_CANISTER_ID,
    agent
  );
  const { identity, isAuthenticated, logout } = useAuthentication();
  const { createOrUpdateUser } = useUsersService();
  const { avatarBase64, setAvatar } = useUsersAvatar();
  const { tokenId } = useAuthorizationContext();

  const reducer = (state: any, action: Action) => {
    switch (action.type) {
      case 'set-user':
        return {
          ...state,
          isUserLoading: false,
          renderUserForm: action.renderUserForm,
          user: action.user,
        };
      case 'set-actor':
        return {
          ...state,
          actor: action.actor,
          isAuthenticated: action.isAuthenticated,
        };
      case 'set-isUserLoading':
        return {
          ...state,
          isUserLoading: action.isUserLoading,
        };
      default:
        return {
          ...state,
        };
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    user: null,
    actor: null,
    isUserLoading: false,
  });

  const [emailVerificationsActor, setEmailVerificationsActor] = useState(
    initialEmailVerificationsActor
  );

  const [firestoreUser, setFirestoreUser] = useState<any>(null);

  const [emailVerified, setEmailVerified] =
    useState<email_verifications_types.Verification | null>(null);

  const updateUser = async (user?: users_types.User | undefined) => {
    if (user) {
      // exlude some fields from the native User object
      const { principal, createdAt, updatedAt, ...rest } = user;

      // we don't want to create the user here on the backend yet
      // we need an on-chain token for them first

      // await createOrUpdateUser(rest);
      set('user', user);
    } else {
      remove('user');
    }
    dispatch({
      type: 'set-user',
      user,
      renderUserForm: !user,
    });
  };

  const getUser = (actor: ActorSubclass<users_types._SERVICE>) => {
    dispatch({
      type: 'set-isUserLoading',
      isUserLoading: true,
    });
    actor.readOwnUser().then((result: any) => {
      if ('ok' in result) {
        const user = result.ok;
        updateUser(user);
      } else {
        if ('NotAuthorized' in result.err) {
          // require a new login
          logout();
        } else if ('NotFound' in result.err) {
          // User has deleted account
        }
        updateUser();
      }
    });
  };

  const initUserActor = (identity: Identity | undefined) => {
    const agent = getAgent(identity);
    const actor = users.createClient(USERS_CANISTER_ID as string, agent);

    dispatch({
      type: 'set-actor',
      actor,
      // add check to see if the identity is anon
      isAuthenticated: !!identity,
    });

    if (
      identity &&
      Object.keys(identity).length &&
      (!state.user || !state.user.email)
    ) {
      getUser(actor);
    }
  };

  useEffect(() => {
    const stored = JSON.parse(get('user')) as users_types.User | undefined;
    if (stored) {
      dispatch({
        type: 'set-user',
        user: stored,
        renderUserForm: false,
      });
    }
    initUserActor(undefined);
  }, []);

  useEffect(() => {
    if (identity && isAuthenticated && !state.isAuthenticated) {
      initUserActor(identity);
    }
  }, [isAuthenticated, identity, state.isAuthenticated]);

  // create or update user in firestore
  // once we have the on-chain token
  useEffect(() => {
    const getFirestoreUser = async (user: users_types.User) => {
      const { principal, createdAt, updatedAt, ...rest } = user;
      const result = await createOrUpdateUser(rest);
      if (result) {
        setFirestoreUser(firestoreUser);
      }
    };
    if (tokenId && state.user && !firestoreUser) {
      getFirestoreUser(state.user);
    }
  }, [firestoreUser, tokenId, state.user]);

  const sendVerifyEmail = async (
    userId: string,
    email: string,
    token: string
  ) => {
    const href = `${window.location.origin}/users/${userId}/verify/${token}`;
    sendVerificationEmail(email, href);
  };

  const createEmailVerification = async (email: string) => {
    emailVerificationsActor?.create().then((result: ActorResponse) => {
      if ('ok' in result) {
        const createResult = result.ok;
        setEmailVerified(createResult);

        sendVerifyEmail(createResult.id, email, createResult.token);
      }
    });
  };

  const getVerificationStatus = async (email: string) => {
    emailVerificationsActor
      ?.getOwnVerification()
      .then((result: ActorResponse) => {
        if ('ok' in result) {
          const getResult = result.ok;
          setEmailVerified(getResult);
        } else {
          const error = Object.keys(result.err)[0];
          if (error === 'NotFound') {
            createEmailVerification(email);
          }
        }
      });
  };

  const initActors = (identity?: any) => {
    const agent = getAgent(identity);

    const emailVerificationsActor = email_verifications.createClient(
      EMAIL_VERIFICATIONS_CANISTER_ID,
      agent
    );

    setEmailVerificationsActor(emailVerificationsActor);
  };

  // allow isAuthenticated users to make authenticated requests
  useEffect(() => {
    initActors(identity);
  }, [identity, isAuthenticated]);

  useEffect(() => {
    if (state.user && state.user.email) {
      getVerificationStatus(state.user.email);
    }
  }, [state.user]);

  const clearUser = () => {
    clear();
    updateUser();
    initUserActor(undefined);
  };

  // const updateAvatar = async (avatar: string) => {
  //   await avatarClient?.upload(new File([avatar as BlobPart], 'avatar'));
  //   setAvatarUrl(myAvatarUrl);
  // }

  return (
    <UserContext.Provider
      value={{
        ...state,
        clearUser,
        updateUser,
        setEmailVerified,
        createEmailVerification,
        emailVerified,
        emailVerificationsActor,
        getVerificationStatus,
        setAvatar,
        avatarBase64,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export function useUserContext() {
  return useContext(UserContext);
}
