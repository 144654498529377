import React from 'react';

export default function LicenseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="48px"
      height="48px">
      <g transform="matrix(2,0,0,2,0,0)">
        <g>
          <path
            d="M13.79,5.69a.49.49,0,0,0,.46.31h0a.52.52,0,0,0,.46-.38L15,4.73a.25.25,0,0,1,.12-.16.26.26,0,0,1,.21,0,8.47,8.47,0,0,1,5.46,7.94,1,1,0,0,0,2,0,10.44,10.44,0,0,0-7-9.91.25.25,0,0,1-.16-.3l.17-.67a.52.52,0,0,0-.21-.54.51.51,0,0,0-.58,0L13,2.6a.5.5,0,0,0-.16.59Z"
            fill="#000000"></path>
          <path
            d="M7.81,22.23a.27.27,0,0,1,.15.29l-.2.87a.52.52,0,0,0,.2.52.5.5,0,0,0,.29.09.48.48,0,0,0,.26-.07l2.5-1.5a.51.51,0,0,0,.19-.65l-1.5-3a.5.5,0,0,0-.94.11L8.51,20a.24.24,0,0,1-.14.17.22.22,0,0,1-.21,0A8.53,8.53,0,0,1,3.25,12.5a1,1,0,0,0-2,0A10.51,10.51,0,0,0,7.81,22.23Z"
            fill="#000000"></path>
          <path
            d="M16.54,11.78a1,1,0,0,0-1,1v.31a.26.26,0,0,1-.2.25,2.61,2.61,0,0,0-.41,5l2.48,1a.6.6,0,0,1,.38.57.61.61,0,0,1-.61.61H14.74a1,1,0,1,0,0,2h.55a.25.25,0,0,1,.25.25V23a1,1,0,0,0,2,0v-.32a.24.24,0,0,1,.2-.24,2.62,2.62,0,0,0,2.05-2.55,2.6,2.6,0,0,0-1.64-2.43l-2.48-1a.61.61,0,0,1,.23-1.17h2.44a1,1,0,0,0,0-2h-.55a.25.25,0,0,1-.25-.25v-.25A1,1,0,0,0,16.54,11.78Z"
            fill="#000000"></path>
          <path
            d="M3.65,7.5a.26.26,0,0,1,.1.2v.55A.76.76,0,0,0,4.5,9H8a.76.76,0,0,0,.75-.75V7.7a.26.26,0,0,1,.1-.2A4.13,4.13,0,0,0,10.5,4.25,4.37,4.37,0,0,0,6.25,0,4.37,4.37,0,0,0,2,4.25,4.13,4.13,0,0,0,3.65,7.5Z"
            fill="#000000"></path>
          <path
            d="M6.17,12.67a.76.76,0,0,0,.75-.75v-.17a.25.25,0,0,1,.25-.25H7.5a.75.75,0,0,0,0-1.5H5a.75.75,0,0,0,0,1.5h.17a.25.25,0,0,1,.25.25v.17A.75.75,0,0,0,6.17,12.67Z"
            fill="#000000"></path>
        </g>
      </g>
    </svg>
  );
}
