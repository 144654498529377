import { useEffect, useMemo, useState } from 'react';
import {
  organizations_teams_types,
  projects_types,
  projects_milestones_types,
  projects_service_types,
  users_types,
} from '@scinet-inc/api';
import { ActorResponse, OrganizationRoles, Project } from 'scinet-types';
import { ProjectCard } from '../../Projects';
import { ActorSubclass, Identity } from '@dfinity/agent';
import { Typography } from '../../Typography';

type Props = {
  orgId: string;
  identity: Identity | undefined;
  user: users_types.User | undefined;
  push: (url: any, options?: any) => Promise<boolean>;
  orgTeamActor: ActorSubclass<organizations_teams_types._SERVICE> | undefined;
  projectsMilestonesActor:
    | ActorSubclass<projects_milestones_types._SERVICE>
    | undefined;
  isAuthorized: (
    userId: string,
    organizationId: string,
    desiredRole: OrganizationRoles,
    orgTeamActor: ActorSubclass<organizations_teams_types._SERVICE>,
  ) => Promise<boolean>;
  getProjectDetails: (
    projectId: string,
    identity?: Identity,
  ) => Promise<{
    project: projects_service_types.ProjectDetails;
    error?: string;
  }>;
  resetFetchAndPush: (projectId: string) => void;
  projectsBaseActor: ActorSubclass<projects_types._SERVICE> | undefined;
  title?: string;
  getIpNft: (projectId: string) => Promise<number | null>;
  filesServiceUrl: string;
};

export const OrgProjects = ({
  orgId,
  identity,
  getProjectDetails,
  projectsMilestonesActor,
  push,
  isAuthorized,
  resetFetchAndPush,
  user,
  orgTeamActor,
  projectsBaseActor,
  title,
  getIpNft,
  filesServiceUrl,
}: Props) => {
  const [orgProjects, setOrgProjects] = useState([]);
  const [projectsFetched, setProjectsFetched] = useState(false);

  const getOrgProjects = async (orgId: string) => {
    projectsBaseActor
      ?.listPublishedProjectsForOrg(orgId)
      .then(async (getResponse: any) => {
        if ('ok' in getResponse) {
          setOrgProjects(getResponse['ok']);
        } else {
          console.error('failure', getResponse);
        }
      });
  };

  useEffect(() => {
    if (orgId && !projectsFetched) {
      getOrgProjects(orgId);
      setProjectsFetched(true);
    }
  }, [orgId, projectsFetched]);

  const memoOrgProjects = useMemo(
    () =>
      orgProjects.map((project: projects_types.ProjectInfo, index: number) => {
        const fetchFullProject = async (
          projectId: string,
        ): Promise<Project> => {
          let details = {};

          const getProjectDetailsRes = await getProjectDetails(
            projectId,
            identity,
          );
          if (!getProjectDetailsRes.error) {
            details = getProjectDetailsRes.project;
          }

          let milestones = {} as projects_milestones_types.Milestones;
          await projectsMilestonesActor
            ?.read(projectId)
            .then(async (getTeamResponse: ActorResponse) => {
              if ('ok' in getTeamResponse) {
                milestones = getTeamResponse['ok'];
              } else {
                console.error('error retrieving project milestones');
              }
            });

          // @ts-ignore
          return { info: project, details, milestones };
        };
        return (
          <ProjectCard
            getIpNft={getIpNft}
            getIsAuthorized={(
              userId: string,
              organizationId: string,
              desiredRole: OrganizationRoles,
            ) =>
              isAuthorized(userId, organizationId, desiredRole, orgTeamActor!)
            }
            deleteProject={() => ({})}
            projectBase={project}
            getProject={fetchFullProject}
            resetFetchAndPush={resetFetchAndPush}
            key={index}
            user={user}
            push={push}
            filesServiceUrl={filesServiceUrl}
            onClick={() => push(`/projects/${project.id}`)}>
            <div className="w-full"></div>
          </ProjectCard>
        );
      }),
    [orgProjects],
  );

  return (
    <div>
      {title && memoOrgProjects.length > 0 && (
        <Typography className="font-lg font-semibold">
          Research Projects
        </Typography>
      )}
      <div className="flex flex-wrap">{memoOrgProjects}</div>
    </div>
  );
};
