import { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { projects_service_types } from '@scinet-inc/api';
import { useIsMobile } from '@scinet-inc/hooks';
import {
  Button,
  Input,
  TextArea,
  Toggle,
  Typography,
  useAuthentication,
  useToast,
  useWizardFormContext,
  WizardNavigation,
  useProjectContext,
} from 'components';
import {
  initializeProjectsActorClient,
  ORGANIZATIONS_TEAMS_CANISTER_ID,
  PROJECTS_INDEX_CANISTER_ID,
} from '../../../../lib';
import { PlusIcon } from '@heroicons/react/24/solid';

const OpportunityStep = () => {
  const { nextStep } = useWizardFormContext();
  const { project, setProject } = useProjectContext();
  const { identity } = useAuthentication();
  const { showToast } = useToast();
  const { isMobile } = useIsMobile();

  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<projects_service_types.UpdateProjectOpportunityRequest>({
    defaultValues: project.details.id
      ? {
          benefits: project.details.benefits,
          applications: project.details.applications,
          opportunities: project.details.opportunities,
          terms: project.details.terms,
          termsAreNegotiable: project.details.termsAreNegotiable,
        }
      : {},
  });

  const { fields, append, remove } = useFieldArray({
    control,
    // @ts-ignore
    name: 'terms' as 'terms',
  });

  const [submitError, setSubmitError] = useState('');

  const projectActorClient = initializeProjectsActorClient(
    PROJECTS_INDEX_CANISTER_ID,
    identity!
  );

  const onSubmit = async (data: any) => {
    if (isDirty) {
      setIsLoading(true);
      try {
        const updateProjectResult = await projectActorClient.update<
          projects_service_types.ProjectsService['updateProjectOpportunity']
        >(
          'projects',
          project.info.id as string,
          (actor: {
            updateProjectOpportunity: (
              arg0: string,
              arg1: string,
              arg2: projects_service_types.UpdateProjectOpportunityRequest,
              arg3: string
            ) => any;
          }) =>
            actor.updateProjectOpportunity(
              project.info.organizationId,
              project.info.id,
              data,
              ORGANIZATIONS_TEAMS_CANISTER_ID
            )
        );

        if ('ok' in updateProjectResult) {
          setProject({ ...project, details: updateProjectResult.ok });
          showToast({
            message: 'Successfully updated project.',
            variant: 'success',
          });
          nextStep();
        } else {
          showToast({
            message: 'Failed to update project.',
            variant: 'error',
          });
          setSubmitError('There was an issue with your request');
          setIsLoading(false);
        }
      } catch (e) {
        console.error('update details error', e);
        setIsLoading(false);
      }
    } else {
      nextStep();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography className="font-semibold">Market Opportunity</Typography>
      <Controller
        name="benefits"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Benefits are required',
          },
        }}
        render={({ field }) => (
          <TextArea
            value={field.value || ''}
            onChange={field.onChange}
            ref={field.ref}
            error={errors.benefits?.message}
            label="Benefits"
            labelClassName="mt-2 ml-1 text-gray-500 text-xs font-semibold"
            placeholder="The benefits of the technology"
            maxLength={2000}
          />
        )}
      />
      <Controller
        name="applications"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Applications is required',
          },
        }}
        render={({ field }) => (
          <TextArea
            value={field.value || ''}
            onChange={field.onChange}
            ref={field.ref}
            error={errors.applications?.message}
            label="Applications"
            labelClassName="mt-2 ml-1 text-gray-500 text-xs font-semibold"
            placeholder="The potential applications of the technology"
            maxLength={2000}
          />
        )}
      />
      <Controller
        name="opportunities"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Opportunities is required',
          },
        }}
        render={({ field }) => (
          <TextArea
            value={field.value || ''}
            onChange={field.onChange}
            ref={field.ref}
            error={errors.opportunities?.message}
            label="Opportunity"
            labelClassName="mt-2 ml-1 text-gray-500 text-xs font-semibold"
            placeholder="How will the project positively impact the world?"
            maxLength={2000}
          />
        )}
      />
      <Typography className="mt-2 ml-1 text-gray-500 text-xs font-semibold">
        Terms
      </Typography>
      <ul className="w-full">
        {fields.map(
          (
            field: Partial<projects_service_types.ProjectDetails>,
            index: number
          ) => (
            <li
              key={index}
              className="flex flex-row w-full justify-between h-8 mb-2">
              <Controller
                name={`terms.${index}`}
                control={control}
                render={({ field }) => (
                  <Input
                    value={field.value || ''}
                    onChange={field.onChange}
                    ref={field.ref}
                    label=""
                    placeholder="Enter a term"
                    maxLength={400}
                  />
                )}
              />
              <Button
                color="tertiary"
                className="mt-1 ml-4 text-red-500"
                onClick={() => remove(index)}>
                Delete
              </Button>
            </li>
          )
        )}
      </ul>
      <Button
        onClick={() => append('')}
        disabled={fields.length > 4}
        className="flex flex-row my-4 mx-auto">
        <PlusIcon className="h-4 w-4 mr-2 mb-[1px]" />
        Add Term
      </Button>
      <Controller
        name="termsAreNegotiable"
        control={control}
        render={({ field }) => (
          <Toggle
            value={field.value || false}
            onChange={field.onChange}
            ref={field.ref}
            error={errors.termsAreNegotiable?.message}
            label="Terms Are Negotiable"
            labelClassName="mt-1 ml-1 text-gray-500 text-xs font-semibold"
          />
        )}
      />
      {submitError && <p className="text-red-500 text-sm">{submitError}</p>}
      <WizardNavigation disablePrimaryButton={isLoading} />
    </form>
  );
};

export default OpportunityStep;
