import { cx } from 'class-variance-authority';

export const PdfIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.82939 31.652C6.87329 31.652 6.12207 30.8868 6.12207 29.9129V6.60853C6.12207 6.53896 6.19036 6.39983 6.19036 6.33027L11.9952 0.417221C12.0635 0.347656 12.1318 0.347656 12.2684 0.347656H26.9513C27.9074 0.347656 28.6587 1.11287 28.6587 2.08679V29.9129C28.6587 30.8868 27.9074 31.652 26.9513 31.652H7.82939Z"
        fill="#F3F4F6"
      />
      <path
        d="M26.951 0.695652C27.7022 0.695652 28.3169 1.32174 28.3169 2.08696V29.913C28.3169 30.6783 27.7022 31.3043 26.951 31.3043H7.82905C7.07783 31.3043 6.4632 30.6783 6.4632 29.913V6.6087L12.2681 0.695652H26.951ZM26.951 0H12.2681C12.0632 0 11.9266 0.0695652 11.79 0.208696L5.98515 6.12174C5.84857 6.26087 5.78027 6.4 5.78027 6.6087V29.913C5.78027 31.0957 6.66808 32 7.82905 32H26.951C28.112 32 28.9998 31.0957 28.9998 29.913V2.08696C28.9998 0.904348 28.112 0 26.951 0Z"
        fill="#001232"
      />
      <path
        d="M6.46256 6.60853C6.32597 6.60853 6.18939 6.53896 6.12109 6.39983C6.12109 6.2607 6.12109 6.12157 6.18939 5.98244L11.6528 0.417221C11.7211 0.347656 11.7894 0.347656 11.926 0.347656C11.9943 0.347656 11.9943 0.347656 12.0626 0.347656C12.1991 0.417221 12.2674 0.556352 12.2674 0.695482V6.2607C12.2674 6.4694 12.1308 6.60853 11.926 6.60853H6.46256Z"
        fill="#E6E6E6"
      />
      <path
        d="M11.9265 0.695652V6.26087H6.46304L11.9265 0.695652ZM11.9265 0C11.7216 0 11.585 0.0695652 11.4484 0.208696L5.98499 5.77391C5.78011 5.98261 5.71182 6.26087 5.8484 6.53913C5.9167 6.81739 6.18987 6.95652 6.46304 6.95652H11.9265C12.3362 6.95652 12.6094 6.67826 12.6094 6.26087V0.695652C12.6094 0.417391 12.4728 0.13913 12.1996 0.0695652C12.1313 0 11.9947 0 11.9265 0Z"
        fill="#001232"
      />
      <path
        d="M20.122 25.739H2.36585C1.61463 25.739 1 25.1129 1 24.3477V15.2347V13.2173L2.36585 14.6086H20.122V25.739Z"
        fill="#F45D5D"
        stroke="#001232"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.12195 14.6088H2.36585C1.61463 14.6088 1 13.9827 1 13.2175C1 12.4523 1.61463 11.8262 2.36585 11.8262H6.12195V14.6088Z"
        fill="#DD3632"
        stroke="#001232"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7808 14.9568V25.3916H2.36619C1.81985 25.3916 1.3418 24.9046 1.3418 24.3481V22.6785C3.73204 22.4698 6.12228 21.9829 8.23936 21.4263C12.2686 20.3829 15.8198 18.3655 18.0052 14.8872H19.7808V14.9568Z"
        fill="#DD3632"
      />
      <path
        d="M5.5752 22.1915V18.0176H7.28251C8.10202 18.0176 8.58007 18.6437 8.58007 19.3393C8.58007 20.035 8.10202 20.6611 7.28251 20.6611H6.18983V22.2611H5.5752V22.1915ZM7.21422 18.5741H6.18983V20.035H7.21422C7.69227 20.035 7.96544 19.7567 7.96544 19.2698C7.96544 18.8524 7.69227 18.5741 7.21422 18.5741Z"
        fill="white"
      />
      <path
        d="M9.2627 22.1915V18.0176H10.7651C12.0627 18.0176 12.8822 18.9219 12.8822 20.1045C12.8822 21.2871 12.0627 22.1915 10.7651 22.1915H9.2627ZM9.87733 21.635H10.6968C11.6529 21.635 12.1993 20.9393 12.1993 20.1045C12.1993 19.2698 11.6529 18.5741 10.6968 18.5741H9.87733V21.635Z"
        fill="white"
      />
      <path
        d="M13.6338 22.1915V18.0176H16.3655V18.5741H14.2484V19.8263H16.3655V20.3828H14.2484V22.2611H13.6338V22.1915Z"
        fill="white"
      />
      <path
        d="M26.9517 0.695801H25.5175V21.7741C25.9273 24.9741 24.1517 28.5914 20.0541 28.5914H6.46387V29.9828C6.46387 30.748 7.0785 31.3741 7.82972 31.3741H26.9517C27.7029 31.3741 28.3175 30.748 28.3175 29.9828V2.08711C28.3175 1.32189 27.7029 0.695801 26.9517 0.695801Z"
        fill="#E6E6E6"
      />
    </svg>
  );
};
