import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';
import cx from 'classnames';
import { get } from 'local-storage';
import { users_types } from '@scinet-inc/api';
import { CameraIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline';

import {
  Button,
  Input,
  Select,
  Typography,
  useAuthentication,
} from 'components';
import { useDropzone } from 'react-dropzone';
import { countries } from '@scinet-inc/constants';
import { useUserContext } from '..';
import { TrackEventName, useAnalytics } from '../../lib/hooks/useAnalytics';
import BlockedCountry from './BlockedCountry';

const logError = (errorResponse: any) => {
  // eslint-disable-next-line no-console
  console.error('failure', errorResponse);
};

const options = countries.map((country: string) => ({
  value: country,
  label: country,
}));

const OFACCountries = [
  'Cuba',
  'Iran (Islamic Republic of)',
  'Russian Federation',
  'Syrian Arab Republic',
  "Democratic People's Republic of Korea",
  'Sudan',
  'Venezuela (Bolivarian Republic of)',
];

function SignUpForm() {
  const {
    actor: userActor,
    updateUser,
    setAvatar,
    avatarBase64,
  } = useUserContext();
  const { login } = useAuthentication();
  const { trackEvent } = useAnalytics();

  const [isLoading, setIsLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState<string | null>(null);

  const [fileSizeError, setFileSizeError] = useState(false);
  const { push } = useRouter();

  const userForm = useForm<Partial<users_types.CreateNewUserRequest>>();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = userForm;

  const { country } = getValues();

  const handleRedirect = () => {
    // if another page disables redirect, then don't redirect to 'get-started'

    const shouldNotRedirect = get('shouldNotDirect');
    if (shouldNotRedirect) {
      return;
    }
    push('/get-started');
  };

  const onSubmit = (data: any) => {
    setSubmissionError(null);

    setIsLoading(true);
    userActor?.create(data).then(async (createResponse: any) => {
      if ('ok' in createResponse) {
        trackEvent(TrackEventName.CREATE_ACCOUNT, {
          country: data.country,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
        });
        updateUser(createResponse.ok);
        handleRedirect();
      } else {
        logError(createResponse);
        setSubmissionError('Try a different email');
        setIsLoading(false);
      }
    });
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setFileSizeError(false);
      setAvatar(acceptedFiles[0]);
    },
    [setFileSizeError, setAvatar]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (country && OFACCountries.includes(country)) {
    return <BlockedCountry country={country} />;
  }

  return (
    <div className="flex w-full h-full flex-col-reverse lg:flex-row">
      <div className="w-full max-w-full min-h-[75vh] lg:max-w-[50%] lg:min-h-[100vh] lg:pt-16 lg:px-16">
        <div className="p-4 pt-0 w-full lg:max-w-96">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col align-center w-full">
              <Typography className="font-semibold mx-auto pt-2 text-xl lg:text-2xl">
                Create Your Free Account
              </Typography>
              <div className="flex flex-row m-auto mb-2" onClick={login}>
                <Typography className="text-sm">
                  Already have an account?
                </Typography>
                <Typography className="text-sm ml-2 text-purple-600">
                  Sign In
                </Typography>
              </div>
              <div>
                <div className="w-full cursor-default">
                  <div className="flex flex-col-reverse w-full  h-auto pb-2 lg:flex-row ">
                    <div {...getRootProps()} className="m-auto">
                      {/** @ts-ignore */}
                      <input {...getInputProps()} />
                      <div
                        className={cx(
                          'flex flex-col bg-gray-200 rounded-full w-16 h-16 justify-center items-center cursor-pointer',
                          {
                            'border-[#2196f3] bg-purple-200': isDragActive,
                          }
                        )}>
                        {isDragActive ? (
                          <CloudArrowUpIcon className="text-gray-400 flex flex-col bg-transparent rounded-full w-8 h-8 justify-center items-center" />
                        ) : avatarBase64 ? (
                          // eslint-disable-next-line @next/next/no-img-element
                          <img
                            height="160px"
                            width="160px"
                            src={avatarBase64}
                            alt="profile-pic"
                            className={cx(
                              'flex flex-col bg-gray-200 rounded-full w-16 h-16 justify-center items-center',
                              {
                                'border-[#2196f3] bg-purple-200': isDragActive,
                              }
                            )}
                          />
                        ) : (
                          <CameraIcon className="text-gray-400 bg-transparent w-8 h-8 rounded-full position-relative " />
                        )}
                      </div>
                    </div>
                  </div>
                  {fileSizeError && (
                    <div className="w-full flex">
                      <p className="text-red-600 m-auto">
                        File must be under 50kb
                      </p>
                    </div>
                  )}
                </div>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'First Name is required',
                    },
                    maxLength: { value: 20, message: 'First Name is too long' },
                  }}
                  render={({ field }) => (
                    <Input
                      value={field.value || ''}
                      onChange={field.onChange}
                      ref={field.ref}
                      label="First Name"
                      error={
                        errors.firstName?.message || errors.firstName?.type
                      }
                      maxLength={20}
                    />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: { value: true, message: 'Last Name is required' },
                    maxLength: { value: 30, message: 'Last Name is too long' },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      value={field.value || ''}
                      onChange={field.onChange}
                      ref={field.ref}
                      label="Last Name"
                      error={errors.lastName?.message || errors.lastName?.type}
                      maxLength={30}
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: { value: true, message: 'Email is required' },
                    maxLength: { value: 50, message: 'Email is too long' },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Email does not match email format',
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      value={field.value || ''}
                      onChange={field.onChange}
                      ref={field.ref}
                      label="Email"
                      type="email"
                      error={errors.email?.message || errors.email?.type}
                      maxLength={50}
                    />
                  )}
                />
                <Controller
                  name="country"
                  control={control}
                  rules={{
                    required: { value: true, message: 'Country is required' },
                  }}
                  render={({ field }) => (
                    <Select
                      value={field.value || ''}
                      onChange={field.onChange}
                      ref={field.ref}
                      error={errors.country?.message}
                      label="Select Country"
                      options={options}
                      className="w-full m-auto"
                    />
                  )}
                />
              </div>
            </div>
            {submissionError && (
              <div className="w-full text-center">
                <Typography className="m-auto text-red-600 ">
                  {submissionError}
                </Typography>
              </div>
            )}
            <div className="w-full">
              <Button
                color={isLoading ? 'tertiary' : 'secondary'}
                className="w-full mb-2 mt-4"
                onClick={() => reset()}>
                {'Reset'}
              </Button>
              <Button
                type="submit"
                className="w-full"
                color={isLoading ? 'secondary' : 'primary'}
                loading={isLoading ? true : false}>
                {isLoading ? 'Saving...' : 'Create'}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="w-[100vw] h-[300px] bg-cover bg-no-repeat bg-[url(/mobile-hero.png)] lg:bg-[url(/desktop-hero.png)] lg:h-[100vh] lg:max-w-[50%]" />
    </div>
  );
}

export default SignUpForm;
