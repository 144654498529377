import { cx } from 'class-variance-authority';

type Props = {
  className?: string;
};

export const Spinner = ({ className }: Props) => {
  return (
    <div className="flex items-center justify-center">
      <div
        className={cx(
          'inline-block animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-purple-300 motion-reduce:animate-[spin_1.5s_linear_infinite]',
          className ? className : 'h-8 w-8',
        )}
        role="status">
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    </div>
  );
};
