import { useCallback, useState } from 'react';
import cx from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { convertToBase64 } from '@scinet-inc/utils';
import { useDropzone } from 'react-dropzone';
import { Typography, Spinner } from '../../';
import { FolderIcon } from '../../Icons';

type Props = {
  addFile: (file: any) => void;
  useFiles: () => { isLoading: boolean; client: any };
};

export const IpFileUploader = ({ addFile, useFiles }: Props) => {
  const { isLoading, client } = useFiles();
  const [fileSizeError, setFileSizeError] = useState(false);
  const [stepError, setStepError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any[]) => {
      if (isUploading) return;
      if (client) {
        // TODO: Better handling the upload process
        const convertedFile = await convertToBase64(acceptedFiles[0]);
        const fileSize = acceptedFiles[0].size;
        if (fileSize > 120000000) {
          setFileSizeError(true);
        } else {
          setIsUploading(true);
          const file = {
            filename: acceptedFiles[0].name,
            data: convertedFile,
          };

          await client.upload(acceptedFiles[0]);
          addFile(file);
          setIsUploading(false);
        }
      } else {
        setStepError('There was an error uploading the file');
      }
    },
    [isLoading, client],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div className="p-4">
      <div {...getRootProps()}>
        {/** @ts-ignore */}
        <input {...getInputProps()} />
        <div
          className={cx(
            'w-full bg-rhino-50 border-rhino-200 border-dashed border-2 border-rhino-200 rounded-md p-4',
            {
              'bg-electric-violet-50 border-primary': isDragActive,
            },
          )}>
          <div className="flex text-center flex-col items-center justify-center">
            {isUploading ? <Spinner /> : <FolderIcon />}
            <div className="mt-2">
              <span className="text-md font-medium text-rhino-900">
                Choose files to upload...
              </span>
              <div className="text-xs text-rhino-600">
                PDF, DOC, DOCX, Max 120MB
              </div>
            </div>
          </div>
        </div>
      </div>
      {stepError && (
        <div className="flex items-center gap-2 py-2 font-bold  text-red-500">
          <XMarkIcon
            className="h-4 w-4 cursor-pointer"
            onClick={() => setStepError(null)}
          />
          <Typography className="text-xs">{stepError}</Typography>
        </div>
      )}
      {fileSizeError && (
        <div className="flex items-center gap-2 py-2">
          <XMarkIcon
            className="h-4 w-4 text-red-500 cursor-pointer"
            onClick={() => setFileSizeError(false)}
          />
          <span className="text-xs text-red-500">
            File size should be less than 120MB
          </span>
        </div>
      )}
    </div>
  );
};
