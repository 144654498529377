import { useEffect, useState } from 'react';
import { users_types } from '@scinet-inc/api';
import { ActorSubclass } from '@dfinity/agent';
import cx from 'classnames';
import { Spinner } from '../Spinner';
import { Typography } from '../Typography';
import { Avatar } from '../Avatar';
import { useAvatar } from '../hooks';

type Props = {
  memberId: string;
  alignRight?: boolean;
  userActor?: ActorSubclass<users_types._SERVICE> | undefined;
  exposeEmail?: boolean;
  filesServiceUrl: string;
};

export const TeamMember = ({
  memberId,
  alignRight,
  userActor,
  filesServiceUrl,
  exposeEmail = false,
}: Props) => {
  const { avatar } = useAvatar(filesServiceUrl, memberId);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<users_types.User | null>();

  const getUser = async (id: string) => {
    setIsLoading(true);
    userActor?.read(id).then((result: any) => {
      if ('ok' in result) {
        setUser(result.ok);
      } else {
        console.log('**get user error', result); //TODO: handle error and show it in ui
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (memberId && !user) {
      getUser(memberId);
    }
  }, [memberId]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div
      className={cx('flex flex-row', {
        ['flex-row-reverse']: alignRight,
      })}>
      <Avatar source={avatar} className="h-12 w-12" />
      <div className="mx-2 flex flex-col">
        <div
          className={cx({
            ['flex flex-row justify-end']: alignRight,
          })}>
          <Typography className="text-md font-bold text-left">
            {user ? `${user?.firstName} ${user?.lastName}` : ''}
          </Typography>
        </div>
        {exposeEmail && user?.email && (
          <div
            className={cx({
              ['flex flex-row justify-end']: alignRight,
            })}>
            <Typography className="text-sm text-gray-500 text-left">
              {user.email}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
