import { cva, VariantProps } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { XMarkIcon } from '@heroicons/react/24/solid';

const chipStyles = cva(
  'font-sans flex items-center text-xs font-medium rounded-full px-2 py-1',
  {
    variants: {
      intent: {
        primary:
          'bg-electric-violet-100 text-primary hover:bg-electric-violet-200',
        secondary: 'bg-primary text-white hover:bg-electric-violet-200',
        tertiary: 'bg-tertiary text-black hover:bg-electric-violet-200',
        error: 'bg-red-500 text-white hover:bg-red-600',
        success: 'bg-green-500 text-white hover:bg-green-600',
      },
    },
    defaultVariants: {
      intent: 'primary',
    },
  },
);

type TypographyProps = VariantProps<typeof chipStyles> & {
  children?: any;
  className?: string;
  onDelete?: () => void;
};

export const Chip = ({
  intent = 'primary',
  children,
  className,
  onDelete,
}: TypographyProps) => {
  const chip = twMerge(
    chipStyles({
      intent,
      className,
    }),
  );

  return (
    <div className={chip}>
      {children}
      {onDelete && (
        <div className="cursor-pointer" onClick={onDelete}>
          <XMarkIcon className="h-4 w-4" />
        </div>
      )}
    </div>
  );
};
