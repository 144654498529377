import { useRouter } from 'next/router';
import { Spinner, Typography, useProjectContext, WizardForm } from 'components';
import { dateFactory } from '@scinet-inc/utils';
import {
  InformationStep,
  SummaryStep,
  TimelineStep,
  TeamStep,
  OpportunityStep,
} from './FormSteps';

import { useOrganizationsTeamsContext, useUserContext } from '../..';
import { getBigIntTime } from '../../../lib';

const renderSteps = () => {
  return [
    <InformationStep key="information" />,
    <SummaryStep key="summary" />,
    <OpportunityStep key="opportunity" />,
    <TimelineStep key="timeline" />,
    <TeamStep key="team" />,
  ];
};

const steps = ['Information', 'Summary', 'Opportunity', 'Timeline', 'Team'];

export default function ProjectWizardForm() {
  const { route } = useRouter();
  const { user } = useUserContext();
  const { isLoading, project } = useProjectContext();
  const { organizationId } = useOrganizationsTeamsContext();

  // TODO: add authorization here
  // if (profile?.id && project?.info.id && profile?.id !== project.owner.id) {
  //   push('/403');
  // }
  // TODO: ensure someone is logged in before they can create or edit

  let content;
  if (
    isLoading ||
    (!project.info.id && route === '/projects/edit') ||
    !user?.id ||
    !organizationId
  ) {
    content = (
      <div className="w-full h-full flex flex-row justify-center align-middle items-center pt-5">
        <Spinner />
      </div>
    );
  } else {
    content = (
      <>
        <div className="flex flex-row justify-between items-center px-4 py-2 md:px-0">
          <Typography
            className="text-black font-sans text-xl font-bold"
            component="h2">
            {route.includes('edit') ? 'Edit Project' : 'Create Project'}
          </Typography>
          {project?.info?.id && (
            <Typography className="text-rhino-600 font-sans text-sm ml-4">
              Last saved: {getBigIntTime(project.info.updatedAt)}
            </Typography>
          )}
        </div>
        <div className="rounded-lg bg-white p-4 md:p-8">
          <WizardForm
            steps={steps}
            id="project"
            disableSteps={!project || !project.info.createdAt}>
            {renderSteps()}
          </WizardForm>
        </div>
      </>
    );
  }
  return <div className="flex flex-col w-[100%] px-4">{content}</div>;
}
