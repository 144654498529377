import { createContext, useContext, useEffect, useState } from 'react';
import { applyTheme, baseTheme, darkTheme } from 'tailwind-config';

const ThemeContext = createContext({
  theme: 'light',
  toggleTheme: () => {},
});

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export const ThemeProvider = ({ children }: any) => {
  const [theme, setTheme] = useState(() => {
    if (typeof window !== 'undefined') {
      const localTheme = localStorage.getItem('theme');
      return localTheme ? localTheme : 'light';
    }
    return 'light';
  });

  useEffect(() => {
    applyTheme(theme === 'light' ? baseTheme : darkTheme);
  }, []);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
    window.localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light');
    applyTheme(theme === 'light' ? darkTheme : baseTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
