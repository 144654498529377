import { convertToBase64 } from '@scinet-inc/utils';
import { useEffect, useState } from 'react';

export const getAvatarRequestPath = (fileServiceUrl: string, id?: string) =>
  `${fileServiceUrl}/avatar/${id}`;

export const useAvatar = (fileServiceUrl: string, userId: string) => {
  const [init, setInit] = useState(false);
  const [avatar, setAvatar] = useState<string>();

  useEffect(() => {
    const getAvatar = async () => {
      if (!avatar) {
        const resp = await fetch(getAvatarRequestPath(fileServiceUrl, userId));
        if (resp.status === 200) {
          const blob = await resp.blob();
          const encodedImage = await convertToBase64(blob);
          setAvatar(encodedImage);
        }
      }
    };
    if (!avatar && !init) {
      getAvatar();
      setInit(true);
    }

  }, [avatar, fileServiceUrl, userId]);

  return {
    avatar,
  };
};
