import { white_lists_types } from '@scinet-inc/api';
import { useState } from 'react';
import { useAppContext } from '../../components/AppContext';

export const useWhiteLists = (projectId: string) => {
  const { whiteListsActor } = useAppContext();
  const [whiteList, setWhiteList] = useState<white_lists_types.WhiteListNode>();

  const getWhiteList = async () => {
    try {
      await whiteListsActor?.get(projectId).then(async (res: any) => {
        if (res.ok) {
          setWhiteList(res.ok);
        }
      });
    } catch (error) {
      console.error('Error fetching white lists:', error);
    }
  };

  return { whiteList, getWhiteList };
};
