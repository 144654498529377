import { cx } from 'class-variance-authority';

type Props = {
  className?: string;
};

export const ProjectsIcon = ({ className }: Props) => {
  return (
    <svg
      className={cx('h-8 text-white', className)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 4.5V19.5H14.9353C15.5939 19.3804 16.5666 19.0441 17.3641 18.4299C18.1618 17.8157 18.75 16.957 18.75 15.75V4.5H7.5ZM6 3.9375C6 3.41973 6.41973 3 6.9375 3H19.3125C19.8303 3 20.25 3.41973 20.25 3.9375V15.75C20.25 17.5331 19.3481 18.7953 18.2794 19.6184C17.2294 20.427 15.9866 20.8431 15.1405 20.9869C15.0859 20.9962 15.0338 21 14.9845 21H6.9375C6.41973 21 6 20.5803 6 20.0625V3.9375Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 16.5H19.5V15H15.1875C14.6697 15 14.25 15.4197 14.25 15.9375V20.25H15.75V16.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 7.875C3.75 7.46079 4.08579 7.125 4.5 7.125H9C9.41421 7.125 9.75 7.46079 9.75 7.875C9.75 8.28921 9.41421 8.625 9 8.625H4.5C4.08579 8.625 3.75 8.28921 3.75 7.875Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 12C3.75 11.5858 4.08579 11.25 4.5 11.25H9C9.41421 11.25 9.75 11.5858 9.75 12C9.75 12.4142 9.41421 12.75 9 12.75H4.5C4.08579 12.75 3.75 12.4142 3.75 12Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 16.125C3.75 15.7108 4.08579 15.375 4.5 15.375H9C9.41421 15.375 9.75 15.7108 9.75 16.125C9.75 16.5392 9.41421 16.875 9 16.875H4.5C4.08579 16.875 3.75 16.5392 3.75 16.125Z"
        fill="white"
      />
    </svg>
  );
};
