import ProjectTabPanel from './ProjectTabPanel';
import { Milestones } from './ProjectTabsContent/Milestones';
import Technology from './ProjectTabsContent/Technology';
import Overview from './ProjectTabsContent/Overview';
import Opportunity from './ProjectTabsContent/Opportunity';
import { Project } from 'scinet-types';

const ProjecTabsContent = ({
  project,
  selected,
}: {
  project: Project;
  selected: number;
}) => {
  return (
    <>
      <ProjectTabPanel value={selected} index={0}>
        <Overview project={project} />
      </ProjectTabPanel>
      <ProjectTabPanel value={selected} index={1}>
        <Opportunity project={project} />
      </ProjectTabPanel>
      <ProjectTabPanel value={selected} index={2}>
        <Technology project={project} />
      </ProjectTabPanel>
      <ProjectTabPanel value={selected} index={3}>
        <Milestones project={project} />
      </ProjectTabPanel>
    </>
  );
};

export default ProjecTabsContent;
