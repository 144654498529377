import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ActorSubclass } from '@dfinity/agent';
import { organizations_teams_types } from '@scinet-inc/api';
import {
  IpNftPage,
  Spinner,
  Typography,
  useAuthentication,
  useProjectContext,
} from 'components';
import {
  fetchFullOrg,
  HOST,
  isAuthorized,
  OrganizationRoles,
  useIPFiles,
} from '../../../lib';
import {
  useAppContext,
  useIPNFTContext,
  useUserContext,
} from '../../../components';

export default function ProjectAssets() {
  const { ipNftTokenId, ipNftError, ipNftIsLoading } = useIPNFTContext();
  const { project } = useProjectContext();
  const { orgActor, orgTeamActor, whiteListsActor } = useAppContext();
  const { identity } = useAuthentication();
  const { user } = useUserContext();
  const { push } = useRouter();

  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isOnWhiteList, setIsOnWhiteList] = useState(false);

  const [isAuthorizedCalled, setIsAuthorizedCalled] = useState(false);

  const checkIfAuthorized = async (
    userId: string,
    orgId: string,
    orgTeamActor: ActorSubclass<organizations_teams_types._SERVICE>
  ) => {
    const authorized = await isAuthorized(
      userId,
      orgId,
      OrganizationRoles.ADMIN,
      orgTeamActor
    );

    if (authorized) {
      setIsAdmin(true);
    }
    setIsAuthorizedCalled(true);
  };

  const checkIsOnWhiteList = async (userId: string, projectId: string) => {
    await whiteListsActor
      ?.isCallerOnWhiteList(projectId, userId)
      .then(async (getResponse: any) => {
        if ('ok' in getResponse) {
          setIsOnWhiteList(true);
        } else {
          // console.log('failure', getResponse);
        }
      });
  };

  useEffect(() => {
    if (
      !isAuthorizedCalled &&
      user &&
      orgTeamActor &&
      project.info.organizationId
    ) {
      checkIfAuthorized(user.id, project.info.organizationId, orgTeamActor);
      checkIsOnWhiteList(user.id, project.info.id);
    }
  }, [isAuthorizedCalled, orgTeamActor, project.info.organizationId, user]);

  const fetchFullOrgFunction =
    orgActor && orgTeamActor
      ? (orgId: string) => fetchFullOrg(orgId, orgActor, orgTeamActor, identity)
      : null;

  return (
    <>
      {ipNftIsLoading ? (
        <Spinner />
      ) : (
        <IpNftPage
          HOST={HOST}
          ipNftTokenId={ipNftTokenId}
          project={project}
          fetchFullOrg={(orgId) => fetchFullOrgFunction!(orgId)}
          push={push}
          isAdmin={isAdmin}
          isOnWhiteList={isOnWhiteList}
          useIPFiles={useIPFiles}
        />
      )}

      {ipNftError && (
        <Typography className="text-red-600">{ipNftError}</Typography>
      )}
    </>
  );
}
