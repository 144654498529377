import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { MdApproval } from 'react-icons/md';
import { Typography } from '../../Typography';
import { Button } from '../../Button';
import { Project } from 'scinet-types';

type Props = {
  project: Project;
};

export default function ProjectStatus({ project }: Props) {
  return (
    <div className="border border-rhino-200 mt-4 rounded-lg p-4 bg-white w-auto">
      <Typography className="text-xl font-bold mb-4">Patents</Typography>
      <div>
        <div className="flex flex-row">
          <MdApproval className="mr-4 mt-6 w-6 h-6 " color="gray" />
          <div className="flex flex-col mt-4 justify-center">
            <Typography className="text-l font-bold ">
              {project?.info?.patentStatus !== ''
                ? project?.info?.patentStatus
                : 'Patent not filed'}
            </Typography>
            <Typography className="text-sm text-gray-500">
              Patent Status
            </Typography>
          </div>
        </div>
        {project?.info?.patentLink && (
          <div className="flex flex-row">
            <GlobeAltIcon className="w-6 mr-2 mt-2" color="gray" />
            <div className="flex flex-col mt-4 justify-center">
              <Button
                color="tertiary"
                onClick={() =>
                  window.open(project?.info?.patentLink, '_blank')
                }>
                {project?.info?.patentLink}
              </Button>
              <Typography className="text-sm text-gray-500 ml-2.5">
                Patent Link
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
