import { Project } from 'scinet-types';
import { Typography } from '../../../Typography';

const Opportunity = ({ project }: { project: Project }) => {
  return (
    <div className="flex flex-col my-4">
      <Typography className="text-lg font-bold mb-2">Opportunity</Typography>
      <Typography className="text-md mb-4">
        {project?.details?.opportunities}
      </Typography>
      <Typography className="text-lg font-bold mb-2">Benefits</Typography>
      <Typography className="text-md mb-4">
        {project?.details?.benefits}
      </Typography>
      <Typography className="text-lg font-bold mb-2">Applications</Typography>
      <Typography className="text-md mb-4">
        {project?.details?.applications}
      </Typography>
      {project.details.terms.length > 0 && (
        <div>
          <div className="flex flex-row">
            <Typography className="text-lg font-bold mb-2">Terms</Typography>
            <Typography className="text-md mb-2 pl-1 pt-0.5 italic">
              {project.details.termsAreNegotiable
                ? 'Negotiable'
                : 'Non-negotiable'}
            </Typography>
          </div>
          <ul>
            {project.details.terms.map((term: string, i: number) => (
              <li key={i}>
                <Typography className="text-md mb-2">{term}</Typography>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Opportunity;
