import { ActorSubclass } from '@dfinity/agent';
import {
  license_nft_v1_types,
  license_nft_metadata_v1_types,
} from '@scinet-inc/api';
import { ActorResponse } from '..';

export const getLicenseAndMetadata = async (
  licenseId: string,
  licenseNFTActor?: ActorSubclass<license_nft_v1_types._SERVICE>,
  licenseNFTMetadataActor?: ActorSubclass<license_nft_metadata_v1_types._SERVICE>
) => {
  if (!licenseNFTActor || !licenseNFTMetadataActor) {
    return { error: 'Actors not found', metadata: null };
  }
  return await licenseNFTActor
    ?.getByLicenseId(licenseId)
    .then(async (nftRes: any) => {
      if (nftRes.ok) {
        return await licenseNFTMetadataActor
          ?.get(licenseId)
          .then(async (metaRes: ActorResponse) => {
            if (metaRes.ok) {
              return { error: null, metadata: metaRes.ok };
            }
            return { error: metaRes.err, metadata: null };
          });
      } else {
        return { error: nftRes.err, metadata: null };
      }
    });
};
