import { useIsMobile } from '@scinet-inc/hooks';
import cx from 'classnames';

function a11yProps(index: number) {
  return {
    id: `project-details-tab-${index}`,
    'aria-controls': `project-details-tabpanel-${index}`,
  };
}

const tabs = ['Overview', 'Opportunity', 'Technology', 'Milestones'];

const ProjectTabs = ({
  onChange,
  selected,
}: {
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  selected: number;
}) => {
  const { isMobile } = useIsMobile();

  return (
    <div
      className={cx('flex flex-row', {
        ['bg-white py-3 top-14 left-0 fixed z-0 justify-between w-full px-2']:
          isMobile,
      })}>
      {tabs.map((tab, index) => (
        <div
          onClick={(e) => onChange(e, index)}
          key={`${tab}-${index}`}
          className={cx('text-gray-600 cursor-pointer lg:mr-4', {
            'text-purple-600 border-b-2 border-purple-600': selected === index,
            'text-xs mr-0': isMobile,
          })}
          {...a11yProps(index)}>
          {tab}
        </div>
      ))}
    </div>
  );
};

export default ProjectTabs;
