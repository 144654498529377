import React from 'react';

export default function PropertyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="48px"
      height="48px">
      <g transform="matrix(2,0,0,2,0,0)">
        <g>
          <path
            d="M14,16.14l3.82-4.77a11.46,11.46,0,0,0,.11-1.56V3.4a1.72,1.72,0,0,0-1-1.6h0a20.93,20.93,0,0,0-15.82,0A1.75,1.75,0,0,0,0,3.4V9.81A10.57,10.57,0,0,0,6.8,19.63l1.88.63a.87.87,0,0,0,.32.06,1,1,0,0,0,.33-.06l1.84-.64a10,10,0,0,0,2.41-1.31A2.28,2.28,0,0,1,14,16.14ZM3.78,9.49V5.16a1,1,0,0,1,.73-1,16.33,16.33,0,0,1,4.26-.56h.37A16.18,16.18,0,0,1,12.48,4L4.33,12.23A7.2,7.2,0,0,1,3.78,9.49Zm5.75,6.68-.22.08a1,1,0,0,1-.72,0l-.21-.09a7,7,0,0,1-2.3-1.43l8-8.15V9.49A7.16,7.16,0,0,1,9.53,16.17Z"
            fill="#000000"></path>
          <path
            d="M24,16.46s0-.05,0-.07a.47.47,0,0,0,0-.17.36.36,0,0,0,0-.09.64.64,0,0,0-.08-.14.81.81,0,0,0-.16-.16l-.09-.06a.44.44,0,0,0-.16-.06.78.78,0,0,0-.22,0H21.26V10.72a.45.45,0,0,0-.08-.26A.45.45,0,0,0,21,10.3h-.34l0,0h0l0,0h0l0,0h0l0,0L17.65,14l-2.5,3.13a.42.42,0,0,0-.09.14,1,1,0,0,0-.07.15.83.83,0,0,0,0,.22s0,0,0,.06a.68.68,0,0,0,.07.27l.05.1a1.19,1.19,0,0,0,.19.2.78.78,0,0,0,.47.15h1.92v5a.22.22,0,0,0,0,.08s0,0,0,0a.35.35,0,0,0,.07.12h0a.38.38,0,0,0,.16.12l0,0,.14,0a.48.48,0,0,0,.24-.06.38.38,0,0,0,.11-.11L23.78,17A.83.83,0,0,0,24,16.46Z"
            fill="#000000"></path>
        </g>
      </g>
    </svg>
  );
}
